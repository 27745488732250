import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-access-booking',
  templateUrl: './access-booking.component.html',
  styleUrls: ['./access-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessBookingComponent {
  @Input() inputs: any;
  @Input() form: FormGroup;
  @Input() isB2C = false;
  @Output() retrieveButton = new EventEmitter<any>();

  errors = {
    expedient: false,
  };

  submitForm() {
    this.retrieveButton.emit();
  }
}
