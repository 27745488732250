/**
 * Client style configuration.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientStyleConfigModel } from 'src/app/shared/models/client-style-config.model';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../authentication/authentication.service';
import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  companyData$ = new BehaviorSubject<ClientStyleConfigModel>(null!);

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private storageService: StorageService
  ) {
    this.auth.getConnectedUserToken$().subscribe((token) => {
      this.getCompanyData();
    });
  }

  getCompanyData() {
    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    if (
      this.auth.getLoggedInStatus() &&
      this.auth.isJWTTokenNotExpired()
      // && !this.storageService.getItem('currentUser')
    ) {
      this.getUserConnectedStaticData(headers);
    } else if (
      !this.getStaticFromStorage() &&
      !this.storageService.getItem('currentUser')
    ) {
      this.getUserPublicStaticData(headers);
    } else {
      this.checkDifferentData(this.getStaticFromStorage());
    }
  }

  // Usuario conectado
  getUserConnectedStaticData(headers) {
    this.http
      // .get<any>('assets/mocks/user-ib_call.json')
      .get<any>(`${environment.api_backend_url}/api/user/data/static`, {
        headers,
      })
      .subscribe((res) => {
        this.setStaticToStorage(res);
        this.checkDifferentData(res);
      });
  }

  // Usuario no conectado
  getUserPublicStaticData(headers?) {
    this.http
      .get<any>(`${environment.api_backend_url}/api/static/user/data`, {
        headers,
      })
      .subscribe((res) => this.checkDifferentData(res));
  }

  checkDifferentData(staticData) {
    if (
      !staticData ||
      !this.companyData$.value ||
      !staticData['favicon'] ||
      !staticData['logo'] ||
      !staticData['logo_png-name'] ||
      !this.companyData$.value['favicon'] ||
      !this.companyData$.value['logo'] ||
      !this.companyData$.value['logo_png'] ||
      staticData['favicon'] !== this.companyData$.value['favicon'] ||
      staticData['logo'] !== this.companyData$.value['logo'] ||
      staticData['logo_png'] !== this.companyData$.value['logo_png']
    ) {
      this.companyData$.next(staticData);
    }
  }

  getStaticFromStorage() {
    if (this.storageService.getItem('client_static_data')) {
      return JSON.parse(this.storageService.getItem('client_static_data')!);
    }
  }

  setStaticToStorage(data) {
    this.storageService.setItem('client_static_data', JSON.stringify(data));
  }
}
