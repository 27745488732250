import {
  Pipe,
  PipeTransform,
  NgZone,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateAgo',
  pure: false,
})
export class DateAgoPipe implements PipeTransform, OnDestroy {
  private timer!: number | null;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}
  transform(value: string) {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      yesterday.getDate() +
        '' +
        yesterday.getMonth() +
        '' +
        yesterday.getFullYear() ===
      d.getDate() + '' + d.getMonth() + '' + d.getFullYear()
    ) {
      return this.translateService.instant('dateAgo.yesterday');
    }

    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds)
      ? 1000
      : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    // let days = Math.round(Math.abs(hours / 24));
    // let months = Math.round(Math.abs(days / 30.416));
    // let years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds < 60) {
      return this.translateService.instant('dateAgo.seconds', { seconds });
    } else if (minutes < 60) {
      return this.translateService.instant('dateAgo.minutes', { minutes });
    } else if (hours < 24) {
      return this.translateService.instant('dateAgo.hours', { hours });
    } else {
      // @ts-ignore
      return this.datePipe.transform(d, 'd MMM', 'UTC').replace('.', '');
    }
  }
  ngOnDestroy(): void {
    this.removeTimer();
  }
  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }
  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 1 secs
      return 1;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
