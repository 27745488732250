import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { readableStreamLikeToAsyncGenerator } from 'rxjs/internal/util/isReadableStreamLike';

@Pipe({
  name: 'divideDecimal',
})
export class DivideDecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: string, currency?: string, args?: string): string {
    let response = value;
    // if (currency && currency === 'COP') {
    //   this.locale = 'en-en';
    // }
    if (value) {
      const separator = getLocaleNumberSymbol(
        this.locale,
        NumberSymbol.CurrencyDecimal
      );
      let split = value.split(separator);
      const sizeSymbol =
        split[0].length <= 3
          ? 'extraBigSize'
          : split[0].length === 5
            ? 'bigSize'
            : 'mediumSize';
      if (split.length > 1) {
        response = value.replace(
          separator + split[1],
          `<small  class="${sizeSymbol}" >` +
            (args ?? separator) +
            split[1] +
            '</small>'
        );
      }
    }
    return response;
  }
}
