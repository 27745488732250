import { Component, EventEmitter, Output, Input } from '@angular/core';
import { RouterService } from 'src/app/core/services/router/router.service';

@Component({
  selector: 'app-general-error-text',
  templateUrl: './general-error-text.component.html',
  styleUrls: ['./general-error-text.component.scss'],
})
export class GeneralErrorTextComponent {
  @Input() showTryAgainButton = false;
  @Output() tryAgain = new EventEmitter<boolean>();

  constructor(private routerService: RouterService) {}

  emitTryAgain() {
    this.tryAgain.emit(true);
  }

  goBack() {
    this.routerService.navigateToHome();
  }
}
