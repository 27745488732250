import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-errors-fields',
  templateUrl: './errors-fields.component.html',
  styleUrls: ['./errors-fields.component.scss'],
})
export class ErrorsFieldsComponent {
  @Input() field: HTMLInputElement;
  @Input() messageError: string;
  errorsM: any = {};
  @Input('errors')
  set errors(value: boolean) {
    this.errorsM = value;
    if (this.field) {
      this.checkErrors();
    }
  }
  showM = false;
  @Input('show')
  set show(value: boolean) {
    this.showM = value;
    if (this.field) {
      this.checkErrors();
    }
  }

  /**
   * Return how lenth have an object
   * @param obj object
   */
  sizeOfObject(obj): number {
    let size = 0;
    let key: any;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }

  /**
   * Check how many errors there's in a input
   * and add a class in the field
   */
  checkErrors(): number {
    const length: number = this.sizeOfObject(this.errorsM);
    if (this.showM && length && this.field?.classList?.length > 0) {
      this.field.classList.add('error');
    } else if (this.field?.classList?.contains('error')) {
      this.field.classList.remove('error');
    }
    return length;
  }
}
