<div
  class="{{ inputs?.class }}"
  [ngClass]="{
    hide:
      (parentForm.get(inputs.select.name)?.disabled ||
        parentForm.get(inputs.input.name)?.disabled) &&
      !inputs.input.options?.alwaysShow,
    disabled:
      parentForm.get(inputs.select.name)?.disabled &&
      parentForm.get(inputs.input.name)?.disabled
  }"
  [id]="inputs?.id"
>
  <label>{{ inputs.select.label | translate }}</label>
  <span
    class="asterisk"
    *ngIf="
      parentForm.get(inputs.input.name)?.validator &&
      this.parentForm.get(inputs.input.name)?.validator({})?.required
    "
  >
    *</span
  >
  <div class="selectInput">
    <app-select
      [parentForm]="parentForm"
      [fCName]="inputs.select.name"
      [options]="inputs.select.options"
      [data]="inputs.select.options.data"
    ></app-select>
    <app-input
      [parentForm]="parentForm"
      [fCName]="inputs.input.name"
      [options]="{ showErrors: inputs.showErrors, id: inputs.input.options.id }"
    >
    </app-input>
  </div>
</div>
