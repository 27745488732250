<!-- {locator: 'JBNF6', agency: 'Bidane Travel', name: 'Leyre Valdes', paxNumber: 3, origin: 'MAD', destionation: 'BCN'}, -->


  <a
    (click)="
      createUrlVoucher({
        locator: lastQuery.bookingID ?? lastQuery?.locator,
        iata: lastQuery.agency
      })
    "
  >
    <div>
      <p>
        <span class="locator">
          {{ lastQuery.locator }}
        </span>
        <span class="agency">
          {{ lastQuery.agency }}
        </span>
      </p>
      <p>
        <span class="name"> {{ lastQuery.name }} • </span>
        <span class="pax"> {{ lastQuery.paxNumber + 'PAX' }} • </span>
        <span class="origin"> {{ lastQuery.origin }} </span>
        <span class="destination" *ngIf="lastQuery.destination">
          - {{ lastQuery.destination }}
        </span>
      </p>
    </div>
    <div>
      <button class="show">{{ 'show_' | translate }}</button>
    </div>
  </a>

