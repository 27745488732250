import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { languages, VERSION } from '../../configurations/configurations';
import { UserService } from '../user/user.service';

export class CustomLoader implements TranslateLoader {
  constructor(
    private httpClient: HttpClient,
    private userServices: UserService
  ) {
    if (localStorage.getItem('currentUser')) {
      this.prefixClientCurrentUser = this.getPrefix(
        JSON.parse(localStorage.getItem('currentUser') ?? '')?.c
      );
    }
    this.userServices?.getCurrentUserObservable().subscribe((res) => {
      const prefix = this.getPrefix(res?.c);
      if (prefix !== this.prefixClientCurrentUser) {
        window.location.reload();
      }
    });
  }

  prefixClientCurrentUser = '';

  getTranslateData(lang: string) {
    return languages.find((lan) => lan.value === lang);
  }

  getPrefix(client: any) {
    switch (client?.tn) {
      case 'iberia':
        return 'ib-';
      default:
        return '';
    }
  }

  createCustomFile(lang) {
    const data = this.getTranslateData(lang);
    const prefix = this.prefixClientCurrentUser;
    const file =
      'assets/i18n/' + prefix.trim() + data?.value + '.json?v=' + VERSION;

    return data?.parent
      ? this.getTranslationWithParent(file, data, prefix)
      : this.getTranslationWithoutParent(file, prefix);
  }

  getTranslation(lang: string): Observable<any> {
    return this.createCustomFile(lang);
  }

  getTranslationWithoutParent(file: string, prefix?: string): Observable<any> {
    // Check if prefix is defined and not empty
    if (prefix && prefix.trim() !== '') {
      return this.httpClient.get(file).pipe(
        mergeMap((resChild) =>
          this.httpClient.get(file.replace(prefix, '')).pipe(
            map((resParent) => {
              return { ...resParent, ...resChild };
            })
          )
        )
      );
    } else {
      // If prefix is not defined or empty, return the child translation only
      return this.httpClient.get(file);
    }
  }

  getTranslationWithParent(
    file: string,
    data: any,
    prefix?: string
  ): Observable<any> {
    if (prefix && prefix.trim() !== '') {
      return this.httpClient.get(file).pipe(
        mergeMap((resChild) =>
          this.httpClient.get(file.replace(prefix, '')).pipe(
            mergeMap((resBase) =>
              this.httpClient.get('assets/i18n/' + data.parent + '.json').pipe(
                map((resParent) => {
                  return { ...resParent, ...resBase, ...resChild };
                })
              )
            )
          )
        )
      );
    } else {
      // If prefix is not defined or empty, return the child translation only
      return this.httpClient.get(file).pipe(
        mergeMap((resChild) =>
          this.httpClient.get('assets/i18n/' + data.parent + '.json').pipe(
            map((resParent) => {
              return { ...resParent, ...resChild };
            })
          )
        )
      );
    }
  }
}
