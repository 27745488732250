import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'betweenDates',
})
export class BetweenDatesPipe implements PipeTransform {
  transform(value: string, args?: string, args2?: string): boolean {
    let isBetweenDates = false;
    if (args && args2) {
      const val = new Date(value);
      const start = new Date(args);
      const end = new Date(args2);
      isBetweenDates = val > start && val < end && val !== end && val !== start;
    }
    return isBetweenDates;
  }
}
