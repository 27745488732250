<ng-container [formGroup]="parentForm">
  <div class="input-container">
    <input type="text" [formControlName]="fCName" [hidden]="true" />
    <input
      placeholder="{{ options.placeholder | translate }}"
      type="{{ type }}"
      [ngClass]="options.class"
      [id]="options.id || fCName"
      autocomplete="off"
      #element
      (keyup)="onKeyUp($event)"
      (focus)="focusIn()"
      (keydown)="onKeyDown($event)"
      [disabled]="parentForm.get(fCName)?.disabled"
      (click)="click()"
      (blur)="focusOut()"
    />
    <span
      *ngIf="options.iata && parentForm.get(fCName)?.value?.value"
      [ngClass]="{ focus: focus }"
      class="info-iata"
    >
      {{ parentForm.get(fCName).value.value }}
    </span>
    <app-errors-fields
      [field]="element"
      [errors]="parentForm.controls[fCName].errors"
      [show]="options.showErrors"
    >
    </app-errors-fields>
  </div>
</ng-container>
<div class="dropdownAutocomplete">
  <ng-container>
    <div (click)="emitShowMap()" class="show-map" *ngIf="showMap">
      <svg-icon
        [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
        src="assets/images/icons/pin.svg"
      ></svg-icon>
      <span>
        {{ 'show on map_' | translate }}
      </span>
    </div>
  </ng-container>
  <app-dropdown
    *ngIf="show"
    [ref]="element"
    [options]="dropdownOptions"
    [(show)]="show"
  >
    <div
      class="expSearch"
      *ngIf="
        elements &&
        elements.length &&
        options.inputs &&
        options.inputs.length > 0
      "
    >
      <app-fields-collection
        [inputs]="options.inputs"
        [form]="parentForm"
      ></app-fields-collection>
    </div>
    <ng-container
      *ngIf="elements && elements.length && elements[0].error; else noError"
    >
      <div class="airports errors">
        <div class="flex">
          <svg-icon
            [svgStyle]="{ 'width.px': 13 }"
            src="assets/images/icons/alert.svg"
          ></svg-icon>
          <div class="flex-column">
            <span
              class="titleLine"
              [innerHTML]="elements[0].title | translate"
            ></span>
            <span
              class="descriptionLine"
              [innerHTML]="elements[0].sub | translate"
            ></span>
          </div>
        </div>
        <p class="iata">
          {{ elements[0].code }}
        </p>
      </div>
    </ng-container>
    <ng-template #noError>
      <div class="airportContainer" (mouseover)="selectDropdown = -1">
        <div class="airportsOptions autocompleteDropdown">
          <ng-container *ngFor="let airport of elements; index as index">
            <div
              (mouseover)="this.elementHover = index"
              (mouseout)="this.elementHover = null"
              class="airports"
              [ngClass]="{
                active: selectDropdown === index,
                nexo: airport.nexoTr || airport.icon,
                'near-airport': airport.nearAirports,
                first: elements[index - 1] && elements[index - 1].nearAirports,
                last:
                  elements[index - 1] &&
                  elements[index - 1].nexoTr &&
                  ((elements[index + 1] && !elements[index + 1].nexoTr) ||
                    !elements[index + 1])
              }"
              (click)="
                selectAirport(
                  airport,
                  options.inputs && options.inputs.length,
                  true
                )
              "
            >
              <div class="flex">
                <svg-icon
                  *ngIf="!airport.disableIcon"
                  class="icon"
                  [svgStyle]="{ 'height.px': 20 }"
                  [src]="airport.icon"
                ></svg-icon>
                <div class="flex-column">
                  <span class="titleLine" [innerHTML]="airport.title"></span>
                  <span
                    class="descriptionLine"
                    [innerHTML]="airport.subtitle"
                  ></span>
                </div>
              </div>
              <p
                [ngClass]="{
                  hover: elementHover === index || selectDropdown === index
                }"
                class="iata"
              >
                {{ airport.obj.code }}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </app-dropdown>
</div>
