<div class="loading" [ngClass]="{loadingFares : faresLoading}" data-cy="loading">
  <div
   class="animation"
   *ngIf="faresLoading"
   >
  </div>
  <h2
  *ngIf="options?.title"
  [ngClass]="{loadingFares : faresLoading}"
  data-cy="title-loading"
  >{{ options.title | translate }}</h2>
  <p *ngIf="options?.subtitle" data-cy="text-loading">{{ options.subtitle | translate }}</p>
  <div class="ball-pulse" >
    <div [ngClass]="{loadingFares : faresLoading}"></div>
    <div [ngClass]="{loadingFares : faresLoading}"></div>
    <div [ngClass]="{loadingFares : faresLoading}"></div>
  </div>
</div>
