<ng-container [formGroup]="parentForm">
  <div class="input-container">
    <div class="icon-label-box">
      <div class="icon-box">
        <svg-icon [src]="options.input.options.icon"></svg-icon>
      </div>
      <div class="label-box">
        <p class="main-label">
          {{ options.input.options.mainLabel | translate }}
        </p>
        <p class="sub-label">
          {{ options.input.options.subLabel | translate | uppercase }}
        </p>
      </div>
    </div>
    <div class="input-box">
      <!-- <app-select-prefix
        class="prefix-data"
        *ngIf="options.select"
        [parentForm]="parentForm"
        [fCName]="options.select.name"
        [options]="options.select.options"
        [data]="options.select.options.data"
        [icon]="'assets/images/icons/spain.svg'"
      ></app-select-prefix> -->
      <input
        autofocus="true"
        [ngClass]="{
          borderRadius: options.select,
          iataInput: fCName === 'iata'
        }"
        [id]="options.input.options.id ?? fCName"
        maxlength="9"
        type="{{ type ? type : 'text' }}"
        placeholder="{{ options.input.options.placeholder | translate }}"
        [formControlName]="fCName"
        [attr.data-cy]="
          options.input.options.id ? options.input.options.id : fCName
        "
      />
    </div>
  </div>
</ng-container>
<app-errors-fields
  [field]=""
  [errors]="parentForm.controls[fCName]?.errors"
  [show]="options.input.options"
  [messageError]="options.errorMessage"
>
</app-errors-fields>
