import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-version-clasic',
  templateUrl: './modal-version-clasic.component.html',
  styleUrls: ['./modal-version-clasic.component.scss']
})
export class ModalVersionClasicComponent implements OnInit {

  @Input() set options(e: any) {
    if (e.buttonsOptions) {
      this.buttonsOptions = e.buttonsOptions;
    }
    this.isFormFeedback = e.isFormFeedback;
  }

  form: FormGroup = this.formBuilder.group({
    error: "",
    function: "",
    dificult: "",
    comment: "",
    button: ""
  });

  emitData = new EventEmitter<FormGroup>();
  buttonsOptions: any = {};
  isFormFeedback: boolean;
  subscriptions: Subscription[] = [];
  inputs = [
  {
    type: 'checkbox',
    name: 'error',
    headerClass: 'checks',
    options: {
      id: 'error',
      class: 'form-input',
      placeholder: 'modal-back-version-clasic_.placeholder_.check_1_',
      label: ''
    }
  },
  {
    type: 'checkbox',
    name: 'function',
    headerClass: 'checks',
    options: {
      id: 'function',
      class: 'form-input',
      placeholder: 'modal-back-version-clasic_.placeholder_.check_2_',
      label: ''
    }
  },
  {
    type: 'checkbox',
    name: 'dificult',
    headerClass: 'checks',
    options: {
      id: 'dificult',
      class: 'form-input',
      placeholder: 'modal-back-version-clasic_.placeholder_.check_3_',
      label: ''
    }
  },
  {
    type: 'textarea',
    name: 'comment',
    options: {
      placeholder: 'modal-back-version-clasic_.placeholder_.text_area_',
      label: '',
      id: 'comment'
    }
  }
 ];

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if(this.isFormFeedback){
      this.initForm(this.inputs)
    }
  }
  initForm(inputs: any[]) {
    if(inputs){
      for (const control of inputs) {
        this.form.addControl(control.name, new FormControl('', []));
        if (control.checked) {
          this.form.get(control.name)?.setValue(control.val);
        }
      }
    }
  }

  send(){
    this.form.get('button')?.setValue("send");
    this.submit()
  }
  cancel(){
    this.form.get('button')?.setValue("cancel");
    this.submit();
  }

  submit(){
    this.emitData.emit(this.form);
  }

  ngOnDestroy(){
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
