import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-select-prefix',
  templateUrl: './select-prefix.component.html',
  styleUrls: ['./select-prefix.component.scss'],
})
export class SelectPrefixComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() data: any[];
  @Input() fCName: string;
  @Input() options: any = {};
  @Input() icon: string;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  active = false;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    if (this.data?.length === 1 && this.options?.oneSelection) {
      this.parentForm.get(this.fCName)?.setValue(this.data[0]?.value);
    }
  }

  show() {
    this.active = true;
  }

  hide() {
    this.active = false;
  }

  getItem(item: any) {
    return item.label;
  }
}
