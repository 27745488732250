<p-header>
  <svg-icon [src]="data?.header?.logo" *ngIf="data.header.logo"></svg-icon>
  <p>{{ data.header.title }}</p>
</p-header>
<div class="separator one"></div>
<ng-container *ngIf="data.links" >
  <div class="content-cont" *ngFor="let links of data.links">
    <div class="title">
      <svg-icon src="assets/images/icons/dot_grey.svg"></svg-icon>
      <h4>
        {{ links.title | translate | uppercase }}
      </h4>
    </div>
    <div class="link" *ngFor="let link of links.links_group">
      <div class="icon-link">
        <svg-icon [src]="'assets/images/icons/link.svg'"></svg-icon>
      </div>

      <a [href]="link.url" target="_blank"> {{ link.name }}</a>
    </div>
  </div>
</ng-container>

