// Servicio para manejar la agancia del usuario conectado
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  private apiUrl: string = environment.api_backend_url;
  // constructor
  constructor(private http: HttpClient) {}

  getAgencies(agencyName: string, type?: string): Observable<any> {
    // Parameters
    const params: any[] = [];
    params.push('key=' + agencyName);
    params.push('limit=5');
    params.push('format=json');
    const urlParam = params.join('&');
    // Connexion
    if (type) {
      return this.http.get<any>(
        this.apiUrl + '/api/autocomplete/' + type + '?' + urlParam
      );
    }
    return this.http.get<any>(
      this.apiUrl + '/api/autocomplete/agency?' + urlParam
    );
  }

  bringReservation(
    user: string,
    password: string,
    locator: string,
    office: string
  ) {
    const params = [
      `user=${user}`,
      `password=${password}`,
      `locator=${locator}`,
      `office=${office}`,
      'format=json',
    ];
    const urlParam = params.join('&');
    return this.http.get<any>(
      `${environment.api_url}/api/v1/flight/responsability.json?${urlParam}`
    );
  }

  setAgency(event) {
    // Parameters
    const params: any[] = [];
    params.push('aid=' + event?.id);
    const urlParam = params.join('&');
    // Connexion
    return this.http.get<any>(
      this.apiUrl + '/api/user/agency/update?' + urlParam
    );
  }

  createIfNotExistsAgen(aiata: string, provider: string) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.post<any>(
      environment.api_backend_url +
        '/api/user/agency/createIfNotExistsFromIata',
      { iata: aiata, provider: provider },
      { headers }
    );
  }

  getDataAgencyById(agencyId: string, agentId: string) {
    let params = new HttpParams();
    params = params.append('agency_id', agencyId);
    params = params.append('agent_id', agentId);

    return this.http.get<any>(this.apiUrl + '/api/user/agency/data', {
      params,
    });
  }
}
