<ng-container [formGroup]="parentForm">
  <div class="card flex justify-content-center">
    <p-multiSelect
      [options]="options.data"
      [formControlName]="fCName"
      optionLabel="name"
      maxSelectedLabels="10"
      display="chip"
    ></p-multiSelect>
  </div>
</ng-container>
