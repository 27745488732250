import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ModalButtons } from 'src/app/modules/application/transport/components/modal-options/modal-options.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserService } from '../user/user.service';
import { ManagementModalService } from '../utils/management-modal/management-modal.service';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { switchMap, tap, takeUntil, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

interface ResponseCrmModal {
  button: string;
  number: string;
}
export interface CrmDataModel {
  form: ResponseCrmModal;
  data: any;
}
@Injectable({
  providedIn: 'root',
})
export class CrmService {
  crmData$ = new Subject<CrmDataModel>();
  crmForm: ResponseCrmModal;

  subscriptions: Subscription[] = [];

  component: any;

  modalOptions = {
    title: 'crm_',
    width: '500px',
    minWidth: '400px',
    id: 'modal-crm',
  };

  inputsOptions: any;
  destroy$: Subject<void>;
  loading = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private managementModal: ManagementModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.destroy$ = new Subject();
  }

  getCrmData$(): Observable<any> {
    return this.crmData$.asObservable();
  }

  setCrmData$(crmData: any): void {
    this.crmData$.next(crmData);
  }

  genModal(cars = false, budget = false): void {
    let resId = '';
    let buttons: ModalButtons[] = [
      {
        text: cars
          ? budget
            ? 'validate and continue_'
            : 'fill driver_'
          : 'back_',
        class:
          cars || budget ? 'btn-primary crm-buttons' : 'btn-back crm-buttons',
        emitOnClick: budget ? 'submit' : 'cancel',
      },
    ];
    if (!cars) {
      buttons = [
        ...buttons,
        {
          text: 'continue_',
          class: 'btn-primary crm-buttons',
          emitOnClick: 'submit',
        },
      ];
    }

    let crmId = 'crm';

    if (!cars && !budget) {
      this.crmInputOptions();
    } else {
      crmId = 'crmBudget';
      this.inputOptions();
    }

    const form: FormGroup = this.formBuilder.group({
      noFound: false,
    });
    const options: any = {
      form,
      inputs: this.inputsOptions.inputs,
      width: '550px',
      noFound: false,
      buttonClose: true,
      stopPropagation: true,
      crmStyles: true,
      selectRadioText: true,
    };
    this.checkCrmData();
    this.managementModal.showCustomModal(
      'crm_',
      '',
      buttons,
      this.managementModal.iconType.crm,
      options,
      { id: crmId, isOpen: false }
    );
    const button = this.managementModal.confirmButton$
      .pipe(
        filter((res) => res.id === 'crm' || res.id === 'crmBudget'),
        tap((res) => {
          if (form?.value?.name && form.value.name !== '') {
            this.crmForm = {
              button: form.value?.contacts,
              number: form.value.name,
            };
          } else {
            this.showErrors();
          }
          if (res.response === 'closedClickOut') {
            this.managementModal.hideModal();
            button.unsubscribe();
          }
        }),
        switchMap((res) => {
          const email = isNaN(parseFloat(form.value.name));
          if (
            form?.value?.name &&
            form.value.name !== '' &&
            (res.id === 'crm' || res.id === 'crmBudget') &&
            res.response !== 'closedClickOut'
          ) {
            resId = res.id;
            form.get('name')?.setErrors(null);
            this.loading = true;
            this.inputsOptions.inputs[0].options.showLoading = true;
            return this.getCrmData(form.value.name, email);
          } else {
            return of({});
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any[]) => {
        this.loading = false;
        this.inputsOptions.inputs[0].options.showLoading = false;
        if (res && res.length > 0) {
          const response: CrmDataModel = {
            form: this.crmForm,
            data: res,
          };
          let userLocalStorage = JSON.parse(
            localStorage.getItem('currentUser')!
          );
          if (userLocalStorage?.config?.idCRM) {
            // info localestorage del cambio en el config
            userLocalStorage.config.idCRM =
              this.crmForm.number !== userLocalStorage?.config?.idCRM
                ? ''
                : response.data[0].crmNo;
            userLocalStorage = {
              config: { idCRM: userLocalStorage.config.idCRM },
              ...userLocalStorage,
            };
            localStorage.setItem(
              'currentUser',
              JSON.stringify(userLocalStorage)
            );
          }
          this.crmData$.next(response);
          if (this.crmForm) {
            form.reset();
          }
          if (resId === 'crmBudget' || resId === 'crm') {
            this.managementModal.deleteStackElement({
              id: resId,
              isOpen: false,
            });
          }
          this.managementModal.hideModal();
          button.unsubscribe();

          if (resId !== 'crmBudget') {
            this.toastr.success(
              "<img src='assets/images/icons/success.svg' alt='success' /><p>" +
                this.translate.instant('genericSuccess_') +
                '</p>',
              ''
            );
          }
        } else {
          form.get('name')?.addValidators(this.isCrmValid());
          form.get('name')?.setErrors({ crmIsValid: true });
          this.showErrors();
        }
      });
  }

  isCrmValid(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control) {
        return { crmIsValid: false };
      }
      return null;
    };
  }

  checkCrmData() {
    if (this.crmForm) {
      this.inputsOptions.inputs[0] = {
        ...this.inputsOptions.inputs[0],
        value: this.crmForm.number,
      };
    } else if (this.userService?.currentUserValue?.config?.idCRM) {
      this.inputsOptions.inputs[0] = {
        ...this.inputsOptions.inputs[0],
        value: this.userService.currentUserValue.config.idCRM,
      };
    }
  }

  getCrmData(data: string, email = false): Observable<any> {
    let params = new HttpParams();
    params = params.append('user', this.userService.currentUserValue.user.ws!);
    params = params.append(
      'password',
      this.userService.currentUserValue.user.wp!
    );
    params = params.append('format', 'json');
    if (email) {
      params = params.append('email', data);
    } else {
      params = params.append('crmNo', data);
    }

    // return this.http.get<any>('assets/mocks/crm.json');
    return this.http.get<any>(environment.api_url + '/api/veci/crm.json', {
      params,
    });
  }

  showErrors() {
    for (const input of this.inputsOptions.inputs) {
      if (input.options) {
        input.options.showErrors = true;
      }
    }
  }

  showNoFound() {
    this.component.componentInstance.noFound = true;
    const modalForm: Subscription =
      this.component.componentInstance.modalForm.valueChanges.subscribe((_) => {
        this.component.componentInstance.noFound = false;
        modalForm.unsubscribe();
      });
  }

  inputOptions() {
    this.inputsOptions = {
      pClass: 'crmBudget',
      text: 'crm number or email_',
      action: 'crmBudget',
      inputs: [
        {
          name: 'name',
          headerClass: 'name',
          required: true,
          options: {
            id: 'crm-number',
            label: 'number of crm or email_',
            placeholder: '000000000',
            class: 'form-input',
          },
        },
      ],
    };
  }

  crmInputOptions() {
    this.inputsOptions = {
      pClass: 'crm',
      text: 'crm number or email_',
      action: 'crm',
      inputs: [
        {
          name: 'name',
          headerClass: 'name crm-input',
          required: true,
          options: {
            id: 'crm-number',
            label: 'number of crm or email_',
            placeholder: '000000000',
            class: 'form-input',
          },
        },
        {
          type: 'radio',
          name: 'contacts',
          val: 'all',
          headerClass: 'crm all-contacts',
          options: {
            placeholder: 'fill all_',
            label: '',
          },
        },
        {
          type: 'radio',
          name: 'contacts',
          val: 'contact',
          headerClass: 'crm only-contact',
          checked: true,
          options: {
            placeholder: 'fill contact_',
            label: '',
          },
        },
      ],
    };
  }

  hideModal() {
    this.destroy();
    this.component.close();
  }

  destroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
