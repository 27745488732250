import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-result-sort',
  templateUrl: './result-sort.component.html',
  styleUrls: ['./result-sort.component.scss'],
})
export class ResultSortComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() data: ResultSort[];
  @Input() orderBy: ResultSort;
  @Input() transport: string;
  @Output() orderResults = new EventEmitter();

  orderActive = false;
  title = '';
  show = false;
  dropdownOptions = {
    class: 'dropdown',
  };

  ngOnInit(): void {
    this.form.get('orderBy')?.setValue(this.orderBy);
    this.title = this.orderBy.label;
  }

  selectValue(option: string): void {
    this.form.get('orderBy')?.setValue(option);
    this.orderResults.emit(option);
    this.form.get('orderBy')?.setValue(option);
    this.orderResults.emit(option);
    this.selectedValue(option);
    this.orderActive = true;
    this.show = false;
  }

  selectedValue(option: any): void {
    this.title = option.label;
  }
}

export interface ResultSort {
  label: string;
  value: string;
}
