import {
  Component,
  Input,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { switchElement } from '../forms/fields/switch-button/switch-button.component';
import { TranslateService } from '@ngx-translate/core';
type FieldsConfigItem = {
  label: string;
  key: string;
};
@Component({
  selector: 'app-generic-list-form',
  templateUrl: './generic-list-form.component.html',
  styleUrls: ['./generic-list-form.component.scss'],
})
export class GenericListFormComponent {
  @Input() form: any;
  @Input() formName: string;
  @Input() serviceName: string;
  @Input() inputs: any;
  @Input() inputsName: string;
  @Input() items: any;
  @Input() fieldsConfig: FieldsConfigItem[];
  @Input() currentPage: number;
  @Input() pageSize: number;
  @Input() tableName: string;
  @Input() titleSection: string = '';
  @Input() showAddButton = true;
  @Input() totalElements = 0;
  @Input() totals = 0;
  @Input() specialKeys = [];
  @Input() havePagination: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() buttonsDisabled: boolean = false;
  @Input() scrollElement: boolean = false;
  @ContentChild(TemplateRef) itemWrapper: TemplateRef<any>;
  @Output() getItemFather = new EventEmitter<{
    name: string;
    page: IPageInfo;
  }>();
  @Output() deleteItemFather = new EventEmitter<{
    itemId: number;
    name: string;
  }>();
  @Output() saveItemFather = new EventEmitter<{
    formValue: any;
    name: string;
    inputs: any;
    form: any;
  }>();

  @Output() activeOrNotActiveElement = new EventEmitter<switchElement>();
  @Output() pagination = new EventEmitter<{
    index: string;
    currentPage: number;
  }>();
  @Output() updateItemFather = new EventEmitter<{
    formValue: any;
    itemId: number;
    name: string;
    inputs: any;
    form: any;
  }>();
  @Output() editElementOpenCustomValues = new EventEmitter<{
    item: any;
    inputsName: string;
    formName: string;
  }>();
  @Output() resetFormInitValue = new EventEmitter<{
    formName: string;
    serviceName: string;
    inputsName: string;
  }>();
  @Output() specialButton = new EventEmitter<string>();
  @ViewChild('focusScroll') focusScroll!: ElementRef;

  editingItem = false;
  editActive: any;
  maximunNumber = this.translateService.instant('limite_25_excedido_');

  inputsWithPorcentIcon = [
    'commission_transferred',
    'over_detained',
    'commission',
    'over',
  ];

  constructor(private translateService: TranslateService) {}

  specialButtonCallFather(clickedButton: string): void {
    this.specialButton.emit(clickedButton);
  }

  scrollToNewElement() {
    setTimeout(() => {
      const focusElement = this.focusScroll.nativeElement;
      const newRefFocus = focusElement.children[0] as HTMLElement;
      newRefFocus.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    //Control de boton disabled si navegamos entre tabs en caso de que el padre tenga este modulo
    if (changes['inputs']) {
      this.editingItem = false;
      this.buttonsDisabled = false;
    }
  }

  getItem(page: IPageInfo) {
    this.getItemFather.emit({ name: this.tableName, page });
  }

  deleteItem(itemId: number) {
    this.deleteItemFather.emit({ itemId, name: this.tableName });
  }

  saveItem(id?: number): void {
    if (id) {
      this.updateItem(id);
      return;
    }
    if (this.form.valid) {
      this.buttonsDisabled = true;
    }
    this.saveItemFather.emit({
      formValue: this.form.value,
      name: this.tableName,
      inputs: this.inputs,
      form: this.form,
    });
    if (this.form.valid) {
      this.editingItem = false;
    }
  }

  updateItem(itemId: number): void {
    if (this.form.valid) {
      this.buttonsDisabled = true;
    }
    this.updateItemFather.emit({
      formValue: this.form.value,
      itemId,
      name: this.tableName,
      inputs: this.inputs,
      form: this.form,
    });
    if (this.form.valid) {
      this.editingItem = false;
    }
  }

  createNewItem(): void {
    this.editingItem = true;
    let newItem = { active: true };
    this.resetFormInitValue.emit({
      formName: this.formName,
      serviceName: this.serviceName,
      inputsName: this.inputsName,
    });
    this.items.unshift(newItem);
    this.scrollToNewElement();
  }

  editItem(item) {
    this.editingItem = true;
    this.buttonsDisabled = false;
    item.active = true;
    this.editElementOpenCustomValues.emit({
      item: item,
      inputsName: this.inputsName,
      formName: this.formName,
    });
  }

  stopEditing(item, itemIndex: number): void {
    let deleteItem = true;

    this.editingItem = false;
    item.active = false;

    Object.keys(item).forEach((key) => {
      if (item[key] && item[key] !== null) {
        deleteItem = false;
      }
    });

    if (deleteItem) {
      this.items.splice(itemIndex, 1);
    }
  }

  activeOrNotActiveElementToParent(data: switchElement) {
    data.section = this.tableName;
    this.activeOrNotActiveElement.emit(data);
  }

  fetchMore(event) {
    this.pagination.emit({
      index: this.tableName,
      currentPage: event,
    });
  }
}
