import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-credentials',
  templateUrl: './info-credentials.component.html',
  styleUrls: ['./info-credentials.component.scss'],
})
export class InfoCredentialsComponent {
  @Input() data: any;
}
