import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

type FieldsConfigItem = {
  label: string;
  key: string;
};

@Component({
  selector: 'app-generic-list-with-filter',
  templateUrl: './generic-list-with-filter.component.html',
  styleUrls: ['./generic-list-with-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericListWithFilterComponent<T> implements OnInit {
  @Input() items: T[] = [];
  @Input() fieldsConfig: any[];
  @Input() title: string;
  @Output() itemSelected = new EventEmitter<T>();
  @Output() deleteItem = new EventEmitter<number>();
  @Output() goToCreatUserEmit = new EventEmitter<T>();

  itemsFiltered$: Observable<T[]>;

  currentPage = 1;
  pageSize = 10;
  pageLength = 0;
  noResults = false;

  filterOptions = [
    {
      type: 'text',
      name: 'search',
      options: {
        class: 'form-input',
        placeholder: 'search_',
      },
    },
  ];

  form: FormGroup = this.formBuilder.group({
    search: '',
  });

  QPS = false;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRouter: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pageLength = this.items.length;
    this.initSearch();
    if (this.activatedRouter.snapshot.routeConfig?.path === 'QPS-TPS') {
      this.QPS = true;
    }
  }

  initSearch() {
    if (this.form.get('search')) {
      this.itemsFiltered$ = this.form.get('search')!.valueChanges.pipe(
        map((searchValue) =>
          this.filter(this.items, this.fieldsConfig, searchValue)
        ),
        tap((itemsFiltered) => {
          this.noResults = false;
          this.pageLength = itemsFiltered.length;
          if (this.pageLength === 0) {
            this.noResults = true;
          }
        }),
        startWith(this.items)
      );
    }
  }

  filter(items: T[], fields: { key: string }[], value: string): T[] {
    if (!value || value === '') {
      return [...this.items];
    }

    return items.filter((item) =>
      fields.some(
        (field) =>
          item[field.key] &&
          item[field.key].toLowerCase().includes(value.toLowerCase())
      )
    );
  }

  fetchMore(event) {
    this.currentPage = event;
  }

  selectItem(item: T) {
    this.itemSelected.emit(item);
  }

  delete(item): void {
    this.deleteItem.emit(item.id);
  }

  goToCreatUser() {
    this.goToCreatUserEmit.emit();
  }

  resetForm() {
    this.form.reset();
  }
}
