import { Injectable, Inject, LOCALE_ID } from '@angular/core';

/**
 * Service load the app's language
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageClientService {
  langs: any[] = [];
  public icons = {
    es: 'spain',
    en: 'united-kingdom',
    pt: 'portugal',
    fr: 'france',
    ar: 'argentina',
    cl: 'chile',
    co: 'colombia',
    mx: 'mexico',
    pe: 'peru',
    it: 'italy',
    de: 'german',
  };

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  getAllLanguagesValues(): string[] {
    this.langs = this.sortLanguages();
    const langs: any[] = this.langs.map((val) => {
      return val.language;
    });
    return langs;
  }

  private sortLanguages(): any[] {
    return this.langs.sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });
  }
}
