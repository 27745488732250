<section class="noResult">
  <div class="icons" [ngClass]="{alertView}">
    <svg-icon
      class="font-flight"
      [ngClass]="{ sizeImg: alertView }"
      [src]="filterChanges ? noResultAvailData?.filter?.img : noResultAvailData?.img"
    >
    </svg-icon>
  </div>

  <div class="options">
    <div class="no-result-header">
      <ng-container *ngIf="alertView">
        <p
          class="message-noResult"
          [ngClass]="{ alertSub: alertView }"
          data-cy="message-noResult-alert"
        >
          {{ noResultAvailData?.title | translate }}
        </p>
        <h1
          data-cy="description-noResult-alert"
          [ngClass]="{ titleAlert: alertView }"
        >
          {{ noResultAvailData?.subtitle | translate }}
        </h1>
      </ng-container>
      <ng-container *ngIf="!alertView">
        <p data-cy="message-noResult">
          {{ noResultAvailData?.title | translate }}
        </p>
        <h1 data-cy="description-noResult">
          {{
            (filterChanges
              ? noResultAvailData?.filter?.subtitle
              : noResultAvailData?.subtitle
            ) | translate
          }}
        </h1>
      </ng-container>
    </div>
    <div class="sugerencias">
      <div class="sugerencias-header">
        <svg-icon src="assets/images/icons/sugerencia.svg"></svg-icon>
        <h1>{{ noResultAvailData?.step | translate }}</h1>
      </div>
      <ul>
        <ng-container
          *ngFor="
            let description of filterChanges
              ? noResultAvailData?.filter?.description
              : noResultAvailData?.description
          "
        >
          <li>{{ description | translate }}</li>
        </ng-container>
      </ul>
    </div>
    <button
      *ngIf="
        !alertView &&
        (noResultAvailData?.button || noResultAvailData?.filter?.button)
      "
      pRipple
      (click)="actionButtonClicked()"
      class="no-result-button btn-primary-alternative"
      data-cy="reset-filters"
    >
      {{
        (filterChanges
          ? noResultAvailData?.filter?.button
          : noResultAvailData?.button
        ) | translate
      }}
    </button>
  </div>
</section>
