// Servicio para manejar la empresa del usuario conectado
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { UserService } from './user.service';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { environment } from 'src/environments/environment';
import { filter, take, tap } from 'rxjs/operators';
import { ModalButtons } from 'src/app/modules/application/transport/components/modal-options/modal-options.component';
import { ManagementModalService } from '../utils/management-modal/management-modal.service';
import { StorageService } from '../utils/storage.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import moment from 'moment';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { NotificationsUtilService } from '../utils/notifications-util/notifications-util.service';
import { TranslateService } from '@ngx-translate/core';
import { VoucherService } from '../transport/voucher/voucher.service';
import { RouterService } from '../router/router.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateService {
  // datos del usuario logueado.
  private user: UserDataModel;
  public isCorporate$ = new BehaviorSubject<boolean>(
    JSON.parse(localStorage.getItem('isCorporate')!)
  );

  documents = [];

  corporateForm: any = this.formBuilder.group({
    corporate: [this.getCompanyName().length > 0],
    name: [this.getCompanyName(), [Validators.required]],
    response: [],
    company: [this.getCompanyName()],
  });

  corporateInputs: any = [
    {
      type: 'genericAutocomplete',
      name: 'name',
      class: 'searchCompany',
      id: 'headerSearchCompany',
      options: {
        // label: 'search company_',
        placeholder: 'select_company_input_placeholder_',
        field: 'descName',
        suggestions: 'company',
        empty: 'no_results_title_',
        id: 'headerSearchCompany',
        showErrors: false,
      },
    },
  ];

  descartesResetFormPassenger = [
    'position',
    'passengerRef',
    'type',
    'emergencyCheck',
    'special',
    'handicappedDetail',
    'especialAnimalDetail',
    'seat',
    'corporateFares',
    'emergency',
    'umnr',
  ];

  public showProfileEmployeeButton$ = new BehaviorSubject<any>(null);
  // constructor
  constructor(
    private userService: UserService,
    private http: HttpClient,
    private managementModal: ManagementModalService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,
    private capitalize: TitleCasePipe,
    private notificationsUtil: NotificationsUtilService,
    private translateService: TranslateService,
    private voucherService: VoucherService,
    private routerService: RouterService
  ) {}

  getCompanyName(): string {
    const company = this.getCompany();
    if (
      company &&
      company.hasOwnProperty('descName') &&
      company.hasOwnProperty('codAccounts') &&
      company.hasOwnProperty('codDepartment')
    ) {
      return `${company.codAccounts} - ${company.descName}`;
    }
    return '';
  }

  getCodDepartment(): string {
    const company = this.getCompany();
    if (company?.hasOwnProperty('codDepartment')) {
      return company.codDepartment;
    }
    return '';
  }

  getCompanies(query: string): Observable<any> {
    // Config
    this.user = this.userService.getCurrentUSer();
    const wsuser = this.user?.u?.ws ?? this.user?.user?.ws;
    const wspass = this.user?.u?.wp ?? this.user?.user?.wp;

    // Parameters
    const params: any[] = [];
    params.push('user=' + wsuser);
    params.push('password=' + wspass);
    params.push('query=' + query);
    params.push('format=json');
    const urlParam = params.join('&');
    // Connexion
    return this.http.get<any>(
      environment.api_url + '/api/corporate/searchcompany.json?' + urlParam
    );
  }

  addCompany(locator: string) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.post<any>(
      `${environment.api_url}/api/profile/v2/company/sync-source?ac=true`,
      { locator: locator, source: '1A', type: 'Company' },
      { headers }
    );
  }

  // set company data.
  setCompany(company) {
    if (company) {
      company.name = company?.descName;
      localStorage.setItem('currentCompany', JSON.stringify(company));
    }
  }
  setIsCorporate(value: boolean) {
    this.isCorporate$.next(value);
    return localStorage.setItem('isCorporate', JSON.stringify(value));
  }
  // get company data.
  getCompany(): any {
    return JSON.parse(localStorage.getItem('currentCompany')!);
  }

  getDataProfileCompany() {
    // Connexion
    if (!this.getCompany()?.id) return of(null);
    return this.http.get<any>(
      environment.api_url +
        `/api/profile/v2/company/view/${this.getCompany().id}`
    );
  }

  corporateModal(): void {
    //Se comprueba si llega el parameter dl para lo levantar el modal de corporate
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('dl')) {
      return;
    }
    if (this.userService.isVeci.value) {
      if (!this.corporateForm.get('codDepartment')) {
        this.corporateForm.addControl('codDepartment', new FormControl());
        this.corporateInputs.push({
          name: 'codDepartment',
          class: 'codDepartment',
          id: 'input-codDepartament',
          options: {
            placeholder: '000',
            label: 'dpto_',
            id: 'headerSearchCompany',
            showErrors: false,
          },
        });
        if (
          this.userService.isVeci.value &&
          this.corporateForm.get('company') &&
          this.getCodDepartment()
        ) {
          this.corporateForm
            .get('codDepartment')
            .setValue(this.getCodDepartment());
        }
      }
    }
    const buttons: ModalButtons[] = [
      {
        text: 'continue_',
        class: 'btn-primary',
        emitOnClick: 'submit',
      },
    ];
    const options: any = {
      form: this.corporateForm,
      inputs: this.corporateInputs,
      width: '585px',
      id: 'company-modal',
      stopPropagation: true,
      isCorporate: true,
      showClosedButton: true,
    };

    const corporateIcon: any = {
      corporateIcon: this.managementModal.iconType.corporate,
      corporateIconPlus: this.managementModal.iconType.corporatePlus,
      corporateError: this.managementModal.iconType.corporateError,
    };

    this.managementModal.showCustomModal(
      'select_company_',
      '',
      buttons,
      corporateIcon,
      options,
      { id: 'corporate', isOpen: false }
    );
    this.corporateForm.get('name').setValue(this.getCompanyName());
    this.corporateInputs[0].options.showErrors = false;
    const button = this.managementModal
      .getConfirmButton$()
      .pipe(
        filter((res) => res?.id === 'corporate'),
        tap(({ response }) => {
          if (response !== 'submit') {
            button.unsubscribe();
            if (
              response.toLowerCase() ===
              this.translateService
                .instant('RECORD LOCATOR NOT FOUND')
                .toLowerCase()
            ) {
              this.corporateForm.reset();
              this.managementModal.hideSpecificModal();
              this.voucherService.showModalErrorRequest(response);
            } else if (response === 'closedClickOut') {
              this.managementModal.hideSpecificModal();
              this.managementModal.deleteAllStackElement();
              if (this.corporateForm.invalid) {
                this.activeCorporate(false);
              }
            } else {
              this.corporateForm.get('name').setValue('');
              if (response === 'cancel') {
                this.managementModal.deleteAllStackElement();
              }
              if (this.corporateForm.get('codDepartment')) {
                this.corporateForm.get('codDepartment').setValue(null);
                document.getElementById('input-codDepartament')!.style.display =
                  'none';
              }
              if (!this.getCompanyName()) {
                this.managementModal.hideSpecificModal();
                if (response !== 'cancel') {
                  this.voucherService.showModalErrorRequest(response);
                }
                this.activeCorporate(false);
              } else {
                this.corporateForm
                  .get('company')
                  .setValue(this.getCompanyName());
                if (response !== 'cancel') {
                  this.managementModal.hideSpecificModal();
                  this.voucherService.showModalErrorRequest(response);
                } else {
                  this.managementModal.deleteAllStackElement();
                }
              }
            }
          } else if (!this.corporateForm.valid && response === 'cancel') {
            this.activeCorporate(false);
          }
        }),
        filter(() => this.corporateForm.valid),
        take(1)
      )
      .subscribe({
        next: () => {
          if (this.corporateForm.get('response').value) {
            this.notificationsUtil.infoSuccess(
              this.translateService.instant('successfully_selected_company_') +
                this.corporateForm.get('name').value
            );
            if (
              this.userService.isVeci.value &&
              this.corporateForm.get('codDepartment')
            ) {
              this.corporateForm.get('response').value.codDepartment =
                this.corporateForm.get('codDepartment').value;
            }
            this.storageService.saveLastCorporateSearch(
              this.corporateForm.get('response').value
            );
            this.setCompany(this.corporateForm.get('response').value);
            this.setIsCorporate(true);
            this.corporateForm.get('company').setValue(this.getCompanyName());
          } else {
            this.corporateForm.get('name').setValue('');
            if (this.corporateForm.get('codDepartment')) {
              this.corporateForm.get('codDepartment').setValue(null);
              document.getElementById('input-codDepartament')!.style.display =
                'none';
            }
          }

          if (this.router.routerState.snapshot.url !== '/transport') {
            const urlWithExtraParams = this.routerService.getUrlWithExtraParams(
              this.user?.getHomePage() ?? '/'
            );
            window.location.href = urlWithExtraParams;
          } else if (
            this.managementModal.listModalsOpen
              .getValue()
              .find((element) => element.id === 'handleRequest')
          ) {
            this.managementModal.deleteStackElement({
              id: 'corporate',
              isOpen: true,
            });
          } else {
            this.managementModal.deleteAllStackElement();
          }
        },
        error: () => {
          this.corporateForm.get('name').setValue(this.getCompanyName());
          this.corporateForm.get('company').setValue(this.getCompanyName());
          if (!this.corporateForm.get('name').value) {
            this.activeCorporate(false);
          }
        },
      });
  }

  activeCorporate(value: boolean): void {
    this.corporateForm.get('corporate').setValue(value);
    this.setIsCorporate(value);
    if (value) {
      this.userService.setTransportCorporateVerticalSelected();
      if (
        this.corporateForm.get('corporate').value &&
        !this.corporateForm.get('name').value &&
        !this.userService.isTransportVacationalVerticalSelected()
      ) {
        this.corporateModal();
      }
    } else {
      this.userService.setTransportVacationalVerticalSelected();
    }
  }

  resetFormPax(paxForm: any, mainForm: any, disabledContactForm?: boolean) {
    Object.keys(paxForm.controls).forEach((element) => {
      if (!this.descartesResetFormPassenger.includes(element)) {
        if (element === 'corporate') {
          paxForm.get(element)?.get('corporate').setValue('');
          paxForm.get(element)?.get('corporate').setValue('');
        } else {
          paxForm.get(element)?.setValue('');
        }
      }
    });
    if (mainForm?.get('largeFamilyInfo')) {
      mainForm.get('largeFamilyInfo').reset();
    }
    if (
      mainForm?.get('contact') &&
      paxForm.get('position').value - 1 === 0 &&
      !disabledContactForm
    ) {
      mainForm.get('contact').reset();
    }
  }

  completeInfoCorporativeFormReservation(
    form: AbstractControl,
    value: any,
    company: string,
    op_company: string,
    candidateResident: string,
    docsOptions: any,
    mainForm: any
  ) {
    this.resetFormPax(form, mainForm?.form, true);
    form.get('name')?.setValue(value.names);
    form.get('surname')?.setValue(value.surnames);
    form.get('surnameAutocomplete')?.setValue(value.surnames);
    form.get('corporate')?.get('corporate')?.setValue(value.locator);
    this.showProfileEmployeeButton$.next({
      index: form.get('position')?.value - 1,
      value: true,
    });
    if (value?.extras?.treatment) {
      value.extras.treatment = value.extras.treatment === 'ms' ? 'mrs' : 'mr';
      form
        .get(form.get('position')?.value - 1 + 'gender')
        ?.setValue(this.capitalize.transform(value.extras.treatment));
    }

    if (
      value?.fidelity_cards &&
      form.get('frequent_traveller') &&
      value?.fidelity_cards.filter((card) => card.company === company).length >
        0
    ) {
      let numberFF = value?.fidelity_cards.find(
        (card) => card.company === company
      )?.number;
      if (!numberFF.includes(company)) {
        numberFF = company.concat(numberFF);
      }
      form.get('frequent_traveller')?.setValue(numberFF);
    } else if (
      value?.fidelity_cards &&
      form.get('frequent_traveller') &&
      value?.fidelity_cards.filter((card) => card.company === op_company)
        .length > 0
    ) {
      let numberFF = value?.fidelity_cards.find(
        (card) => card.company === op_company
      )?.number;
      if (!numberFF.includes(op_company)) {
        numberFF = op_company.concat(numberFF);
      }
      form.get('frequent_traveller')?.setValue(numberFF);
    }

    if (value?.documents.length > 0) {
      this.documents = value.documents;
      let doc;
      if (form.get('doc_type') && form.get('doc_type')?.value) {
        doc = value.documents.find(
          (doc) => doc.type === form.get('doc_type')?.value
        );
      } else if (value.documents && docsOptions) {
        doc = value.documents.find((doc) =>
          docsOptions.enums.includes(doc.type)
        );
      }
      if (doc) {
        form.get('doc_type')?.setValue(doc.type);
      }
    }

    if (value?.birthdate && form.get('birthdate')) {
      form
        .get('birthdate')
        ?.setValue(moment(value.birthdate).format('DD-MM-YYYY'));
    }
    if (
      value?.resident_type &&
      form.get('resident_city') &&
      candidateResident &&
      value?.resident_type === candidateResident
    ) {
      form.get('resident_city')?.setValue(value?.resident_city);
    }
    if (mainForm) {
      if (
        mainForm?.form?.get('largeFamilyInfo') &&
        value.family_discount_number &&
        mainForm.type === value.family_discount_type
      ) {
        mainForm.form
          .get('largeFamilyInfo')
          .get('numCard')
          .setValue(value.family_discount_number);
        mainForm.form
          .get('largeFamilyInfo')
          .get('autonomousCommunity')
          .setValue(value.resident_type);
      }
      if (
        mainForm?.form?.get('contact') &&
        form.get('position')?.value - 1 === 0
      ) {
        mainForm.form.get('contact').get('name').setValue(value.names);
        mainForm.form.get('contact').get('surname').setValue(value.surnames);
        mainForm.form
          .get('contact')
          .get('email')
          .setValue(value.emails ? value.emails[0] : '');
        mainForm.form
          .get('contact')
          .get('phone')
          .setValue(value.phones ? value.phones[0] : '');
      }
    }
  }

  profileEmployeeCorporate(value: any) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    //return this.http.get<any>('assets/mocks/employeeProfile.json');
    return this.http.get<any>(
      `${environment.api_url}/api/profile/v2/traveller/view-profile/${value}`,
      { headers }
    );
  }

  searchEmployeeCorporate(value: any) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    const body = {
      locator: value,
      type: 'Traveler',
      source: '1A',
    };
    //return this.http.get<any>('assets/mocks/apellidosSearchLocator.json');
    return this.http.post<any>(
      `${environment.api_url}/api/profile/v2/traveller/sync-source`,
      body,
      { headers }
    );
  }

  searchEmployeeCorporateBySurname(value: any) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    headers = headers.append('intercept', 'no');
    let params = new HttpParams();
    params = params.append('format', 'json');
    params = params.append('company', this.getCompany()?.id);
    params = params.append('key', value ?? '');
    //return this.http.get<any>('assets/mocks/apellidosSearch.json');
    return this.http.get<any>(
      environment.api_url + '/api/profile/v2/traveller/search',
      { params, headers }
    );
  }

  reloadCorporate() {
    this.user = this.userService.getCurrentUSer();
    const wsuser = this.user?.u?.ws ?? this.user?.user?.ws;
    const wspass = this.user?.u?.wp ?? this.user?.user?.wp;
    const companyId = this.getCompany()?.id;

    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    let params = new HttpParams();
    params = params.append('locale', 'es');
    params = params.append('connector', '1A');
    params = params.append('user', wsuser!);
    params = params.append('password', wspass!);

    return this.http.get<any>(
      environment.api_url + '/api/corporate/company/sync/' + companyId,
      { params, headers }
    );
  }
}
