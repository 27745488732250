<ng-container *ngIf="parentForm" [formGroup]="parentForm">
  <label class="switch">
    <input
      type="checkbox"
      [value]="parentForm.get(fCName).value"
      [id]="options.id ? options.id : fCName"
      [formControlName]="fCName"
    />
    <span
      class="slider round"
      [ngClass]="{showSVGCheck,colorClient: options.colorClient}"
    >
    </span>
  </label>
  <app-errors-fields
    [errors]="parentForm.controls[fCName].errors"
    [show]="options.showErrors"
  >
  </app-errors-fields>
</ng-container>
<ng-container *ngIf="!parentForm">
  <label
    class="switch"
    [pTooltip]="item.tooltipText | translate"
    [tooltipPosition]="'top'"
    [tooltipDisabled]="!item?.isDisabled && item?.showTooltip"
    [ngClass]="{ colorDisabled: disabledSwitch }"
  >
    <input
      type="checkbox"
      [checked]="item.value"
      (click)="sendActionParent(item)"
      [disabled]="item.isDisabled"
    />
    <span
      class="slider round"
      [ngClass]="{showSVGCheck,colorClient: options.colorClient, colorDisabled: disabledSwitch}"
    >
    </span>
  </label>
</ng-container>
