import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class SurnamesInitials implements PipeTransform {
  transform(value: string): string {
    const names = value.split(' ').filter((n) => n !== '');
    const initials = names.reduce(
      (result, name) => (result += `${name.charAt(0)}. `),
      ''
    );
    return initials.toUpperCase();
  }
}
