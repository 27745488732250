import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FlightSearchForm } from 'src/app/modules/application/transport/flights/form-type/flight-search-form';
import { TrainsSearchForm } from 'src/app/modules/application/transport/components/search/train-search-form';
import { SearchCommonCalendarModel } from 'src/app/modules/application/transport/components/search/components/search-common-calendar/search-common-calendar-model.component';

@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
})
export class InputCalendarComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() isCallCenter = false;
  @Input() transport  = 'flight';
  @Input() dataComponent: SearchCommonCalendarModel;

  @Output() daysFlexSelected = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];

  formFocus: FormGroup;
  show = false;
  minDate = moment().toDate();
  maxDate = moment().add(1, 'year').toDate();
  private wasInside = false;
  private controlNameFocus = '';
  private allControlName = [
    'initialDay',
    'finalDay',
    'initialDay0',
    'initialDay1',
    'initialDay2',
    'initialDay3',
    'initialDay4',
  ];
  // showIata: number;
  private name = '';
  private index = '';
  private shift = false;
  dateHover: Pdate;

  @HostListener('click', ['$event'])
  clickInside(): void {
    this.eventInside();
  }

  @HostListener('document:click', ['$event.target'])
  clickOut(event: Element): void {
    this.checkShow(event);
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent): void {
    const target = event.target as HTMLTextAreaElement;

    if (this.shift && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === 'Tab' && this.show) {
        if (this.name !== 'finalDay') {
          this.closeCalendar();
        }
        if (this.transport === 'flight') {
          this.flightSearchForm.focusPreviousElement(this.name, this.index);
        } else if (this.transport === 'train') {
          this.trainsSearchForm.focusPreviousElement(this.name);
        }
      } else if (
        event.key === 'Tab' &&
        (target.id.includes('origin') ||
          target.id.includes('destination') ||
          target.id === 'pax-selector' ||
          target.id === 'search-common-passangers')
      ) {
        if (this.transport === 'flight') {
          this.flightSearchForm.focusPreviousElement(target.id, this.index);
        } else if (this.transport === 'train') {
          this.trainsSearchForm.focusPreviousElement(target.id);
        }
      }
      return;
    }
    if (event.key === 'Tab' && this.show) {
      if (
        this.form.get('type')?.value !== 'round-trip' ||
        this.name === 'finalDay'
      ) {
        this.closeCalendar();
      }
      if (this.transport === 'train' && this.name !== '') {
        this.trainsSearchForm.focusNextElement(this.name);
      } else {
        this.flightSearchForm.focusNextElement(this.name, this.index);
      }
    }

    // Control para multidestino cuando se pulsa el tabulador y abrir calendario

    if (
      event.key === 'Tab' &&
      this.form.get('type')?.value === 'multi-destination' &&
      target.id !== 'destination' &&
      target.id.includes('destination')
    ) {
      this.index = target.id.charAt(target.id.length - 1);
      const nextInitialDay = document.getElementById(
        'initialDay' + this.index
      ) as HTMLTextAreaElement;
      this.checkShow(nextInitialDay);
    }

    if (event.key === 'Shift') {
      this.shift = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUp(event: KeyboardEvent): void {
    if (event.key === 'Shift') {
      this.shift = false;
    }
  }

  constructor(
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private flightSearchForm: FlightSearchForm,
    private trainsSearchForm: TrainsSearchForm
  ) {}

  ngOnInit(): void {
    this.config.setTranslation(this.translateService.instant('calendar.trans'));
    this.initCalendar();
    this.initForceCalendarOpen();
    if (this.transport !== 'train') {
      this.initFlexDays();
    }
    this.initControlFlexDays();
  }

  private eventInside(): void {
    this.wasInside = true;
  }

  private checkShow(event: Element): void {
    if (!this.wasInside) {
      if (this.allControlName.includes(event.id)) {
        if (
          document.getElementById(this.name + this.index) &&
          document
            .getElementById(this.name + this.index)
            ?.classList.contains('focus')
        ) {
          document
            .getElementById(this.name + this.index)
            ?.classList.remove('focus');
          this.show = false;
        }
        this.name = event.id;
        this.index = '';
        this.formFocus = this.form;
        if (event.id.includes('initialDay')) {
          this.name = 'initialDay';
          if (event.id !== 'initialDay') {
            this.index = event.id.charAt(event.id.length - 1);
            this.formFocus = (
              this.form.get('multiDestination') as FormArray
            ).at(+this.index) as FormGroup;
          }
        }
        if (this.form.get('calendar')) {
          this.form
            .get('calendar')
            ?.setValue(this.formFocus.get(this.name)?.value, {
              emitEvent: false,
            });
        }

        if (
          this.form.get('type')?.value !== 'multi-destination' ||
          (this.form.get('type')?.value === 'multi-destination' &&
            this.name !== 'finalDay')
        ) {
          if (document.getElementById(this.controlNameFocus)) {
            document
              .getElementById(this.controlNameFocus)
              ?.classList.remove('focus');
          }
          this.controlNameFocus = this.name;
          document.getElementById(event.id)?.classList.add('focus');
        }
      }
      if (event?.classList?.contains('calendar-search-bar')) {
        if (
          this.form.get('type')?.value !== 'multi-destination' ||
          (this.form.get('type')?.value === 'multi-destination' &&
            this.name !== 'finalDay')
        ) {
          this.show = true;
          document.getElementById('calendar-dropdown-box')!.style.top =
            (event.getBoundingClientRect().top + scrollY + 38).toString() +
            'px';
        }
      } else {
        this.closeCalendar();
      }
    }
    this.wasInside = false;
  }

  private closeCalendar(buttonComplete: boolean = false): void {
    this.show = false;
    if (
      document.getElementById(this.controlNameFocus) &&
      document
        .getElementById(this.controlNameFocus)
        ?.classList.contains('focus')
    ) {
      document.getElementById(this.controlNameFocus)?.classList.remove('focus');
    }
    if (
      this.index !== '' &&
      document.getElementById(this.name + this.index) &&
      document
        .getElementById(this.name + this.index)
        ?.classList.contains('focus')
    ) {
      document
        .getElementById(this.name + this.index)
        ?.classList.remove('focus');
    }

    if (buttonComplete) {
      if (this.transport === 'flight') {
        this.flightSearchForm.focusNextElement(this.name, this.index);
      } else if (this.transport === 'train') {
        this.trainsSearchForm.focusNextElement(this.name, this.index);
      }
    }
  }

  private initCalendar(): void {
    if (this.isCallCenter){
      this.maxDate = moment().add(330, 'days').toDate();
    }
    this.form.addControl('calendar', new FormControl());
    this.subscriptions.push(
      this.form.get('calendar')!.valueChanges.subscribe((res) => {
        if (this.formFocus && this.formFocus.get(this.controlNameFocus)) {
          this.formFocus.get(this.controlNameFocus)?.setValue(res);
          if (
            this.name !== 'finalDay' &&
            this.form.get('type')?.value !== 'one-way'
          ) {
            if (this.form.get('type')?.value !== 'round-trip') {
              this.closeCalendar();
            }
            if (this.transport === 'train') {
              this.trainsSearchForm.focusNextElement(this.name, this.index);
            } else {
              this.flightSearchForm.focusNextElement(this.name, this.index);
            }
          }
        }
      })
    );
  }

  private initForceCalendarOpen(): void {
    this.subscriptions.push(
      this.form.get('forceCalendarOpen')!.valueChanges.subscribe((event) => {
        if (event) {
          this.checkShow(event);
        }
      })
    );

    const multiDestination = this.form.get('multiDestination') as FormArray;

    multiDestination?.controls.forEach((element, index): void => {
      this.subscriptions.push(
        multiDestination
          .at(index)
          .get('forceCalendarOpen')!
          .valueChanges.subscribe((event) => {
            if (event) {
              this.checkShow(event);
            }
          })
      );
    });
  }

  initControlFlexDays(): void {
    if (this.form.get('type')) {
      this.subscriptions.push(
        this.form.get('type')!.valueChanges.subscribe((value) => {
          if (value !== 'round-trip') {
            this.form.get('originDayFlex')?.reset();
            this.form.get('destinationDayFlex')?.reset();
            this.form.get('daysFlex')?.reset();
          }
        })
      );
    }
  }

  controlDaysFlex(name: string): void {
    this.form.get(name)?.setValue(!this.form.get(name)?.value);
  }

  initFlexDays(): void {
    this.subscriptions.push(
      this.form.get('daysFlex')!.valueChanges.subscribe((val) => {
        this.daysFlexSelected.emit(val);

        if (
          val &&
          (this.form.get('originDayFlex')?.value ||
            this.form.get('destinationDayFlex')?.value)
        ) {
          this.form.get('originDayFlex')?.setValue(false, { emitEvent: false });
          this.form
            .get('destinationDayFlex')
            ?.setValue(false, { emitEvent: false });
        }
      })
    );
    this.subscriptions.push(
      this.form.get('originDayFlex')!.valueChanges.subscribe((val) => {
        if (val && this.form.get('daysFlex')?.value) {
          this.daysFlexSelected.emit(true);
          this.form.get('daysFlex')?.setValue(false, { emitEvent: false });
        } else if (!val && !this.form.get('destinationDayFlex')?.value) {
          this.daysFlexSelected.emit(false);
        } else {
          this.daysFlexSelected.emit(true);
        }
      })
    );
    this.subscriptions.push(
      this.form.get('destinationDayFlex')!.valueChanges.subscribe((val) => {
        if (val && this.form.get('daysFlex')?.value) {
          this.daysFlexSelected.emit(true);
          this.form.get('daysFlex')?.setValue(false, { emitEvent: false });
        } else if (!val && !this.form.get('originDayFlex')?.value) {
          this.daysFlexSelected.emit(false);
        } else {
          this.daysFlexSelected.emit(true);
        }
      })
    );
  }
  //Se comenta este metodo hasta la implatación de la visualizción de iata
  // fillShowIata(date: Pdate){
  //   const dateToCompare = moment()
  //     .year(date.year)
  //     .month(date.month)
  //     .date(date.day)
  //   const initialDay = moment(this.form.get('initialDay').value);
  //   const multiForm: FormArray = this.form.get('multiDestination') as FormArray;
  //   const initialDay0 = moment(multiForm.at(0).get('initialDay').value);
  //   const initialDay1 = moment(multiForm.at(1).get('initialDay').value);
  //   const initialDay2 = moment(multiForm.at(2).get('initialDay').value);
  //   const initialDay3 = moment(multiForm.at(3).get('initialDay').value);
  //   const initialDay4 = moment(multiForm.at(4).get('initialDay').value);

  //   if(dateToCompare.isSame(initialDay, 'day')){
  //     this.showIata = 5
  //   }
  //   if
  //       (multiForm.at(0).get('initialDay').enabled &&
  //         dateToCompare.isSame(initialDay0, 'day')){
  //         this.showIata = 0;
  //         }
  //   if
  //       (multiForm.at(1).get('initialDay').enabled &&
  //         dateToCompare.isSame(initialDay1, 'day')){
  //           this.showIata = 1;
  //          }
  //   if
  //       (multiForm.at(2).get('initialDay').enabled &&
  //         dateToCompare.isSame(initialDay2, 'day')){
  //            this. showIata = 2;
  //         }
  //         if
  //       (multiForm.at(3).get('initialDay').enabled &&
  //         dateToCompare.isSame(initialDay3, 'day')){
  //             this.showIata = 3;
  //         }
  //         if
  //         (multiForm.at(4).get('initialDay').enabled &&
  //           dateToCompare.isSame(initialDay4, 'day')){
  //            this.showIata = 4;

  //           }
  //  return this.showIata;
  // }

  otherDaysSelected(date: Pdate): boolean {
    let response = false;
    const dateToCompare = moment()
      .year(date.year!)
      .month(date.month!)
      .date(date.day!);
    const initialDay = moment(this.form.get('initialDay')?.value);
    const finalDay = moment(this.form.get('finalDay')?.value);
    if (this.transport === 'train') {
      if (
        (this.form.get('type')?.value !== 'one-way' &&
          this.form.get('type')?.value === 'round-trip' &&
          dateToCompare.isSame(finalDay, 'day')) ||
        dateToCompare.isSame(initialDay, 'day')
      ) {
        response = true;
      }
      return response;
    }

    const multiForm: FormArray = this.form.get('multiDestination') as FormArray;
    const initialDay0 = moment(multiForm.at(0).get('initialDay')?.value);
    const initialDay1 = moment(multiForm.at(1).get('initialDay')?.value);
    const initialDay2 = moment(multiForm.at(2).get('initialDay')?.value);
    const initialDay3 = moment(multiForm.at(3).get('initialDay')?.value);
    const initialDay4 = moment(multiForm.at(4).get('initialDay')?.value);
    if (
      this.form.get('type')?.value !== 'one-way' &&
      ((this.form.get('type')?.value === 'round-trip' &&
        dateToCompare.isSame(finalDay, 'day')) ||
        dateToCompare.isSame(initialDay, 'day') ||
        (multiForm.at(0).get('initialDay')?.enabled &&
          dateToCompare.isSame(initialDay0, 'day')) ||
        (multiForm.at(1).get('initialDay')?.enabled &&
          dateToCompare.isSame(initialDay1, 'day')) ||
        (multiForm.at(2).get('initialDay')?.enabled &&
          dateToCompare.isSame(initialDay2, 'day')) ||
        (multiForm.at(3).get('initialDay')?.enabled &&
          dateToCompare.isSame(initialDay3, 'day')) ||
        (multiForm.at(4).get('initialDay')?.enabled &&
          dateToCompare.isSame(initialDay4, 'day')))
    ) {
      response = true;
    }
    return response;
  }

  daysBetween(date: Pdate): boolean {
    let response = false;
    const dateToCompare = moment()
      .year(date.year!)
      .month(date.month!)
      .date(date.day!);
    const initialDay = moment(this.form.get('initialDay')?.value);
    const finalDay = moment(this.form.get('finalDay')?.value);
    if (
      this.form.get('type')?.value === 'round-trip' &&
      dateToCompare.isBetween(initialDay, finalDay)
    ) {
      response = true;
    }
    return response;
  }

  selectedFocus(date: Pdate): boolean {
    let response = false;
    const dateToCompare = moment()
      .year(date.year!)
      .month(date.month!)
      .date(date.day!);
    let focus = moment();
    if (this.index === '') {
      focus = moment(this.form.get(this.controlNameFocus)?.value);
    } else {
      focus = moment(
        (this.form.get('multiDestination') as FormArray)
          .at(+this.index)
          .get(this.name)?.value
      );
    }
    if (dateToCompare.isSame(focus, 'day')) {
      response = true;
    }
    return response;
  }

  flexibleDates(date: Pdate): boolean {
    let response = false;
    const dateToCompare = moment()
      .year(date.year!)
      .month(date.month!)
      .date(date.day!);
    if (
      this.form.get('originDayFlex')?.value &&
      (dateToCompare.isBetween(
        moment(this.form.get('initialDay')?.value).subtract(1, 'day'),
        moment(this.form.get('initialDay')?.value).add(1, 'day'),
        'day',
        '[]'
      ) ||
        (this.dateHover &&
          this.controlNameFocus === 'initialDay' &&
          dateToCompare.isBetween(
            moment()
              .year(this.dateHover.year!)
              .month(this.dateHover.month!)
              .date(this.dateHover.day!)
              .subtract(1, 'day'),
            moment()
              .year(this.dateHover.year!)
              .month(this.dateHover.month!)
              .date(this.dateHover.day!)
              .add(1, 'day'),
            'day',
            '[]'
          )))
    ) {
      response = true;
    }
    if (
      this.form.get('destinationDayFlex')?.value &&
      (dateToCompare.isBetween(
        moment(this.form.get('finalDay')?.value).subtract(1, 'day'),
        moment(this.form.get('finalDay')?.value).add(1, 'day'),
        'day',
        '[]'
      ) ||
        (this.dateHover &&
          this.controlNameFocus === 'finalDay' &&
          dateToCompare.isBetween(
            moment()
              .year(this.dateHover.year!)
              .month(this.dateHover.month!)
              .date(this.dateHover.day!)
              .subtract(1, 'day'),
            moment()
              .year(this.dateHover.year!)
              .month(this.dateHover.month!)
              .date(this.dateHover.day!)
              .add(1, 'day'),
            'day',
            '[]'
          )))
    ) {
      response = true;
    }
    if (
      this.form.get('daysFlex')?.value &&
      (dateToCompare.isBetween(
        moment(this.form.get('initialDay')?.value).subtract(3, 'day'),
        moment(this.form.get('initialDay')?.value).add(3, 'day'),
        'day',
        '[]'
      ) ||
        (dateToCompare.isBetween(
          moment(this.form.get('finalDay')?.value).subtract(3, 'day'),
          moment(this.form.get('finalDay')?.value).add(3, 'day'),
          'day',
          '[]'
        ) &&
          this.form.get('type')?.value !== 'one-way') ||
        (this.dateHover &&
          dateToCompare.isBetween(
            moment()
              .year(this.dateHover.year!)
              .month(this.dateHover.month!)
              .date(this.dateHover.day!)
              .subtract(3, 'day'),
            moment()
              .year(this.dateHover.year!)
              .month(this.dateHover.month!)
              .date(this.dateHover.day!)
              .add(3, 'day'),
            'day',
            '[]'
          )))
    ) {
      response = true;
    }
    return response;
  }

  dateOver(date: Pdate): void {
    this.dateHover = date;
  }

  dateOut(): void {
    this.dateHover = null!;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

interface Pdate {
  day?: number;
  month?: number;
  selectable?: boolean;
  today?: boolean;
  year?: number;
}
