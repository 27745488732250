export const imageSrc = {
  LG: 'luxair.svg',
  KM: 'airmalta.svg',
  '9F': 'eurostars.svg',
  XQ: 'sunexpress.svg',
  A3: 'aegean-lleego.svg',
  FZ: 'flydubai.svg',
  ME: 'middleeastairline.svg',
  PS: 'flyuia.svg',
  SU: 'aeroflot.svg',
  N4: 'nordwindairlines.svg',
  LO: 'lotpolishairlines.svg',
  KU: 'kuwaitairlines.svg',
  TU: 'tunisair-lleego.svg',
  ug: 'tunisair_express-lleego.svg',
  UG: 'tunisair_express-lleego.svg',
  SV: 'saudiarabianairlines.svg',
  UL: 'srilankan.svg',
  WE: 'thaismile.svg',
  SL: 'thailionair.svg',
  TG: 'thaiairwaysinternational.svg',
  IB: 'iberia.svg',
  VY: 'vueling.svg',
  AF: 'airfrance.svg',
  BA: 'britishairways.svg',
  CJ: 'british_cityflyer.svg',
  OS: 'austrian.svg',
  SN: 'brussels.svg',
  LX: 'swiss.svg',
  LH: 'lufthansa.svg',
  KL: 'klm.svg',
  I2: 'iberiaexpress.svg',
  TP: 'tapairportugal.svg',
  UX: 'aireuropa.svg',
  AA: 'americanairlines.svg',
  I0: 'level.svg',
  AC: 'aircanada.svg',
  AZ: 'ita.svg',
  YW: 'airnostrum.svg',
  EW: 'eurowings.svg',
  FR: 'ryanair.svg',
  RR: 'ryanair.svg',
  RK: 'ryanair.svg',
  UA: 'united.svg',
  LA: 'latam.svg',
  DS: 'easyjet-imagotipo.svg',
  AV: 'avianca.svg',
  AT: 'royalairmaroc.svg',
  TK: 'turkishairlines.svg',
  DY: 'norwegian.svg',
  D8: 'norwegian.svg',
  EK: 'emirates.svg',
  QR: 'qatarairways.svg',
  DL: 'delta.svg',
  NT: 'bintercanarias.svg',
  PU: 'plusultra.svg',
  OK: 'czechairlines.svg',
  AM: 'aeromexico.svg',
  TO: 'transavia.svg',
  HV: 'transavia.svg',
  V7: 'volotea.svg',
  EI: 'aerlingus.svg',
  W6: 'wizzair.svg',
  OE: 'laudamotion.svg',
  LS: 'jet2.svg',
  '0B': 'blueair.svg',
  DE: 'condor.svg',
  DX: 'danish.svg',
  NK: 'spirit.svg',
  '4O': 'interjet.svg',
  BV: 'bluepanorama.svg',
  D7: 'airasia.svg',
  F9: 'frontier.svg',
  JQ: 'jetstar.svg',
  VB: 'viva.svg',
  '2W': '2w.svg',
  E9: 'lleego-iberojet-imagotipo.svg',
  BT: 'lleego-airbaltic.svg',
  SK: 'lleego-scandinavian_airlines.svg',
  RNF: 'lleego-renfe.svg',
  RN: 'lleego-renfe.svg',
  AVLO: 'renfe_avlo.svg',
  AVE: 'renfe_ave.svg',
  'AVE-TGV': 'renfe_ave.svg',
  'AVE INT': 'renfe_ave.svg',
  EY: 'lleego-etihad.svg',
  F7: 'lleego-etihad.svg',
  MS: 'lleego-egyptair.svg',
  RJ: 'lleego-royal_jordanian.svg',
  PC: 'lleego-pegasus.svg',
  OP: 'lleego-pegasus.svg',
  QS: 'lleego-smartwings.svg',
  AY: 'lleego-finnair.svg',
  FI: 'lleego-icelandair.svg',
  AR: 'lleego-aerolineasargentinas.svg',
  IRY: 'iryo.svg',
  IR: 'iryo.svg',
  I6: 'iryo.svg',
  '9B': 'accesrail.svg',
  '3O': 'airarabia.svg',
  CA: 'airchina.svg',
  NH: 'anaallniponairlines.svg',
  EH: 'anaallniponairlines.svg',
  PG: 'bangkokairways.svg',
  OB: 'boaboliviadeaviacion.svg',
  K6: 'cambodia_angkor_air.svg',
  CX: 'cathaypacific.svg',
  CM: 'copaairlines.svg',
  U2: 'easyjet.svg',
  Q4: 'euroairlines.svg',
  JL: 'jal.svg',
  SQ: 'singapore_airlines.svg',
  RO: 'tarom.svg',
  WT: 'uepfly.svg',
  VN: 'vietnam_airlines.svg',
  VX: 'virgin-america-lleego.svg',
  HR: 'hahn-air-lleego.svg',
  TC: 'air-tanzania-lleego.svg',
  TX: 'air-caraibes-lleego.svg',
  BF: 'french-bee-lleego.svg',
  P5: 'wingo-lleego.svg',
  ET: 'ethiopian-airlines-lleego.svg',
  KQ: 'kenya-airways-lleego.svg',
  A5: 'hop.svg',
  KE: 'korean-air.svg',
  W4: 'wizzair.svg',
  AI: 'air-india.svg',
  MF: 'xiamen_airlines_lleego.svg',
  VS: 'virgin_atlantic_lleego.svg',
  TR: 'flyscoot_lleego.svg',
  UK: 'vistara_lleego.svg',
  PR: 'philippine_airlines_lleego.svg',
  H2: 'sky_airline_lleego.svg',
  GQ: 'sky_express_lleego.svg',
  JU: 'airserbia_lleego.svg',
  HC: 'Air_Senegal.svg',
  H1: 'Hahn_Air_Systems.svg',
  LY: 'EL_AL.svg',
  L6: 'mauritania_airlines.svg',
  XZ: 'aeroitalia.svg',
  QF: 'qantas.svg',
  AH: 'air_algerie_logo.svg',
  HF: 'aircote_lleego.svg',
  IZ: 'arkia_lleego.svg',
  X1: 'hahn_lleego.svg',
  '6H': 'israir_lleego.svg',
  WJ: 'jetsmart_lleego.svg',
  DT: 'taag_lleego.svg',
  MH: 'malaysia_airlines.svg',
  OA: 'OLYMPIC_AIR.svg',
  G3: 'gol.svg',
  A1: 'apg.svg',
  AD: 'azul_brazilian_airlines.svg',
  UR: 'utair.svg',
  ZN: 'naysa.svg',
  CZ: 'china_southern.svg',
  ES: 'estelar.svg',
  '4Y': 'eurowings_discover.svg',
  NI: 'portugalia.svg',
  MU: 'china_eastern.svg',
  FM: 'shanghai.svg',
  OZ: 'asiana.svg',
  BR: 'eva_air.svg',
  OU: 'croatia_airlines.svg',
  VA: 'virgin_australia.svg',
  '4Z': 'airlink.svg',
  ZH: 'shenzhen_airlines.svg',
  HY: 'uzbekistan_airlines.svg',
  HH: 'qANOT.svg',
  GF: 'gult_air.svg',
  S7: 's7airlines.svg',
  SA: 'south_african_airways.svg',
  PM: 'canaryFly.svg',
  HU: 'hainan.svg',
  X3: 'tui_fly.svg',
  '4R': 'renfe.svg',
  GA: 'garuda_indonesia.svg',
  QV: 'lao_airlines.svg',
  WF: 'wideroe.svg',
  OD: 'malindo.svg',
  ID: 'batikAir.svg',
  AS: 'alaska.svg',
  '2L': 'helvetic.svg',
  A0: 'british_euro.svg',
  JD: 'capital_airlines.svg',
  '5I': 'alsa.svg',
  KC: 'air astana.svg',
  VZ: 'vietjetair.svg',
  VJ: 'vietjetair.svg',
  A9: 'georgian airways.svg',
  J2: 'azerbaijan airlines.svg',
  HO: 'juneyao airlines.svg',
  MM: 'peach aviaton.svg',
  W2: 'FLEXFLIGHT APS.svg',
  S4: 'azores airlines.svg',
  TB: 'tui fly.svg',
  EN: 'air dolomiti.svg',
  LM: 'Loganair.svg',
  YP: 'air premia.svg',
  CI: 'china airlines.svg',
  PN: 'West Air China.svg',
  '1S': '1S.svg',
  '1A': '1A.svg',
  '1G': '1G.svg',
  EV: 'EV.svg',
  NV: 'NV.svg',
  PY: 'PY.svg',
  TF: 'TF.svg',
  NX: 'NX.svg',

  // TODO: nos falta el IATA APRA ASOCIARLO
  //VB: 'lleego-iryo.svg',
};
