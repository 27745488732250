import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-chip-multiselect',
  templateUrl: './chip-multiselect.component.html',
  styleUrls: ['./chip-multiselect.component.scss'],
})
export class ChipMultiselectComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};

}
