<ng-container [formGroup]="parentForm">
  <div class="form">
    <input class="checkbox" type="checkbox" [checked]="checked" />
    <label (click)="checkboxControl()" [for]="options.id ? options.id : fCName">
      <div></div>
    </label>
    <input
      type="{{ type ? type : 'text' }}"
      placeholder="{{ options.placeholder | translate }}"
      [ngClass]="options.class"
      [id]="options.id ? options.id : fCName"
      [formControlName]="fCName"
      #icon
      OnlyNumber="{{ options.onlyNumber }}"
      [mask]="options.mask"
      [prefix]="options.prefix || ''"
      [readonly]="options.readonly"
      [maxlength]="options.maxlength"
      [pTooltip]="options.tooltip | translate"
      [tooltipPosition]="'top'"
      [attr.role]="'combobox'"
      aria-autocomplete="both"
      aria-haspopup="false"
      autocapitalize="off"
      autocomplete="on"
      autocorrect="off"
      spellcheck="false"
      [attr.data-cy]="options.id ? options.id : fCName"
    />
    <span class="inputLabel">{{ options.label | translate }}</span>
  </div>
</ng-container>
<app-errors-fields
  [field]="icon"
  [errors]="parentForm.controls[fCName]?.errors"
  [show]="options.showErrors"
  [messageError]="options.errorMessage"
>
</app-errors-fields>
