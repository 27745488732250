<ng-container [formGroup]="parentForm">
  <input
    type="checkbox"
    [value]="options.val ? options.val : 'None'"
    [id]="options.id ? options.id : fCName"
    [formControlName]="fCName"
  />
  <label
    [class]="options.class"
    [ngClass]="{ disabled: parentForm.get(fCName)?.disabled }"
    [for]="options.id ? options.id : fCName"
    [attr.data-datavalue]="options.dataValue"
    [pTooltip]="options?.tooltip | translate"
    [tooltipPosition]="'left'"
    [tooltipDisabled]="options?.tooltipDisabled"
    [attr.data-cy]="options.id ? options.id : fCName"
  >
    <div></div>
    <p *ngIf="options.htmlPlaceholder" [innerHTML]="options.placeholder"></p>
    <p *ngIf="!options.truncate && !options.htmlPlaceholder">
      {{ options.placeholder | translate }}
    </p>
    <p
      *ngIf="options.truncate && !options.htmlPlaceholder"
      [pTooltip]="options.description || options.placeholder | translate"
      [tooltipPosition]="'top'"
      [tooltipDisabled]="options.placeholder <= options.truncate"
    >
      {{ options.placeholder | translate | truncate : options.truncate }}
    </p>
    <p class="label-value" *ngIf="options?.labelValue">
      {{ options.labelValue }}
    </p>
    <svg-icon *ngIf="options?.icon" [src]="options.icon"></svg-icon>
  </label>
  <button
    *ngIf="options.info"
    mat-raised-button
    [matTooltip]="options.tooltipText | translate"
    [matTooltipPosition]="options.tooltipPosition"
    [matTooltipClass]="options.class"
    aria-label="Button that displays a tooltip when focused or hovered over"
  >
    <svg-icon src="assets/images/icons/info.svg"></svg-icon>
  </button>
</ng-container>
