<div class="calendar-info">
  <div
    class="date-content"
    [ngClass]="{ focus: initialDay.classList.contains('focus') }"
    data-cy="initialDay"
  >
    <span
      #initialDay
      [id]="
        multiDestinationIndex
          ? 'initialDay' + multiDestinationIndex
          : 'initialDay'
      "
      class="calendar-search-bar span-selector"
      [tabindex]="tabIndexPrefix! + 3"
      (focus)="onFocus($event.target)"
    ></span>
    <div class="date-box">
      <div class="title">
        <span class="title-journey">{{
        'one way_' | translate | uppercase
      }}</span>
      <span *ngIf="originDayFlex" class="flex">&#177; 1</span>
      <span *ngIf="daysFlex" class="flex">&#177; 3</span>
      </div>

      <div class="departure">
        <p>
          {{
            form.get('initialDay')?.value
              | formatDate: 'inputCalendarMoment'
              | titlecase
              | replace: ' ':''
          }}
        </p>
        <svg-icon src="assets/images/icons/icon calendar.svg"> </svg-icon>
      </div>
    </div>
  </div>
  <div
    class="date-content"
    [ngClass]="{
      focus: finalDay.classList.contains('focus'),
      'no-action': !canAddMultiLine()
    }"
    data-cy="finalDay"
  >
    <span
      #finalDay
      id="finalDay"
      class="calendar-search-bar span-selector"
      (click)="changeTypeForm()"
      (focus)="onFocus($event.target)"
      [tabindex]="
        form.get('type') && form.get('type')?.value === 'round-trip' ? 4 : -1
      "
    ></span>
    <div class="date-box">
      <ng-container
        *ngIf="form.get('type') && form.get('type')?.value === 'round-trip'"
      >
      <div class="title">
        <span class="title-journey">{{
        'return_' | translate | uppercase
      }}</span>
      <span  *ngIf="destinationDayFlex" class="flex">&#177; 1</span>
      <span  *ngIf="daysFlex" class="flex">&#177; 3</span>
      </div>
        <div class="return">
          <p>
            {{
              form.get('finalDay').value
                | formatDate: 'inputCalendarMoment'
                | titlecase
                | replace: ' ':''
            }}
          </p>
          <svg-icon src="assets/images/icons/icon calendar.svg"> </svg-icon>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          (form.get('type') && form.get('type')?.value !== 'round-trip') ||
          multiDestinationIndex
        "
      >
        <p
          *ngIf="form.get('type') && form.get('type').value === 'one-way'"
          class="add-date"
        >
          + {{ 'add return_' | translate }}
        </p>
        <p
          *ngIf="
            (form.get('type') &&
              form.get('type').value === 'multi-destination') ||
            multiDestinationIndex
          "
          [ngClass]="{ active: canAddMultiLine() }"
          class="add-date"
        >
          + {{ 'add new_' | translate }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
