export enum PaymentsMethods {
  key1 = 'credit',
  credit = 1,
  key2 = 'transfer',
  transfer = 2,
  key3 = 'deposit',
  deposit = 3,
  key4 = 'uatp',
  uatp = 4,
  key5 = 'cash',
  cash = 5,
  key6 = 'imported',
  imported = 6,
  key7 = 'corporate',
  corporate = 7,
  key8 = 'picard',
  picard = 8,
  key11 = 'voucher',
  voucher = 11,
}
