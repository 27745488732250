import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-autocomplete-ngprime',
  templateUrl: './input-autocomplete-ngprime.component.html',
  styleUrls: ['./input-autocomplete-ngprime.component.scss'],
})
export class InputAutocompleteNgprimeComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() data: ItemAutocomplete[];
  @Input() inputId: string = '';
  @Input() options: any;
  @Input() isTrain: boolean = false;
  @Input() inputsName: string;
  @Output() focus = new EventEmitter<boolean>(false);
  @Output() completed = new EventEmitter<boolean>(false);
  @Output() indexFocus = new EventEmitter<string>();

  inputFocus = false;
  selectedAnyOption = false;

  icon = {
    airport: 'Airport',
    train: 'nav-train',
    all: 'pin',
    nearAirports: 'square',
    error: 'alert',
  };

  onFocus() {
    this.inputFocus = true;
    if (this.parentForm.get(this.fCName)?.value === '' && this.inputsName) {
      this.selectedAnyOption = false;
    }
    this.focus.emit(true);
    this.indexFocus.emit(this.inputId);
    this.completed.emit(false);
  }

  onBlur() {
    this.inputFocus = false;
    this.focus.emit(false);
    this.completed.emit(false);
    if (
      !this.selectedAnyOption &&
      this.inputsName &&
      (this.parentForm.get(this.fCName)?.value === '' ||
        !(this.parentForm.get(this.fCName)?.value instanceof Object))
    ) {
      this.parentForm.get(this.fCName)?.setValue('');
      this.parentForm
        .get('errors')
        ?.setValue({ name: this.inputsName, error: true });
    }
  }

  onSelect(event: ItemAutocomplete) {
    if (event?.nearAirports) {
      if (this.fCName === 'origin') {
        this.parentForm.get('nearAirports')?.setValue(true);
      } else if (this.fCName === 'destination') {
        this.parentForm.get('nearAirportsDest')?.setValue(true);
      }
    } else if (
      this.parentForm.get('nearAirports') &&
      this.parentForm.get('nearAirportsDest')
    ) {
      if (this.fCName === 'origin') {
        this.parentForm.get('nearAirports')?.setValue(false);
      } else if (this.fCName === 'destination') {
        this.parentForm.get('nearAirportsDest')?.setValue(false);
      }
    }

    if (this.inputsName) {
      this.parentForm
        .get('errors')!
        .setValue({ name: this.inputsName, error: false });
      this.selectedAnyOption = true;
    }
    if (event?.title === 'Without results?_') {
      this.parentForm?.get(this.fCName)?.setValue('');
    } else {
      this.completed.emit(true);
    }
  }

  selectIcon(data: ItemAutocomplete) {
    let icon = '';

    if (data.originalData?.name === 'TODOS') {
      icon = !data.nexoTr ? this.icon.all : this.icon.nearAirports;
    } else if (data.error) {
      icon = this.icon.error;
    } else if (data.originalData.type === 'R') {
      icon = this.icon.train;
    } else {
      icon = this.icon.airport;
      if (this.isTrain) {
        icon = this.icon.train;
      }
    }

    return icon;
  }

  checkFirstOfNexo(airport: ItemAutocomplete) {
    const index = this.data.findIndex((item) => item.title === airport.title);
    return this.data[index - 1] && this.data[index - 1].nearAirports;
  }

  checkLastOfNexo(airport: ItemAutocomplete) {
    const index = this.data.findIndex((item) => item.title === airport.title);
    return (
      this.data[index - 1] &&
      this.data[index - 1].nexoTr &&
      ((this.data[index + 1] && !this.data[index + 1].nexoTr) ||
        !this.data[index + 1])
    );
  }
}

export interface ItemAutocomplete {
  title: string;
  subtitle: string;
  nexo: boolean;
  nexoTr: boolean;
  selection: string;
  originalData: any;
  nearAirports?: boolean;
  error?: boolean;
  iata?: string;
  shortName?: string;
}
