import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-only-read',
  templateUrl: './text-only-read.component.html',
  styleUrls: ['./text-only-read.component.scss'],
})
export class TextOnlyReadComponent {
  @Input() options: any = {};
}
