import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DropOffEnum, RentStageEnum } from '../../../modules/application/transport/cars/models/drop-off-enum';
export interface Marker {
  position: {
    lat: number;
    lng: number;
  };
  label?: {
    color: string;
  };
  options: {
    animation: google.maps.Animation;
    draggable?: boolean;
    icon: string;
    clickable: boolean;
  };
  title: string;
  code: string;
  street: string;
  provider: string;
}
export interface MapPosition {
  latitude: number;
  longitude: number;
  zoom?: number;
  markers: Marker[];
}

export interface MapCoordinates {
  tabValue: MapPosition;
}
@Injectable({
  providedIn: 'root'
})
export class MapsService {
  defaultMapPosition: MapPosition = {
    latitude: 40.42950743962384,
    longitude: -3.7056362317815585,
    zoom: 6,
    markers: []
  };

  subscriptions: Subscription[] = [];

  show = new BehaviorSubject<boolean>(false);
  dropOffValue = new BehaviorSubject<string>(DropOffEnum.sameDropOff);
  tabValue = new BehaviorSubject<string>(RentStageEnum.pickup);
  mapPosition = new BehaviorSubject<MapCoordinates>(null!);

  constructor(
  ) {
    this.initMapPosition();
  }

  initMapPosition() {
    const aux: any = {};
    for (const stage of Object.keys(RentStageEnum)) {
      aux[RentStageEnum[stage]] = { ...this.defaultMapPosition };
    }
    this.mapPosition.next(aux);
  }

  getMapPosition(): Observable<MapCoordinates> {
    return this.mapPosition.asObservable();
  }

  resetService() {
    this.show.next(false);
    this.dropOffValue.next(DropOffEnum.sameDropOff);
    this.initMapPosition();
  }

}
