import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsUtilService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  /**
   * Generic notification message
   * @param res; Api response
   * @param message: costume message
   */
  infoResponse(res: any, message?: string) {
    if (res.success) {
      this.toastr.success(
        "<img src='assets/images/icons/success.svg' alt='success' /><p>" +
          this.translateService.instant(message ?? 'genericSuccess_') +
          '</p>',
        ''
      );
    } else {
      this.toastr.error(
        "<img src='assets/images/icons/fail.svg' alt='error' /><p>" +
          this.translateService.instant('genericError_') +
          '</p>',
        ''
      );
    }
  }

  /**
   * Success notification message
   * @param message: costume message
   */
  infoSuccess(message: string) {
    this.toastr.success(
      "<img src='assets/images/icons/success.svg' alt='success' /><p>" +
        this.translateService.instant(message) +
        '</p>',
      ''
    );
  }

  /**
   * Success notification message
   * @param message: costume message
   */
  infoError(message: string, time?) {
    this.toastr.error(
      "<img src='assets/images/icons/fail.svg' alt='error' /><p>" +
        this.translateService.instant(message) +
        '</p>',
      ''
    );
  }
}
