<button
  pRipple
  [disabled]="disabled"
  type="button"
  [ngClass]="class"
  (click)="moveDesElementByID()"
  data-cy="scrollElementButton"
>
  {{ textButton | translate }}
</button>
