import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-select-prueba',
  templateUrl: './select-prueba.component.html',
  styleUrls: ['./select-prueba.component.scss'],
})
export class SelectPruebaComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() data: any[] = [];
  @Input() fCName: string = '';
  @Input() options: any = {};
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  active = false;

  constructor(
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.data?.length === 1 && this.options?.oneSelection) {
      this.parentForm.get(this.fCName)?.setValue(this.data[0]?.value);
    }
  }

  show() {
    this.active = true;
  }

  hide() {
    this.active = false;
  }

  getItem(item: any, options: any) {
    return options.phone
      ? item.name[this.languageService.getLocaleCode()] + ' +' + item.value
      : item.label;
  }
}
