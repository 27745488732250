import { Pipe, PipeTransform } from '@angular/core';
import { Frequency } from 'src/app/modules/application/transport/flights/components/flight-search-form/components/flight-search-frequencies/components/results-frequencies/results-frequencies.component';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(frequencies: Frequency[], fild: string, asc: boolean): Frequency[] {
    if (asc) {
      frequencies = frequencies.sort((a, b) => (a[fild] > b[fild] ? 1 : -1));
    } else {
      frequencies = frequencies.sort((a, b) => (a[fild] < b[fild] ? 1 : -1));
    }

    return frequencies;
  }
}
