import { AgencyModel } from './agency.model';
import { ClientModel } from './client.model';
import { UserModel } from './user.model';
import { DeserializableAbstract } from './deserializable.abstact';
import { ConfigModel } from './user-config.model';
import { UserProviderModel } from './user-provider.model';

// getUserData api response
export class UserDataModel extends DeserializableAbstract {
  // home page default.
  private homePageDefault = '/transport';
  // Agency data.
  public agency: AgencyModel;
  // Agency data.
  public a: AgencyModel;
  // Consolidator data.
  public client: ClientModel;
  // Consolidator data.
  public c: ClientModel;
  // Puede voidear reservas.
  public ibcc: any;
  // User data.
  public user: UserModel;
  // User data.
  public u: UserModel;
  // User services.
  public s: any;
  // iata list.
  public t: any;
  // User provider.
  public p: UserProviderModel[];
  // config: idCRM and idEXP
  public config?: ConfigModel;
  // Url version v1
  public urlBackToVersionOne?: string;
  // User css
  public css: {
    pc: string; // primary color
    sc: string; // secondary color
    tc: string; // third color
    bc: string; // button color
    bhc: string; // button hover color
  };

  override deserialize(input: any): this {
    if (input === null) {
      return input;
    }
    Object.assign(this, input);
    if (input.a) {
      this.agency = new AgencyModel().deserialize(input.a);
    }
    if (input.c) {
      this.client = new ClientModel().deserialize(input.c);
    }
    if (input.u) {
      this.user = new UserModel().deserialize(input.u);
    }
    return this;
  }

  // return homepage for user or default
  public getHomePage(): string {
    if (this.user?.he !== undefined) {
      return this.user.he;
    }
    if (!this.user || (this.user && Object.keys(this.user).length === 0)) {
      return '/login';
    }
    return this.homePageDefault;
  }

  getTransportVerticalKey(): string {
    return 'transport_vertical_' + this.client?.i;
  }
}
