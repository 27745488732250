<section class="alert-modal">

  <ng-container *ngIf="timeToExpire <= 0; else notExpired">
    <div>
      <img id="expirada" src="assets/images/icons/icon-timeout-end.svg" alt="expired"/>
    </div>
    <h2>
      <span>{{ 'sesión expirada_' | translate }}</span>
    </h2>
    <p class="expirada">

      <span>{{ 'por favor vuelva a conectarse_' | translate }}</span>
    </p>
    <div><p id="timer0">{{ '00:00' }}</p></div>
    <div class="buttons">
      <button pRipple class="btn-primary" (click)="refreshToken()">
        <span>{{ 'back to login_' | translate }}</span>
      </button>
    </div>

  </ng-container>

  <ng-template #notExpired>
    <div>
      <img id="timeout" src="assets/images/icons/icon-timeout.svg" alt="timeout"/>
      <div><p id="timer">{{ countDown | formatDate: 'alerts' }}</p></div>
    </div>
    <h2>
      <span>{{ 'sesion a punto de expirar_' | translate }}</span>
    </h2>
    <p>
      <span>{{ 'texto sesion a punto de expirar_' | translate }}</span>
    </p>
    <div class="buttons">
      <button pRipple class="btn-back" (click)="logout()">
        <span>{{ 'logout_close_' | translate }}</span>
      </button>
      <button pRipple class="btn-primary" (click)="refreshToken()">
        <span>{{ 'im here_' | translate }}</span>
      </button>
    </div>

  </ng-template>



</section>
