<div class="type-content" [ngClass]="{ 'type-train': transport === 'train' }">
  <div
    pRipple
    class="title"
    [ngClass]="{ orderActive: orderActive }"
    #ref
    data-cy="flight-search-type"
  >
    <span>
      {{ title | translate }}
    </span>
    <svg-icon
      class="arrow"
      [svgStyle]="{ 'width.px': 7 }"
      src="assets/images/icons/select_arrow.svg"
    ></svg-icon>
  </div>
  <app-dropdown [ref]="ref" [options]="dropdownOptions" [(show)]="show">
    <ul>
      <ng-container *ngFor="let option of data; first as first; last as last">
        <li
          *ngIf="option.value !== form.get('orderBy').value.value"
          (click)="selectValue(option)"
        >
          <div class="separator"></div>
          <span [ngClass]="{ margin: last }">
            {{ option.label | translate }}
          </span>
        </li>
      </ng-container>
    </ul>
  </app-dropdown>
</div>
