<ng-container [formGroup]="parentForm">
  <div
    class="box-autocomplete"
    [ngClass]="{
      errorInput: !parentForm.get(fCName)?.value && options.showErrors
    }"
  >
    <p-autoComplete
      [formControlName]="fCName"
      [suggestions]="data"
      [field]="'selection'"
      [autoHighlight]="true"
      [scrollHeight]="null"
      [inputId]="inputId"
      [delay]="0"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
      (onSelect)="onSelect($event.value)"
      [placeholder]="options?.placeholder"
      [tabindex]="options?.tabindex"
      [attr.data-cy]="fCName"
      [autoOptionFocus]="true"
    >
      <ng-template let-airport let-index pTemplate="item">
        <div
          class="airports"
          [ngClass]="{
            nexo: airport.nexoTr || airport.icon,
            'near-airport': airport.nearAirports,
            first: checkFirstOfNexo(airport),
            last: checkLastOfNexo(airport)
          }"
        >
          <div class="flex">
            <svg-icon
              *ngIf="!airport.disableIcon"
              [class]="'icon ' + selectIcon(airport)"
              [svgStyle]="{ 'height.px': 20 }"
              [src]="'assets/images/icons/' + selectIcon(airport) + '.svg'"
            ></svg-icon>
            <div class="flex-column">
              <span
                class="titleLine"
                [innerHTML]="airport.title | translate"
              ></span>
              <span
                class="descriptionLine"
                [innerHTML]="airport.subtitle | translate"
              ></span>
            </div>
          </div>
          <p class="iata" data-cy="iata">
            {{ airport?.originalData?.code }}
          </p>
        </div>
      </ng-template>
    </p-autoComplete>

    <span
      *ngIf="options?.iata && parentForm.get(fCName)?.value?.value"
      [ngClass]="{ focus: inputFocus }"
      class="info-iata"
    >
      {{ parentForm.get(fCName).value.value }}
    </span>
  </div>
</ng-container>
<app-errors-fields
  [field]="icon"
  [errors]="parentForm.controls[fCName].errors"
  [show]="!parentForm.get(fCName)?.value && options.showErrors"
>
</app-errors-fields>
