import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class RailAppAgencyService {
  constructor(private storageService: StorageService) {}

  getInitialStateBasic() {
    const data = JSON.parse(atob(this.storageService.getItem('RAUD')!));
    return {
      agency_name: ['', [Validators.required, Validators.maxLength(45)]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
        ],
      ],
      pcc: [data.pcc, [Validators.required]],
      agent_id: [data.agentId, [Validators.required]],
      iata: ['', [Validators.pattern(/^[0-9]{8}$/)]],
      errors: [false, []],
    };
  }

  getInitialStateDetail() {
    const data = JSON.parse(atob(this.storageService.getItem('RAUD')!));
    return {
      phonePrefix: ['34', []],
      phone: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      country: [data.country, []],
      city: ['', [Validators.maxLength(45)]],
      timeZone: ['Europe/Madrid', []],
      errors: [false, []],
    };
  }

  getInputsStateFormBasic() {
    return [
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'agency_name',
        options: {
          id: 'agency_name',
          class: 'form-input',
          label: 'agency_name_',
          placeholder: 'agency_name_',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'email',
        options: {
          id: 'email',
          class: 'form-input',
          label: 'email_',
          placeholder: 'agency_email_placeholder_',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'pcc',
        options: {
          id: 'pcc',
          class: 'form-input',
          label: 'pcc_',
          placeholder: 'A0A0',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'agent_id',
        options: {
          id: 'agent_id',
          class: 'form-input',
          label: 'agent_id_',
          placeholder: '000000',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'iata',
        options: {
          id: 'iata',
          class: 'form-input',
          label: 'iata_',
          placeholder: '00000000',
          showErrors: false,
          required: true,
        },
      },
    ];
  }

  getInputsStateFormDetail(countryObj: any, timezoneObj: any) {
    return [
      {
        type: 'select-input',
        headerClass: 'inputPhone1 block-input',
        options: {
          showErrors: false,
          required: true,
          select: {
            label: 'telephone_',
            name: 'phonePrefix',
            showErrors: false,
            required: true,
            options: {
              preselected: '+34',
              value: '+34',
              phone: true,
              class: 'phone',
              data: [],
            },
          },
          input: {
            name: 'phone',
            options: {
              class: 'form-input',
              label: '',
            },
          },
        },
      },
      {
        headerClass: 'block-input country',
        type: 'select',
        name: 'country',
        options: {
          id: 'country',
          class: 'form-input',
          label: 'country_',
          placeholder: 'country_',
          data: countryObj,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'city',
        options: {
          id: 'city',
          class: 'form-input',
          label: 'city_',
          placeholder: 'city_',
          showErrors: false,
        },
      },
      {
        headerClass: 'block-input timezone',
        type: 'select',
        name: 'timeZone',
        options: {
          id: 'timeZone',
          class: 'form-input',
          label: 'time_zone_',
          placeholder: 'time_zone_',
          data: timezoneObj,
        },
      },
    ];
  }

  getAgencyFieldsConfig(): any[] {
    return [
      [
        {
          label: 'agency_name_',
          key: 'agency_name',
        },
        {
          label: 'email_',
          key: 'email',
        },
        {
          label: 'pcc_',
          key: 'pcc',
        },
        {
          label: 'agent_id_',
          key: 'agent_id',
        },
        {
          label: 'iata_',
          key: 'iata',
        },
      ],
      [
        {
          label: 'telephone_',
          key: 'phone',
        },
        {
          label: 'country_',
          key: 'country',
        },
        {
          label: 'city_',
          key: 'city',
        },
        {
          label: 'time_zone_',
          key: 'timeZone',
        },
      ],
    ];
  }
}
