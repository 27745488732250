<ng-container [formGroup]="parentForm">
  <div class="inputContainer">
    <ng-container
      *ngFor="
        let input of options.multipleInputLength;
        index as index;
        first as first
      "
    >
      <input
        autofocus="{{ first }}"
        [id]="options.id + index"
        OnlyNumber="{{ options.onlyNumber }}"
        maxlength="{{ options.maxlength }}"
        type="{{ type ? type : 'text' }}"
        placeholder="{{ options.placeholder | translate }}"
        [formControlName]="fCName + index"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
      />
    </ng-container>
  </div>
</ng-container>
<app-errors-fields
  [field]=""
  [errors]="parentForm.controls[fCName]?.errors"
  [show]="options"
  [messageError]="options.errorMessage"
>
</app-errors-fields>
