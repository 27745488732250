<section>
  <div class="box col-12">
    <div class="img">
      <svg-icon src="assets/images/icons/robot404.svg"></svg-icon>
    </div>
    <div class="text-info">
      <h4>
        {{ 'forbidden_subtitle_' | translate }}
      </h4>
      <h2>
        {{ 'forbidden_title_' | translate }}
      </h2>
      <p>
        {{ 'forbidden_body_' | translate }}
      </p>
    </div>
  </div>
</section>
