import { FormGroup } from '@angular/forms';
import snakecaseKeys from 'snakecase-keys';
import { PaymentsMethods } from 'src/app/modules/application/transport/flights/models/payments-methods';
import { DeserializableAbstract } from '../../deserializable.abstact';

export class PaymentDataModel {
  query: PaymentModel;

  static transDataPaymentQuery(
    form: FormGroup,
    additionalPaymentInfo?: any
  ): any {
    const dataInit = new PaymentDataModel();
    const query = new PaymentModel();
    if (form) {
      const paymentsForm = form.getRawValue();
      const paymentType = paymentsForm.type;

      if (paymentType.name === 'transfer' || paymentType.name === 'deposit') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          depositTransference: {
            bank: paymentsForm[paymentType.name].bank,
            moment: paymentsForm[paymentType.name].moment,
          },
        };
      } else if (paymentType.name === 'uatp') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          card: {
            form: paymentsForm[paymentType.name]?.cardForm || null,
            category: paymentsForm[paymentType.name].cardType.split(';')[1],
            card_type: paymentsForm[paymentType.name].cardType.split(';')[0],
            holder: paymentsForm[paymentType.name].cardHolder,
            number: paymentsForm[paymentType.name].cardNumber.replace(
              /\s/g,
              ''
            ),
            expire_month: paymentsForm[paymentType.name].cardExpire.slice(0, 2),
            expire_year: paymentsForm[paymentType.name].cardExpire.includes('/')
              ? paymentsForm[paymentType.name].cardExpire.split('/')[1]
              : paymentsForm[paymentType.name].cardExpire.slice(2, 6),
            cvv: paymentsForm[paymentType.name].cvv,
          },
        };
      } else if (paymentType.name === 'prisma') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          card: {
            number: paymentsForm[paymentType.name]?.card_number,
            holder: paymentsForm[paymentType.name]?.card_holder_name,
            expire_month: paymentsForm[paymentType.name]?.card_expiration_month,
            expire_year: paymentsForm[paymentType.name]?.card_expiration_year,
            category: paymentsForm[paymentType.name]?.cardType?.split(';')?.[1],
            card_type:
              paymentsForm[paymentType.name]?.cardType?.split(';')?.[0],
            bin: paymentsForm[paymentType.name]?.bin,
            cvv: paymentsForm[paymentType.name]?.security_code,
          },
          token: paymentsForm[paymentType.name]?.token,
        };
      } else if (paymentType.name === 'ib_pay') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          ibPayment: {
            tokenized_card_number:
              paymentsForm[paymentType.name].tokenizedCardNumber,
            billing_address: paymentsForm[paymentType.name].billingAddress,
            street: paymentsForm[paymentType.name].street,
            city: paymentsForm[paymentType.name].city,
            postalCode: paymentsForm[paymentType.name].postalCode.replace(
              /\s/g,
              ''
            ),
            country: paymentsForm[paymentType.name].country,
          },
        };
      } else if (paymentType.name === 'cash') {
        query.payment = {
          type: paymentType.id,
          cash: true,
        };
      } else if (paymentType.name === 'corporate') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          payment_data: {
            credit_card_type:
              paymentsForm[paymentType.name].corporate.credit_card_type,
            credit_card_number:
              paymentsForm[paymentType.name].corporate.credit_card_number,
            type: paymentsForm[paymentType.name].corporate.type,
          },
        };
      } else if (paymentType.id.includes('-')) {
        query.payment = {
          type: parseInt(PaymentsMethods['credit'].toString()),
          cash: false,
          recharge_method: paymentType.id.split('-')[1],
          card_selected:
            paymentsForm[paymentType.name]?.cardType?.toString() ?? null,
          amount: paymentsForm['amount']?.total,
        };
      } else if (paymentType.name === 'voucher') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          voucher: {
            number: paymentsForm[paymentType.name].number,
            mail: paymentsForm[paymentType.name].mail,
          },
        };
      } else if (paymentType.name === 'tpvindra') {
        query.payment = {
          type: paymentType.id,
          cash: false,
          includeServiceFee:
            additionalPaymentInfo?.paymentsAdditionalChecksForm?.serviceFeeTPV,
        };
      } else {
        query.payment = {
          type: paymentType.id,
          cash: false,
        };
      }
    }
    dataInit.query = query;

    const plainObject = JSON.parse(JSON.stringify(dataInit));

    return snakecaseKeys(plainObject);
  }
}

export class PaymentModel extends DeserializableAbstract {
  public payment: PaymentDataQueryModel;
}

export class PaymentDataQueryModel {
  public type: number;
  public amount?: number;
  public includeServiceFee?: boolean;
  public cash? = false;
  public card?: PaymentCardQueryModel;
  public depositTransference?: PaymentDepositTransference;
  public recharge_method?: number;
  public card_selected?: string;
  public payment_data?: PaymentCorporate;
  public voucher?: PaymentVoucher;
  public token?: string;
  public ibPayment?: ibPaymentModel;
}

export class PaymentVoucher {
  number: string;
  mail: string;
}

export class PaymentCorporate {
  credit_card_type: string;
  credit_card_number: string;
  type: string;
}

export class PaymentCardQueryModel {
  card_type?: string;
  category?: string;
  cvv?: string;
  expire_month?: string;
  expire_year?: string;
  form?: string;
  holder?: string;
  number?: string;
  bin?: string;
}

export class ibPaymentModel {
  tokenized_card_number: string;
  billing_address: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
}

export class PaymentDepositTransference {
  bank: string;
  moment: string;
}
