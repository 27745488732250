import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-iata',
  templateUrl: './input-iata.component.html',
  styleUrls: ['./input-iata.component.scss'],
})
export class InputIataComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'text';
  @Input() options: any = {};
  @Input() inputs: any;
}
