import { Injectable, OnDestroy } from '@angular/core';
import { ModalBuilderService } from 'src/app/core/services/modal/modal-builder.service';
import { LoadingComponent } from 'src/app/modules/application/components/loading/loading.component';
import {
  ModalButtons,
  ModalIcon,
  ModalOptionsComponent,
} from 'src/app/modules/application/transport/components/modal-options/modal-options.component';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ModalTutorialComponent } from 'src/app/modules/application/transport/flights/pages/home/modalTutorial/modal-tutorial.component';
import { ModalCarouselComponent } from 'src/app/modules/application/transport/components/modal-carousel/modal-carousel.component';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalErrorsMultiproviders } from 'src/app/modules/application/transport/components/modal-errors-multiproviders/modal-errors-multiproviders.component';

@Injectable({
  providedIn: 'root',
})
export class ManagementModalService implements OnDestroy {
  confirmButton$: Subject<any> = new Subject();
  destroy$: Subject<void> = new Subject();
  listModalsOpen: any = new BehaviorSubject([]);

  subscriptions: Subscription[] = [];

  component: any;

  LoadingModalOptions = {
    width: '380px',
    minHeight: '240px',
    disableClose: true,
  };

  newsOptions = {
    maxWidth: '80vh',
    minHeight: '600px',
    disableClose: true,
    id: 'modal-carousel',
  };

  confirmModalOptions = {
    width: '585px',
    disableClose: true,
  };

  initTutorialModalOptions = {
    width: '380px',
    disableClose: true,
    initTutorial: true,
  };

  residentModal = {
    width: '450px',
  };

  subtitle = 'thank_you_waiting_';

  iconType: IconsCollection = {
    warning: {
      src: 'assets/images/icons/alert.svg',
      class: 'alert',
    },
    info: {
      src: 'assets/images/icons/info.svg',
      class: 'info',
    },
    error: {
      src: 'assets/images/icons/alert.svg',
      class: 'alert',
    },
    budget: {
      src: 'assets/images/icons/budget.svg',
      class: 'budget',
    },
    email: {
      src: 'assets/images/icons/email.svg',
      class: 'email',
    },
    print: {
      src: 'assets/images/icons/print.svg',
      class: 'print',
    },
    generalError: {
      src: 'assets/images/icons/error.svg',
      class: 'error',
    },
    serverError: {
      src: 'assets/images/icons/serverError.svg',
      class: 'error',
    },
    crm: {
      src: 'assets/images/icons/crm.svg',
      class: 'crm',
    },
    condition: {
      src: 'assets/images/icons/bookOpen.svg',
      class: 'crm',
    },
    corporate: {
      src: 'assets/images/icons/corporate.svg',
      class: 'search',
    },
    corporateError: {
      src: 'assets/images/icons/corporateError.svg',
      class: 'alert',
    },
    corporatePlus: {
      src: 'assets/images/icons/create-corporate.svg',
      class: 'search',
    },
    agency: {
      src: 'assets/images/icons/agency.svg',
      class: 'search',
    },
    clock: {
      src: 'assets/images/icons/wait_clock.svg',
      class: 'clock',
    },
    duplicateOver: {
      src: 'assets/images/icons/over_duplicate.svg',
      class: 'over',
    },
    validation: {
      src: 'assets/images/icons/validation.svg',
      class: 'validation',
    },
  };

  controlSpecial = ['crm', 'crmBudget'];

  constructor(private modalBuilder: ModalBuilderService) {}

  getConfirmButton$(): Observable<any> {
    return this.confirmButton$.asObservable();
  }

  getIcon(source: string): ModalIcon {
    return this.iconType[source] || this.iconType.warning;
  }

  showLoadingModal(
    title: string,
    subtitle: string = this.subtitle,
    options: any = this.LoadingModalOptions
  ) {
    this.component = this.modalBuilder.openModal(LoadingComponent, options);
    this.component.componentInstance.options = { title, subtitle };
  }

  showCustomModal(
    title: string,
    description: string,
    buttons: ModalButtons[],
    icon: ModalIcon,
    options?: any,
    params?: any,
    warningAditional?: WarningModel
  ) {
    if (this.existElementIdIntoStack(params.id)) {
      if (params.id === 'agency') {
        return;
      }
      this.changeIsOpen(params);
    } else {
      this.listModalsOpen.next([...this.listModalsOpen.getValue(), params]);
    }
    this.subscriptions.push(
      this.listModalsOpen
        .pipe(
          filter(
            () =>
              this.existElementIdIntoStack(params.id) &&
              this.thereAreNotOpenModal()
          )
        )
        .subscribe(() => {
          const specificComponent = this.modalBuilder.openModal(
            ModalOptionsComponent,
            {
              ...this.confirmModalOptions,
              ...options,
            }
          );
          this.stackManagement({ id: params.id, isOpen: true });
          this.createSpecificComponent(
            options,
            specificComponent,
            warningAditional,
            title,
            description,
            icon,
            buttons
          );
          this.component = specificComponent;
          this.subscriptions.push(
            specificComponent.componentInstance.buttonEmit.pipe().subscribe(
              (res) => {
                if (params.id === 'corporate') {
                  this.confirmButton$.next({
                    id: params.id,
                    response: res,
                  });
                  return;
                }
                // Se comenta para la BAC-1631
                // if (this.controlSpecial.includes(params.id)) {
                //   this.deleteAllStackElement();
                // }
                if (res === 'closeAll') {
                  this.hideSpecificModal();
                  this.deleteAllStackElement();
                  return;
                }
                if (res !== 'closedClickOut') {
                  this.controlClosedClickOut(res, options, params);
                } else {
                  this.deleteStackElement({ id: params.id, isOpen: false });
                  if (
                    this.controlSpecial.includes(params.id) ||
                    params.id === 'corporate'
                  ) {
                    this.confirmButton$.next({
                      id: params.id,
                      response: res,
                    });
                  }
                }
              },
              () => {
                this.deleteStackElement({ id: params.id, isOpen: false });
              },
              () => {
                this.deleteStackElement({ id: params.id, isOpen: false });
              }
            )
          );
          this.modalBuilder.dialogRef.beforeClosed().subscribe(() => {
            if (
              params.id === 'crmBudget' ||
              params.id === 'crm' ||
              params.id === 'corporate'
            ) {
              specificComponent.componentInstance.buttonEmit.next(
                'closedClickOut'
              );
            }
          });
        })
    );
  }

  createSpecificComponent(
    options,
    specificComponent,
    warningAditional,
    title,
    description,
    icon,
    buttons
  ) {
    if (options?.form) {
      if (options.resetForm) {
        options?.form.reset();
      }
      specificComponent.componentInstance.modalForm = options.form;
    }
    if (options?.inputs) {
      if (options.resetForm) {
        options.inputs.forEach((input) => {
          input.options.showErrors = false;
        });
      }
      specificComponent.componentInstance.inputs = options.inputs;
    }
    if (options?.isVeciAndWorkCRMExpediente) {
      specificComponent.componentInstance.crmData = options.crmData;
      specificComponent.componentInstance.showCrmInputs = true;
    }
    if (options?.modalPrintPadding) {
      specificComponent.componentInstance.modalPrintPadding =
        options.modalPrintPadding;
    }
    if (options?.hasOwnProperty('noFound')) {
      specificComponent.componentInstance.noFound = options.noFound;
    }
    if (options?.changePrice) {
      specificComponent.componentInstance.changePrice = options.changePrice;
    }
    if (options?.changeRate) {
      specificComponent.componentInstance.changeRate = options.changeRate;
    }
    if (options?.containTex) {
      specificComponent.componentInstance.containTex = options.containTex;
    }
    if (options?.isCorporate) {
      specificComponent.componentInstance.isCorporate = options.isCorporate;
    }
    if (options?.isAgencyModal) {
      specificComponent.componentInstance.isAgencyModal = options.isAgencyModal;
    }
    if (options?.showClosedButton) {
      specificComponent.componentInstance.showClosedButton =
        options.showClosedButton;
    }
    if (options?.id) {
      specificComponent.componentInstance.classId = options.id;
    }
    if (options?.isVoucher) {
      specificComponent.componentInstance.isVoucher = options.isVoucher;
    }
    if (options?.crmStyles) {
      specificComponent.componentInstance.crmStyles = options.crmStyles;
    }
    if (options?.selectRadioText) {
      specificComponent.componentInstance.selectRadioText =
        options.selectRadioText;
    }
    if (warningAditional) {
      specificComponent.componentInstance.warningAditional = warningAditional;
    }
    if (options?.voidFee) {
      specificComponent.componentInstance.voidFee = options.voidFee;
    }
    specificComponent.componentInstance.title = title;
    specificComponent.componentInstance.description = description;
    specificComponent.componentInstance.icon = icon;
    specificComponent.componentInstance.buttons = buttons;
  }

  controlClosedClickOut(res, options, params) {
    if (options?.form && !options.form.valid && res !== 'cancel') {
      for (const x of options.inputs) {
        x.options.showErrors = true;
      }
      if (params.id === 'crmBudget' || params.id === 'crm') {
        this.confirmButton$.next({
          id: params.id,
          response: res,
        });
      }
    } else {
      if (params.id === 'emissionPin' && res === 'submit') {
      } else if (
        !options?.form ||
        (options?.form &&
          options.form.valid &&
          params.id !== 'crmBudget' &&
          params.id !== 'crm') ||
        res === 'cancel' ||
        !options?.stopPropagation
      ) {
        this.hideSpecificModal();
        this.deleteStackElement({ id: params.id, isOpen: false });
      }

      this.confirmButton$.next({
        id: params.id,
        response: res,
        form: options?.form,
      });
    }
  }

  changeIsOpen(params: any) {
    this.listModalsOpen
      .getValue()
      .filter((p: any) => p.id === params.id)[0].isOpen = false;
  }

  showConfirmModal(
    title: string,
    description: string,
    params?: any,
    warning?: WarningModel,
    options: any = this.confirmModalOptions
  ) {
    const buttonTextsCancel = {
      confirmChange: 'no_changes',
      cancelBooking: 'modals_confirm_action.cancelBooking.button_back',
      emitBooking: 'modals_confirm_action.emitBooking.button_back',
      listActionVoideo: 'modals_confirm_action.voidBooking.button_back',
      voidBooking: 'modals_confirm_action.voidBooking.button_back',
      confirmRefund: 'modals_confirm_action.confirmRefund.button_back',
      deleteBudget: 'modals_confirm_action.delete_budget.button_back',
    };
    const buttonTextsConfirm = {
      cancelBooking: 'modals_confirm_action.cancelBooking.button_confirm',
      emitBooking: 'modals_confirm_action.emitBooking.button_confirm',
      listActionVoideo: 'modals_confirm_action.voidBooking.button_confirm',
      voidBooking: 'modals_confirm_action.voidBooking.button_confirm',
      confirmRefund: 'modals_confirm_action.confirmRefund.button_confirm',
      deleteBudget: 'modals_confirm_action.delete_budget.button_confirm',
    };
    const buttons: ModalButtons[] = [
      {
        text: buttonTextsCancel[params.id] ?? 'cancel_',
        class: 'btn-back',
        emitOnClick: 'cancel',
      },
      {
        text: buttonTextsConfirm[params.id] ?? 'confirm_',
        class: 'btn-primary',
        emitOnClick: 'submit',
      },
    ];

    this.showCustomModal(
      title,
      description,
      buttons,
      this.iconType.warning,
      options,
      params,
      warning
    );
  }

  showInitiTutorial(title: string, text: string) {
    this.component = this.modalBuilder.openModal(
      ModalTutorialComponent,
      this.initTutorialModalOptions
    );
    this.component.componentInstance.showButtons = false;
    this.component.componentInstance.optional.text = text;
    this.component.componentInstance.optional.title = title;
    this.component.componentInstance.init = true;
    this.component.componentInstance.import = true;
    this.subscriptions.push(
      this.component.componentInstance.closedEmit.subscribe((res) => {
        this.confirmButton$.next(res);
      })
    );
  }

  showModalMultiProviderErrors(
    title: string,
    subtitle: string,
    elements: any[],
    buttons: ModalButtons[],
    icon: ModalIcon
  ): void {
    this.component = this.modalBuilder.openModal(
      ModalErrorsMultiproviders,
      this.newsOptions
    );
    this.component.componentInstance.title = title;
    this.component.componentInstance.subtitle = subtitle;
    this.component.componentInstance.ListBookings = elements;
    this.component.componentInstance.buttons = buttons;
    this.component.componentInstance.icon = icon;
    this.component.componentInstance.closedEmit
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.confirmButton$.next(res);
      });
  }

  showModalCarosuel(listImg: string[]) {
    this.component = this.modalBuilder.openModal(
      ModalCarouselComponent,
      this.newsOptions
    );
    this.component.componentInstance.listImgCarosuel = listImg;
    this.subscriptions.push(
      this.component.componentInstance.closedEmit.subscribe((res) => {
        this.confirmButton$.next(res);
      })
    );
  }

  stackManagement(data: any) {
    this.listModalsOpen.next(
      this.listModalsOpen.getValue().map((p) => {
        if (p.id === data.id) {
          p.isOpen = data.isOpen;
        }
        return p;
      })
    );
  }

  existElementIdIntoStack(id: any): boolean {
    return this.listModalsOpen.getValue().find((element) => element.id === id);
  }

  thereAreNotOpenModal(): boolean {
    return this.listModalsOpen.getValue().every((x) => x.isOpen === false);
  }

  deleteStackElement(data: any) {
    this.listModalsOpen.next(
      this.listModalsOpen.getValue().filter((p) => p.id !== data.id)
    );
  }

  deleteAllStackElement() {
    this.listModalsOpen.next([]);
    this.hideModal();
  }

  hideSpecificModal() {
    if (this.component) {
      this.component.close();
    }
  }

  hideModal() {
    this.destroy();
    this.ngOnDestroy();
    if (this.component) {
      this.component.close();
    }
  }

  destroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface IconsCollection {
  warning: ModalIcon;
  info: ModalIcon;
  error: ModalIcon;
  budget: ModalIcon;
  email: ModalIcon;
  print: ModalIcon;
  generalError: ModalIcon;
  serverError: ModalIcon;
  crm: ModalIcon;
  condition: ModalIcon;
  corporate: ModalIcon;
  corporateError: ModalIcon;
  corporatePlus: ModalIcon;
  agency: ModalIcon;
  clock: ModalIcon;
  duplicateOver: ModalIcon;
  validation: ModalIcon;
}

export interface WarningModel {
  title: string;
  message: string;
}
