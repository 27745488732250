import {
  Component,
  Input,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import countries from 'src/assets/json/countries.json';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { switchElement } from '../forms/fields/switch-button/switch-button.component';
import { LanguageService } from '../../../../core/services/language/language.service';

@Component({
  selector: 'app-form-generic-admin',
  templateUrl: './form-generic-admin.component.html',
  styleUrls: ['./form-generic-admin.component.scss'],
})
export class FormGenericAdminComponent implements OnChanges {
  @Input() form: any;
  @Input() formName: any;
  @Input() inputs: any;
  @Input() id: any;
  @Input() itemData: any;
  @Input() titlesSectionForm: any;
  @Input() titleSection: any;
  @Input() titleIcon: any;
  @Input() saveButton = 'save_';
  @Input() fieldsConfig?: any = null;
  @Input() showCredentialValidation = false;
  @Input() credentialValidation = true;
  @Input() loading = false;
  @Input() showLoading = false;
  @Input() switch = false;
  @Input() disabledSwitch = false;
  @Input() editElement = false;
  @Input() titleInfoTooltip: string;
  @ContentChild(TemplateRef) itemWrapper: TemplateRef<any>;
  @Output() getItemFather = new EventEmitter<{
    name: string;
    page: IPageInfo;
  }>();
  @Output() saveItemFather = new EventEmitter<{
    inputs: any;
    form: any;
  }>();

  @Output() activeOrNotActiveElement = new EventEmitter<switchElement>();

  @Output() updateItemFather = new EventEmitter<{
    itemId: number;
    inputs: any;
    form: any;
  }>();

  @Output() editElementOpenCustomValues = new EventEmitter<{
    itemId: number;
    inputs: any;
    formName: string;
  }>();

  @Output() updateTitles = new EventEmitter<{
    status: string;
    itemId?: number;
    item: number;
    inputs: any;
    formName: string;
  }>();

  @Output() saveButtonClicked = new EventEmitter<void>();

  editingItem = false;
  loadingComponent = false;

  inputsWithPorcentIcon = [
    'commission_transferred',
    'over_detained',
    'commission',
    'over',
  ];

  constructor(private languageService: LanguageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Control de boton disabled si navegamos entre tabs en caso de que el padre tenga este modulo
    if (changes['inputs']) {
      this.editingItem = false;
    }
    if (changes['editElement']) {
      this.editingItem = changes['editElement'].currentValue;
    }
    if (changes['credentialValidation']) {
      this.credentialValidation = changes['credentialValidation'].currentValue;
    }
    if (
      this.showLoading &&
      changes['loading'] &&
      changes['loading'].currentValue !== this.loadingComponent
    ) {
      this.loadingComponent = changes['loading'].currentValue;
    }
    if (changes['disabledSwitch']) {
      this.disabledSwitch = changes['disabledSwitch'].currentValue;
    }
  }

  saveItem(itemId?: number): void {
    if (this.form.every((group) => group.valid === true) && this.showLoading) {
      this.loadingComponent = true;
    }

    if (itemId) {
      this.updateItem(itemId);
      return;
    }

    this.saveItemFather.emit({
      inputs: this.inputs,
      form: this.form,
    });
  }

  updateItem(itemId: number): void {
    if (this.form.every((group: any) => group.valid === true)) {
      if (this.showLoading) {
        this.loadingComponent = true;
      }
    }
    this.updateItemFather.emit({
      itemId,
      inputs: this.inputs,
      form: this.form,
    });
  }

  editButtonControl(e: string): void {
    this.editingItem = !this.editingItem;

    this.updateTitles.emit({
      status: e,
      item: this.itemData,
      inputs: this.inputs,
      formName: this.formName,
    });
  }

  sendActiveOrNotActiveElement(e: any): void {
    e.value = !e.value;
    this.activeOrNotActiveElementToParent(e);
  }

  activeOrNotActiveElementToParent(data: switchElement): void {
    data.value = !data.value;
    this.activeOrNotActiveElement.emit(data);
  }

  getCountryName(iso2: string): string {
    return countries?.countries?.find((country) => country.iso2 === iso2)?.name[
      this.languageService.getLocaleCode()
    ];
  }
}
