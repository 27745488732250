import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-dashboard',
  templateUrl: './generic-dashboard.html',
  styleUrls: ['./generic-dashboard.scss'],
})
export class GenericDashboard {

}
