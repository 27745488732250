import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  infoCredentials
} from '../../../modules/application/rail-app/components/rail-app-form/rail-app-form.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RailAppProviderService {
  constructor(private translateService: TranslateService) {}

  getRenfeProviderForm() {
    return {
      uagency: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{4}$/)]],
      branch: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{3}$/)]],
      password: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{6,}$/)]],
      errors: [false, []],
    };
  }

  getIryoProviderForm() {
    return {
      apikey: ['', [Validators.required]],
      subscription_key: ['', [Validators.required]],
      username: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
        ],
      ],
      worldpayShopper: [
        '',
        [Validators.required, Validators.pattern(/^shopperID-\d{3}$/)],
      ],
      errors: [false, []],
    };
  }

  getRenfeProviderInputs() {
    return [
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'uagency',
        options: {
          id: 'uagency',
          class: 'form-input',
          label: 'uagency_',
          placeholder: 'uagency_',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'branch',
        options: {
          id: 'branch',
          class: 'form-input',
          label: 'branch_',
          placeholder: 'branch_',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'password',
        name: 'password',
        options: {
          id: 'password',
          class: 'form-input',
          label: 'password_',
          placeholder: 'password_',
          showErrors: false,
          required: true,
        },
      },
    ];
  }

  getIryoProviderInputs() {
    return [
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'apikey',
        options: {
          id: 'apikey',
          class: 'form-input',
          label: 'Api Key',
          placeholder: '',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'subscription_key',
        options: {
          id: 'subscription_key',
          class: 'form-input',
          label: 'Subscription Key',
          placeholder: '',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'username',
        options: {
          id: 'username',
          class: 'form-input',
          label: 'username_',
          placeholder: '',
          showErrors: false,
          required: true,
        },
      },
      {
        headerClass: 'block-input',
        type: 'text',
        name: 'worldpayShopper',
        options: {
          id: 'worldpayShopper',
          class: 'form-input',
          label: 'WorldPay Shopper',
          placeholder: '',
          showErrors: false,
          required: true,
        },
      },
    ];
  }

  getCredentials(provider: string): infoCredentials {
    return {
      type: 'credentials',
      header: {
        logo: `assets/images/airlines/${provider.toLowerCase()}.svg`,
        title: this.translateService.instant('get_credentials_'),
      },
      ...(provider === 'Iryo' && {
        link: {
          name: 'Web Iryo',
          url: 'https://agencias.iryo.eu/es/home',
        },
      }),
      info: [
        {
          text: this.translateService.instant(
            `credentials_rail_text_.${provider.toLowerCase()}_`
          ),
          note: this.translateService.instant(
            `credentials_rail_note_.${provider.toLowerCase()}_`
          ),
        },
        {
          text: this.translateService.instant('credentials_rail_remember_'),
        },
      ],
    };
  }
}
