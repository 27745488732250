<div class="isCallCenter">
  <p class="language-title">
    {{ 'client_language_' | translate | uppercase }}
  </p>
  <app-select
    [data]="dataLangs"
    [parentForm]="formLanguage"
    [fCName]="'language'"
    [options]="{
      class: 'callCenterLanguage'
    }"
  ></app-select>
</div>
