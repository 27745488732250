import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})

export class AccordionComponent implements OnInit {
  @Input() tabStatus: boolean[] = [];
  @Input() tabContext: any[];
  @Input() multiple: boolean = true;
  @Input() showBody: boolean = false;
  @Output() onTabToggleEvent = new EventEmitter<any>();

  ngOnInit(): void {
    this.tabStatus = this.tabStatus?.length === this.tabContext?.length  ? this.tabStatus : Array.from({length: this.tabContext?.length}, (value, index) => false);
  }

  onTabOpen(e) {
    this.tabStatus[e.index] = !this.tabStatus[e.index];
    if(!this.multiple){
      this.tabStatus = Array.from({length: this.tabContext.length}, (value, index) => false)
      this.tabStatus[e.index] = true;
    }
    this.onTabToggle(e.index, "open");
  }

  onTabClose(e) {
    this.tabStatus[e.index] = !this.tabStatus[e.index];
    if(!this.multiple){
      this.tabStatus = Array.from({length: this.tabContext.length}, (value, index) => false)
    }
    this.onTabToggle(e.index, "close");
  }

  onTabToggle(index:number, action:string){
    this.onTabToggleEvent.emit({index, action});
  }
}
