import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scrollElementButton',
  templateUrl: './scrollElementButton.component.html',
  styleUrls: ['./scrollElementButton.component.scss'],
})
export class ScrollElementButtonComponent {
  @Input() idElement: string;
  @Input() textButton: string;
  @Input() disabled: boolean;
  @Input() class: string;

  moveDesElementByID() {
    if (document.getElementById(this.idElement)) {
      window.scrollTo({
        top: document.getElementById(this.idElement)!.offsetTop - 70,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
