import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FlightResultsService } from 'src/app/core/services/transport/flight-results/flight-results.service';

@Component({
  selector: 'app-no-result-generic',
  templateUrl: './no-result-generic.component.html',
  styleUrls: ['./no-result-generic.component.scss'],
})
export class NoResultGenericComponent {
  filterOn: boolean;
  subscriptions: Subscription[] = [];

  @Input() options: any;
  @Output() resetFiltersFather = new EventEmitter<void>();

  constructor(public flightResultsService: FlightResultsService) {}

  resetAll(): void {
    this.resetFiltersFather.emit();
  }
}
