<form [formGroup]="form" (submit)="submitForm()">
  <div class="container-access">
    <div *ngIf="!isB2C" class="header-container-access">
      <svg-icon [src]="'assets/images/icons/info.svg'"></svg-icon>
      <p class="locatorLabel">{{ 'cc_locator_special_label_' | translate }}</p>
    </div>
    <div class="body-container-access">
      <app-fields-collection
        [form]="form"
        [inputs]="inputs"
      ></app-fields-collection>
    </div>
    <div class="buttonBox">
      <app-submit
        [literal]="'validate_'"
        [options]="{ class: 'button-acces-booking' }"
      ></app-submit>
    </div>
  </div>
</form>
