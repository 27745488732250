import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { KeyValueStorage } from '../interfaces/storage.interface';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBStorageService implements KeyValueStorage {
  private DB_NAME = 'Lleego';
  private DB_VERSION = 1;
  private DB_STORE_NAME = 'lleego_store';
  private db!: IDBDatabase;

  constructor() {
    const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);
    request.onerror = (_) =>
      console.error('Fatal Error: indexedDB not supported');
    request.onsuccess = () => {};
    request.onupgradeneeded = (event) => {
      const target = event.currentTarget! as any;
      const db: IDBDatabase = target.result;
      db.createObjectStore(this.DB_STORE_NAME);
    };
    // request.onupgradeneeded = function (evt) {
    //   console.log("openDb.onupgradeneeded");
    //   var store = evt.currentTarget.result.createObjectStore(
    //     DB_STORE_NAME, { keyPath: 'id', autoIncrement: true }
    //   );

    //   store.createIndex('biblioid', 'biblioid', { unique: true });
    //   store.createIndex('title', 'title', { unique: false });
    //   store.createIndex('year', 'year', { unique: false });
    // };
  }

  setItem(key: string, value: any): Observable<void> {
    const setItemSubject = new ReplaySubject();

    const openDBRequest: IDBOpenDBRequest = indexedDB.open(
      this.DB_NAME,
      this.DB_VERSION
    );
    openDBRequest.onerror = (event) => console.error(event);
    openDBRequest.onsuccess = () => {
      const db: IDBDatabase = openDBRequest.result;
      const transaction = db.transaction(this.DB_STORE_NAME, 'readwrite');
      const objectStore = transaction.objectStore(this.DB_STORE_NAME);

      const putRequest = objectStore.put(value, key);
      putRequest.onsuccess = (_) => {
        setItemSubject.next(true);
        setItemSubject.complete();
        db.close();
      };
      putRequest.onerror = (error) => {
        setItemSubject.error(error);
        setItemSubject.complete();
        db.close();
      };
    };

    return setItemSubject.asObservable() as Observable<void>;
  }

  getItem<T>(key: string): Observable<T> {
    const getItemSubject = new ReplaySubject<T>();

    const openDBRequest: IDBOpenDBRequest = indexedDB.open(
      this.DB_NAME,
      this.DB_VERSION
    );
    openDBRequest.onerror = (event) => console.error(event);
    openDBRequest.onsuccess = () => {
      const db: IDBDatabase = openDBRequest.result;
      const transaction = db.transaction(this.DB_STORE_NAME);
      const objectStore = transaction.objectStore(this.DB_STORE_NAME);
      const getRequest = objectStore.get(key);
      getRequest.onerror = (error) => {
        getItemSubject.error(error);
        getItemSubject.complete();
        db.close();
      };
      getRequest.onsuccess = (_) => {
        const data = getRequest.result;
        getItemSubject.next(data);
        getItemSubject.complete();
        db.close();
      };
    };

    return getItemSubject.asObservable();
  }

  removeItem(key: string): Observable<void> {
    const deleteItemSubject = new ReplaySubject();

    const openDBRequest: IDBOpenDBRequest = indexedDB.open(
      this.DB_NAME,
      this.DB_VERSION
    );
    openDBRequest.onerror = (event) => console.error(event);
    openDBRequest.onsuccess = () => {
      const db: IDBDatabase = openDBRequest.result;
      const deleteRequest = db
        .transaction(this.DB_STORE_NAME, 'readwrite')
        .objectStore(this.DB_STORE_NAME)
        .delete(key);
      deleteRequest.onsuccess = (_) => {
        deleteItemSubject.next(true);
        deleteItemSubject.complete();
        db.close();
      };
      deleteRequest.onerror = (error) => {
        deleteItemSubject.error(error);
        deleteItemSubject.complete();
        db.close();
      };
    };

    return deleteItemSubject.asObservable() as Observable<void>;
  }
  removeItems(keys: string[]): Observable<void> {
    throw new Error('Method not implemented.');
  }
  clearAll(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  clearExpired(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  clearExcept(keys: string[]): Observable<void> {
    throw new Error('Method not implemented.');
  }
}
