// api getUserData response.
// User info
import { DeserializableAbstract } from './deserializable.abstact';

export class UserModel extends DeserializableAbstract {
  // nombre del usuario.
  public name?: string;
  // home endpoint del usuario.
  public he: string;
  // source id si lo tiene.
  public si?: string;
  // wslogin
  public ws?: string;
  // wspassword
  public wp?: string;
  // User roles
  public r?: string;
  // Permiso de emision
  public ce: boolean;
  // name canonical
  public nc?: string;
  // url CC IB (MIXTO/B2B/B2C)
  public cc_p?: string;
  public cc_r?: string;
  public cc_l?: string;
  // need mfa auth
  public mfa?: boolean;
  public rail?: boolean;

  override deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getBasic() {
    return window.btoa(this.ws + ':' + this.wp);
  }
}
