import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any;

  maxValue: number;

  ngOnInit() {
    this.checkMaxValue();
  }

  checkMaxValue() {
    if (this.options?.maxValue) {
      this.maxValue = this.options.maxValue;
    } else {
      this.maxValue = 20;
    }
  }

  changeSelected(num: number) {
    if (this.parentForm.get(this.fCName)?.enabled) {
      this.parentForm
        .get(this.fCName)
        ?.setValue(this.parentForm.controls[this.fCName].value + num);
    }
  }

  isDisabled(num: number) {
    if (
      (this.parentForm.controls[this.fCName].value === 0 && 0 > num) ||
      (this.parentForm.controls[this.fCName].value === this.maxValue && 0 < num)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
