<section>
  <svg-icon
    [svgStyle]="{ 'width.px': 50, 'height.px': 50 }"
    src="assets/images/icons/alert.svg"
  >
  </svg-icon>
  <h3>
    {{ 'an error has occurred_' | translate }}
  </h3>
  <p>
    {{ 'the information could not be obtained_' | translate }}
  </p>
  <button
    pRipple
    *ngIf="showTryAgainButton"
    type="button"
    class="btn-primary"
    (click)="emitTryAgain()"
  >
    {{ 'try again_' | translate }}
  </button>
  <button
    pRipple
    *ngIf="!showTryAgainButton"
    type="button"
    class="btn-back"
    (click)="goBack()"
  >
    {{ 'back_' | translate }}
  </button>
</section>
