import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertModel } from 'src/app/shared/models/alert.model';
import { filter } from 'rxjs/operators';
import { WebsocketService } from '../../websocket/websocket.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  listIDWithChanges = new BehaviorSubject<string[]>([]);
  numChatUnreadMessages = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private webSocketServices: WebsocketService
  ) {
    this.webSocketServices
      .getSocket()
      .pipe(filter((res) => res))
      .subscribe({
        next: (res) => {
          if (res?.time && res?.TTL) {
            const date = new Date(res.time);
            date.setMinutes(date.getMinutes() + res.TTL / 60);

            if (date > new Date()) {
              this.gestionDataWS(res);
            }
          }
        },
        error: () => {
          this.webSocketServices.authUser();
          this.webSocketServices.authConsolidator();
        },
      });
  }

  getListIDWithChanges() {
    return this.listIDWithChanges.asObservable();
  }

  getNumChatUnreadMessages() {
    return this.numChatUnreadMessages.asObservable();
  }

  gestionDataWS(data: any) {
    switch (data.type) {
      case 'search':
        break;
      case 'update':
        this.addID(data.bookingID);
        break;
      case 'notification':
        this.addID(JSON.parse(data.contents.replace('""', '')).id);
        break;
    }
  }

  addID(id: string) {
    const aux: any = [...this.listIDWithChanges.value];
    aux.push(id);
    this.listIDWithChanges.next(aux);
  }

  removedID(id: string) {
    const aux: any = [...this.listIDWithChanges.value].filter(
      (idAux) => idAux !== id
    );
    this.listIDWithChanges.next(aux);
  }

  getAlerts(options): Observable<any> {
    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    const start =
      options.page.pageSize * options.page.currentPage - options.page.pageSize;
    const numItems = options.page.pageSize;
    let params = new HttpParams();
    params = params.append('start', start.toString());
    params = params.append('num', numItems.toString());
    params = params.append(
      'query',
      options.filter.search ? options.filter.search : ''
    );

    // return this.http.get<AlertModel[]>('assets/mocks/alertsList.json')
    return this.http.get<AlertModel[]>(
      environment.api_url + '/private/v2/transport/notifications/list',
      { params, headers }
    );
  }

  getChatAlerts(options): Observable<any> {
    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    const start =
      options.page.pageSize * options.page.currentPage - options.page.pageSize;
    const numItems = options.page.pageSize;
    let params = new HttpParams();
    params = params.append('start', start.toString());
    params = params.append('num', numItems.toString());
    params = params.append(
      'query',
      options?.filter?.search ? options.filter.search : ''
    );

    // return this.http.get<AlertModel[]>('assets/mocks/alertsChatList.json')
    return this.http.get<AlertModel[]>(
      environment.api_url + '/private/v2/transport/notifications/chat/list',
      { params, headers }
    );
  }

  deleteAlerts(id: any): Observable<any> {
    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.put<AlertModel[]>(
      environment.api_url + '/private/v2/transport/notifications/read/' + id,
      { headers }
    );
  }
}
