import { Component, EventEmitter } from '@angular/core';
import { JwtService } from 'src/app/core/services/user/jwt.service';
import {SharedModule} from "../../../../../shared/shared.module";
import {CommonComponentsModule} from "../../../components/common-components.module";

@Component({
  selector: 'app-alert-refresh-token',
  templateUrl: './alert-refresh-token.component.html',
  styleUrls: ['./alert-refresh-token.component.scss'],
  standalone: true,
  imports:[SharedModule, CommonComponentsModule]
})
export class AlertRefreshTokenComponent {
  buttonEmit = new EventEmitter<any>();

  timeToExpire: number;

  countDown: Date;

  constructor(private jwt: JwtService) {
    this.calculateTime();
    this.initCountdown();
  }

  refreshToken(): void {
    this.buttonEmit.emit('close');
  }

  logout(): void{
    this.buttonEmit.emit('logout');
  }

  private initCountdown(): void {
    const interval = setInterval(() => {
      this.calculateTime();
      if (this.timeToExpire <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  private calculateTime(): void {
    if (!this.jwt.getTokenExpirationDate()) {
      return;
    }
    this.timeToExpire =
      this.jwt.getTokenExpirationDate()!.getTime() - new Date().getTime();
    this.countDown = new Date(this.timeToExpire);
  }
}
