import {
  Component,
  Input,
  Output,
  HostListener,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fields-collection',
  templateUrl: './fields-collection.component.html',
  styleUrls: ['./fields-collection.component.scss'],
})
export class FieldsCollectionComponent {
  @Input() inputs: any[];
  @Input() form: FormGroup;
  @Input() options: any = {};
  @Input() groupName: any = {};
  @Input() showButtonOnly = false;
  @Input() numberOfMonths: any = 2;
  @Input() inputsName: string;
  @Output() return = new EventEmitter<any>();
  @Output() onlyButton = new EventEmitter<any>();
  @Output() openAddCompany = new EventEmitter<boolean>();
  @Output() openAddCompanyError = new EventEmitter<any>();
  @Output() controlValueInput = new EventEmitter<any>();
  @Output() cleanInvalidInput = new EventEmitter<any>();

  calendarOptions: any = {
    initialDay: new Date(),
    class: 'inputOnSearchBar white',
  };
  fieldTextType = false;
  src = {
    password: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },

    confirmPassword: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    data_password: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    data_agtpwd: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    data_SecurityPassword: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },

    data_logonPassword: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
    password_confirm: {
      src: '/assets/images/icons/closedeye.svg',
      fieldText: false,
    },
  };

  // Numero de calendarios segun tamaño de pantalla
  public phoneWidth: any = environment.phoneWidth;

  @HostListener('window:resize', ['$event'])
  // TODO: lanzar método en oninit
  onResize(event) {
    if (event.target.innerWidth > this.phoneWidth) {
      this.numberOfMonths = 2;
    } else {
      this.numberOfMonths = 1;
    }
  }

  toggleFieldTextType(name) {
    this.src[name].fieldText = !this.src[name].fieldText;

    if (!this.src[name].fieldText) {
      this.src[name].src = '/assets/images/icons/closedeye.svg';
    } else {
      this.src[name].src = '/assets/images/icons/openeye.svg';
    }
  }

  butttonOnly(name: string) {
    this.onlyButton.emit(name);
  }

  openAddCompanyModule(): void {
    this.openAddCompany.emit(true);
  }
  openAddCompanyErrorSend(data: any): void {
    this.openAddCompanyError.emit(data);
  }

  enforceNumericInputField(event): void{
    this.controlValueInput.emit(event)
  }

  cleanInvalidInputField(event): void{
    this.controlValueInput.emit(event)
  }

  checkIfFormHaveControls(form: any) {
    let check = false;

    if (form?.value !== null && typeof form?.value === 'object') {
      check = true;
    }

    return check;
  }
}
