import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-generic-price-breakdown',
  templateUrl: './generic-price-breakdown.component.html',
  styleUrls: ['./generic-price-breakdown.component.scss'],
})
export class GenericPriceBreakdownComponent {
  @Input() refundData: any;
}
