import { FlightResultsSegmentsModel } from './flight-results-segments.model';
import { FlightResultsJourneysModel } from './flight-results-journeys.model';
import { FlightResultsFaresModel } from './flight-results-fares.model';
import { FlightResultsSolutionsModel } from './flight-results-solutions.model';
import { FlightResultsDataModel } from './flight-results-data.model';
import { DeserializableAbstract } from '../deserializable.abstact';

export class FligthResultsModel extends DeserializableAbstract {
  public token: string;
  public segments: {
    segmentId: FlightResultsSegmentsModel
  };
  public journeys: {
    journeyId: FlightResultsJourneysModel
  };
  public fares: {
    fareId: FlightResultsFaresModel
  };
  public solutions: FlightResultsSolutionsModel[];
  public data: FlightResultsDataModel;
}

