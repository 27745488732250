<section class="modal">
  <div class="content-info">
    <div *ngIf="icon" class="icon" [ngClass]="icon.class">
      <svg-icon [src]="icon.src"></svg-icon>
    </div>
    <div class="box-text-info">
      <div class="title">{{ title }}</div>
      <div class="subtitle" [innerHTML]="subtitle"></div>
    </div>
    <div class="box-info-transport">
      <div
        *ngFor="let line of ListBookings; index as index; last as last"
        class="box-container"
        [ngClass]="{ marginBottom: !last }"
      >
        <div class="info-status-box">
          <div class="info">
            <div class="data">
              <div class="data-direcction">
                <svg-icon
                  [ngClass]="{ rotate: index > 0 }"
                  src="assets/images/icons/green-arrow.svg"
                ></svg-icon>
                <strong>{{
                  line.departureDate | formatDate : 'resultItemHeader'
                }}</strong>
              </div>
              <div class="separator"></div>
              <div>
                <span>{{ line.destinationArrival }}</span>
              </div>
            </div>
            <div class="separator"></div>
            <div class="provider">
              <img
                [src]="
                  baseUrlImgProvidersFavicon + line.provider.id + '_favicon.svg'
                "
                [alt]="line.provider.id"
              />
              <span>{{ line.provider.long_name }}</span>
            </div>
          </div>
          <div class="status-error">
            <svg-icon [src]="'assets/images/icons/cross.svg'"></svg-icon>
            <span>{{ line.status }}</span>
          </div>
        </div>
        <div *ngIf="line.description" class="description-error">
          {{ line.description }}
        </div>
      </div>
    </div>
    <div class="button-box">
      <ng-container *ngFor="let button of buttons">
        <div>
          <button
            data-cy="btn-modal"
            pRipple
            [class]="button.class"
            (click)="emitInfo(button.emitOnClick)"
          >
            {{ button?.text | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</section>
