import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ModalVersionClasicComponent } from 'src/app/modules/application/transport/components/modal-version-clasic/modal-version-clasic.component';
import { DataSendFeedBack } from 'src/app/modules/application/transport/components/navbar-dropdown/navbar-dropdown.component';
import { AuthenticationService } from '../authentication/authentication.service';
import { VERSION } from '../configurations/configurations';
import { ModalBuilderService } from '../services/modal/modal-builder.service';
import { SendFeedbackUserService } from '../services/send-feedback/send-feedback-user.service';
import { NotificationsUtilService } from '../services/utils/notifications-util/notifications-util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public readonly version = VERSION;
  isAutologin = false;
  component: any;
  versionClasicModal = {
    width: '586px',
    disableClose: true,
  };

  dataSend: DataSendFeedBack = {
    error: false,
    function: false,
    dificult: false,
    comment: '',
  };

  currentYear = new Date().getFullYear();

  constructor(
    private modalBuilder: ModalBuilderService,
    private sendFeedbackUser: SendFeedbackUserService,
    private authService: AuthenticationService,
    private notifications: NotificationsUtilService,
  ) {}

  backVersionClasic(): void {
    this.component = this.modalBuilder.openModal(
      ModalVersionClasicComponent,
      this.versionClasicModal
    );
    this.component.componentInstance.buttonsOptions = {
      back: {
        literal: 'modal-back-version-clasic_.button_.cancel_',
        options: {
          class: 'btn-back',
        },
      },
      send: {
        literal: 'modal-back-version-clasic_.button_.send_',
        options: {
          class: 'btn-primary',
        },
      },
    };
    this.component.componentInstance.isFormFeedback = true;
    this.component.componentInstance.emitData
      .pipe(
        tap(() => this.component.close()),
        filter((res: FormGroup) => res.get('button')?.value === 'send'),
        switchMap((res: FormGroup) => {
          this.dataSend.error = res.get('error')?.value;
          this.dataSend.function = res.get('function')?.value;
          this.dataSend.dificult = res.get('dificult')?.value;
          this.dataSend.comment = res.get('comment')?.value;

          return this.sendFeedbackUser.sendFeedBackFromUser(this.dataSend);
        })
      )
      .subscribe(
        () => {
          this.redirect();
        },
        () => {
          this.notifications.infoError('errorRecoveryPassword_');
        }
      );
  }

  redirect(): void {
    this.component = this.modalBuilder.openModal(
      ModalVersionClasicComponent,
      this.versionClasicModal
    );
    this.component.componentInstance.isFormFeedback = false;
    setTimeout(() => {
      this.authService.logoutNavegateVersionOne();
    }, 1000);
  }
}
