<section>
  <div class="box col-12">
    <div class="img">
      <svg-icon src="assets/images/icons/robot404.svg"></svg-icon>
    </div>
    <div class="text-info">
      <h4>
        {{ 'ops_all_my_circuits_' | translate }}
      </h4>
      <h2>
        {{ '404 error_' | translate }}
      </h2>
      <p>
        {{ 'page_dont_exist' | translate }}
      </p>
      <button pRipple type="button" class="btn-back" (click)="goBack()">
        {{ 'go_main_page' | translate }}
      </button>
    </div>
  </div>
</section>
