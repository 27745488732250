import { UserService } from '../user/user.service';
import { Socket } from 'ngx-socket-io';

export class ConsolidatorSocket extends Socket {
  constructor(private userService: UserService) {
    super({
      url: `wss://socket.lleego.es/?ch=consolidator${userService.getCurrentUSer()?.client?.ci}`,
      options: {
        transports: ['websocket']
      }
    });
  }
}
