<div class="timepickerContainer">
  <div class="headerTimepicker">
    <p>{{ 'departure_' | translate }}</p>
  </div>
  <div class="calendarSlider">
    <ngx-slider
      [(value)]="minValueDeparture"
      [(highValue)]="maxValueDeparture"
      [options]="optionsSliderDeparture"
      (valueChange)="changeDepartureHours()"
    >
    </ngx-slider>
  </div>
  <div class="options">
    <p
      *ngFor="let option of optionsRange"
      (click)="changeDeparture(option.option)"
      [ngClass]="{ primary: optionSelectedDeparture === option.option }"
      [attr.data-cy]="option.title + 'Departure'"
    >
      {{ option.title | translate }}
    </p>
  </div>
  <div class="headerTimepicker">
    <p>{{ 'arrive_' | translate }}</p>
  </div>
  <div class="calendarSlider">
    <ngx-slider
      [(value)]="minValueArrival"
      [(highValue)]="maxValueArrival"
      [options]="optionsSliderArrival"
      (valueChange)="changeArrivalHours()"
    >
    </ngx-slider>
  </div>
  <div class="options">
    <p
      *ngFor="let option of optionsRange"
      (click)="changeArrival(option.option)"
      [ngClass]="{ primary: optionSelectedArrival === option.option }"
      [attr.data-cy]="option.title + 'Arrive'"
    >
      {{ option.title | translate }}
    </p>
  </div>
</div>
