<ng-container [formGroup]="parentForm">
  <input
    [type]="type"
    [ngClass]="options.class"
    [value]="value ? value : name"
    [id]="
      options.id
        ? options.id + (options.placeholder ?? '')
        : value
        ? value
        : name + 'id'
    "
    [name]="fCName"
    [formControlName]="fCName"
    (change)="fireChange()"
    [checked]="
      checked
        ? checked
        : parentForm.get(fCName)?.value === (value ? value : name)
    "
  />

  <label
    *ngIf="options.label !== false"
    [for]="
      options.id
        ? options.id + (options.placeholder ?? '')
        : value
        ? value
        : name + 'id'
    "
    [class]="fCName"
    [ngClass]="{
      error: parentForm.controls[fCName]?.errors && options?.showErrors
    }"
    [attr.data-cy]="fCName"
  >
    <div></div>
    <ng-container *ngIf="options?.flag">
      <img
        *ngIf="options.flag !== 'eu'"
        src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
        [class]="'flag flag-' + options.flag"
        style="width: 18px; height: 12px"
        alt="flag"
      />
      <img
        *ngIf="options.flag === 'eu'"
        src="assets/images/icons/ue.png"
        [class]="'flag flag-' + options.flag"
        style="width: 18px; height: 13px"
        alt="flag"
      />
    </ng-container>
    {{ options.placeholder | translate }}
  </label>
</ng-container>
