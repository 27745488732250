<ng-container [formGroup]="parentForm">
  <textarea 
    placeholder="{{ options.placeholder | translate }}" 
    [ngClass]="options.class" 
    [formControlName]="fCName"
    [id]="options.id ? options.id : fCName" 
    #icon>
  </textarea>
</ng-container>
<app-errors-fields 
[field]="icon" 
[errors]="parentForm.controls[fCName].errors" 
[show]="options.showErrors">
</app-errors-fields>