<div class="market">
  <app-select
    [data]="marketData"
    [parentForm]="marketForm"
    [fCName]="'market'"
    [options]="{
      class: 'market',
      truncate: 10,
      filter: true,
      filterPlaceholder: 'market_',
      isFlagSelect: true
    }"
  ></app-select>
</div>
