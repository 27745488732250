import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateListSeparator',
})
export class DateListSeparatorPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translate: TranslateService
  ) {}

  transform(value: any, lastDate?: any, lang?: any): any {
    const date = new Date(value);
    const dateL = new Date(lastDate);
    const today = new Date();

    // si la fecha coincide con el dia de hoy
    const dateFormatted =
      date.getDate() + '' + date.getMonth() + '' + date.getFullYear();
    const todayFormatted =
      today.getDate() + '' + today.getMonth() + '' + today.getFullYear();

    // si la fecha coincide con el día de ayer
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayFormatted =
      yesterday.getDate() +
      '' +
      yesterday.getMonth() +
      '' +
      yesterday.getFullYear();

    // si la fecha ya se ha pintado, pasamos a la siguiente sin pintar nada.
    const dateLFormatted =
      dateL &&
      dateL.getDate() + '' + dateL.getMonth() + '' + dateL.getFullYear();
    if (dateLFormatted === dateFormatted) {
      return null;
    } else {
      if (dateFormatted === todayFormatted) {
        return this.translate.instant('today_');
      }
      if (dateFormatted === yesterdayFormatted) {
        return this.translate.instant('yesterday_');
      }
      // En otro caso lo pintamos en formato de fecha.
      if (this.datePipe) {
        const dateStri = this.datePipe.transform(date, 'EEE, d LLL', 'UTC');
        if (date.getFullYear() !== today.getFullYear()) {
          return (
            dateStri?.charAt(0).toUpperCase() +
            dateStri!.slice(1) +
            ' ' +
            date.getFullYear()
          );
        }
        return dateStri?.charAt(0).toUpperCase() + dateStri!.slice(1);
      }
    }
  }
}
