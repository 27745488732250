<ng-container>
  <div class="list-form">
    <h2>{{ title | translate }}</h2>
    <app-fields-collection
      [form]="form"
      [inputs]="filterOptions"
    ></app-fields-collection>
  </div>
  <div class="items-container">
    <div class="list-header">
      <div *ngFor="let field of fieldsConfig">
        <h3>{{ field.label | translate }}</h3>
      </div>
    </div>
    <div *ngIf="noResults" class="no-results">
      <div class="options">
        <h3>
          <svg-icon
            [svgStyle]="{ 'width.px': 29, 'height.px': 29 }"
            src="assets/images/icons/search.svg"
          >
          </svg-icon>
          {{ 'noResultBookings.title_' | translate }}
        </h3>
        <button pRipple (click)="resetForm()" class="btn-primary">
          {{ 'clean filters_' | translate }}
        </button>
      </div>
    </div>
    <ng-container
      *ngFor="
        let item of itemsFiltered$
          | async
          | slice: (currentPage - 1) * pageSize:currentPage * pageSize;
        last as last
      "
    >
      <div
        class="list-container"
        [ngClass]="{ last: last }"
        (click)="selectItem(item)"
      >
        <div *ngFor="let field of fieldsConfig">
          <p>{{ item[field.key] }}</p>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="this.pageLength > 0">
    <ngb-pagination
      (pageChange)="fetchMore($event)"
      [collectionSize]="pageLength"
      [pageSize]="pageSize"
      [rotate]="true"
      [(page)]="currentPage"
      [boundaryLinks]="true"
      [maxSize]="3"
      aria-label="Default pagination"
    >
    </ngb-pagination>
  </ng-container>
</ng-container>
