<ng-container [formGroup]="parentForm">
  <div
    class="box-select {{ fCName }}"
    [ngClass]="{ noLabel: options.fixLabel }"
  >
    <p-dropdown
      (onShow)="show()"
      (onHide)="hide()"
      [options]="data"
      [class]="options.class"
      [formControlName]="fCName"
      [disabled]="options.disabled"
      [id]="options.id"
      [virtualScroll]="false"
      placeholder="{{ options.placeholder | translate }}"
      [ngClass]="{
        error:
          options.showErrors &&
          parentForm.get(fCName)?.errors &&
          !parentForm.get(fCName)?.value
      }"
      [attr.data-cy]="fCName"
    >
      <ng-template
        pTemplate="item"
        let-item
        *ngIf="!options.noTranslate && !options.suffixDate"
      >
        <div>
          <svg-icon
            *ngIf="item?.icon"
            src="assets/images/icons/{{ item.icon }}.svg"
          ></svg-icon>
          {{ getItem(item, options) | translate }}
        </div>
      </ng-template>

      <ng-template
        pTemplate="selectedItem"
        let-item
        *ngIf="!options.noTranslate && !options.suffixDate"
      >
        <div>
          <div class="header-selected">
            <svg-icon
              *ngIf="item?.icon"
              src="assets/images/icons/{{ item.icon }}.svg"
            ></svg-icon>
            <p>{{ item.value.cod_country | uppercase }}</p>
          </div>
          <div class="iata-value">
            {{ item.value.iata }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</ng-container>
<app-errors-fields
  class="select"
  [errors]="parentForm.controls[fCName].errors"
  [show]="options.showErrors"
>
</app-errors-fields>
