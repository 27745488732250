import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-last-search',
  templateUrl: './error-last-search.component.html',
  styleUrls: ['./error-last-search.component.scss'],
})
export class ErrorLastSearchComponent implements OnInit {
  @Input() textDescription: string = 'no_result_last_search_';
  @Input() isCallCenter: boolean = false;
  loopSkeleton: number[];

  ngOnInit() {
    let arrayLength: number = 5;

    if (this.isCallCenter) {
      arrayLength = 3;
    }

    this.loopSkeleton = Array(arrayLength)
      .fill(0)
      .map((x, i) => i);
  }
}
