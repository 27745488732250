/**
 * @todo Sacar los servicios de Material a otro modulo.
 */
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AccordionModule } from 'primeng/accordion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DateListSeparatorPipe } from './pipes/date-list-separator/date-list-separator.pipe';
import { CalendarModule } from 'primeng/calendar';
import { StatusPipe } from './pipes/status/status.pipe';
import { DivideDecimalPipe } from './pipes/divide-decimal.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { ReplacePipe } from './pipes/replace/replace.pipe';
import { UnserializeAirlinesPipe } from './pipes/unserialize-airlines/unserialize-airlines.pipe';
import { IsNumber } from './pipes/is-number/is-number.pipe';
import { OnlyNumber } from './directive/onlynumber.directive';
import { ProviderNamePipe } from './pipes/provider-name/provider-name.pipe';
import { BetweenDatesPipe } from './pipes/between-dates/between-dates.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { DateAgoPipe } from './pipes/dateAgo.pipe';
import { InputMaskModule } from 'primeng/inputmask';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from '../core/footer/footer.component';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { CurrencySymbolPipe } from './pipes/currency-symbol/currency-symbol.pipe';
import { ImagePreloadDirective } from './directive/image-preload/image-preload.directive';
import { FormatDatePipe } from './pipes/formatDate.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { EditorModule } from 'primeng/editor';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FileUploadModule } from 'primeng/fileupload';
import { NgModule } from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { CarouselModule } from 'primeng/carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { SurnamesInitials } from './pipes/surnames-initials/surnames-initials.pipe';
import { CalculateCO2TotalBySegmentPipe } from './pipes/co2/co2CalculateBySegment.pipe';
import { NameProvidersFlightsPipe } from './pipes/name-providers-flights/name-providers-flights.pipe';
import { TextTreatmentsPipe } from './pipes/text-treatments/text-treatments.pipe';
import { SortPipe } from './pipes/sort-frequencies/sort.pipe';
import { CurrencyCustomPipe } from './pipes/currency-custom/currency-custom.pipe';
import { FormatCurrencyPricesShow } from './pipes/format-prices-show/format-prices-show.pipe';
import { TraductionTreatmentsPipe } from './pipes/traduction-treatments/traduction-treatments.pipe';
import { TokenizationPipe } from './pipes/tokenization/tokenization.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
// For dynamic progressbar demo
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormatPatterTextPipe } from './pipes/format-cuil-cuit.pipe';
import { DifferenceBetweenDatesPipe } from './pipes/difference-between-dates.pipe';

@NgModule({
  declarations: [
    DateListSeparatorPipe,
    SurnamesInitials,
    CurrencySymbolPipe,
    StatusPipe,
    DurationPipe,
    DivideDecimalPipe,
    DifferenceBetweenDatesPipe,
    ReplacePipe,
    UnserializeAirlinesPipe,
    BetweenDatesPipe,
    IsNumber,
    TruncatePipe,
    ProviderNamePipe,
    DateAgoPipe,
    FooterComponent,
    OnlyNumber,
    ImagePreloadDirective,
    FormatDatePipe,
    CalculateCO2TotalBySegmentPipe,
    NameProvidersFlightsPipe,
    TextTreatmentsPipe,
    SortPipe,
    CurrencyCustomPipe,
    FormatCurrencyPricesShow,
    TraductionTreatmentsPipe,
    TokenizationPipe,
    FormatPatterTextPipe,
  ],
  imports: [
    RippleModule,
    EditorModule,
    FileUploadModule,
    ColorPickerModule,
    PanelMenuModule,
    SkeletonModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    TranslateModule,
    AngularSvgIconModule,
    AccordionModule,
    NgxSliderModule,
    MatInputModule,
    MatDialogModule,
    MatMenuModule,
    MatDatepickerModule,
    MatGridListModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    TooltipModule,
    TabViewModule,
    MatTooltipModule,
    DropdownModule,
    VirtualScrollerModule,
    CalendarModule,
    MultiSelectModule,
    InputMaskModule,
    AutoCompleteModule,
    NgbPaginationModule,
    RouterModule,
    CarouselModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    TableModule,
  ],
  exports: [
    RippleModule,
    EditorModule,
    FileUploadModule,
    ColorPickerModule,
    PanelMenuModule,
    SkeletonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    AngularSvgIconModule,
    NgxSliderModule,
    AccordionModule,
    MatTooltipModule,
    MatInputModule,
    MatMenuModule,
    MatDatepickerModule,
    MatGridListModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    TooltipModule,
    TabViewModule,
    DropdownModule,
    MultiSelectModule,
    VirtualScrollerModule,
    DateListSeparatorPipe,
    SurnamesInitials,
    CurrencySymbolPipe,
    CalendarModule,
    StatusPipe,
    DurationPipe,
    DivideDecimalPipe,
    DifferenceBetweenDatesPipe,
    ReplacePipe,
    UnserializeAirlinesPipe,
    BetweenDatesPipe,
    IsNumber,
    TruncatePipe,
    ProviderNamePipe,
    InputMaskModule,
    AutoCompleteModule,
    NgbPaginationModule,
    FooterComponent,
    NgbPaginationModule,
    OnlyNumber,
    DateAgoPipe,
    ImagePreloadDirective,
    FormatDatePipe,
    CarouselModule,
    CalculateCO2TotalBySegmentPipe,
    NameProvidersFlightsPipe,
    TextTreatmentsPipe,
    SortPipe,
    CurrencyCustomPipe,
    FormatCurrencyPricesShow,
    TraductionTreatmentsPipe,
    TokenizationPipe,
    ProgressBarModule,
    ToastModule,
    ProgressSpinnerModule,
    FormatPatterTextPipe,
    TableModule,
  ],
  providers: [
    FormsModule,
    DateListSeparatorPipe,
    CurrencySymbolPipe,
    StatusPipe,
    DurationPipe,
    SurnamesInitials,
    DivideDecimalPipe,
    DifferenceBetweenDatesPipe,
    ReplacePipe,
    UnserializeAirlinesPipe,
    BetweenDatesPipe,
    ProviderNamePipe,
    IsNumber,
    TruncatePipe,
    FormatDatePipe,
    TitleCasePipe,
    CalculateCO2TotalBySegmentPipe,
    NameProvidersFlightsPipe,
    TextTreatmentsPipe,
    CurrencyCustomPipe,
    TraductionTreatmentsPipe,
    TokenizationPipe,
    ToastModule,
    FormatPatterTextPipe,
  ],
})
export class SharedModule {}
