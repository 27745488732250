import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FlightResultsService } from 'src/app/core/services/transport/flight-results/flight-results.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Injectable()
export class FlightSearchForm {
  form: FormGroup;
  tabOrder = ['origin', 'destination', 'initialDay'];

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private titlecase: TitleCasePipe,
    private flightResults: FlightResultsService,
    private userService: UserService
  ) {}

  focusNextElement(actualElement: string, index?: string): void {
    const actualIndex = this.tabOrder.findIndex((o) => o === actualElement);
    let nextElement: any = null;

    if (this.userService.haveDisney() && actualElement === 'destination') {
      const destination = document.getElementById(
        'destination'
      ) as HTMLSelectElement;

      if (destination?.value?.toUpperCase() === 'PARIS') {
        return;
      }
    }

    if (
      actualElement === 'initialDay' &&
      !index &&
      index !== '0' &&
      this.form?.get('type')?.value === 'round-trip'
    ) {
      nextElement = document.getElementById('finalDay');
    } else if (actualElement === 'finalDay') {
      nextElement = null;
    } else if (this.tabOrder[actualIndex + 1]) {
      nextElement = document.getElementById(
        this.tabOrder[actualIndex + 1] + index
      );
    } else if (
      document.getElementById(
        this.tabOrder[0] + (index ? (parseInt(index) + 1).toString() : '0')
      )
    ) {
      nextElement = document.getElementById(
        this.tabOrder[0] + (index ? (parseInt(index) + 1).toString() : '0')
      );
    }
    if (nextElement) {
      setTimeout(() => {
        nextElement.focus();
      }, 10);
    } else {
      setTimeout(() => {
        document.getElementById('pax-selector')?.focus();
      }, 0);
    }
  }

  focusPreviousElement(actualElement: string, index?: string): void {
    let previousElement: any = null;

    if (
      actualElement.includes('origin') &&
      actualElement !== 'origin' &&
      this.form?.get('type')?.value === 'multi-destination'
    ) {
      if (actualElement === 'origin0') {
        previousElement = document.getElementById('initialDay');
      } else {
        const initIndex = actualElement.charAt(actualElement.length - 1);

        previousElement = document.getElementById(
          'initialDay' + (parseInt(initIndex) - 1)
        );
      }
    } else if (actualElement.includes('destination')) {
      if (actualElement === 'destination') {
        previousElement = document.getElementById('origin');
      } else {
        const initIndex = actualElement.charAt(actualElement.length - 1);
        previousElement = document.getElementById('origin' + initIndex);
      }
    } else if (actualElement === 'finalDay') {
      previousElement = document.getElementById('initialDay');
    } else if (actualElement === 'initialDay') {
      previousElement = document.getElementById('destination' + index);
    } else if (actualElement === 'pax-selector') {
      if (this.form?.get('type')?.value === 'round-trip') {
        previousElement = document.getElementById('finalDay');
      } else if (this.form?.get('type')?.value === 'multi-destination') {
        previousElement = document.getElementById(
          'initialDay' + (this.form.value.multiDestination.length - 1)
        );
      } else {
        previousElement = document.getElementById('initialDay');
      }
    }

    setTimeout(() => {
      previousElement?.focus();
    }, 0);
  }

  getFormGroup(): FormGroup {
    return this.form;
  }

  initFormGroup(): FormGroup {
    this.resetForm();
    return this.form;
  }

  // Slug de la busqueda
  getSearchSlug(parameters: { search: any }) {
    const search = parameters?.search ? parameters?.search : parameters;
    return (
      search.origin.value +
      '-' +
      search.destination.value +
      '-' +
      this.datePipe.transform(search.initialDay, 'ddMMyyyy') +
      (search.type === 'round-trip'
        ? '-' + this.datePipe.transform(search.finalDay, 'ddMMyyyy')
        : '') +
      (search.passengers.adults > 0
        ? '-adults' + search.passengers.adults
        : '') +
      (search.passengers.senior > 0
        ? '-senior' + search.passengers.senior
        : '') +
      (search.passengers.youngs > 0
        ? '-youngs' + search.passengers.youngs
        : '') +
      (search.passengers.childs > 0
        ? '-childs' + search.passengers.childs
        : '') +
      (search.passengers.infants > 0
        ? '-infants' + search.passengers.infants
        : '') +
      (search.passengers.umnr > 0 ? '-umnr' + search.passengers.umnr : '') +
      ('-' + window.crypto.getRandomValues(new Uint32Array(1))[0].toString(36).substring(2, 9))
    );
  }

  fillFormFromLastSearch(lastSearch: any): void {
    const criterias = lastSearch.request.criterias[0];

    // type
    if (
      (criterias.travel.journeys.length === 2 &&
        this.form.get('type')?.value !== 'round-trip') ||
      (criterias.travel.journeys.length === 1 &&
        this.form.get('type')?.value !== 'one-way') ||
      criterias.travel.journeys.length > 2
    ) {
      this.form
        .get('type')?.setValue(
          criterias.travel.journeys.length === 1
            ? 'one-way'
            : criterias.travel.journeys.length === 2
            ? 'round-trip'
            : 'multi-destination'
        );
    }

    const multiForm = this.form.get('multiDestination') as FormArray;
    for (const form of multiForm.controls) {
      form.patchValue({ origin: '', destination: '' });
      form.disable();
    }

    if(criterias?.travel?.currency){
      const currencyUser = this.userService.getDoubleCurrency()
      if(currencyUser.length > 0 && currencyUser.includes(criterias.travel.currency)){
        this.form?.get('doubleCurrency')?.setValue(criterias.travel.currency);
      }
    }

    for (const [index, journey] of criterias.travel.journeys.entries()) {
      const airports: any[] = [];
      let form: FormGroup;
      let multi = false;
      if (
        criterias.travel.journeys.length <= 2 ||
        (criterias.travel.journeys.length > 2 && index === 0)
      ) {
        multi = false;
        form = this.form;
      } else {
        multi = true;
        form = multiForm.at(index - 1) as FormGroup;
        form.enable();
      }
      airports.push(journey.origin);
      airports.push(journey.destination);
      const getAirports = this.flightResults
        .getAirportsData(airports)
        .subscribe((res) => {
          getAirports.unsubscribe();
          if (res.success) {
            if (index === 0 || multi) {
              // location
              form.get('origin')?.setValue({
                value: journey.origin,
                title: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.origin)[0]
                    .cityName
                ),
                selection: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.origin)[0]
                    .cityName
                ),
              });
              form.get('destination')?.setValue({
                value: journey.destination,
                title: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.destination)[0]
                    .cityName
                ),
                selection: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.destination)[0]
                    .cityName
                ),
              });
              form.get('initialDay')?.setValue(new Date(journey.date));
            }
            if (index === 1 && !multi) {
              form.get('finalDay')?.setValue(new Date(journey.date));
            }
          }
        });
    }

    const passengers = {
      adults: 0,
      childs: 0,
      infants: 0,
      senior: 0,
      youngs: 0,
      umnr: 0,
    };

    for (const age of criterias.travel.paxes_distribution.passengers_ages) {
      if (
        criterias.travel.paxes_distribution.passengers_ages.length !==
        criterias.travel.paxes_distribution.passengers_ages.filter(
          (age) => age === 7
        ).length
      ) {
        if (age >= 70) {
          passengers.senior++;
        } else if (age >= 30) {
          passengers.adults++;
        } else if (age >= 13) {
          passengers.youngs++;
        } else if (age >= 7) {
          passengers.childs++;
        } else if (age <= 1) {
          passengers.infants++;
        }
      } else {
        passengers.umnr++;
      }
    }
    this.form.get('passengers')?.patchValue(passengers);

    this.form.get('update')?.setValue(true);
    setTimeout(() => {
      this.form.get('update')?.setValue(false);
    }, 600);
  }

  resetForm() {
    this.form = this.formBuilder.group({
      errors: [false, []],
      update: [false, []],
      forceCalendarOpen: [null],
    });
  }
}
