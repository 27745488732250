import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebsocketService } from '../services/websocket/websocket.service';
import { UserService } from '../services/user/user.service';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  bookingList$ = new BehaviorSubject(null);
  lastSearches$ = new BehaviorSubject(null);
  listIDWithChanges = new BehaviorSubject<string[]>([]);

  // constructor
  constructor(
    private http: HttpClient,
    private webSocketServices: WebsocketService,
    private userService: UserService,
    private titlecase: TitleCasePipe
  ) {
    this.webSocketServices
      .getSocket()
      .pipe(filter((res) => res))
      .subscribe({
        next: (res) => {
          if (res?.time && res?.TTL) {
            const date = new Date(res.time);
            date.setMinutes(date.getMinutes() + res.TTL / 60);

            if (date > new Date()) {
              this.gestionDataWS(res);
            }
          }
        },
        error: () => {
          this.webSocketServices.authUser();
          this.webSocketServices.authConsolidator();
        },
      });
  }

  getListIDWithChanges() {
    return this.listIDWithChanges.asObservable();
  }

  gestionDataWS(data: any) {
    switch (data.type) {
      case 'search':
        break;
      case 'update':
        // actualizao vista home
        this.addID(data.bookingID);
        break;
      case 'booking':
        // actualizao vista home
        this.addID(data.bookingID);
        break;
    }
  }

  addID(id: string) {
    const aux: any = [...this.listIDWithChanges.value];
    aux.push(id);
    this.listIDWithChanges.next(aux);
  }

  removedID(id: string) {
    const aux: any = [...this.listIDWithChanges.value].filter(
      (idAux) => idAux !== id
    );
    this.listIDWithChanges.next(aux);
  }

  // home list bookings.
  public getBookingList(options, transport) {
    let currentPage = options.page.currentPage;

    if (options.filter.searchAll && options.filter.searchAll !== '') {
      currentPage = 1;
    }

    const start = options.page.pageSize * currentPage - options.page.pageSize;
    const numItems = options.page.pageSize;
    let params: HttpParams = new HttpParams();
    params = params.append('start', start.toString());
    params = params.append('num', numItems.toString());
    params = params.append('product_type', this.titlecase.transform(transport));
    params = params.append(
      'orderBy',
      options.filter.orderBy ? options.filter.orderBy : ''
    );
    params = params.append(
      'status',
      options.filter.status !== null ? options.filter.status.join(':') : ''
    );
    params = params.append(
      'provider',
      options.filter.provider !== null ? options.filter.provider.join(':') : ''
    );
    if (
      options.filter.listBooking !== 'agency' ||
      (options?.filter?.searchAll &&
        options.filter.searchAll !== '' &&
        this.userService.userIsOperator())
    ) {
      params = params.append('agencyFilter', 'all');
    }
    params = params.append(
      this.userService.getCurrentUSer().client.sb,
      options.filter.searchAll ? options.filter.searchAll : ''
    );
    // return this.http.get<any>('assets/mocks/bookinglist.json');
    return this.http.get<any>(
      `${environment.api_url}/private/v2/transport/bookings/list`,
      {
        params,
      }
    );
  }

  public getGenericBookingList(options, transport) {
    const start =
      options.page.pageSize * options.page.currentPage - options.page.pageSize;
    const numItems = options.page.pageSize;
    let params: HttpParams = new HttpParams();
    params = params.append('start', start.toString());
    params = params.append('product_type', this.titlecase.transform(transport));
    params = params.append('num', numItems.toString());
    params = params.append(
      'orderBy',
      options.filter.orderBy ? options.filter.orderBy : ''
    );
    params = params.append(
      'status',
      options.filter.status !== null ? options.filter.status.join(':') : ''
    );
    params = params.append(
      'provider',
      options.filter.provider !== null ? options.filter.provider.join(':') : ''
    );
    if (
      options.filter.listBooking !== 'agency' ||
      (options?.filter?.searchAll &&
        options.filter.searchAll !== '' &&
        this.userService.userIsOperator())
    ) {
      params = params.append('agencyFilter', 'all');
    }
    params = params.append(
      this.userService.getCurrentUSer().client.sb,
      options.filter.searchAll ? options.filter.searchAll : ''
    );
    return this.http.get<any>(
      `${environment.api_url}/private/v2/transport/bookings/list`,
      {
        params,
      }
    );
    // return this.http.get<any>('assets/mocks/listHome.json');
  }

  // home last searches list new version.
  public getSearchesListV2(options, transport?: string) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    const start =
      options.page.pageSize * options.page.currentPage - options.page.pageSize;
    const numItems = options.page.pageSize;
    let params: HttpParams = new HttpParams();
    params = params.append('start', start.toString());
    params = params.append('num', numItems.toString());
    params = params.append('orderBy', 'created_at');
    params = params.append('product_type', transport!);
    return this.http.get<any>(
      `${environment.api_url}/private/v2/transport/searches/list`,
      {
        headers,
        params,
      }
    );
  }

  public getChangedItem(bookingID: string) {
    const user = this.userService.getCurrentUSer();
    const wsuser = user?.u?.ws ?? user?.user?.ws;
    const wspass = user?.u?.wp ?? user?.user?.wp;

    let params: HttpParams = new HttpParams();
    params = params.append('locale', 'es');
    params = params.append('user', wsuser!);
    params = params.append('password', wspass!);
    params = params.append('format', 'json');
    params = params.append('bookingID', bookingID);
    // return this.http.get<any>('assets/mocks/bookingcache.json');
    return this.http.get<any>(
      `${environment.api_url}/api/v1/flight/bookingcache.json`,
      {
        params,
      }
    );
  }

  /**
   * @deprecated Usar getSearchesListV2
   * @param options
   * @param transport
   * @returns
   */
  public getLastSearchesListV2(options, transport?: string) {
    return this.getSearchesListV2(options, transport);
  }

  convertBookingsIntoArray(res) {
    const array: any[] = [];
    const aux = { ...res };
    if (res?.bookings && Object.keys(res.bookings).length > 0) {
      for (const key of Object.keys(res.bookings)) {
        array.push(res.bookings[key]);
      }
    }
    aux.bookings = array;
    return aux;
  }

  convertlastSearchesIntoArray(res) {
    const array: any[] = [];
    const aux = { ...res };
    if (res.searches && Object.keys(res.searches).length > 0) {
      for (const key of Object.keys(res.searches)) {
        array.push(res.searches[key]);
      }
    }
    aux.searches = array;
    return aux;
  }
}
