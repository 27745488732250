import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ManagementModalService } from '../../utils/management-modal/management-modal.service';
import { filter, map, tap } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { PaymentDataModel } from 'src/app/shared/models/flight-results/voucher/flight-emit-request-payment.model';
import { FormGroup } from '@angular/forms';
import { PaymentsMethods } from 'src/app/modules/application/transport/flights/models/payments-methods';
import { ErrorsService } from '../../erros/errors.service';
import { VoucherModalsService } from './voucher-modals.service';
import { VoucherBookingService } from '../../voucher-booking/voucher-booking.services';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  apiUrl: string = environment.api_url;
  backendUrl: string = environment.api_backend_url;
  buyAncillariesServices = false;

  private voucher$ = new BehaviorSubject<any>(null);
  private seatsAvailability$ = new Subject<any[]>();
  private pricing$ = new BehaviorSubject<any>(null);
  private payments$ = new BehaviorSubject<any>(null);
  private voucherMethod$ = new BehaviorSubject<string>('');
  numberExpdientCommon$ = new BehaviorSubject<any>(null);

  voucher: any;
  seatsAvailability: any;
  pricing: any;
  standaloneCatalogue: any;
  payments: any;
  modalButton$: Subscription;
  voucherMethod: string;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private managementModal: ManagementModalService,
    private userService: UserService,
    private errorsService: ErrorsService,
    private voucherModalsService: VoucherModalsService,
    private voucherBooking: VoucherBookingService
  ) {}

  // get changes on voucher
  getVoucher$(): Observable<any> {
    return this.voucher$.asObservable();
  }

  // get changes on seats
  getSeatsAvailability$(): Observable<any[]> {
    return this.seatsAvailability$.asObservable();
  }

  getVoucherMethod$(): Observable<string> {
    return this.voucherMethod$.asObservable();
  }

  getNumberExpdientCommon$(): Observable<any> {
    return this.numberExpdientCommon$.asObservable();
  }

  getPricing$(): Observable<any> {
    return this.pricing$.asObservable();
  }

  getPayments$(): Observable<any> {
    return this.payments$.asObservable();
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return headers;
  }

  getVoucher(bookingId: any, locator: string): void {
    let params: HttpParams = new HttpParams();
    if (locator) {
      params = params.append('locator', locator);
    }
    this.http
      .get<any>(
        environment.api_url +
          '/api/v2/transport/catalogue/availability/' +
          bookingId,
        { params }
      )
      // this.http
      //   .get<any>('assets/mocks/catalogue.json')
      .pipe(
        map((res) => {
          return {
            ...res,
            booking: {
              ...res.booking,
              lines: res.booking.lines.map((line) => {
                line.travel.parameters_booking.map((parameterBooking) => {
                  if (parameterBooking?.fields) {
                    parameterBooking.fields.map((field) => {
                      if (field?.pax_refs && !Array.isArray(field.pax_refs)) {
                        const paxRefs: any[] = [];
                        for (const index of Object.keys(field.pax_refs)) {
                          if (!isNaN(Number(index))) {
                            paxRefs[parseInt(index)] = field.pax_refs[index];
                          }
                        }
                        field.pax_refs = paxRefs;
                      }
                      return field;
                    });
                  }
                });
                return line;
              }),
            },
          };
        })
      )
      .subscribe({
        next: (res) => {
          if (res?.booking) {
            this.voucherMethod =
              environment.api_url + '/api/v2/transport/catalogue/availability/';
            this.voucherMethod$.next('catalogue');
            this.voucher = { ...res };
            this.voucher$.next(this.voucher);
          }

          this.convertToPricing(res);
          this.convertToStandaloneCatalogue(res);
        },
        error: () => {
          this.managementModal.deleteAllStackElement();
        },
      });
  }

  getRetrieve(bookingId: string, locator: string): false | Subscription {
    if (!bookingId) {
      return false;
    }

    let params: HttpParams = new HttpParams();

    if (locator) {
      params = params.append('locator', locator);
    }

    return (
      this.http
        .get<any>(
          environment.api_url + '/api/v2/transport/retrieve/' + bookingId,
          { params }
        )
        // return this.http
        // .get<any>('assets/mocks/retrieve.json')
        .subscribe((res) => {
          this.convertToPricing(res);
          this.convertToStandaloneCatalogue(res);
          this.voucherMethod =
            environment.api_url + '/api/v2/transport/retrieve/';
          this.voucher = { ...res };
          this.voucher$.next(this.voucher);
        })
    );
  }

  getVoucherPrintOnlyFlight(paramsBookingUrl: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('user', paramsBookingUrl.queryParams.user);
    params = params.append('password', paramsBookingUrl.queryParams.password);
    params = params.append('companies', paramsBookingUrl.queryParams.companies);
    params = params.append(
      'airportsDestination',
      paramsBookingUrl.queryParams.airportsDestination
    );
    params = params.append(
      'airportsOrigin',
      paramsBookingUrl.queryParams.airportsOrigin
    );
    params = params.append('type', paramsBookingUrl.queryParams.type);
    params = params.append(
      'departureDates',
      paramsBookingUrl.queryParams.departureDates
    );
    return this.http.get<any>(
      environment.api_url + '/api/private/travel-text/search',
      { params }
    );
  }

  getPaymentAvail(booking: any): Observable<any> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    // return this.http.get<any>('assets/mocks/payments.json');

    return this.http.get<any>(
      environment.api_url + '/private/v2/transport/payment/avail/' + booking.id,
      { params, headers }
    );
  }

  void(booking: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('locator', booking.locator);

    return this.http.put<any>(
      environment.api_url + '/api/v2/transport/void/' + booking.id,
      null,
      { params }
    );
  }

  cancel(booking: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('locator', booking.locator);
    params = params.append('checkStatus', booking.checkStatus);

    return this.http.put<any>(
      environment.api_url + '/api/v2/transport/cancel/' + booking.id,
      null,
      { params }
    );
  }

  assignExpedient(locator: string, numberExpedient: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (locator) {
      params = params.append('locator', locator);
    }
    params = params.append('expedientNumber', numberExpedient);
    return this.http.get<any>(
      environment.api_url + '/api/v2/transport/booking/complete.json?',
      { params }
    );
  }

  emit(booking: any, data: FormGroup): Observable<any> {
    const dataQuery: any = PaymentDataModel.transDataPaymentQuery(
      data,
      booking
    );

    let params: HttpParams = new HttpParams();
    params = params.append('locator', booking.locator);
    const form = data.getRawValue();
    if (!PaymentsMethods[form.payments?.type.id]) {
      dataQuery.query.voucher_url = `${window.location.origin}/transport/voucher/${booking.id}`;
      dataQuery.query.referer = `${window.location.origin}/transport/voucher/${booking.id}`;
    }
    if (booking.expedient) {
      dataQuery.query.expedient = {};
      dataQuery.query.expedient.action = booking.expedient.action;
      if (booking.expedient.number) {
        dataQuery.query.expedient.number = booking.expedient.number;
      }
      if (booking.expedient.folder) {
        dataQuery.query.expedient.folder = booking.expedient.folder;
      }
    }

    return this.http.put<any>(
      `${environment.api_url}/api/v2/transport/emit/${booking.id}`,
      dataQuery,
      { params }
    );
  }

  modify(voucherId: string, data: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (data.locator && data.locator !== '') {
      params = params.append('locator', data.locator);
    }
    return this.http
      .put(this.apiUrl + '/private/v2/transport/modify/' + voucherId, data, {
        params,
      })
      .pipe(
        tap((res: any) => {
          if (res?.success) {
            this.fillVoucher(res);
          }
        })
      );
  }

  fillFlightChangeBasicQuery(data): HttpParams {
    let params = new HttpParams();
    params = params.append('format', 'json');
    params = params.append('user', this.userService.currentUserValue.user.ws!);
    params = params.append(
      'password',
      this.userService.currentUserValue.user.wp!
    );
    params = params.append('locator', data.locator);
    params = params.append(
      'apply-fee-change-include',
      data.apply_fee_change_include
    );
    params = params.append(
      'apply-fee-upgrade-include',
      data.apply_fee_upgrade_include
    );
    for (const pax of data.passengers) {
      if (pax.value) {
        params = params.append('pax[' + pax.code + ']', pax.value);
      }
    }

    if (data?.segments) {
      for (const [index, segment] of data.segments.entries()) {
        if (segment?.enabled) {
          params = params.append('segment[' + index + ']', segment.id);
        }
        params = params.append(
          'change-flight-date[' + segment.id + ']',
          segment.date
        );
      }
    }

    return params;
  }

  requestExpedientCommon(params: HttpParams): Observable<any> {
    return this.http.get(
      this.apiUrl + '/api/v2/transport/booking/complete.json',
      {
        params,
      }
    );
  }

  savedExpedientCommon(data: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('format', 'json');
    params = params.append('user', this.userService.currentUserValue.user.ws!);
    params = params.append(
      'password',
      this.userService.currentUserValue.user.wp!
    );
    params = params.append('locator', data.locator);
    params = params.append('expedientNumber', data.expedientNumber);
    this.managementModal.showLoadingModal('Updating_booking_');
    return this.requestExpedientCommon(params);
  }

  requestFlightChanges(params: HttpParams): Observable<any> {
    const urlSplit: string[] = window.location.pathname.split('/');
    const bookingID: string = urlSplit[urlSplit.length - 1];
    const url = window.location.origin + '/transport/voucher/' + bookingID;
    params = params.append('referer', url);

    let data = '?';

    params.keys()?.forEach((key) => {
      data +=
        encodeURIComponent(key) +
        '=' +
        encodeURIComponent(params.get(key) ?? '') +
        '&';
    });

    // return this.http.get<any>('assets/mocks/flightchange.json');
    return this.http.get(
      this.apiUrl + '/api/v1/flight/flightchange.json' + data
    );
  }

  changesAvail(data: any): Observable<any> {
    const params: HttpParams = this.fillFlightChangeBasicQuery(data);
    return this.requestFlightChanges(params);
  }

  upgradeAvail(data: any): Observable<any> {
    let params: HttpParams = this.fillFlightChangeBasicQuery(data);
    params = params.append('multifare', 'true');
    params = params.append('upgrade', 'true');
    params = params.append('token', '');
    return this.requestFlightChanges(params);
  }

  confirmChanges(data: any, formPayments: any): Observable<any> {
    data.payment =
      PaymentDataModel.transDataPaymentQuery(formPayments)?.query?.payment;
    return this.confirmUpgradeAndChanges(data);
  }

  confirmUpgrade(data: any, formPayments: any): Observable<any> {
    data.payment =
      PaymentDataModel.transDataPaymentQuery(formPayments)?.query?.payment;
    return this.confirmUpgradeAndChanges(data);
  }

  confirmUpgradeAndChanges(data: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(
      this.apiUrl + '/private/v2/transport/order_change',
      data,
      { headers }
    );
  }

  confirmRefundAPI(bookingID: string, data: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(
      this.apiUrl + '/api/v2/transport/refund-confirm/' + bookingID,
      data,
      { headers }
    );
  }

  refundAvail(data: any): Observable<any> {
    let params: HttpParams = this.fillFlightChangeBasicQuery(data);
    params = params.append('delete', 'true');
    return this.requestFlightChanges(params);
  }

  confirmRefund(
    bookingID: string,
    data: any,
    formPayments: any
  ): Observable<any> {
    data.payment =
      PaymentDataModel.transDataPaymentQuery(formPayments)?.query?.payment;
    return this.confirmRefundAPI(bookingID, data);
  }

  getFlightChangePolicy(data: any): Observable<any> {
    let params: HttpParams = this.fillFlightChangeBasicQuery(data);
    params = params.append('conditions', 'true');
    params = params.append('crc', '');
    params = params.append('selected', data.selected);
    return this.http.get(
      this.apiUrl + '/api/v1/flight/flightchangepolicy.json',
      { params }
    );
  }

  repricing(bookingId: string): Observable<any> {
    this.managementModal.showLoadingModal('repricing voucher.title_');

    return this.http
      .put<any>(
        environment.api_url + '/api/v2/transport/repricing/' + bookingId,
        null
      )
      .pipe(
        tap(() => this.managementModal.hideModal()),
        tap((res) => {
          if (res.errors) {
            const message = this.errorsService.treatmentsErrorDescription(res);
            this.showModalErrorRequest(message);
          }
        }),
        filter((res: any) => res.success),
        tap((res) => this.fillVoucher(res))
      );
  }

  getRepolicy(bookingId: string, locator: string): Observable<any> {
    const headers = this.getHeaders();
    let params: HttpParams = new HttpParams();
    if (locator && locator !== '') {
      params = params.append('locator', locator);
    }
    // return this.http.get<any>('assets/mocks/repolicy.json');
    return this.http.get<any>(
      environment.api_url + '/api/v2/transport/repolicy/' + bookingId,
      { params, headers }
    );
  }

  getActions(booking: any): Observable<any> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    // return this.http.get<any>('assets/mocks/L6YYP-actions-notificacions.json');
    return this.http.get<any>(
      environment.api_url + '/api/v2/transport/history-summary/' + booking.id,
      { params, headers }
    );
  }

  getCryptic(booking: any): Observable<any> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    return this.http.get<any>(
      environment.api_url + '/api/v2/transport/cryptic/' + booking.id,
      { params, headers }
    );
  }

  getGhostPNR(booking: any): Observable<any> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.append('format', 'json');
    params = params.append('user', this.userService.currentUserValue.user.ws!);
    params = params.append(
      'password',
      this.userService.currentUserValue.user.wp!
    );

    params = params.append('locator', booking);

    params = params.append(
      'locale',
      localStorage?.getItem('lang') ? localStorage.getItem('lang')! : 'es'
    );
    params = params.append('ghost', 'true');
    return this.http.get<any>(
      environment.api_url + '/api/v1/flight/cryptic.json',
      { params, headers }
    );
  }

  getHistory(booking: any, category: string): Observable<any> {
    const headers = this.getHeaders();
    let params: HttpParams = new HttpParams();
    params = params.append('category', category);
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    return this.http.get<any>(
      environment.api_url + '/api/v2/transport/history/' + booking.id,
      { params, headers }
    );
  }

  addElementOP(bookingId: string, query: any): Observable<any> {
    return this.http.post<any>(
      environment.api_url +
        '/api/v1/flight/addreminders.json?locale=es&user=' +
        this.userService.currentUserValue.user.ws +
        '&password=' +
        this.userService.currentUserValue.user.wp +
        '&locator=' +
        bookingId +
        '&format=json',
      query
    );
  }

  deleteElementOP(bookingId: string, opId: string): Observable<any> {
    return this.http.get<any>(
      environment.api_url +
        '/api/v1/flight/removeline.json?user=' +
        this.userService.currentUserValue.user.ws +
        '&password=' +
        this.userService.currentUserValue.user.wp +
        '&locator=' +
        bookingId +
        '&format=json&opid=1' +
        '&line0=' +
        opId
    );
  }

  getSeats(bookingId: string, locator: string) {
    const headers = this.getHeaders();
    let params: HttpParams = new HttpParams();
    if (locator) {
      params = params.append('locator', locator);
    }
    return (
      this.http
        .get<any>(
          environment.api_url +
            '/api/v2/transport/seats/availability/' +
            bookingId,
          { params, headers }
        )
        // .get<any>('assets/mocks/1-a-cc-ib-seatmap.json')
        .subscribe((res) => {
          if (res?.solutions) {
            this.seatsAvailability = { ...res };
            this.seatsAvailability$.next(this.seatsAvailability);
          } else {
            this.seatsAvailability$.next(res);
          }
        })
    );
  }

  setSeats(booking: any, data: any): Observable<any> {
    this.buyAncillariesServices = true;
    let params: HttpParams = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    return this.http.put<any>(
      environment.api_url + '/api/v2/transport/seats/confirm/' + booking.id,
      data,
      { params }
    );
  }

  setAncillaries(booking: any, data: any): Observable<any> {
    this.buyAncillariesServices = true;
    let params: HttpParams = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    return this.http.put<any>(
      environment.api_url + '/api/v2/transport/catalogue/confirm/' + booking.id,
      data,
      { params }
    );
  }

  setPNRComments(booking: any, type: string, content: string): any {
    this.managementModal.showLoadingModal('Updating_booking_');
    let params: HttpParams = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    return this.http
      .post<any>(
        environment.api_url + '/api/v2/transport/remark/' + booking.id,
        { type, content },
        { params }
      )
      .pipe(
        tap((res) => {
          this.managementModal.hideModal();
          if (res.success) {
            this.fillVoucher(res);
          }
          this.infoResponse(res);
        })
      );
  }

  setNotes(booking: any, type: number, content: string) {
    this.managementModal.showLoadingModal('Updating_booking_');
    let params: HttpParams = new HttpParams();
    params = params.append(
      'locator',
      booking.lines[0].booking_reference.locator
    );
    return this.http
      .post<any>(
        environment.api_url + '/api/v2/transport/note/' + booking.id,
        {
          type,
          content,
        },
        { params }
      )
      .pipe(
        tap((res) => {
          this.managementModal.hideModal();
          if (res.success) {
            this.fillVoucher(res);
          }
          this.infoResponse(res);
        })
      );
  }

  deletePNRComments(bookingId: string, id: string) {
    this.managementModal.showLoadingModal('Updating_booking_');

    return this.http
      .delete<any>(
        environment.api_url +
          '/api/v2/transport/remark/' +
          bookingId +
          '?id=' +
          id
      )
      .pipe(
        tap((res) => {
          this.managementModal.hideModal();
          if (res.success) {
            this.fillVoucher(res);
          }
          this.infoResponse(res);
        })
      );
  }

  sendEmail(data: any, transportType = 'flight'): Observable<any> {
    let body = {};
    body = {
      name: data.name,
      email: data.email,
      price_format: data.priceFormat,
      voucher_url: this.voucherMethod + data.bookingId,
      locator: data?.locator,
      transportType,
    };

    return this.http.post<any>(
      this.backendUrl + '/api/voucher/flight/send-email',
      body,
      { observe: 'response' }
    );
  }

  infoResponse(res: any): void {
    if (res.success) {
      this.toastr.success(
        "<img src='assets/images/icons/success.svg' alt='success' /><p>" +
          this.translateService.instant('saveSuccess_') +
          '</p>',
        ''
      );
    } else {
      this.toastr.error(
        "<img src='assets/images/icons/fail.svg' alt='error' /><p>" +
          this.translateService.instant('saveError_') +
          '</p>',
        ''
      );
    }
  }

  fillVoucher(res: any): void {
    this.voucher = { ...res };
    this.voucher$.next(this.voucher);
  }

  resetService(): void {
    this.voucher = null;
    this.pricing = null;
    this.standaloneCatalogue = null;
    this.seatsAvailability = null;
    this.payments = null;
    this.voucher$.next(this.voucher);
    this.pricing$.next(this.pricing);
    this.seatsAvailability$.next(this.seatsAvailability);
    this.payments$.next(this.payments);
  }

  getParameters(): any[] {
    const parametersToCheck: any = [];
    const parameters: any = [];

    for (const line of this.voucher.booking.lines) {
      for (const parameter of line.travel.parameters_booking) {
        const group = parameters.filter(
          (p: any) => p.type === parameter.type
        )[0];
        if (!group) {
          // si no existe el grupo lo creo
          parameters.push(parameter);
        } else {
          // si existe lo guardo para compararlo luego
          parametersToCheck.push(parameter);
        }
      }
    }

    this.voucherBooking.compareRepeatedFields(parameters, parametersToCheck);

    return parameters;
  }

  convertToPricingReturn(res: any): {
    item: any;
    journeyS: any;
  } {
    let mainIndex = 0;
    const journeyS: any = {};
    let item: any;
    const fareTypes: string[] = ['public', 'private', 'priv'];
    item = {
      token: res.token,
      journeys: {},
      segments: {},
      fares: {},
      data: {
        request: [{ is_surface: res?.booking?.lines[0]?.is_surface }],
        company: {},
        provider: {},
        port: {},
      },
      solutions: [
        {
          is2ow: this.getIs2ow(res),
          data: {
            associations: [],
          },
        },
      ],
      bookingId: res.booking.id,
    };

    if (res?.booking?.lines) {
      for (const [index, line] of res.booking.lines.entries()) {
        if (res.booking.lines) {
          item.solutions[0] = this.getItemSolution(item, line, res);

          this.addItemProviders(item, line);

          if (line.travel.services_groups) {
            for (const [i, journey] of line.travel.services_groups.entries()) {
              let journeyId = '';
              if (journey.services[0]?.id) {
                journeyId = journey.services[0].id;
              } else if (journey.services[0].segments) {
                journey.services[0].segments.forEach(
                  (segment: any, index: number) => {
                    let separator = '';
                    if (index > 0) {
                      separator = '@';
                    }
                    journeyId += separator + segment?.source_i_d;
                  }
                );
              }
              const journeyDuration = journey.services[0]?.duration
                ? journey.services[0].duration
                : journey.services[0].segments[0].duration;

              item.solutions[0].data.associations.push(
                this.getItemAssociations(journeyId, journey, line)
              );
              journeyS[i + line.travel.id] = journeyId;
              item.journeys[journeyId] = this.getItemJourneys(
                journeyId,
                journeyDuration,
                journey
              );
              item.solutions[0].transport.origin_destinations.push(
                this.getItemOriginDestinations(
                  journey,
                  line,
                  journeyDuration,
                  journeyId
                )
              );

              if (journey?.services[0]?.segments) {
                for (const [
                  j,
                  segment,
                ] of journey.services[0].segments.entries()) {
                  item.fares[line.provider.id + '#' + segment.fare_basis_code] =
                    this.getItemFares(segment, journey, fareTypes);
                  item.solutions[0].data.associations[
                    mainIndex
                  ].segment_references[journeyId.split('@')[j]] =
                    this.getItemSegmentReferences(segment);
                  item.journeys[journeyId].segments.push(
                    journeyId.split('@')[j]
                  );

                  const segmentData = this.getSegmentData(segment);

                  item.segments[journeyId.split('@')[j]] = segmentData;
                  item.solutions[0].transport.origin_destinations[
                    i
                  ].journeys[0].segments.push(segmentData);

                  if (!item.data.company[segment?.company?.iata]) {
                    item.data.company[segment?.company.iata] = {
                      name: segment?.company.short_name,
                    };
                  }
                  if (!item.data.company[segment?.operating_company?.iata]) {
                    item.data.company[segment?.operating_company?.iata] = {
                      name: segment?.operating_company?.short_name,
                    };
                  }

                  const ports = Object.keys(item.data.port);

                  this.addItemPorts(item, segment, ports);

                  this.addItemTechnicalStops(item, segment, ports);
                }
              }
              mainIndex++;
            }
          }
        }
      }
    }

    return { item, journeyS };
  }

  private addItemProviders(item: any, line: any): void {
    if (!item.data.provider[line.provider.id]) {
      item.data.provider[line.provider.id] = {
        provider: line.provider.id,
        name: line.provider.long_name || line.provider.short_name || '',
        lowcost: line.travel.services_groups[0]?.services[0]?.low_cost,
      };
    }
  }

  private getItemSolution(item: any, line: any, res: any): any {
    return {
      ...item.solutions[0],
      id: line.id,
      ...(line.avail_repolicy && { avail_repolicy: line.avail_repolicy }),
      bookingId: res.booking.id,
      need_doc_apis: line.travel.need_doc_apis,
      price: { ...line.travel.price, pax: line.travellers.length },
      providers: [],
      supplements: line.travel.supplements,
      time_limits: {
        last_date: line.travel.last_date,
      },
      total_price: { ...line.travel.price, pax: line.travellers.length },
      transport: {
        accept_national_document: line.travel.accept_national_document,
        allow_baggage_pre: line.travel.allow_baggage_pre,
        catalogue: line.travel.catalogue,
        id: line.travel.id,
        last_ticked_date: line.travel.last_ticket_date,
        multi_family: line.travel.multi_family,
        multi_fare: line.travel.multi_fare,
        need_ctcl: line.travel.need_ctcl,
        need_doc_apis: line.travel.need_doc_apis,
        need_ruc: line.travel.need_ruc,
        origin_destinations: [],
        price: line.travel.price,
        provider: line.provider.id,
        solution_id: line.travel.id,
      },
    };
  }

  private getSegmentData(segment: any): any {
    return {
      arrival: segment?.destination?.iata ?? segment?.destination?.ltmco,
      arrival_date: segment?.arrival_date_time,
      arrival_date_time: segment?.arrival_date_time,
      arrival_time: segment?.arrival_date_time.split('T')[1],
      ...(segment?.baggage?.quantity && {
        baggage: segment?.baggage?.quantity,
      }),
      ...(segment?.baggage?.unit && {
        baggage_unit: segment?.baggage?.unit,
      }),
      ...(segment?.baggage?.weight && {
        weight: segment?.baggage?.weight,
      }),
      cabin: segment?.cabin?.long_name,
      ...(segment?.family_name && {
        family_name: segment?.family_name,
      }),
      class: segment?.class_of_service,
      company_iata: segment?.company?.iata,
      company_name: segment?.company?.short_name,
      departure: segment?.origin?.iata ?? segment?.origin?.ltmco,
      departure_date: segment?.departure_date_time,
      departure_date_time: segment?.departure_date_time,
      departure_time: segment?.departure_date_time.split('T')[1],
      destination_city_name: segment?.destination?.city_name,
      destination_iata: segment?.destination?.iata,
      destination_short_name: segment?.destination?.short_name,
      ...(segment?.duration && { duration: segment.duration }),
      equipment: segment?.equipment,
      fare_basis_code: segment?.fare_basis_code,
      fare_type: segment?.fare_type,
      marketing_company: segment?.company?.iata,
      marketing_name: segment?.company.short_name,
      nv: segment?.company.iata + segment?.transport_number,
      op_company_iata: segment?.operating_company?.iata,
      op_company_name: segment?.operating_company?.short_name,
      operating_company: segment?.operating_company?.iata,
      origin_city_name: segment?.origin?.city_name,
      origin_iata: segment?.origin?.iata,
      origin_short_name: segment?.origin?.short_name,
      ...(segment?.seats && { seats: segment?.seats }),
      ...(segment?.status && { status: segment?.status }),
      tf: segment?.cabin?.id,
      arrival_terminal: segment?.destination_terminal,
      departure_terminal: segment?.origin_terminal,
      type: segment?.transport_type,
      source_i_d: segment.source_i_d,
      ...(segment?.technical_stops && {
        technical_stops: segment.technical_stops.reduce(
          (arr: any, technicalStop: any) => {
            arr = [
              ...arr,
              {
                ...technicalStop,
                port: technicalStop.location.iata,
              },
            ];
            return arr;
          },
          []
        ),
      }),
    };
  }

  private getItemFares(segment: any, journey: any, fareTypes: string[]): any {
    return {
      provider: segment.company.iata,
      farebasis: segment.fare_basis_code,
      fare_type: segment.fare_type,
      ...(journey?.fare_type &&
        !fareTypes.includes(journey?.fare_type.toLowerCase()) && {
          fare_code: journey.fare_type,
        }),
    };
  }

  private getItemSegmentReferences(segment: any): any {
    return {
      ...(segment?.baggage && { baggage: segment?.baggage }),
      cabin: segment?.cabin,
      ...((segment?.family_name || segment?.fare_name) && {
        fare_name: segment?.fare_name || segment?.family_name,
      }),
      class_of_service: segment?.class_of_service,
      fare_basis_code: segment?.fare_basis_code,
      ...(segment?.seats && { seats: segment?.seats }),
      ...(segment?.status && { status: segment?.status }),
    };
  }

  private getItemAssociations(journeyId: string, journey: any, line: any): any {
    return {
      journey_references: [journeyId],
      origin_destination_references: [
        journey.services[0].segments[0].origin.iata +
          journey.services[0].segments[journey.services[0].segments.length - 1]
            .destination.iata,
      ],
      provider: line.provider.id,
      segment_references: {},
      status: line.booking_reference.status,
    };
  }

  private getItemJourneys(
    journeyId: string,
    journeyDuration: any,
    journey: any
  ): any {
    return {
      id: journeyId,
      duration: journeyDuration,
      departure_date: journey.services[0].segments[0].departure_date_time,
      layovers: journey.services[0].segments.length - 1,
      ...(journey.services[0].low_cost && {
        lowcost: journey.services[0].low_cost,
      }),
      segments: [],
    };
  }

  private getItemOriginDestinations(
    journey: any,
    line: any,
    journeyDuration: any,
    journeyId: string
  ): any {
    return {
      context: journey.context,
      fare_code: journey.fare_type,
      fare_id: line.travel.price.fares
        ? line.travel.price.fares[0]?.source_i_d
        : null,
      id: journey.id,
      journeyId: journey.services[0].segments[0]?.source_i_d,
      journeySourceID: journey.services[0]?.source_i_d,
      journeys: [
        {
          departure_date: journey.services[0].segments[0].departure_date_time,
          duration: journeyDuration,
          id: journeyId,
          layovers: journey.services[0].segments.length - 1,
          lowcost: journey.services[0]?.low_cost,
          segments: [],
        },
      ],
      source_id: journey.source_i_d,
      validating_company_iata: line.travel.validating_company,
    };
  }

  private addItemPorts(item: any, segment: any, ports: any): void {
    if (!ports.includes(segment.destination.iata)) {
      item.data.port[segment.destination.iata] = {
        city: segment.destination.city_name,
        country: segment.destination.country_name,
        name: segment.destination.short_name,
        type: segment.destination.type,
      };
    }
    if (!ports.includes(segment.origin.iata)) {
      item.data.port[segment.origin.iata] = {
        city: segment.origin.city_name,
        country: segment.origin.country_name,
        name: segment.origin.short_name,
        type: segment.origin.type,
      };
    }
  }

  private addItemTechnicalStops(item: any, segment: any, ports: any): void {
    if (segment?.technical_stops) {
      for (const technicalStop of segment.technical_stops) {
        if (!ports.includes(technicalStop.location.iata)) {
          item.data.port[technicalStop.location.iata] = {
            city: technicalStop.location.city_name,
            contry: technicalStop.location.country_name,
            name: technicalStop.location.long_name,
            type: technicalStop.location.type,
          };
        }
      }
    }
  }

  getIs2ow(res: any): boolean {
    return res.booking.lines[0].travel.services_groups.every(
      (service) => service.price
    );
  }

  convertToPricing(res: any): void {
    if (!res?.booking) {
      return;
    }
    this.pricing = this.convertToPricingReturn(res);
    this.pricing$.next(this.pricing);
  }

  convertToStandaloneCatalogueReturn(res: any): any {
    const standaloneCatalogue: any = {
      solutions: [
        {
          distributions: {},
          paxes_types: {},
          selections: {},
          services: [],
        },
      ],
      token: res.token,
      version: res.version,
    };

    if (res?.booking?.lines[0]?.travellers) {
      for (const [index, pax] of res.booking.lines[0].travellers.entries()) {
        standaloneCatalogue.solutions[0].paxes_types[pax.source_i_d] = pax.type;
      }
    }

    if (res.booking.lines[0]?.supplements) {
      standaloneCatalogue.solutions[0].services =
        res.booking.lines[0]?.supplements;
      for (const supplement of res.booking.lines[0].supplements) {
        if (
          !standaloneCatalogue.solutions[0].distributions[supplement.category]
        ) {
          standaloneCatalogue.solutions[0].distributions[supplement.category] =
            supplement.distribution;
        }

        if (!standaloneCatalogue.solutions[0].selections[supplement.category]) {
          standaloneCatalogue.solutions[0].selections[supplement.category] =
            supplement.selection;
        }
      }
      for (const service of standaloneCatalogue.solutions[0].services) {
        const paxReferences: any[] = [];

        if (service.pax_references) {
          service.pax_references.map((pax: any) => {
            paxReferences.push(
              res.booking.lines[0].travellers
                .filter((p) => p.source_i_d === pax)[0]
                ?.source_i_d?.toString()
            );
          });
        }
        service.pax_references = paxReferences;
      }
    }
    return standaloneCatalogue;
  }

  convertToStandaloneCatalogue(res: any): void {
    if (!res?.booking) {
      return;
    }
    this.standaloneCatalogue = this.convertToStandaloneCatalogueReturn(res);
  }

  setReferences(locator: string, referenceData: any): Observable<any> {
    this.managementModal.showLoadingModal('Updating_booking_');
    const headers = this.getHeaders();

    let params: HttpParams = new HttpParams();
    params = this.transformReferenceData(referenceData);
    params = params.append('bookingID', locator);

    return this.http
      .get<any>(
        environment.api_url + '/private/v2/transport/corporate/references',
        { params, headers }
      )
      .pipe(
        tap((res) => {
          this.managementModal.hideModal();
          if (res.success) {
            this.fillVoucher(res);
          }
          this.infoResponse(res);
        })
      );
  }

  transformReferenceData(referenceData: any): HttpParams {
    let params: HttpParams = new HttpParams();

    Object.keys(referenceData).forEach((pax) => {
      params = params.append(
        'rms[REF1][' + pax + ']',
        referenceData[pax].cod_viajero
      );
      params = params.append(
        'rms[REF2][' + pax + ']',
        referenceData[pax].departamento
      );

      if (
        referenceData[pax].refs &&
        Object.keys(referenceData[pax].refs).length > 0
      ) {
        Object.keys(referenceData[pax].refs).forEach((refKey) => {
          params = params.append(
            'rms[' + refKey + '][' + pax + ']',
            referenceData[pax].refs[refKey]
          );
        });
      }

      if (referenceData[pax].newRefs && referenceData[pax].newRefs.length > 0) {
        referenceData[pax].newRefs.forEach((refKey, newRefsIndex) => {
          if (refKey?.numRef && refKey?.codRef) {
            params = params.append(
              'refIndex[' + pax + '][' + (newRefsIndex + 1) + ']',
              refKey.numRef
            );
            params = params.append(
              'refValues[' + pax + '][' + (newRefsIndex + 1) + ']',
              refKey.codRef
            );
          }
        });
      }
    });

    return params;
  }

  // Inicio VoucherModalsService

  showModalErrorRequest(description: string, title = '', reload = false): void {
    this.voucherModalsService.showModalErrorRequest(description, title, reload);
  }

  expedientCommonModalError(error: any): void {
    this.voucherModalsService.expedientCommonModalError(error);
  }

  showCheckTicketsModal(warnings: any): void {
    this.voucherModalsService.showCheckTicketsModal(warnings);
  }

  bringReservationModal(
    user: string,
    password: string,
    locator: string,
    office: string
  ): void {
    this.voucherModalsService.bringReservationModal(
      user,
      password,
      locator,
      office
    );
  }

  openModalWarningException(bookingID: string): void {
    this.voucherModalsService.openModalWarningException(bookingID);
  }

  modalVoucherErrorWarningPrice(): void {
    this.voucherModalsService.modalVoucherErrorWarningPrice();
  }

  modalNoHaveBalanceByChange(
    typeUrlNavegated: string,
    bookingID: string
  ): void {
    this.voucherModalsService.modalNoHaveBalanceByChange(
      typeUrlNavegated,
      bookingID
    );
  }

  modalRepricing(): void {
    this.voucherModalsService.modalRepricing();
  }

  showLoadingModal(data: string): void {
    this.voucherModalsService.showLoadingModal(data);
  }

  hideModal(): void {
    this.voucherModalsService.hideModal();
  }

  deleteAllStackElement(): void {
    this.voucherModalsService.deleteAllStackElement();
  }

  getModalConfirmButton$(): Observable<any> {
    return this.voucherModalsService.getConfirmButton$();
  }

  // Final VoucherModalsService
}
