import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { LastQuery } from './last-query/last-query.component';

@Component({
  selector: 'app-last-queries',
  templateUrl: './last-queries.component.html',
  styleUrls: ['./last-queries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastQueriesComponent {
  @Input() lastQueries: LastQuery[];
  @Input() pageSize = 6;
  @Output() navigateBooking = new EventEmitter<{
    locator: string;
    iata?: string;
  }>();

  lastQueriesList: LastQuery[];

  /* pagination */
  currentPage = 1;
  timeout: any;
  pageLength: any;
  maxPages: number;
  navigateNewTab = false;

  // this.lastQueriesList = this.lastQueries;
  // if (this.lastQueries && this.lastQueries.length > 0) {
  //   this.maxPages = Math.ceil(this.lastQueries.length / this.pageSize);
  //   this.pageLength = this.lastQueries.length || 0;
  //   this.maxPages = Math.ceil(this.pageLength / this.pageSize);
  //   this.paginator();
  // }

  // fetchMore(pageNumber) {
  //   this.currentPage = pageNumber;
  //   this.paginator();
  // }

  // paginator() {
  //   let offset = (this.currentPage - 1) * this.pageSize;
  //   this.lastQueriesList = this.lastQueries
  //     .slice(offset)
  //     .slice(0, this.pageSize);
  // }

  lastQueryNavigate(e: { locator: string; iata?: string }): void {
    this.navigateBooking.emit(e);
  }
}
