import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any;
  sub: any;

  ngOnInit(): void {
    if (this.parentForm.get(this.fCName)) {
      this.sub = this.parentForm
        .get(this.fCName)!
        .valueChanges.subscribe((e) => {
          if (this.parentForm.get(this.fCName)?.value === null) {
            this.parentForm
              .get(this.fCName)
              ?.setValue([this.options.minValue, this.options.maxValue]);
          }
        });
    }
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
