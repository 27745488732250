import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input() listImgCarosuel: string[] = [];
  @Input() showNavigators: boolean = false;
  @Input() showIndicators: boolean = false;
  @Input() autoplayInterval: number = 5_000;
  @Input() numVisible: number = 1;
  @Input() indicatorStyleTop: boolean = false;
  @Input() isLastSearchesLastCarousel: boolean;
  @Input() inLoadingPage: boolean;

  errorImg(event) {
    this.isLastSearchesLastCarousel = true;

    event.target.src = 'assets/images/banner-loading/banner-default2.svg';
  }
}
