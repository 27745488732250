<div class="box-no-search">
  <div class="box-info">
    <div class="image">
      <svg-icon
        src="assets/images/images-generic/search-not-found.svg"
      ></svg-icon>
    </div>
    <div class="text-info">
      <div class="paragraph">
        <p>
          {{ textDescription | translate }}
        </p>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let index of loopSkeleton">
    <div class="box-info-esqueleton">
      <div class="skeleton-left">
        <div class="mb-2">
          <p-skeleton animation="none" width="120px" height="19px"></p-skeleton>
        </div>
        <div class="skeleton-middel mb-2">
          <div>
            <p-skeleton
              animation="none"
              width="83px"
              height="16px"
            ></p-skeleton>
          </div>
        </div>
        <div class="mb-2">
          <p-skeleton animation="none" width="29px" height="16px"></p-skeleton>
        </div>
      </div>
      <div class="skeleton-right">
        <div class="mb-2">
          <p-skeleton animation="none" width="42px" height="19px"></p-skeleton>
        </div>
        <div>
          <p-skeleton animation="none" width="20px" height="15px"></p-skeleton>
        </div>
      </div>
    </div>
  </ng-container>
</div>
