import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlightResultsService } from 'src/app/core/services/transport/flight-results/flight-results.service';
import { VoucherService } from 'src/app/core/services/transport/voucher/voucher.service';
import { ErrorsService } from '../../../../core/services/erros/errors.service';

interface TypeAccordition {
  header: string;
  body: string;
}

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss'],
})
export class ConditionsComponent implements OnInit {
  @Input() token: any;
  @Input() flight: any;
  @Input() lines: any;
  @Input() solutionsID: any;
  @Input() solutions: any;
  @Input() modal: any;
  @Input() selectForm: any;
  @Input() id: string;
  @Input() conditionData: any;
  @Input() journeysSteps: any;
  @Input() title = true;
  @Input() transportType = 'flight';
  @Input() isBooking = false;
  @Input() isAvail = false;
  @Input() infoSegments: any;

  ready = false;
  noInfo = false;
  propertyAccordition: any[] = [];
  conditions: any;
  journeys: any[] = [];
  selected: any;
  showJourneyButtons = false;
  noResponse = {
    solutions: [
      {
        penalties: [
          {
            message: this.translateService.instant('no information_'),
          },
        ],
      },
    ],
  };
  modalButton$: Subscription;
  locator: string;
  errorMessage: string;

  constructor(
    private translateService: TranslateService,
    private flightResultsService: FlightResultsService,
    private voucherService: VoucherService,
    private route: ActivatedRoute,
    private errorsService: ErrorsService
  ) {}

  ngOnInit() {
    this.locator = this.route.snapshot.paramMap.get('locator') || '';
    this.initConditions();
  }

  initConditions = (): void => {
    if (!this.conditionData || this.conditionData.length === 0) {
      if (this.token) {
        if (this.solutions?.length > 0) {
          this.getMultiPolicy();
        } else {
          this.getPolicy();
        }
      } else if (this.flight?.avail_repolicy) {
        this.getRepolicy();
      } else if (this.flight.query) {
        this.getFlightChangePolicy();
      } else {
        this.showNoInformation();
      }
    } else {
      this.treatmentResponse(this.conditionData);
    }
  };

  getFlightChangePolicy(): void {
    let solutionsId = this.flight.id;
    if (this.id) {
      solutionsId = this.id;
    }
    const data = { ...this.flight.query, selected: solutionsId };
    this.voucherService
      .getFlightChangePolicy(data)
      .pipe(
        tap((res) => {
          if (res?.results) {
            res.solutions = res.results;
          }
        })
      )
      .subscribe((res) => {
        this.treatmentResponse(res);
      });
  }

  showNoInformation = (): void => {
    this.noInfo = true;
  };

  getRepolicy() {
    this.voucherService
      .getRepolicy(this.flight.bookingId, this.locator)
      .subscribe(
        (res) => {
          this.treatmentResponse(res);
        },
        (error) => {
          this.treatmentResponse(error);
        }
      );
  }

  treatmentResponse(res: any): void {
    if (
      (this.conditionData && this.conditionData.length > 0) ||
      (res?.solutions && res.solutions[0]?.penalties.length > 0)
    ) {
      this.response(res);
    } else {
      this.response(this.noResponse);
      this.showNoInformation();
    }
  }

  getPolicy(): void {
    let solutionsId =
      this.route.snapshot.paramMap.get('flightId') ||
      this.flight.bookingId ||
      this.solutionsID ||
      this.flight.id ||
      this.flight?.transport?.solution_id;
    if (this.id) {
      solutionsId = this.id;
    }

    if (this.isBooking && this.transportType === 'train') {
      solutionsId = this.solutionsID;
    }

    const solutionIds = solutionsId.includes('[')
      ? JSON.parse(solutionsId)
      : solutionsId;

    let fillJourneysWithTransport = false;
    for (const association of this.flight.data.associations) {
      for (const journey of association.journey_references) {
        if (
          this.selectForm &&
          this.selectForm.value[this.flight.id] &&
          Object.values(this.selectForm.value[this.flight.id]).includes(journey)
        ) {
          this.journeys.push(journey);
        } else if (this.selectForm && !this.selectForm.value[this.flight.id]) {
          this.journeys.push(journey);
        } else if (this.flight?.transport) {
          fillJourneysWithTransport = true;
        }
      }
    }
    if (fillJourneysWithTransport) {
      this.flight.transport.origin_destinations.forEach(
        (originDestination: any) => {
          this.journeys.push(originDestination.journeyId);
          solutionsId = this.flight.transport.solution_id;
        }
      );
    }

    this.flightResultsService
      .getConditions(this.token, solutionIds, this.journeys)
      .subscribe({
        next: (res) => {
          this.treatmentResponse(res);
        },
        error: (error) => {
          this.treatmentResponse(error);
        },
      });
  }

  getMultiPolicy(): void {
    const solutionIds: any[] = [];

    this.solutions.forEach((solution: any) => {
      let solutionsId =
        solution.flight?.transport?.solution_id ||
        this.solutionsID ||
        solution.flight.id;
      if (this.id) {
        solutionsId = this.id;
      }
      solutionIds.push(
        solutionsId.includes('[') ? JSON.parse(solutionsId) : solutionsId
      );

      let fillJourneysWithTransport = false;
      for (const association of solution.flight.data.associations) {
        for (const journey of association.journey_references) {
          if (
            this.selectForm &&
            this.selectForm.value[solution.flight.id] &&
            Object.values(this.selectForm.value[solution.flight.id]).includes(
              journey
            )
          ) {
            this.journeys.push(journey);
          } else if (
            this.selectForm &&
            !this.selectForm.value[solution.flight.id]
          ) {
            this.journeys.push(journey);
          } else if (solution.flight?.transport) {
            fillJourneysWithTransport = true;
          }
        }
      }
      if (fillJourneysWithTransport) {
        solution.flight.transport.origin_destinations.forEach(
          (originDestination: any) => {
            this.journeys.push(originDestination.journeyId);
            solutionsId = solution.flight.transport.solution_id;
          }
        );
      }
    });

    this.flightResultsService
      .getConditions(this.token, solutionIds, this.journeys)
      .subscribe({
        next: (res) => {
          this.treatmentResponse(res);
        },
        error: (error) => {
          this.treatmentResponse(error);
        },
      });
  }

  getPropertyAccordition(res: any): void {
    res.forEach((aux: any, indexSolution: number) => {
      aux.forEach((element: any) => {
        const headerLabel = Object.keys(element).find(
          (head) => head === 'short_name'
        )
          ? Object.keys(element).filter((head) => head === 'short_name')[0]
          : Object.keys(element)[0];
        const bodyLabel = Object.keys(element).find(
          (head) => head === 'message'
        )
          ? Object.keys(element).filter((head) => head === 'message')[0]
          : Object.keys(element)[1];
        const item = {
          header: element[headerLabel]
            ? element[headerLabel]
            : element[bodyLabel],
          body: element[bodyLabel] ? element[bodyLabel] : '',
        };
        if (!this.propertyAccordition[indexSolution]) {
          this.propertyAccordition[indexSolution] = [];
        }
        this.propertyAccordition[indexSolution].push(item);
      });
    });
  }

  getPropertyAccorditionFromRFN(data: any[]): TypeAccordition[] {
    const itemCondition = {
      header: '',
      body: '',
    };
    if (
      data.length > 1 &&
      data.filter((item) => item.hasOwnProperty('short_name')).length === 1
    ) {
      itemCondition.header = data.filter((item) =>
        item.hasOwnProperty('short_name')
      )[0].short_name;
      const messages = data.filter(
        (item) => !item.hasOwnProperty('short_name')
      );
      messages.forEach((element) => {
        itemCondition.body += `<p>${element.message}</p>`;
      });
      data = [itemCondition];
    }
    return data;
  }

  response(res: any): void {
    const auxRes: any[] = [];

    this.errorMessage = this.errorsService.treatmentsErrorDescription(res);

    if (res.solutions) {
      if (
        res.solutions?.length > 1 &&
        (this.lines?.length > 1 || this.isAvail || this.isBooking)
      ) {
        res.solutions.forEach((solution: any, index: number) => {
          solution.penalties.forEach((penaltie: any) => {
            if (penaltie.message || penaltie.short_name) {
              if (!auxRes[index]) {
                auxRes[index] = [];
              }
              auxRes[index].push(penaltie);
            }
          });
        });
        this.showJourneyButtons = true;
      } else {
        const penalties =
          res.solutions.length > 1 && this.lines?.length === 1
            ? this.lines?.[0]?.penalties
            : res.solutions[0].penalties;
        penalties.forEach((penaltie: any) => {
          if (!auxRes[0]) {
            auxRes[0] = [];
          }
          if (penaltie.message || penaltie.short_name) {
            auxRes[0].push(penaltie);
          }
        });
      }

      res.solutions = [];
      auxRes?.forEach((aux, index) => {
        if (!res.solutions[index]) {
          res.solutions[index] = [];
        }
        res.solutions[index] = this.getPropertyAccorditionFromRFN(aux);
      });

      this.conditions = res;

      this.getPropertyAccordition(res.solutions);
    } else {
      if (this.conditionData.length > 1) {
        this.showJourneyButtons = true;
      }

      this.getPropertyAccordition(this.conditionData);
      this.conditions = { solutions: this.conditionData };
    }

    this.ready = true;
    setTimeout(() => {
      const content = document.getElementById('conditionsID')
        ? document.getElementById('conditionsID')
        : document.getElementsByTagName('app-accordion')[0];
      const button = document.getElementsByClassName('goUp')[0];
      if (content) {
        content.addEventListener('scroll', () => {
          if (content.scrollTop > 50) {
            button.classList.add('show');
          } else {
            button.classList.remove('show');
          }
        });
      }
    }, 0);
  }

  getOriginDestination(index: number): string {
    let journey = '';
    if (this.journeysSteps?.length > 0) {
      return ' (' + this.journeysSteps[index].text + ')';
    } else if (this.flight?.data.associations.length > 1 && !this.solutions) {
      journey =
        this.infoSegments && this.transportType !== 'flight'
          ? this.infoSegments[
              this.flight.data.associations[index]
                ?.origin_destination_references[0]
            ].departure +
            this.infoSegments[
              this.flight.data.associations[index]
                ?.origin_destination_references[0]
            ].arrival
          : this.flight.data.associations[index]
              ?.origin_destination_references[0];
    } else if (this.solutions) {
      journey =
        this.solutions[index].flight.data.associations[0]
          ?.origin_destination_references[0];
    }

    return ` (${journey.slice(0, 3)}-${journey.slice(3)})`;
  }

  scrollToTop(): void {
    const content = document.getElementById('conditionsID')
      ? document.getElementById('conditionsID')
      : document.getElementsByTagName('app-accordion')[0];
    if (content) {
      content.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
