import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RouterService } from 'src/app/core/services/router/router.service';
import { LanguageClientService } from 'src/app/core/services/language/language-client.service';

@Component({
  selector: 'app-language-client-select',
  templateUrl: './language-client-select.component.html',
  styleUrls: ['./language-client-select.component.scss'],
})
export class LanguageClientSelectComponent implements OnInit, OnDestroy {
  @Input() clientStaticData: any;
  formLanguage: FormGroup = this.formBuilder.group({
    language: [''],
  });
  dataLangs: any[];
  isCallCenter = false;
  subscriptions: Subscription[] = [];
  icons = this.languageccService.icons;

  constructor(
    private formBuilder: FormBuilder,
    private languageccService: LanguageClientService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.languageControl(this.clientStaticData.languages);
    this.initChangeLanguage();
  }

  languageControl(language: any[]): void {
    this.dataLangs = language.map((lang: any) => {
      return {
        label: lang.label,
        value: lang.language,
        language: lang.language,
        ...(this.icons[lang.language] && {
          icon: this.icons[lang.language],
        }),
      };
    });

    const currentCode =
      this.routerService.getParamFromUrl('lang') ??
      this.dataLangs[0]?.language?.toLocaleLowerCase();

    const currentLang =
      this.dataLangs.find(
        (lang) =>
          lang?.value?.toLocaleLowerCase() === currentCode?.toLocaleLowerCase()
      ) ?? this.dataLangs[0].language;

    this.formLanguage?.controls['language']?.setValue(
      currentLang?.value?.toLocaleLowerCase() ?? 'es'
    );
    this.agregarParametro(currentLang?.value?.toLocaleLowerCase() ?? 'es');
  }

  agregarParametro(lang): void {
    const section = this.routerService.getParamFromUrl('sib');
    const market = this.routerService.getParamFromUrl('m');
    const parametros = {
      lang: lang,
      m: market,
      sib: section,
    };
    this.routerService.addGetParamsToUrl(parametros);
  }

  initChangeLanguage(): void {
    if (this.formLanguage.get('language')) {
      this.subscriptions.push(
        this.formLanguage.get('language')!.valueChanges.subscribe((res) => {
          this.agregarParametro(res);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
