import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const booking = value;
    let status = {};
    const isNotTransferredOrStatusIsNotT =
      !booking.is_transferred || booking.status !== 'T';

    status = isNotTransferredOrStatusIsNotT
      ? this.setStatus(booking)
      : { name: 'Transferida', code: 'T' };

    return status;
  }

  private setStatus = (booking): any => {
    const statusObjects = {
      RSVD: { name: 'pending_', code: 'P' },
      PNDT: { name: 'Pendiente Emisión', code: 'PE' },
      ONRQ: { name: 'Sin confirmar', code: 'Q' },
      CNFD: { name: 'Emit', code: 'E' },
      VOID: { name: 'voided_', code: 'V' },
      CNLD: { name: 'cancelled_', code: 'C' },
      RFND: { name: 'Reembolsada', code: 'R' },
      MANL: { name: 'No confirmada', code: 'M' },
      PAYG: { name: 'pending summary_', code: 'PG' },
      T: { name: 'transferred_', code: 'T' },
    };
    const statusIsReservedAndHasAirPaymentsWithPTE =
      booking.status === 'RSVD' &&
      booking.air_payments &&
      booking.air_payments.length > 0 &&
      booking.air_payments[0].status === 'PTE';

    let status = statusObjects[booking.status];

    if (statusIsReservedAndHasAirPaymentsWithPTE) {
      status = { name: 'Pendiente Validación Pago', code: 'PV' };
    }
    if (booking.status === 'CNLD' && booking.hasOwnProperty('issued_date')) {
      status = { name: 'voided_', code: 'V' };
    }

    if (!status) {
      status = { name: 'status.NPRC', code: 'U' };
    }
    return status;
  };
}
