import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-last-query',
  templateUrl: './last-query.component.html',
  styleUrls: ['./last-query.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastQueryComponent {
  @Input() lastQuery: LastQuery;
  @Input() first: boolean;
  @Input() last: boolean;
  @Output() lastQueryNavigate = new EventEmitter<{
    locator: string;
    iata?: string;
  }>();
  // {locator: 'JBNF6', agency: 'Bidane Travel', name: 'Leyre Valdes', paxNumber: 3, origin: 'MAD', destionation: 'BCN'},

  createUrlVoucher(e: { locator: string; iata?: string }): void {
    this.lastQueryNavigate.emit(e);
  }
}

export interface LastQuery {
  locator: string;
  bookingID?: string;
  agency?: string;
  name: string;
  paxNumber: number;
  origin: string;
  destination?: string;
}
