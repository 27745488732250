import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
@Pipe({
  name: 'nameProvidersFlights',
})
export class NameProvidersFlightsPipe implements PipeTransform {
  providerList: any[] = [];

  constructor(private userService: UserService) {
    this.providerList = this.userService.getProvidersList();
  }

  transform(providerCodeName: string): any {
    let currentProvider: any;

    if (providerCodeName) {
      currentProvider = this.providerList?.find(
        (provider) =>
          provider.code === providerCodeName ||
          providerCodeName
            .toLocaleLowerCase()
            .includes(provider.name.toLocaleLowerCase()) ||
          providerCodeName
            .toLocaleLowerCase()
            .includes(provider?.nameVisual?.toLocaleLowerCase())
      );
    }

    return currentProvider?.nameVisual || currentProvider?.name || '';
  }
}
