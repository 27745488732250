<section class="loading">
  <header [ngClass]="{ isCallCenter: isCallCenter }">
    <div class="header-box" [ngClass]="{ isCallCenter: isCallCenter }">
      <div class="logo-box">
        <a (click)="navigateToHome()">
          <img
            [src]="user?.client?.ls"
            (error)="errorImg($event)"
            alt="Lleego"
          />
        </a>
      </div>
    </div>
  </header>
  <div class="progressBar">
    <p-progressBar
      [ngClass]="{isCallCenter}"
      mode="indeterminate"
      [style]="{ height: '3px' }"
    ></p-progressBar>
  </div>
  <div class="main-container">
    <div id="navbarTemplate-skeleton" class="navbarTemplate-skeleton">
      <div class="layout-main-menu" id="navbar-skeleton">
        <ul class="fix-width">
          <li>
            <p-skeleton shape="circle" size="2rem"></p-skeleton>
            <p-skeleton shape="circle" size="2rem"></p-skeleton>
            <p-skeleton shape="circle" size="2rem"></p-skeleton>
          </li>
          <li>
            <p-skeleton shape="circle" size="2rem"></p-skeleton>
            <p-skeleton shape="circle" size="2rem"></p-skeleton>
          </li>
          <li>
            <p-skeleton shape="circle" size="2rem"></p-skeleton>
            <p-skeleton
              shape="circle"
              size="2rem"
              styleClass="mb-20"
            ></p-skeleton>
          </li>
          <li class="last">
            <p-skeleton
              shape="circle"
              size="2rem"
              styleClass="mr-2"
            ></p-skeleton>
          </li>
        </ul>
      </div>
    </div>
    <div class="carousel-text">
      <p [innerHTML]="text | translate : { transporType }"></p>
    </div>
    <div class="search-bar-skeleton container">
      <div class="filters">
        <p-skeleton width="6rem" height="18px"></p-skeleton>
        <svg-icon src="assets/images/icons/arrowDownGreen.svg"></svg-icon>
        <p-skeleton width="4rem" height="18px"></p-skeleton>
        <svg-icon src="assets/images/icons/arrowDownGreen.svg"></svg-icon>
        <p-skeleton width="6rem" height="18px"></p-skeleton>
        <svg-icon src="assets/images/icons/arrowDownGreen.svg"></svg-icon>
        <p-skeleton width="8rem" height="18px"></p-skeleton>
        <svg-icon src="assets/images/icons/arrowDownGreen.svg"></svg-icon>
      </div>
      <div class="searchform-skeleton">
        <div class="inputs-skeleton">
          <div class="org" *ngIf="dataLoading?.type !== 'multi-destination'">
            <p [innerHTML]="dataLoading?.dataResult?.origin"></p>
            <div class="iata" *ngIf="dataLoading?.dataResult?.originIata">
              <p [innerHTML]="dataLoading.dataResult.originIata"></p>
            </div>
          </div>
          <div
            class="loading-auto"
            *ngIf="dataLoading?.type !== 'multi-destination'"
          >
            <svg-icon
              src="assets/images/icons/loading-autocomplete.svg"
            ></svg-icon>
          </div>

          <div class="dest" *ngIf="dataLoading?.type !== 'multi-destination'">
            <p [innerHTML]="dataLoading?.dataResult?.destination"></p>
            <div class="iata" *ngIf="dataLoading?.dataResult?.destinationIata">
              <p [innerHTML]="dataLoading.dataResult.destinationIata"></p>
            </div>
          </div>
          <div
            class="multidest"
            *ngIf="dataLoading?.type === 'multi-destination'"
          >
            <div
              class="org-multi"
              *ngFor="let data of dataLoading?.multidestination; last as Last"
            >
              <p [innerHTML]="data.origin"></p>
              <div class="iata">
                <p [innerHTML]="data.originIata"></p>
              </div>
              <div class="separator" *ngIf="!Last"></div>
            </div>
          </div>
          <div class="date" *ngIf="dataLoading?.type !== 'multi-destination'">
            <svg-icon
              class="org-arrow"
              src="assets/images/icons/Arrow-left.svg"
            ></svg-icon>
            <p class="date-org">
              {{
                dataLoading?.dataResult.initialDay
                  | formatDate : 'inputCalendarMoment'
                  | titlecase
              }}
            </p>
            <svg-icon
              *ngIf="dataLoading?.type === 'round-trip'"
              src="assets/images/icons/Arrow-left.svg"
            ></svg-icon>
            <p *ngIf="dataLoading?.type === 'round-trip'">
              {{
                dataLoading.dataResult.finalDay
                  | formatDate : 'inputCalendarMoment'
                  | titlecase
              }}
            </p>
          </div>
          <div
            class="date multi"
            *ngIf="dataLoading?.type === 'multi-destination'"
          >
            <svg-icon
              class="org-multi"
              src="assets/images/icons/calendar-loading.svg"
            ></svg-icon>
            <div
              class="date-multi"
              *ngFor="
                let data of dataLoading?.multidestination;
                first as First;
                last as Last
              "
            >
              <p class="date-org multi-date" [ngClass]="{ first: first }">
                {{ data.initialDay | formatDate : 'multiLoading' | titlecase }}
              </p>
              <div *ngIf="!Last">
                <p class="mr-2">,</p>
              </div>
            </div>
          </div>
          <div class="pax">
            <svg-icon
              *ngIf="dataLoading?.passengers"
              src="assets/images/icons/pax.svg"
            ></svg-icon>
            <p>{{ dataLoading?.passengers }}</p>
          </div>
          <div class="emply"></div>
        </div>
        <div class="filters-skeleton">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="expedient-skeleton">
        <div class="left"></div>
        <div class="right">
          <div></div>
        </div>
      </div>
      <div class="results-skeleton">
        <div class="result-filters-skeleton">
          <div class="filter-des">
            <p-skeleton width="10rem" height="18px"></p-skeleton>
          </div>
          <p-skeleton
            *ngFor="let i of [0, 1, 2]"
            width="11rem"
            height="29px"
            styleClass="mt-2"
          ></p-skeleton>
          <div class="filter-des">
            <p-skeleton width="10rem" height="18px"></p-skeleton>
          </div>
          <p-skeleton
            width="11rem"
            height="45px"
            styleClass="mt-2"
          ></p-skeleton>
          <p-skeleton width="7rem" height="18px" styleClass="mt-2"></p-skeleton>
          <p-skeleton width="7rem" height="18px" styleClass="mt-2"></p-skeleton>
          <p-skeleton width="9rem" height="18px" styleClass="mt-2"></p-skeleton>
          <p-skeleton
            *ngFor="let i of [0, 1, 2, 3]"
            width="11rem"
            height="29px"
            styleClass="mt-2"
          ></p-skeleton>
          <div class="filter-des">
            <p-skeleton width="10rem" height="18px"></p-skeleton>
          </div>
        </div>
        <div class="result-skeleton">
          <div
            *ngFor="let i of [0, 1, 2, 3]; first as first"
            class="card"
            [ngClass]="first"
          >
            <div class="sub-card-1">
              <div class="title">
                <svg-icon
                  class="org-arrow"
                  src="assets/images/icons/Arrow-left.svg"
                  styleClass="mr-2"
                ></svg-icon>
                <p-skeleton
                  width="10rem"
                  height="18px"
                  styleClass="ml-2"
                ></p-skeleton>
                <p-skeleton
                  width="5rem"
                  height="18px"
                  styleClass="ml-2"
                ></p-skeleton>
              </div>
              <div *ngFor="let i of [0, 1]" class="layover">
                <p-skeleton
                  width="42px"
                  height="16px"
                  styleClass="mt-0"
                ></p-skeleton>
                <div class="separator"></div>
                <p-skeleton
                  width="59px"
                  height="16px"
                  styleClass="mt-0"
                ></p-skeleton>
                <div class="separator"></div>
                <p-skeleton
                  width="42px"
                  height="16px"
                  styleClass="mt-0"
                ></p-skeleton>
              </div>
            </div>
            <div class="sub-card-2">
              <div class="first">
                <p-skeleton width="6rem" height="16px"></p-skeleton>
                <p-skeleton
                  shape="circle"
                  size="16px"
                  styleClass="ml-2"
                ></p-skeleton>
              </div>
            </div>
            <div class="sub-card-3">
              <p-skeleton
                width="82px"
                height="27px"
                styleClass="mt-0"
              ></p-skeleton>
              <p-skeleton
                width="127px"
                height="35px"
                styleClass="mt-2"
              ></p-skeleton>
              <p-skeleton
                width="121px"
                height="16px"
                styleClass="mt-2"
              ></p-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-animation">
      <app-carousel
        *ngIf="listImgCarosuel.length > 0; else default"
        class="carousel"
        [listImgCarosuel]="listImgCarosuel"
        [numVisible]="1"
        [inLoadingPage]="true"
      ></app-carousel>
      <ng-template #default>
        <svg-icon
          class="img-default"
          src="assets/images/banner-loading/banner-default2.svg"
        ></svg-icon>
      </ng-template>
    </div>
  </div>
</section>
