<ng-container [formGroup]="parentForm">
  <input [name]="name" type="checkbox" [value]="options.val ? options.val : 'None'" [id]="options.id ? options.id : fCName" [formControlName]="fCName"
  (change)="onChanges($event, options.val)"/>
  <label [ngClass]="options.class" [for]="options.id ? options.id : fCName" [attr.data-datavalue]="options.dataValue">
    <div></div>
    <p *ngIf="!options.truncate">{{ options.placeholder | translate }}</p>
    <p *ngIf="options.truncate" [pTooltip]="options.placeholder|translate" [tooltipPosition]="'top'"
      [tooltipDisabled]="options.placeholder <= options.truncate">
      {{ options.placeholder | translate | truncate: options.truncate }}</p>
      <p class="label-value" *ngIf="options?.labelValue">{{options.labelValue}}</p>
  </label>
  <button *ngIf="options.info" mat-raised-button [matTooltip]="options.tooltipText | translate"
    [matTooltipPosition]="options.tooltipPosition" [matTooltipClass]="options.class"
    aria-label="Button that displays a tooltip when focused or hovered over">
    <svg-icon src="assets/images/icons/info.svg"></svg-icon>
  </button>
</ng-container>
