import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { bufferTime, distinct, filter, take } from 'rxjs/operators';
import {
  ModalButtons,
  ModalIcon,
} from 'src/app/modules/application/transport/components/modal-options/modal-options.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ManagementModalService } from '../utils/management-modal/management-modal.service';
import { errorTPV } from '../../configurations/configurations';
import { JwtService } from '../user/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  private errorStream = new Subject<{
    error: TypeError;
    url: string;
    token: string;
  }>();
  private errorMessage: string = 'genericError_';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private managementModal: ManagementModalService,
    private zone: NgZone,
    private jwt: JwtService
  ) {
    this.zone.runOutsideAngular(() => {
      this.errorStream
        .pipe(
          filter((_) => environment.production),
          distinct((error) => error?.error?.message),
          bufferTime(1000),
          filter((errors) => errors.length > 0)
        )
        .subscribe((errors) => this.sendErrorToBack(errors));

      this.errorStream
        .pipe(filter((_) => !environment.production))
        .subscribe((error) => {
          console.error(error);
        });
    });
  }

  public registerError(err: TypeError, token: string, route?: string) {
    this.errorStream.next({ error: err, url: route!, token: token });
  }

  private sendErrorToBack(
    errors: { error: any; url: string; token: string }[]
  ) {
    if (this.jwt.isTokenExpired()) {
      return;
    }
    const errorsString = errors.map((e) => {
      e.error = JSON.stringify(e.error, Object.getOwnPropertyNames(e.error));
      return e;
    });
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    this.http
      .post(
        environment.api_backend_url + environment.backend_error_logs,
        { error: `[${environment.env}] ${JSON.stringify(errorsString)}` },
        { headers }
      )
      .subscribe();
  }

  public errorPaymentRechargeMethod() {
    if (this.route.snapshot.queryParamMap.get('error_level')) {
      const icon =
        this.route.snapshot.queryParamMap.get('error_level') === 'critical'
          ? this.managementModal.iconType.generalError
          : this.managementModal.iconType.error;

      const message =
        (this.route.snapshot.queryParamMap.get('error_category') &&
        this.route.snapshot.queryParamMap.get('error_category') === 'ticketing'
          ? this.translateService.instant('error_ticketing_')
          : '</br>') +
        '<p class="message-api-error-recharge-method">' +
        this.translateService.instant('ref_error_') +
        this.route.snapshot.queryParamMap.get('error_message') +
        '</p>';
      this.openModalErrorPaymentRechargeMethod(message, icon);
    } else if (this.route.snapshot.queryParamMap.get('tpv_error')) {
      const icon = this.managementModal.iconType.generalError;
      const message =
        this.route.snapshot.queryParamMap.get('error_msg') ??
        this.translateService.instant(
          errorTPV[this.route.snapshot.queryParamMap.get('tpv_error')!]
        );
      this.openModalErrorPaymentRechargeMethod(message, icon);
    }
  }

  private openModalErrorPaymentRechargeMethod(
    message: string,
    icon: ModalIcon,
    title = ''
  ) {
    const modalButtonsErrorUrl: ModalButtons[] = [
      {
        text: 'close_',
        class: 'btn-primary',
        emitOnClick: 'cancel',
      },
    ];
    this.managementModal.showCustomModal(
      title,
      message,
      modalButtonsErrorUrl,
      icon,
      null,
      { id: 'ModalErrorPaymentRechargeMethod', isOpen: false }
    );
    this.managementModal
      .getConfirmButton$()
      .pipe(
        filter((res) => res.id === 'handleRequest'),
        filter((res) => res.response === 'submit'),
        take(1)
      )
      .subscribe();
  }

  public errorResponseNotControl(err: HttpErrorResponse) {
    const errorDescription = err?.error?.error?.message;
    errorDescription && this.showModalErrorRequest(errorDescription);
  }

  private showModalErrorRequest(description, title = '') {
    const modalButtons: ModalButtons[] = [
      {
        text: 'close_',
        class: 'btn-primary',
        emitOnClick: 'submit',
      },
    ];
    this.managementModal.showCustomModal(
      title,
      description,
      modalButtons,
      this.managementModal.iconType.warning,
      null,
      { id: 'errorRequest', isOpen: false }
    );
    this.managementModal
      .getConfirmButton$()
      .pipe(
        filter((res) => res.id === 'errorRequest'),
        take(1)
      )
      .subscribe();
  }

  public treatmentsErrorDescription(
    dataError: any,
    defaultMessage = this.translateService.instant(this.errorMessage),
    addRefCode: boolean = false
  ): string {
    let message = '';
    let codeError = '';
    if (typeof dataError === 'string') {
      message = dataError;
    } else if (dataError?.error) {
      if (
        dataError?.error?.errors &&
        Array.isArray(dataError.error.errors) &&
        dataError.error.errors.length > 0
      ) {
        message = this.dataErrorsMessageArray(dataError.error, defaultMessage);
      } else if (typeof dataError?.error?.errors === 'string') {
        message = dataError?.error?.errors;
      } else {
        message = this.translateService.instant(
          dataError.error.description ||
            dataError.error.message ||
            dataError.error.error ||
            defaultMessage
        );
      }

      if (dataError.error.code) {
        codeError =
          this.translateService.instant('code_') + dataError.error.code;
      }
    } else if (
      Array.isArray(dataError?.errors) &&
      dataError.errors.length > 0
    ) {
      message = this.dataErrorsMessageArray(dataError, defaultMessage);
      codeError = this.dataErrorsCodeArray(dataError);
    } else if (
      dataError?.errors &&
      !Array.isArray(dataError?.errors) &&
      Object.keys(dataError.errors).length > 0
    ) {
      Object.keys(dataError.errors).forEach((errorKey) => {
        if (errorKey === 'message' || errorKey === 'description') {
          message +=
            this.translateService.instant(dataError.errors[errorKey]) + '</br>';
        }
      });
      if (dataError.errors['code']) {
        codeError =
          this.translateService.instant('code_') + dataError.errors[0].code;
      }
    }

    if (message === '') {
      message = defaultMessage + (codeError !== '' ? '</br>' + codeError : '');
    }

    if (addRefCode && codeError !== '') {
      message = '[' + codeError + '] ' + message;
    }

    return message;
  }

  private dataErrorsMessageArray(dataError: any, defaultMessage): string {
    let message = '';

    dataError.errors.forEach((error) => {
      message +=
        this.translateService.instant(
          error.description ||
            error.message ||
            (typeof error === 'string' ? error : defaultMessage)
        ) + '</br>';
    });

    return message;
  }

  private dataErrorsCodeArray(dataError: any): string {
    let codeError = '';

    if (dataError.errors[0].code) {
      codeError =
        this.translateService.instant('code_') + dataError.errors[0].code;
    }

    return codeError;
  }

  public getCodeError(dataError: any): number | null {
    let codeError = null;
    if (typeof dataError === 'string') {
      return null;
    }
    if (dataError?.error && dataError.error.code) {
      codeError = dataError.error.code;
    } else if (
      Array.isArray(dataError?.errors) &&
      dataError.errors.length > 0 &&
      dataError.errors[0].code
    ) {
      codeError = dataError.errors[0].code;
    } else if (
      !Array.isArray(dataError?.errors) &&
      dataError?.errors &&
      Object.keys(dataError?.errors).length > 0 &&
      dataError.errors['code']
    ) {
      codeError = dataError.errors[0].code;
    }
    return codeError;
  }
}
