<section class="modal">
  <div class="col-1 closed-button">
    <button type="button" class="close" data-cy="close" (click)="closedNews()">
      <svg-icon
        src="assets/images/icons/close.svg"
        [svgStyle]="{ 'width.px': 14, 'heigth.px': 14 }"
      ></svg-icon>
    </button>
  </div>
  <app-carousel
    class="carousel"
    [listImgCarosuel]="listImgCarosuel"
  ></app-carousel>
</section>
