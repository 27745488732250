<div class="counter-box">
  <div class="paxArrow" [formGroup]="parentForm">
    <button
      [ngClass]="{ disabled: parentForm.get(fCName)?.disabled }"
      tabindex="-1"
      type="button"
      (click)="changeSelected(-1)"
      class="less"
      [disabled]="isDisabled(-1)"
      data-cy="counter-less"
    >
      <svg-icon src="assets/images/icons/rest2.svg"></svg-icon>
    </button>
    <span>{{ parentForm.get(fCName)?.value }}</span>
    <button
      [ngClass]="{ disabled: parentForm.get(fCName)?.disabled }"
      tabindex="-1"
      type="button"
      (click)="changeSelected(1)"
      [disabled]="isDisabled(1)"
      data-cy="counter-more"
    >
      <svg-icon src="assets/images/icons/plus2.svg"></svg-icon>
    </button>
  </div>
  <div *ngIf="options" class="extra-info" [ngClass]="options?.class">
    <p *ngIf="options?.placeholder">{{ options.placeholder }}</p>
    <p *ngIf="options?.labelValue">{{ options.labelValue }}</p>
  </div>
</div>
