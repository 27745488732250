import { UserService } from '../user/user.service';
import { Socket } from 'ngx-socket-io';

export class UserSocket extends Socket {
  constructor(private userService: UserService) {
    super({
      url: `wss://socket.lleego.es/?ch=${userService.getCurrentUSer()?.u?.wp}`,
      options: {
        transports: ['websocket']
      }
    });
  }
}
