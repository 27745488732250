import { Injectable } from '@angular/core';
import { ModalButtons } from 'src/app/modules/application/transport/components/modal-options/modal-options.component';
import { ManagementModalService } from '../../utils/management-modal/management-modal.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { StorageService } from '../../utils/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ConsolidatorsService } from '../../admin/consolidators/consolidators.service';
import { Router } from '@angular/router';
import { NotificationsUtilService } from '../../utils/notifications-util/notifications-util.service';
import { ErrorsService } from '../../erros/errors.service';
import { AgencyService } from '../../user/agency.service';
import { Observable } from 'rxjs';
import { RouterService } from '../../router/router.service';

@Injectable({
  providedIn: 'root',
})
export class VoucherModalsService {
  constructor(
    private managementModal: ManagementModalService,
    private storageService: StorageService,
    private translate: TranslateService,
    private consolidatorsService: ConsolidatorsService,
    private router: Router,
    private notificationsUtil: NotificationsUtilService,
    private errorsService: ErrorsService,
    private agencyService: AgencyService,
    private routerService: RouterService
  ) {}

  showCheckTicketsModal(warnings: any): void {
    if (!warnings) return;

    warnings.forEach((warning) => {
      if (warning.id === 'CHECK_TICKETS') {
        const modalButtons: ModalButtons[] = [
          {
            text: 'close_',
            class: 'btn-primary',
            emitOnClick: 'cancel',
          },
        ];
        this.managementModal.showCustomModal(
          '',
          'CHECK_TICKETS',
          modalButtons,
          this.managementModal.iconType.warning,
          null,
          { id: 'checkTickets', isOpen: false }
        );
        this.managementModal.getConfirmButton$().subscribe({
          next: (_) => {
            this.managementModal.hideModal();
          },
        });
      }
    });
  }

  openModalWarningException(bookingID: string): void {
    const modalButtons: ModalButtons[] = [
      {
        text: 'close_',
        class: 'btn-primary',
        emitOnClick: 'cancel',
      },
    ];
    this.managementModal.showCustomModal(
      'title_errorFF_',
      'errorFF_',
      modalButtons,
      this.managementModal.iconType.warning,
      null,
      { id: 'createBooking', isOpen: false }
    );
    this.managementModal.confirmButton$
      .pipe(
        filter((res) => res.id === 'createBooking'),
        take(1)
      )
      .subscribe({
        next: (_) => {
          if (bookingID) {
            this.storageService.deleteWarningsToVoucher(bookingID);
          }
        },
      });
  }

  modalVoucherErrorWarningPrice(): void {
    const modalButtons: ModalButtons[] = [
      {
        text: 'close_',
        class: 'btn-back',
        emitOnClick: 'submit',
      },
    ];

    this.managementModal.showCustomModal(
      this.translate.instant('alert repricing_modal.title_'),
      this.translate.instant('alert repricing_modal.subtitle_'),
      modalButtons,
      this.managementModal.iconType.warning,
      null,
      { id: 'errorRequest', isOpen: false }
    );
    this.managementModal.getConfirmButton$().subscribe({
      next: (_) => {
        this.managementModal.hideModal();
      },
    });
  }
  modalRepricing(): void {
    const modalButtons: ModalButtons[] = [
      {
        text: 'great_',
        class: 'btn-back',
        emitOnClick: 'submit',
      },
    ];

    this.managementModal.showCustomModal(
      'repricing_modal.title_',
      this.translate.instant('repricing_modal.subTitle_'),
      modalButtons,
      this.managementModal.iconType.validation,
      null,
      { id: 'errorRequest', isOpen: false }
    );
    this.managementModal.getConfirmButton$().subscribe({
      next: (res) => {
        this.storageService.setItem('repricing', 'repricing');
        window.location.reload();
        this.managementModal.hideModal();
      },
    });
  }

  modalNoHaveBalanceByChange(
    typeUrlNavegated: string,
    bookingID: string
  ): void {
    const modalButtons: ModalButtons[] = [
      {
        text: 'increase_balance_',
        class: 'btn-back',
        emitOnClick: 'increaseBalance',
      },
      {
        text: 'continuing_the_change_',
        class: 'btn-primary',
        emitOnClick: 'submit',
      },
    ];
    this.managementModal.showCustomModal(
      'insufficient balance_',
      'modal_question_balance_',
      modalButtons,
      this.managementModal.iconType.warning,
      null,
      { id: 'haveBalance', isOpen: false }
    );
    this.managementModal
      .getConfirmButton$()
      .pipe(
        filter((res) => res.id === 'haveBalance'),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.response === 'increaseBalance') {
            const urlWithExtraParams = this.routerService.getUrlWithExtraParams(
              this.consolidatorsService.getAutologinUrl('/admin/perfile/add')
            );
            window.open(urlWithExtraParams);
          } else {
            this.router.navigate([
              `${typeUrlNavegated}/voucher/changes/${bookingID}`,
            ]);
          }
        },
      });
  }

  expedientCommonModalError(error: any): void {
    const modalButtons: ModalButtons[] = [
      {
        text: 'close_',
        class: 'btn-primary',
        emitOnClick: 'cancel',
      },
    ];

    this.managementModal.hideModal();
    this.managementModal.showCustomModal(
      '',
      error?.errors?.[0]?.description,
      modalButtons,
      this.managementModal.iconType.warning,
      null,
      { id: 'expedientCommonError', isOpen: false }
    );

    this.managementModal.confirmButton$
      .pipe(
        filter((res) => res.id === 'expedientCommonError'),
        filter((res) => res.response === 'submit'),
        take(1)
      )
      .subscribe();
  }

  bringReservationModal(
    user: string,
    password: string,
    locator: string,
    office: string
  ): void {
    this.managementModal.showConfirmModal('sure bring_', 'continue?_', {
      id: 'bringReservation',
      isOpen: false,
    });

    const button = this.managementModal.confirmButton$
      .pipe(
        tap(({ response }) => {
          if (response !== 'submit') {
            button.unsubscribe();
          }
        }),
        filter((res) => res.id === 'bringReservation'),
        filter(({ response }) => response === 'submit'),
        tap(() => {
          this.managementModal.showLoadingModal('verifying_');
        }),
        switchMap(() =>
          this.agencyService.bringReservation(user, password, locator, office)
        ),
        take(1)
      )
      .subscribe({
        next: (res: any) => {
          this.managementModal.hideModal();
          if (!res.errors) {
            window.location.reload();
            this.notificationsUtil.infoSuccess('bring success_');
          } else {
            const message = this.errorsService.treatmentsErrorDescription(res);
            this.showModalErrorRequest(message);
          }
        },
        error: () => {
          this.managementModal.deleteAllStackElement();
          this.notificationsUtil.infoError('an error has occurred_');
        },
      });
  }

  showModalErrorRequest(description: string, title = '', reload = false): void {
    const modalButtons: ModalButtons[] = [
      {
        text: 'close_',
        class: 'btn-primary',
        emitOnClick: 'submit',
      },
    ];
    this.managementModal.showCustomModal(
      title,
      description,
      modalButtons,
      this.managementModal.iconType.warning,
      null,
      { id: 'errorRequest', isOpen: false }
    );
    this.managementModal
      .getConfirmButton$()
      .pipe(
        filter((res) => res.id === 'errorRequest'),
        take(1)
      )
      .subscribe({
        next: () => {
          if (reload) {
            window.location.reload();
          }
        },
      });
  }

  showLoadingModal(data: string): void {
    this.managementModal.showLoadingModal(data);
  }

  hideModal(): void {
    this.managementModal.hideModal();
  }

  deleteAllStackElement(): void {
    this.managementModal.deleteAllStackElement();
  }

  getConfirmButton$(): Observable<any> {
    return this.managementModal.getConfirmButton$();
  }
}
