<div class="lang" [ngClass]="{isCallCenter,isRailApp}">
  <app-select
    [data]="dataLangs"
    [parentForm]="formLanguage"
    [fCName]="'language'"
    [options]="{
      class: 'lang' + (isCallCenter ? ' isCallCenterSelect' : '')
    }"
  ></app-select>
</div>
