<ng-container *ngIf="showClosedButton">
  <div class="col-11 pr-0 mr-0 box-closed-text">
    <ng-container *ngIf="!isCorporate">
      <p>{{ 'no_show_modal_service_fee_' | translate }}</p>
      <button
        (click)="emitInfo('cancel')"
        type="button"
        class="closeServiceFee"
        data-cy="close"
      >
        <svg-icon
          src="assets/images/icons/close.svg"
          [svgStyle]="{ 'width.px': 14, 'heigth.px': 14 }"
        ></svg-icon>
      </button>
    </ng-container>
  </div>
  <div class="col-1 closed-button" *ngIf="isCorporate">
    <button
      (click)="emitInfo('cancel')"
      type="button"
      class="close"
      data-cy="close"
    >
      <svg-icon
        src="assets/images/icons/close.svg"
        [svgStyle]="{ 'width.px': 14, 'heigth.px': 14 }"
      ></svg-icon>
    </button>
  </div>
</ng-container>
<section
  [class]="'modal ' + classId"
  [ngClass]="{ crm: showCrmInputs, modalPadding: warningAditional }"
>
  <div *ngIf="icon" class="icon" [ngClass]="icon.class">
    <svg-icon [src]="icon.src"></svg-icon>
  </div>
  <div
    *ngIf="icon && !activeAddCompany && !activeAddCompanyError"
    [ngClass]="icon.corporateIcon?.class"
    [ngClass]="{ icon: icon.corporateIcon?.src }"
  >
    <svg-icon [src]="icon.corporateIcon?.src"></svg-icon>
  </div>
  <div
    *ngIf="icon && activeAddCompany && !activeAddCompanyError"
    class="icon"
    [ngClass]="icon.corporateIconPlus?.class"
  >
    <svg-icon [src]="icon.corporateIconPlus?.src"></svg-icon>
  </div>
  <div
    *ngIf="icon && !activeAddCompany && activeAddCompanyError"
    class="icon alert"
    [ngClass]="icon.corporateError?.class"
  >
    <svg-icon [src]="icon.corporateError?.src"></svg-icon>
  </div>
  <ng-container *ngIf="!activeAddCompany && !activeAddCompanyError">
    <h2 class="title" *ngIf="title" data-cy="modal-title">
      {{ title | translate }}
    </h2>
  </ng-container>
  <ng-container
    *ngIf="activeAddCompany && isCorporate && !activeAddCompanyError"
  >
    <h2 class="title" data-cy="modal-title">
      {{ 'create_company_' | translate }}
    </h2>
  </ng-container>
  <ng-container
    *ngIf="!activeAddCompany && isCorporate && activeAddCompanyError"
  >
    <h2 class="title" data-cy="modal-title">
      {{ 'error_corporate_title_' | translate }}
    </h2>
  </ng-container>
  <!-- Modificando el CRM hemos visto que no se utiliza, revisar-->
  <app-fields-collection
    *ngIf="showCrmInputs"
    class="crm-inputs"
    [form]="modalForm"
    [inputs]="modalInputsCrm"
  ></app-fields-collection>
  <p
    *ngIf="description && !changePrice"
    class="option-tex"
    [ngClass]="{ 'with-inputs': showCrmInputs || inputs.length > 0 }"
    [innerHTML]="
      description.includes('EXCEEDS 12 HR')
        ? translateService.instant(
            'EXCEEDS 12 HR TRANSFER FOR RES DISC-ISSUE SEPARATE TKTS'
          )
        : translateService.instant(description)
    "
    data-cy="option-text"
  ></p>
  <p
    *ngIf="voidFee"
    class="option-tex"
    [innerHTML]="translateService.instant(voidFee.voidText, {fee : voidFee.fee | currencyCustom : voidFee.currency
    } )"
    data-cy="option-text"
  ></p>
  <div
    class="warning-box-modal"
    *ngIf="warningAditional && warningAditional.message !== '' && !changePrice"
  >
    <div class="warning-image">
      <svg-icon src="assets/images/icons/caution.svg"></svg-icon>
    </div>
    <div class="warning-text">
      <p
        class="warning title-font"
        [innerHTML]="translateService.instant(warningAditional.title)"
      ></p>
      <p
        class="warning description"
        [innerHTML]="translateService.instant(warningAditional.message)"
      ></p>
    </div>
  </div>

  <div class="profile-box" *ngIf="containTex">
    <p class="profile" [innerHTML]="containTex"></p>
  </div>
  <div *ngIf="changeRate" class="change-rate">
    <div
      [ngClass]="{
        previus: changeRate.originalTotalRate > changeRate.currentTotalRate,
        current: changeRate.originalTotalRate < changeRate.currentTotalRate
      }"
    >
      <div>
        <svg-icon src="assets/images/icons/green-arrow.svg"></svg-icon>
        <p>
          {{ 'change_original_amount_' | translate }}
        </p>
        <p class="amount">
          {{
            changeRate.originalTotalRate | currencyCustom : changeRate.currency
          }}
        </p>
      </div>
      <p class="rate">
        {{
          'change_original_rate_'
            | translate : { changeOriginalRate: changeRate.originalRate }
        }}
      </p>
    </div>
    <div class="line-separetor"></div>
    <div
      [ngClass]="{
        previus: changeRate.originalTotalRate < changeRate.currentTotalRate,
        current: changeRate.originalTotalRate > changeRate.currentTotalRate
      }"
    >
      <div>
        <svg-icon src="assets/images/icons/green-arrow.svg"></svg-icon>
        <p>{{ 'change_current_amount_' | translate }}</p>
        <p class="amount">
          {{
            changeRate.currentTotalRate | currencyCustom : changeRate.currency
          }}
        </p>
      </div>
      <p class="rate">
        {{
          'change_current_rate_'
            | translate : { changeCurrentRate: changeRate.currentRate }
        }}
      </p>
    </div>
  </div>

  <div *ngIf="changePrice" class="change-price" data-cy="change-price">
    <div
      [ngClass]="{
        previus: changePrice.originalAmount > changePrice.currentAmount,
        current: changePrice.originalAmount < changePrice.currentAmount
      }"
    >
      <svg-icon src="assets/images/icons/green-arrow.svg"></svg-icon>
      <div>
        <p data-cy="pricePrevious">
          {{
            changePrice.originalAmount | currencyCustom : changePrice.currency
          }}
        </p>
        <p>
          <small>{{ 'previous_' | translate }}</small>
        </p>
      </div>
    </div>
    <div class="line-separetor"></div>
    <div
      [ngClass]="{
        previus: changePrice.currentAmount > changePrice.originalAmount,
        current: changePrice.currentAmount < changePrice.originalAmount
      }"
    >
      <svg-icon src="assets/images/icons/green-arrow.svg"></svg-icon>
      <div>
        <p data-cy="priceNew">
          {{
            changePrice.currentAmount | currencyCustom : changePrice.currency
          }}
        </p>
        <p>
          <small>{{ 'new_' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!activeAddCompany && !activeAddCompanyError">
    <div class="companyInputIcon">
      <app-fields-collection
        [ngClass]="{
          paddingOptions: modalPrintPadding,
          corporateCollection: isCorporate && userService.isVeci.value,
          crmPaddingOptions: crmStyles
        }"
        *ngIf="inputs.length > 0"
        [form]="modalForm"
        [inputs]="inputs"
        [options]="{
      selectRadioText
    }"
        (openAddCompany)="toggleAddCompany()"
        (openAddCompanyError)="openAddCompanyError($event)"
        data-cy="fields-collection"
      ></app-fields-collection>
      <svg-icon
        *ngIf="
          modalForm?.get('name')?.valid && !activeAddCompany && isCorporate
        "
        [ngClass]="{
          veci: userService.isVeci.value,
          noVeci: !userService.isVeci.value
        }"
        src="assets/images/icons/tick3.svg"
      ></svg-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="isCorporate && activeAddCompany">
    <input
      class="addCompanyInput"
      [placeholder]="'company code_' | translate"
      [formControl]="newCompany"
    />

    <ul class="p-autocomplete-items save-company" *ngIf="companyError">
      <li class="p-autocomplete-empty-message">
        {{ 'company_code_not_exist_' | translate }}
      </li>
    </ul>
  </ng-container>
  <ng-container
    *ngIf="isCorporate && !activeAddCompany && activeAddCompanyError"
  >
    <div class="corporate-error-description">
      {{ 'error_corporate_description_' | translate }}
    </div>
  </ng-container>
  <div
    *ngIf="
      modalForm && modalForm.get('noFound') && modalForm.get('noFound')?.value
    "
    class="no-found"
  >
    <p>
      {{ 'no found_' | translate }}
    </p>
  </div>
  <div
    *ngIf="
      lastCorporateSearchs &&
      lastCorporateSearchs?.length > 0 &&
      lastCorporateSearchs[0] !== null &&
      isCorporate &&
      !activeAddCompany &&
      !activeAddCompanyError
    "
    class="latest-searchs-container"
  >
    <span
      ><strong>{{ 'your latest searches_' | translate }}:</strong></span
    >
    <div>
      <div
        (click)="setCompany(item)"
        *ngFor="let item of lastCorporateSearchs"
        [ngClass]="{
          'selected-company':
            item?.codAccounts &&
            companySelected &&
            companySelected?.codAccounts === item?.codAccounts &&
            ((companySelected?.codDepartment &&
              companySelected?.codDepartment !== '' &&
              companySelected?.codDepartment === item?.codDepartment) ||
              companySelected?.codDepartment === '')
        }"
      >
        <span>{{ item.descName }}</span>
        <div class="data-company">
          {{ item?.codAccounts }}
          <span *ngIf="userService.isVeci.value"
            >{{ 'dpto_' | translate }}{{ item?.codDepartment }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="buttons"
    class="button-box"
    [ngClass]="{ center: buttons && buttons.length === 1 }"
  >
    <div *ngIf="residentCandidates.length > 0" class="resident-options">
      <ng-container *ngFor="let button of buttons">
        <div class="content-buttons-residents">
          <button
            data-cy="btn-modal"
            pRipple
            *ngIf="button.resident"
            [class]="button.class"
            (click)="emitInfo(button.emitOnClick)"
          >
            {{ button?.text | translate }}
          </button>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!activeAddCompany && !activeAddCompanyError">
      <ng-container *ngFor="let button of buttons">
        <button
          data-cy="btn-modal"
          pRipple
          *ngIf="!button.resident"
          [class]="button.class"
          (click)="emitInfo(button.emitOnClick)"
          [disabled]="modalForm?.get('name')?.invalid && isCorporate"
        >
          <svg-icon
            *ngIf="button.class.includes('refresh-icon')"
            [ngClass]="{ rotate: iconRotateCorporate }"
            src="assets/images/icons/reload-two-arrows.svg"
            [svgStyle]="{ 'margin-left.px': 5 }"
          ></svg-icon>
          {{ button?.text | translate }}
        </button>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="isCorporate && activeAddCompany && !activeAddCompanyError"
    >
      <button pRipple class="btn-back" (click)="toggleAddCompany()" data-cy="btn-modal">
        {{ 'back_' | translate }}
      </button>
      <button
        pRipple
        class="btn-primary"
        (click)="addCompany(newCompany.value)"
        [disabled]="newCompany.invalid"
        data-cy="btn-modal"
      >
        {{ 'save_' | translate }}
      </button>
    </ng-container>
    <ng-container
      *ngIf="isCorporate && !activeAddCompany && activeAddCompanyError"
    >
      <button pRipple class="btn-back" (click)="emitInfo('cancel')" data-cy="btn-modal">
        {{ 'close_' | translate }}
      </button>
      <button pRipple class="btn-primary" (click)="openAddCompanyError()" data-cy="btn-modal">
        {{ 'modal_error_update_corporate_.buttonRetry' | translate }}
      </button>
    </ng-container>
  </div>
  <div
    class="addComapany"
    *ngIf="isCorporate && !activeAddCompany && !activeAddCompanyError"
  >
    <p>
      <ng-container>{{ 'not_found_company_' | translate }}</ng-container>
      <span (click)="toggleAddCompany()">
        <ng-container>{{ 'url_save_company_' | translate }}</ng-container>
      </span>
    </p>
  </div>
</section>
