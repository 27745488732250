import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientUtilService {
  /**
   * Guess client name
   */
  static guessClientName(): string {
    let clientName = 'lleego';
    if (
      environment.veci_domains.includes(window.location.hostname) ||
      window.location.href.includes('clientsrc=veci')
    ) {
      clientName = 'veci';
    }
    return clientName;
  }
}
