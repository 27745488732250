<div
  id="calendar-dropdown-box"
  [ngClass]="{ train: transport === 'train', isCallCenter }"
>
  <div
    *ngIf="show"
    class="calendar-dropdown"
    [ngClass]="{ isNotCallCenter: !isCallCenter }"
  >
    <form [formGroup]="form" data-cy="calendar">
      <p-calendar
        [formControlName]="controlName"
        [inline]="true"
        [firstDayOfWeek]="1"
        [numberOfMonths]="2"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [showOtherMonths]="false"
        dateFormat="dd-MMM-yyyy"
      >
        <ng-template pTemplate="date" let-date>
          <span
            (mouseover)="dateOver(date)"
            (mouseout)="dateOut()"
            [ngClass]="{
              'other-days-selected': otherDaysSelected(date),
              'days-between': daysBetween(date) && !otherDaysSelected(date),
              'selected-focus': selectedFocus(date)
            }"
            >{{ date.day }}
          </span>
          <!-- Parte comentada hasta que se defina por diseño su implementación -->
          <!-- <span
            *ngIf="form.get('type').value === 'multi-destination'"
            (mouseover)="dateOver(date)"
            (mouseout)="dateOut()"
            [ngClass]="{
              'other-days-selected': otherDaysSelected(date)  ,
              'days-between': daysBetween(date) && !otherDaysSelected(date),
              'selected-focus': selectedFocus(date)

            }"


            >{{ date.day }}
             <p
             class="iata"
             *ngIf="form.get('type').value === 'multi-destination' &&
            (fillShowIata(date) === 5  || !index)&&
             (otherDaysSelected(date) ||selectedFocus(date) )
            "
           >{{form.get('origin').value.value}}</p>
          <p
          class="iata"
            *ngIf="form.get('type').value === 'multi-destination' &&
            fillShowIata(date) === 0 &&
            (otherDaysSelected(date) ||selectedFocus(date) )"
           >{{form.get('multiDestination').at(fillShowIata(date)).get('origin').value.value}}</p>
           <p
           class="iata"
            *ngIf="form.get('type').value === 'multi-destination' &&
            fillShowIata(date) === 1 &&
            (otherDaysSelected(date) ||selectedFocus(date) )"
           >{{form.get('multiDestination').at(fillShowIata(date)).get('origin').value.value}}</p>
           <p
           class="iata"
            *ngIf="form.get('type').value === 'multi-destination' &&
            fillShowIata(date)  === 2 &&
            (otherDaysSelected(date) ||selectedFocus(date) )"
           >{{form.get('multiDestination').at(fillShowIata(date)).get('origin').value.value}}</p>
           <p
           class="iata"
            *ngIf="form.get('type').value === 'multi-destination' &&
            fillShowIata(date) === 3 &&
            (otherDaysSelected(date) ||selectedFocus(date) )"
           >{{form.get('multiDestination').at(fillShowIata(date)).get('origin').value.value}}</p>

           <p
           class="iata"
            *ngIf="form.get('type').value === 'multi-destination' &&
            fillShowIata(date)  === 4 &&
            (otherDaysSelected(date) ||selectedFocus(date) )"
           >{{form.get('multiDestination').at(fillShowIata(date)).get('origin').value.value}}</p>
          </span> -->
        </ng-template>
      </p-calendar>
    </form>
    <div
      *ngIf="!isCallCenter"
      class="flexible-dates-container"
      [ngClass]="{ train: transport === 'train' }"
    >
      <div [ngClass]="{ hide: form.get('availDivided')?.value }" class="flexible-dates-container" *ngIf="transport !== 'train'">
        <div
          *ngIf="
            (form.get('type') && form.get('type').value === 'round-trip') ||
            form.get('type').value === 'one-way'
          "
          class="flexible-dates-div"
        >
          <span>{{ 'flexible_days_' | translate }}:</span>
          <p>{{ 'one way_' | translate }}</p>

          <button
            class="flexible-dates-button"
            type="button"
            [ngClass]="{
              selected: this.form.get('originDayFlex').value
            }"
            (click)="controlDaysFlex('originDayFlex')"
            data-cy="oneFlexDay-going"
          >
            &#177; 1
          </button>
          <button
            class="flexible-dates-button"
            type="button"
            [ngClass]="{ selected: this.form.get('daysFlex').value }"
            (click)="controlDaysFlex('daysFlex')"
            data-cy="threeFlexDays-going"
          >
            &#177; 3
          </button>
        </div>
        <div
          *ngIf="form.get('type') && form.get('type').value === 'round-trip'"
          class="flexible-dates-div return"
        >
          <p>{{ 'return_' | translate }}</p>
          <button
            class="flexible-dates-button"
            type="button"
            [ngClass]="{ selected: this.form.get('destinationDayFlex').value }"
            (click)="controlDaysFlex('destinationDayFlex')"
            data-cy="oneFlexDay-return"
          >
            &#177; 1
          </button>
          <button
            class="flexible-dates-button"
            type="button"
            [ngClass]="{ selected: this.form.get('daysFlex').value }"
            (click)="controlDaysFlex('daysFlex')"
            data-cy="threeFlexDays-return"
          >
            &#177; 3
          </button>
        </div>
      </div>
      <div class="button-complete">
        <button class="complete" type="button" (click)="closeCalendar(true)">
          {{ 'complete_' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
