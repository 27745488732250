<ng-container [formGroup]="parentForm">
  <p-autoComplete
    #autocomplete
    (onFocus)="suggestionsOnFocus(autocomplete, options?.autoFocus)"
    [formControlName]="fCName"
    [suggestions]="results"
    (completeMethod)="search($event)"
    [placeholder]="options?.placeholder | translate"
    [autofocus]="options?.autoFocus"
    [emptyMessage]="options?.empty | translate"
    [showEmptyMessage]="!noCompaniesFound ? true : false"
    (onSelect)="setSelectedValue($event)"
    [forceSelection]="
      options.forceSelection === false ? options?.forceSelection : true
    "
    [minLength]="options?.minLength ? options.minLength : 1"
    autocomplete="off"
    [inputStyleClass]="options?.showErrors ? 'error' : ''"
    [class]="options?.class || ''"
    [attr.data-cy]="fCName"
  >
  </p-autoComplete>
  <ul *ngIf="noCompaniesFound" class="p-autocomplete-items save-company">
    <li class="p-autocomplete-empty-message">
      {{ 'company not found_' | translate }}
    </li>
    <button class="create" (click)="openAddCompanyModule()">
      {{ 'create_' | translate }}
    </button>
  </ul>
  <ul *ngIf="noAgencyFound" class="p-autocomplete-items save-company">
    <li class="p-autocomplete-empty-message">
      {{ 'agency not found_' | translate }}
      <strong (click)="addAgencyPostSearchInput()"
        ><u>{{ 'click here to using_' | translate }}</u></strong
      >
    </li>
  </ul>
</ng-container>
