import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'calculateCO2TotalBySegment',
})
export class CalculateCO2TotalBySegmentPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private sanitized: DomSanitizer
  ) {}

  transform(value: any): SafeHtml {
    const text: string = this.translateService.instant('co2_', {
      number: parseInt(value),
    });
    return this.sanitized.bypassSecurityTrustHtml(text);
  }
}
