import { Component, Input } from '@angular/core';
import { baseUrlImgAirlines } from 'src/app/core/configurations/configurations';
import { imageSrc } from './airline-icon.component.module';

@Component({
  selector: 'app-airline-icon',
  templateUrl: './airline-icon.component.html',
  styleUrls: ['./airline-icon.component.scss'],
})
export class AirlineIconComponent {
  @Input() airline: string;
  @Input() iata: string;
  public readonly urlAirlines = baseUrlImgAirlines;
  imageSrc = imageSrc;
}
