import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { FlightResultsService } from 'src/app/core/services/transport/flight-results/flight-results.service';

@Injectable()
export class TrainsSearchForm {
  form: FormGroup;
  tabOrder = ['origin', 'destination', 'initialDay'];

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private titlecase: TitleCasePipe,
    private flightResults: FlightResultsService
  ) { }

  focusNextElement(actualElement: string, index: string = '') {
    const actualIndex = this.tabOrder.findIndex((o) => o === actualElement);
    let nextElement: any = null;
    if (
      actualElement === 'initialDay' &&
      !index &&
      index !== '0' &&
      this.form.get('type')?.value === 'round-trip'
    ) {
      nextElement = document.getElementById('finalDay');
    } else if (actualElement === 'finalDay' || actualElement === 'initialDay') {
      nextElement = document.getElementById('search-common-passangers');
    } else if (actualElement === 'search-common-passangers') {
      nextElement = null;
    } else if (this.tabOrder[actualIndex + 1]) {
      nextElement = document.getElementById(
        this.tabOrder[actualIndex + 1] + index
      );
    } else if (
      document.getElementById(
        this.tabOrder[0] + (index ? (parseInt(index) + 1).toString() : '0')
      )
    ) {
      nextElement = document.getElementById(
        this.tabOrder[0] + (index ? (parseInt(index) + 1).toString() : '0')
      );
    }
    if (nextElement) {
      setTimeout(() => {
        nextElement.focus();
      }, 10);
    }
  }

  focusPreviousElement(actualElement: string): void {
    let previousElement: any = null;

    if (actualElement === 'destination') {
      previousElement = document.getElementById('origin');
    } else if (actualElement === 'initialDay') {
      previousElement = document.getElementById('destination');
    } else if (actualElement === 'finalDay') {
      previousElement = document.getElementById('initialDay');
    } else if (actualElement === 'search-common-passangers') {
      if (this.form?.get('type')?.value === 'round-trip') {
        previousElement = document.getElementById('finalDay');
      } else {
        previousElement = document.getElementById('initialDay');
      }
    }

    setTimeout(() => {
      previousElement?.focus();
    }, 0);
  }

  getFormGroup(): FormGroup {
    return this.form;
  }

  initFormGroup(): FormGroup {
    this.resetForm();
    return this.form;
  }

  // Slug de la busqueda
  getSearchSlug(parameters: { search: any }) {
    const search = parameters?.search ? parameters?.search : parameters;

    return (
      search.origin.value +
      '-' +
      search.destination.value +
      '-' +
      this.datePipe.transform(search.initialDay, 'ddMMyyyy') +
      (search.type === 'round-trip'
        ? '-' + this.datePipe.transform(search.finalDay, 'ddMMyyyy')
        : '') +
      (search.passengers.adults > 0
        ? '-adults' + search.passengers.adults
        : '') +
      (search.passengers.senior > 0
        ? '-senior' + search.passengers.senior
        : '') +
      (search.passengers.youngs > 0
        ? '-youngs' + search.passengers.youngs
        : '') +
      (search.passengers.childs > 0
        ? '-childs' + search.passengers.childs
        : '') +
      (search.passengers.infants > 0
        ? '-infants' + search.passengers.infants
        : '') +
      (search.passengers.umnr > 0 ? '-umnr' + search.passengers.umnr : '') +
      ('-' + window.crypto.getRandomValues(new Uint32Array(1))[0].toString(36).substring(2, 9))
    );
  }

  fillFormFromLastSearch(lastSearch: any) {
    const criterias = lastSearch.request.criterias[0];

    if (
      (criterias.travel.journeys.length === 2 &&
        this.form.get('type')?.value !== 'round-trip') ||
      (criterias.travel.journeys.length === 1 &&
        this.form.get('type')?.value !== 'one-way') ||
      criterias.travel.journeys.length > 2
    ) {
      this.form
        .get('type')!
        .setValue(
          criterias.travel.journeys.length === 1
            ? 'one-way'
            : criterias.travel.journeys.length === 2
              ? 'round-trip'
              : 'multi-destination'
        );
    }

    for (const [index, journey] of criterias.travel.journeys.entries()) {
      const airports: any[] = [];
      let form: FormGroup;
      let multi = false;
      if (
        criterias.travel.journeys.length <= 2 ||
        (criterias.travel.journeys.length > 2 && index === 0)
      ) {
        multi = false;
        form = this.form;
      }
      airports.push(journey.origin);
      airports.push(journey.destination);
      const getAirports = this.flightResults
        .getAirportsData(airports)
        .subscribe((res) => {
          getAirports.unsubscribe();
          if (res.success) {
            if (index === 0) {
              // location
              form.get('origin')?.setValue({
                value: journey.origin,
                title: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.origin)[0]
                    .cityName
                ),
                selection: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.origin)[0]
                    .cityName
                ),
              });
              form.get('destination')?.setValue({
                value: journey.destination,
                title: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.destination)[0]
                    .cityName
                ),
                selection: this.titlecase.transform(
                  res.results.filter((a) => a.code === journey.destination)[0]
                    .cityName
                ),
              });
              //date
              this.form.addControl('initialDay', new FormControl('', []));
              form.get('initialDay')?.setValue(new Date(journey.date));
            }

            if (index === 1) {
              this.form.addControl('finalDay', new FormControl('', []));
              form.get('finalDay')?.setValue(new Date(journey.date));
            }
          }
        });
    }

    const passengers = {
      adults: 0,
      childs: 0,
      infants: 0,
      senior: 0,
      youngs: 0,
      umnr: 0,
    };

    for (const age of criterias.travel.paxes_distribution.passengers_ages) {
      if (criterias.travel.paxes_distribution.passengers_ages.length) {
        if (age >= 30) {
          passengers.adults++;
        } else if (age >= 13) {
          passengers.youngs++;
        } else if (age >= 4) {
          passengers.childs++;
        } else if (age <= 3) {
          passengers.infants++;
        }
      }
    }

    const passengersControls = {
      adults: [{ value: 1, disabled: false }, []],
      youngs: [{ value: 0, disabled: false }, []],
      childs: [{ value: 0, disabled: false }, []],
      infants: [{ value: 0, disabled: false }, []],
      senior: [{ value: 0, disabled: false }, []],
      umnr: [{ value: 0, disabled: false }, []],
      largeFamily: [null, []],
    };
    this.form.addControl(
      'passengers',
      this.formBuilder.group(passengersControls)
    );
    this.form.get('passengers')?.patchValue(passengers);
  }

  resetForm() {
    this.form = this.formBuilder.group({
      errors: [false, []],
      update: [false, []],
      forceCalendarOpen: [null],
      passengers: [],
    });

    this.form.addControl('focus', new FormControl('', []));

    this.form.addControl('type', new FormControl('round-trip', []));
    this.form.addControl('cabinType', new FormControl('all', []));

    // destination
    this.form.addControl('origin', new FormControl('', []));
    this.form.addControl('destination', new FormControl('', []));

    // date
    var today = moment();
    const calendarControls = {
      initialDate: today.add(1, 'day').toDate(),
      initialDateFlexibleDate: 0,
      finalDate: today.add(8, 'day').toDate(),
      finalDateFlexibleDate: 0,
    };

    const calendarForm = this.formBuilder.group(calendarControls);

    // tarifas
    this.form.addControl('fare_type', new FormControl('', []));

    // Pasajeros

    let passengersControls = {
      adults: [
        {
          value: 1,
          disabled: false,
        },
        [],
      ],
      youngs: [
        {
          value: 0,
          disabled: false,
        },
        [],
      ],
      childs: [
        {
          value: 0,
          disabled: false,
        },
        [],
      ],
      infants: [{ value: 0, disabled: false }, []],
      senior: [{ value: 0, disabled: false }, []],
      umnr: [{ value: 0, disabled: false }, []],
      largeFamily: [null, []],
    };
    let passengersForm = this.formBuilder.group(passengersControls);
    this.form.addControl('passengers', passengersForm);
  }

  setValuesSlug(slug: string): any {
    const localStorageData = JSON.parse(localStorage.getItem(slug)!);
    this.form = this.initFormGroup();
    this.form.get('type')?.setValue(localStorageData.lastForm.type);
    this.form.get('cabinType')?.setValue(localStorageData.lastForm.cabinType);
    this.form.get('origin')?.setValue(localStorageData.lastForm.origin);
    this.form
      .get('destination')!
      .setValue(localStorageData.lastForm.destination);
    if (!this.form.get('initialDay')) {
      this.form.addControl('initialDay', new FormControl('', []));
    }
    this.form
      .get('initialDay')!
      .setValue(new Date(localStorageData.lastForm.initialDay));
    if (!this.form.get('finalDay')) {
      this.form.addControl('finalDay', new FormControl('', []));
    }
    this.form
      .get('finalDay')!
      .setValue(new Date(localStorageData.lastForm.finalDay));

    const calendarControls = {
      initialDate: localStorageData.lastForm.initialDay,
      initialDateFlexibleDate: 0,
      finalDate: localStorageData.lastForm.finalDay,
      finalDateFlexibleDate: 0,
    };

    const calendarForm = this.formBuilder.group(calendarControls);
    this.form.addControl('calendarSearch', calendarForm);
    return this.form;
  }
}
