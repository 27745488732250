import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserService} from '../../user/user.service';
import {LanguageService} from '../../language/language.service';
import {ConsolidatorCss} from './consolidators.service';
import {environment} from '../../../../../environments/environment';
import {FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {
  backendUrl: string = environment.api_backend_url;


  dataAirService: AirServicesData;
  airServices: AirServicesData[] = [];
  dataGdsService: GdsServicesData;
  gdsServices: GdsServicesData[] = [];
  inputsAir: any[] = [];
  inputsGds: any[] = [];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private languageService: LanguageService,
  ) { }



  getAirServices(id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.get<AirServicesData[]>( 'assets/mocks/air-services.json'
      // this.backendUrl + '/api/admin/consolidator/air/service/list' + id,
      // {
      //   headers,
      // }
    );
  }



  fillDataService(service, data): void {
    if (service.value.checksGds) {
    const id = data.id;
    this.dataGdsService = {
        provider: data.id,
        active_agency: service.value.checksGds[data.index][`active_agency_${id}`],
        available:  service.value.checksGds[data.index][`available_${id}`],
        active: service.value.checksGds[data.index][`active_${id}`],
      };
    } else if (data){
      this.airServices = [];
      const id = data.code;
      service.value.checksAir.forEach((checkAir, index)  => {
        this.dataAirService = {
          category: data[index].category,
          service: data[index].service,
          code: data[index].code,
          available: checkAir[`available_${data[index].code}`],
          active: checkAir[`active_${data[index].code}`],
        };
        this.airServices.push(this.dataAirService);
      });
    }
  }

  saveAirServices(id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.put<any[]>(
     this.backendUrl + '/api/admin/consolidator/air/service/update' + id,
      this.airServices,
     {
      headers,
     }
    );
  }

  saveGdsServices(id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.put<any[]>(
      this.backendUrl + '/api/admin/consolidator/gds/service/update' + id,
      this.dataGdsService,
      {
        headers,
      }

    );

  }

  getGdsServices(id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.get<any[]>( 'assets/mocks/gds-services.json'
      // this.backendUrl + '/api/admin/consolidator/gds/service/list' + id,
      // {
      //   headers,
      // }
    );
  }
}
export interface AirServicesData {
  category: string;
  service: string;
  code: string;
  available: boolean;
  active: boolean;

}

export interface GdsServicesData {
  provider: string;
  available: boolean;
  active_agency: boolean;
  active: boolean;
}



