<footer>
  <p>
    <svg-icon
      [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"
      src="assets/images/icons/logo-normal.svg"
    >
    </svg-icon>
    {{ 'footer.copy right_' | translate: { year: currentYear } }}
  </p>
  <div>
    <span>v{{ version }}</span>
    <a target="blank" [routerLink]="['/legal/legal']">{{
      'footer.legal alert_' | translate
    }}</a>
    <a target="blank" [routerLink]="['/legal/privacy']">{{
      'footer.policy privacity_' | translate
    }}</a>
    <a *ngIf="isAutologin" (click)="backVersionClasic()">
      <svg-icon
        src="assets/images/icons/ic_twotone-transit-enterexit.svg"
        [svgStyle]="{ 'width.px': 20, 'height.px': 16 }"
      ></svg-icon>
      {{ 'modal-back-version-clasic_.link_' | translate }}
    </a>
  </div>
</footer>
