import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'textTreatments'
})
export class TextTreatmentsPipe implements PipeTransform {
  transform(text: string, textSearch?: string , textNew?: string): string {
    if (typeof text !== 'string') {
      return '';
    }
    let result = text.replace(/\n/g, '<br>');
    result = result.replace(/\$/g, '');
    result = result.replace('/', '');
    return result;
  }

}

