import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface SearchCommonTimeTablesModel {
  oneWay?:string;
}


export function getEmptyModelTimeTables (): SearchCommonTimeTablesModel{

    return {
      oneWay: "round-trip"
    };
}
@Component({
  selector: 'app-search-timetables',
  templateUrl: './search-timetables.component.html',
  styleUrls: ['./search-timetables.component.scss'],
})
export class SearchTimetablesComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() showComponent: boolean;
  @Input() set reset(value: boolean) {
    if (value) {
      this.timetablesForm.patchValue(this.initialState);
    }
  }
  @Output() isActive = new EventEmitter<boolean>(false);

  subscriptions: Subscription[] = [];

  filterActive = false;

  show = false;
  dropdownOptions = {
    class: 'dropdown',
  };
  journeyActive = 1;

  initialState = {
    sameDay1: false,
    sameDay2: false,
    schedulesDeparture: [6, 6],
    schedulesReturn: [6, 6],
    schedulesDepartureArrival: [6, 6],
    schedulesReturnArrival: [6, 6],
  };

  timetablesForm: FormGroup = this.formBuilder.group({});

  sameDay1 = [
    {
      type: 'checkbox',
      name: 'sameDay1',
      options: {
        class: 'form-input tab',
        placeholder: 'arrive the same day_',
      },
    },
  ];

  sameDay2 = [
    {
      type: 'checkbox',
      name: 'sameDay2',
      options: {
        class: 'form-input tab',
        placeholder: 'arrive the same day_',
      },
    },
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.initControlFrom();
  }

  initForm() {
    Object.keys(this.initialState).forEach((control) => {
      this.timetablesForm.addControl(
        control,
        new FormControl(this.initialState[control])
      );
    });
    this.form.addControl('timetables', this.timetablesForm);
  }

  setJourneyActive(num: number) {
    this.journeyActive = num;
  }

  initControlFrom() {
    this.subscriptions.push(
      this.timetablesForm.valueChanges.subscribe((val: object) => {
        this.checkFiltersActive(val);
      })
    );
  }

  checkFiltersActive(values: object) {
    this.filterActive = false;
    Object.keys(values).forEach((key) => {
      if (!Array.isArray(this.initialState[key])) {
        if (this.initialState[key] !== values[key]) {
          this.filterActive = true;
        }
      } else {
        this.initialState[key].forEach((value: string, index: number) => {
          if (value !== values[key][index]) {
            this.filterActive = true;
          }
        });
      }
    });
    this.isActive.emit(this.filterActive);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
