<p-accordion id="acordition" [multiple]="multiple" (onOpen)="onTabOpen($event)" (onClose)="onTabClose($event)" data-cy="conditions-group">
  <p-accordionTab [ngClass]="{'showBody': showBody}" *ngFor="let item of tabContext; index as i" [disabled]="showBody && item?.body?.length <= 145  ? true : false"  [(selected)]="tabStatus[i]" >
    <!-- head -->
    <p-header>
      <span ngClass="titleAccordion" data-cy="condition">{{ item.header || (item.body | truncate: 80) }}</span>
      <span [ngClass]="['readMore']" *ngIf="!showBody || item?.body?.length > 145 ">
        <!-- icon and text -->
        <span ngClass="active"  *ngIf="!tabStatus[i] ">
          {{ 'read_more_' | translate }}
          <svg-icon src="assets/images/icons/acordionarrow.svg"></svg-icon>
        </span>
        <span ngClass="inactive" *ngIf="tabStatus[i] ">
          {{ 'read_less_' | translate }}
          <svg-icon src="assets/images/icons/acordionarrow.svg"></svg-icon>
        </span>
      </span>
    </p-header>

    <!-- body -->
    <div [ngClass]="['content']" data-cy="content-condition">
      <p *ngIf="tabStatus[i]" [innerHTML]="item?.body | textTreatments"></p>
      <p *ngIf="!tabStatus[i]" [innerHTML]="item?.body  | truncate: 145"></p>
    </div>

  </p-accordionTab>
</p-accordion>
