import { DeserializableInterface } from './deserializable.interface';
export abstract class DeserializableAbstract implements DeserializableInterface {
  deserialize(input: any): this {
    const data = { ...input };
    if (input === null) {
      return input;
    }
    const keys = Object.keys(data);
    for (const key of keys) {
      let newKey = key;
      if (key.includes('_')) {
        const keyArray = key.split('_').map((c, i) => {
          if (i > 0) {
            newKey += c.charAt(0).toUpperCase() + c.slice(1);
          } else {
            newKey = c;
          }
        }).join('');
      }
      this[newKey] = data[key];
      delete data[key];
    }
    return this;
  }
}
