<p-carousel
  [value]="listImgCarosuel"
  [showNavigators]="showNavigators && listImgCarosuel.length > 1"
  [showIndicators]="showIndicators && listImgCarosuel.length > 1"
  [autoplayInterval]="autoplayInterval"
  [circular]="listImgCarosuel.length > 1"
  [numVisible]="numVisible"
  [contentClass]="showIndicators?'spaceIndicators ' + (indicatorStyleTop?'orderIndicators':''):''"
  [ngClass]="{'inloadingpage-class': inLoadingPage}"
>
  <ng-template let-img pTemplate="item">
    <img src="{{ img }}" [alt]="img" class="product-image" [ngClass]="{errorImgLastS: isLastSearchesLastCarousel}" (error)="errorImg($event)" />
  </ng-template>
</p-carousel>
