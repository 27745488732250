<div *ngIf="showComponent" class="type-content" data-cy="schedulesFilter">
  <div pRipple class="title" [ngClass]="{ active: filterActive }" #ref>
    <span class="cursor-pointer">
      {{ 'schedules_' | translate }}
    </span>
    <svg-icon
      class="arrow"
      [svgStyle]="{ 'width.px': 7 }"
      src="assets/images/icons/select_arrow.svg"
    ></svg-icon>
  </div>
  <app-dropdown [ref]="ref" [options]="dropdownOptions" [(show)]="show">
    <div
      class="journeys"
      [ngClass]="{
        'round-trip':
          form.get('type') && form.get('type').value === 'round-trip'
      }"
      data-cy="SchedulesFilterBox"
    >
      <p-tabView>
        <p-tabPanel>
          <ng-template
            *ngIf="form.get('type') && form.get('type').value === 'round-trip'"
            pTemplate="header"
            >{{ 'one way_' | translate | uppercase }}</ng-template
          >
          <app-search-timetables-detail
            [parentForm]="timetablesForm"
            [fCName]="'initialDay'"
          ></app-search-timetables-detail>
          <app-fields-collection
            [form]="timetablesForm"
            [inputs]="sameDay1"
          ></app-fields-collection>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="form.get('type') && form.get('type').value === 'round-trip'"
        >
          <ng-template pTemplate="header">{{
            'return_' | translate | uppercase
          }}</ng-template>
          <app-search-timetables-detail
            [parentForm]="timetablesForm"
            [fCName]="'finalDay'"
          ></app-search-timetables-detail>
          <app-fields-collection
            [form]="timetablesForm"
            [inputs]="sameDay2"
          ></app-fields-collection>
        </p-tabPanel>
      </p-tabView>
    </div>
  </app-dropdown>
</div>
<ng-template #NotOriginOrDestination>
  <span>
    {{ 'to select_' | translate }}
  </span>
</ng-template>
