import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of, Subject } from 'rxjs';
import { Flight } from 'src/app/shared/models/flight';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { StorageService } from '../../utils/storage.service';
import { FligthResultsModel } from 'src/app/shared/models/flight-results/flight-results.model';
import { CorporateService } from '../../user/corporate.service';

@Injectable({
  providedIn: 'root',
})
export class FlightResultsService {
  backendUrl: string = environment.api_backend_url;
  flightResults$ = new BehaviorSubject<FligthResultsModel>(null!);
  private allResults$ = new BehaviorSubject<FligthResultsModel>(null!);
  private filtersWithoutResults$ = new BehaviorSubject<any[]>(null!);
  allResults: any;
  flightResults: any;
  ttl = 5 * 60 * 1000;
  criteriaSearch: any;
  resultDived: any = { 0: {}, 1: {} };
  selectedSwitch$ = new BehaviorSubject<number>(0);
  allResultsDivided: any;
  sectionType$ = new BehaviorSubject<string>('departure');
  resetSearchForm$ = new BehaviorSubject<boolean>(null!);
  private destroy$: Subject<void>;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private corporateService: CorporateService
  ) {
    this.destroy$ = new Subject();
  }

  resetService(): void {
    this.allResults = null;
    this.flightResults = null;
    this.allResultsDivided = [];
    this.flightResults$.next(this.flightResults);
    this.allResults$.next(this.allResults);
    this.filtersWithoutResults$.next(null!);
    this.selectedSwitch$.next(0);
    this.sectionType$.next('departure');
  }

  getSelectedSwitch$(): Observable<number> {
    return this.selectedSwitch$.asObservable();
  }

  getResultDived(): any {
    return this.resultDived;
  }

  // get changes on flights
  getFlightResults$(): Observable<FligthResultsModel> {
    return this.flightResults$.asObservable();
  }

  // get changes on section
  getSectionType$(): Observable<string> {
    return this.sectionType$.asObservable();
  }

  // get changes on selected provider
  getResetSearchForm$(): Observable<boolean> {
    return this.resetSearchForm$.asObservable();
  }

  // get all flights
  getAllResults$(): Observable<FligthResultsModel> {
    return this.allResults$.asObservable();
  }

  // get options if no results after filter results
  getFilterWithoutResults$(): Observable<any[]> {
    return this.filtersWithoutResults$.asObservable();
  }

  // sort results
  sortFlightResults(filters: any, results: any): void {
    if (!filters) {
      return;
    }
    if (!results?.solutions) {
      return;
    }
    switch (filters.value) {
      case 'departure':
        this.orderFilterDepartureApply(results);
        break;
      case 'departureHour':
        this.orderFilterDepartureHourApply(results);
        break;
      case 'arriveHour':
        this.orderFilterArriveHourApply(results);
        break;
      case 'return':
        this.orderFilterReturnApply(results);
        break;
      case 'cost':
        this.orderFilterCostApply(results);
        break;
      default:
        this.orderFilterDefaultApply(results);
        break;
    }

    let journeysFiltered: string[] = [];
    let items = JSON.parse(JSON.stringify(results.solutions));

    for (const solution of results.solutions) {
      for (const association of solution.data.associations) {
        journeysFiltered.push(association.journey_references);
      }
    }

    journeysFiltered = journeysFiltered.reduce(
      (acc, val: any) => acc.concat(val),
      []
    );

    journeysFiltered = journeysFiltered.filter(this.onlyUnique);

    for (const solution of items) {
      for (const association of solution.data.associations) {
        association.journey_references = association.journey_references.filter(
          (e) => journeysFiltered.includes(e)
        );
        if (association.journey_references.length === 0) {
          solution.delete = true;
        }
      }
    }

    items = items.filter((i: any) => !i.delete);

    if (this.flightResults?.solutions) {
      this.flightResults.solutions = items;
    }

    this.flightResults$.next(this.flightResults);
  }

  orderFilterDefaultApply(element: any): any {
    element.solutions.sort((a: any, b: any) => {
      return a.total_price.total - b.total_price.total;
    });

    element.solutions.map((solution: any) => {
      solution.data.associations.map((association: any) => {
        association.journey_references.sort((a: any, b: any) => {
          return (
            new Date(
              element.segments[
                element?.journeys[a]?.segments[0]
              ]?.departure_date
            )?.getTime() -
            new Date(
              element.segments[
                element?.journeys[b]?.segments[0]
              ]?.departure_date
            )?.getTime()
          );
        });
      });
    });
  }

  orderFilterCostApply(element: any): any {
    element.solutions.sort((a: any, b: any) => {
      a = a.total_price.over_price?.amount;
      b = b.total_price.over_price?.amount;
      return a - b;
    });

    element.solutions.map((solution: any) => {
      solution.data.associations.map((association: any) => {
        association.journey_references.sort((a: any, b: any) => {
          return (
            new Date(
              element.segments[element.journeys[a].segments[0]].departure_date
            ).getTime() -
            new Date(
              element.segments[element.journeys[b].segments[0]].departure_date
            ).getTime()
          );
        });
      });
    });
  }

  orderFilterReturnApply(element: any): any {
    element.solutions.map((solution: any) => {
      solution.data.associations[1]?.journey_references.sort(
        (a: any, b: any) => {
          return element.journeys[a].duration - element.journeys[b].duration;
        }
      );
    });
    element.solutions.sort((a: any, b: any) => {
      return (
        element.journeys[a.data.associations[1].journey_references[0]]
          .duration -
        element.journeys[b.data.associations[1].journey_references[0]].duration
      );
    });
    element.solutions.map((solution: any) => {
      solution.data.associations.map((association: any, index: any) => {
        if (index !== 1) {
          association.journey_references.sort((a: any, b: any) => {
            return (
              new Date(
                element.segments[element.journeys[a].segments[0]].departure_date
              ).getTime() -
              new Date(
                element.segments[element.journeys[b].segments[0]].departure_date
              ).getTime()
            );
          });
        }
      });
    });
  }

  orderFilterArriveHourApply(element: any): any {
    element.solutions.sort((a: any, b: any) => {
      return a.total_price.total - b.total_price.total;
    });
    element.solutions.map((solution: any) => {
      solution.data.associations[0]?.journey_references?.sort(
        (a: any, b: any) => {
          return (
            new Date(
              element.segments[element.journeys[a].segments[0]].arrival_date
            ).getTime() -
            new Date(
              element.segments[element.journeys[b].segments[0]].arrival_date
            ).getTime()
          );
        }
      );
    });
    element.solutions.sort((a: any, b: any) => {
      return (
        new Date(
          element.segments[
            a.data.associations[0].journey_references[0]
          ].arrival_date
        ).getTime() -
        new Date(
          element.segments[
            b.data.associations[0].journey_references[0]
          ].arrival_date
        ).getTime()
      );
    });
  }

  orderFilterDepartureHourApply(element: any): any {
    element.solutions.sort((a: any, b: any) => {
      return a.total_price.total - b.total_price.total;
    });
    element.solutions.map((solution: any) => {
      solution.data.associations[0]?.journey_references?.sort((a, b) => {
        return (
          new Date(
            element.segments[element.journeys[a].segments[0]].departure_date
          ).getTime() -
          new Date(
            element.segments[element.journeys[b].segments[0]].departure_date
          ).getTime()
        );
      });
    });
    element.solutions.sort((a: any, b: any) => {
      return (
        new Date(
          element.segments[
            a.data.associations[0].journey_references[0]
          ]?.departure_date
        ).getTime() -
        new Date(
          element.segments[
            b.data.associations[0].journey_references[0]
          ]?.departure_date
        ).getTime()
      );
    });
  }

  orderFilterDepartureApply(element: any): any {
    element.solutions.map((solution: any) => {
      solution.data.associations[0]?.journey_references?.sort(
        (a: any, b: any) => {
          return element.journeys[a].duration - element.journeys[b].duration;
        }
      );
    });

    element.solutions.sort((a, b) => {
      return (
        element.journeys[a.data.associations[0].journey_references[0]]
          .duration -
        element.journeys[b.data.associations[0].journey_references[0]].duration
      );
    });

    element.solutions.map((solution: any) => {
      solution.data.associations.map((association: any, index: number) => {
        if (index !== 0) {
          association.journey_references.sort((a: any, b: any) => {
            return (
              new Date(
                element.segments[element.journeys[a].segments[0]].departure_date
              ).getTime() -
              new Date(
                element.segments[element.journeys[b].segments[0]].departure_date
              ).getTime()
            );
          });
        }
      });
    });
  }

  // get avail divided
  getAvailDivided(
    criterias: any,
    searchSlug?: string,
    formSearch?: any
  ): Observable<any> {
    this.resetService();
    const resultSlug = searchSlug + '-result';
    this.criteriaSearch = criterias;

    // Si hay filtros en local storage carga el buscador y lanza la búsqueda
    const resultLocalCache = this.storage.getWithExpiry(resultSlug);
    if (localStorage.getItem('noActiveProviders')) {
      localStorage.removeItem('noActiveProviders');
    }
    if (
      localStorage.hasOwnProperty(resultSlug) === true &&
      resultLocalCache !== null
    ) {
      const resultLocalCacheClass = Array.isArray(resultLocalCache)
        ? resultLocalCache
        : [resultLocalCache];
      Object.keys(resultLocalCacheClass).forEach((result, i) => {
        this.resultDived[i] = resultLocalCacheClass[i];
      });
      const firstResultLocalCacheClass =
        resultLocalCacheClass[0] ?? JSON.parse('{}');
      // TODO cambiar this.flightResults partial_solutions.
      this.allResults = { ...firstResultLocalCacheClass };
      this.flightResults = { ...firstResultLocalCacheClass };
      this.allResultsDivided = resultLocalCacheClass;
      setTimeout(() => {
        this.allResults$.next(firstResultLocalCacheClass);
        this.flightResults$.next(firstResultLocalCacheClass);
      }, 0);
      return of({ ...firstResultLocalCacheClass });
    }

    return (
      this.http
        // .get<any>('assets/mocks/avail_partials.json')
        .post<FligthResultsModel>(
          environment.api_url + '/api/v2/transport/avail',
          criterias
        )
        .pipe(
          tap((res) => {
            this.setValueResultDivided(res, resultSlug, criterias);
          }),
          catchError((err) => {
            const response = new FligthResultsModel();
            this.allResults = response;
            this.flightResults = response;
            this.allResults$.next(response);
            this.flightResults$.next(response ?? JSON.parse('{}'));
            if (err?.error?.error?.code === 4007) {
              localStorage.setItem('noActiveProviders', 'true');
            }
            return throwError(err);
          }),
          takeUntil(this.destroy$)
        )
    );
  }

  setValueResultDivided(res: any, resultSlug: string, criterias: any): void {
    const resultLocalCacheClassAux: any[] = [];

    if (
      (criterias.query.criterias[0].travel.journeys.length === 1 &&
        (!res.solutions || res.solutions.length === 0)) ||
      (criterias.query.criterias[0].travel.journeys.length > 1 &&
        (!res.partial_solutions ||
          Object.keys(res.partial_solutions).length === 0))
    ) {
      return;
    }

    resultLocalCacheClassAux.push(JSON.parse(JSON.stringify(res)));

    if (
      res !== null &&
      res.partial_solutions &&
      Object.keys(res.partial_solutions).length > 1
    ) {
      resultLocalCacheClassAux.push(JSON.parse(JSON.stringify(res)));
    }

    if (resultLocalCacheClassAux && resultLocalCacheClassAux[0]) {
      Object.keys(resultLocalCacheClassAux[0].partial_solutions).forEach(
        (solution, indSolution) => {
          if (indSolution === 0) {
            Object.keys(
              resultLocalCacheClassAux[0].partial_solutions[solution]
            ).forEach((result, i) => {
              Object.keys(
                resultLocalCacheClassAux[0].partial_solutions[solution][result]
              ).forEach((resultSolution, i) => {
                resultLocalCacheClassAux[0].solutions.push(
                  resultLocalCacheClassAux[0].partial_solutions[solution][
                    result
                  ][resultSolution]
                );
              });
            });
          }
        }
      );
    }

    if (resultLocalCacheClassAux.length > 1) {
      Object.keys(resultLocalCacheClassAux[1].partial_solutions).forEach(
        (solution, indSolution) => {
          if (indSolution !== 0) {
            Object.keys(
              resultLocalCacheClassAux[1].partial_solutions[solution]
            ).forEach((result, i) => {
              Object.keys(
                resultLocalCacheClassAux[1].partial_solutions[solution][result]
              ).forEach((resultSolution, i) => {
                resultLocalCacheClassAux[1].solutions.push(
                  resultLocalCacheClassAux[1].partial_solutions[solution][
                    result
                  ][resultSolution]
                );
              });
            });
          }
        }
      );
    }

    if (resultLocalCacheClassAux) {
      this.allResultsDivided = resultLocalCacheClassAux;
      resultLocalCacheClassAux.forEach((result, i) => {
        this.resultDived[i] = result;
      });
      this.storage.setWithExpiry(
        resultSlug,
        resultLocalCacheClassAux,
        this.ttl
      );
      this.allResults = { ...resultLocalCacheClassAux[0] };
      this.flightResults = { ...resultLocalCacheClassAux[0] };
      this.allResults$.next(resultLocalCacheClassAux[0]);
      this.flightResults$.next(resultLocalCacheClassAux[0]);
    } else {
      const response = new FligthResultsModel();
      this.allResults = response;
      this.flightResults = response;
      this.allResults$.next(response);
      this.flightResults$.next(response);
    }
  }

  setValueResult(res: any, resultSlug: string): void {
    if (res) {
      this.storage.setWithExpiry(resultSlug, res, this.ttl);
      this.allResults = { ...res };
      this.flightResults = { ...res };
      this.allResults$.next(res);
      this.flightResults$.next(res);
    } else {
      const response = new FligthResultsModel();
      this.allResults = response;
      this.flightResults = response;
      this.allResults$.next(response);
      this.flightResults$.next(response);
    }
  }

  // get avail
  getAvail(criterias: any, searchSlug?: string): Observable<any> {
    this.resetService();
    const resultSlug = searchSlug + '-result';
    this.criteriaSearch = criterias;

    // Si hay filtros en local storage carga el buscador y lanza la búsqueda
    const resultLocalCache = this.storage.getWithExpiry(resultSlug);
    if (
      localStorage.hasOwnProperty(resultSlug) === true &&
      resultLocalCache !== null
    ) {
      const resultLocalCacheClass = resultLocalCache as FligthResultsModel;
      this.allResults = { ...resultLocalCacheClass };
      this.flightResults = { ...resultLocalCacheClass };
      setTimeout(() => {
        this.allResults$.next(resultLocalCacheClass);
        this.flightResults$.next(resultLocalCacheClass);
      }, 500);
      return of({ ...resultLocalCacheClass });
    }

    return (
      this.http
        // .get<FligthResultsModel>('assets/mocks/avail.json')
        .post<FligthResultsModel>(
          environment.api_url + '/api/v2/transport/avail',
          criterias
        )
        .pipe(
          tap((res) => {
            this.setValueResult(res, resultSlug);
          }),
          catchError((err) => {
            const response = new FligthResultsModel();
            this.allResults = response;
            this.flightResults = response;
            this.allResults$.next(response);
            this.flightResults$.next(response);
            return throwError(err);
          }),
          takeUntil(this.destroy$)
        )
    );
  }

  applyChangesSelectedSwitchButtonDepartureArrival(e: {
    id: string | number;
  }): any {
    this.allResults = { ...this.resultDived[e.id] };
    this.flightResults = { ...this.resultDived[e.id] };
    this.selectedSwitch$.next(+e.id);
    this.flightResults$.next(this.resultDived[e.id]);
    this.allResults$.next(this.resultDived[e.id]);
    this.sectionType$.next(e.id === 0 ? 'departure' : 'arrive');
    return this.resultDived[e.id];
  }

  resetSearchFormEmit(): void {
    this.resetSearchForm$.next(true);
  }

  // url pricing
  getPricingUrl(data: any, transportType = 'flight'): string {
    const sulutionIds = this.getSolutionsIds(data, transportType);

    const journeys = data.flightsSelect
      ? Object.values(JSON.parse(data.flightsSelect))
      : Object.values(data);

    const solutionsIdsPar: string = this.getSolutionsIdsPar(sulutionIds);

    const journeyPar: string = this.getJourneyPar(sulutionIds, journeys);

    let url = `${environment.api_url}/api/v2/transport/pricing?format=json${solutionsIdsPar}&token=${data.token}${journeyPar}&extend=true`;

    if (data?.resident) {
      url = url.concat('&resident=', data.resident);
    }

    return url;
  }

  private getSolutionsIds(data: any, transportType: string): any {
    let solutionIds = data.flightId.includes('[')
      ? JSON.parse(data.flightId)
      : data.flightId;

    if (
      transportType === 'train' &&
      JSON.parse(data.flightsSelect) &&
      Object.keys(JSON.parse(data.flightsSelect))
    ) {
      solutionIds = Object.keys(JSON.parse(data.flightsSelect));
    }

    return solutionIds;
  }

  private getSolutionsIdsPar(sulutionIds: any): string {
    let solutionsIdsPar = '';

    if (Array.isArray(sulutionIds)) {
      for (let i = 0; i < sulutionIds.length; i++) {
        solutionsIdsPar += '&solutionID' + i + '=' + sulutionIds[i];
      }
    } else {
      solutionsIdsPar = '&solutionID0=' + sulutionIds;
    }

    return solutionsIdsPar;
  }

  private getJourneyPar(sulutionIds: any, journeys: any): string {
    let journeyPar = '';

    // FRONT 1600 - TODO: Se debe invertir el journey pasado a API para que funcione trenes multiproveedor
    if (Array.isArray(sulutionIds) && sulutionIds.length > 1) {
      for (let i = 0; i < journeys.length; i++) {
        journeyPar += '&journey' + i + '0=' + journeys[i];
      }
    } else {
      for (let i = 0; i < journeys.length; i++) {
        journeyPar += '&journey0' + i + '=' + journeys[i];
      }
    }

    return journeyPar;
  }

  // get pricing
  getPricing(options: any, tranportType = 'flight'): Observable<any> {
    // return this.http.get<Flight[]>('assets/mocks/pricing.json');
    return this.http.get<Flight[]>(this.getPricingUrl(options, tranportType));
  }

  // get policy
  getConditions(token: any, solutionID: any, journeys?: any): Observable<any> {
    solutionID = solutionID.includes('[') ? JSON.parse(solutionID) : solutionID;
    let params = new HttpParams();
    params = params.append('token', token);
    if (Array.isArray(solutionID)) {
      for (let i = 0; i < solutionID.length; i++) {
        params = params.append('solutionID' + i, solutionID[i]);
      }
    } else {
      params = params.append('solutionID0', solutionID);
    }

    // Se debe invertir el journey pasado a API para que funcione multiproveedor
    if (Array.isArray(solutionID) && solutionID.length > 1) {
      for (let i = 0; i < journeys.length; i++) {
        params = params.append('journey' + i + '0', journeys[i]);
      }
    } else {
      for (let i = 0; i < journeys.length; i++) {
        params = params.append('journey0' + i, journeys[i]);
      }
    }

    return this.http.get<Flight[]>(
      environment.api_url + '/api/v2/transport/policy',
      { params }
    );
  }

  getReasons(options: any): Observable<any> {
    const params = new HttpParams();
    return this.http.get<any>(
      `${environment.api_url}/api/profile/v2/company/reasons/${options.id}`,
      { params }
    );
  }

  // get multi-family
  getFares(
    options: any,
    pricevariants: boolean = false,
    transportType = 'flight'
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    let params = new HttpParams();

    const SolutionIds = options.solutionId.includes('[')
      ? JSON.parse(options.solutionId)
      : options.solutionId;

    params = params.append('token', options.token);

    if (Array.isArray(SolutionIds) && SolutionIds.length > 1) {
      for (const [index, solution] of SolutionIds.entries()) {
        let i: any;
        if (index < 10 && transportType?.toLocaleLowerCase() !== 'train') {
          i = '0' + index;
        } else {
          i = index;
        }
        params = params.append('solutionID' + i, solution);
      }
    } else if (Array.isArray(SolutionIds) && SolutionIds.length === 1) {
      params = params.append('solutionID0', SolutionIds[0]);
    } else {
      params = params.append('solutionID0', SolutionIds);
    }

    const array: string[] = options.journeys
      ? Object.values(options.journeys)
      : [];

    if (Array.isArray(SolutionIds) && SolutionIds.length > 1) {
      for (let i = 0; i < array.length; i++) {
        params = params.append('journey' + i + '0', array[i]);
      }
    } else {
      for (let i = 0; i < array.length; i++) {
        params = params.append('journey0' + i, array[i]);
      }
    }

    if (pricevariants) {
      params = params.append('pricevariants', true);
      params = params.append(
        'account_id',
        this.corporateService.getCompany()?.id
      );
    }

    return this.http.get<Flight[]>(
      environment.api_url + '/api/v2/transport/multi-family',
      { params, headers }
    );
  }

  getDataContact(): Observable<any> {
    return this.http.get<any>(this.backendUrl + '/api/user/contact/data');
  }

  setDataContact(data: any): Observable<any> {
    return this.http.post(this.backendUrl + '/api/user/contact/update', data);
  }

  getConvertObject(e: any): any {
    let mainIndex = 0;
    const fareTypes: string[] = ['public', 'private', 'priv'];
    const item = JSON.parse(JSON.stringify(e));
    const convert: any = {
      token: item.token,
      journeys: {},
      segments: {},
      fares: {},
      solutions: [
        {
          is2ow: this.getIs2ow(item),
          data: {
            allow_multi_family: false,
            associations: [],
          },
          providers: [],
          id: item.solutions[0].transport.id,
          time_limits: {
            last_ticket_date_data: this.fillLastTicketDate(item),
          },
          total_price: { ...item.price },
          price: { ...item.price },
          transport: { ...item.solutions[0].transport },
        },
      ],
      data: {
        request: [
          { is_surface: false, total_pax: item.solutions[0]?.price?.pax },
        ],
        company: {},
        provider: {},
        port: {},
      },
    };

    if (item.solutions) {
      const lenSeg = {};
      convert.solutions[0].data = {
        associations: [],
      };

      for (const [indexS, solution] of item.solutions.entries()) {
        if (solution.transport.allow_multi_family) {
          convert.solutions[0].data.allow_multi_family = true;
        }

        convert.solutions[0].providers.push(solution.transport.provider);

        for (const [
          indexJ,
          originDestination,
        ] of solution.transport.origin_destinations.entries()) {
          originDestination.journeys[0].layovers =
            originDestination.journeys[0].segments.length - 1;
          convert.journeys[originDestination.journeys[0].id] = {
            ...originDestination.journeys[0],
          };
          this.addProvider(convert, item, indexS, originDestination);

          convert.journeys[originDestination.journeys[0].id].segments = [];
          convert.solutions[0].data.associations.push({
            provider_id: item.solutions[indexS].transport.provider_id,
            provider: item.solutions[indexS].transport.provider,
            origin_destination_references: [originDestination.journeys[0].id],
            journey_references: [originDestination.journeys[0].id],
            segment_references: {},
          });

          for (const [
            index,
            segment,
          ] of originDestination.journeys[0].segments.entries()) {
            this.addFares(convert, segment, originDestination, fareTypes);
            this.addAssociations(
              convert,
              segment,
              originDestination,
              mainIndex,
              index
            );
            this.addJourneys(convert, originDestination, index);
            this.addCompany(convert, segment);
            this.addSegmentData(segment);

            lenSeg[originDestination.journeys[0].id.split('@')[index]] =
              segment;
            const ports = Object.keys(convert.data.port);
            this.addPorts(segment, ports, convert);
            this.addTechnicalStops(segment, ports, convert);
          }
          mainIndex++;
        }
      }
      convert.segments = lenSeg;
      return { ...convert };
    }
  }

  private addProvider(
    convert: any,
    item: any,
    indexS: number,
    originDestination: any
  ): void {
    if (!convert.data.provider[item.solutions[indexS].transport.provider]) {
      convert.data.provider[item.solutions[indexS].transport.provider] = {
        id: item.solutions[indexS].transport.provider_id,
        name: item.solutions[indexS].transport.provider,
        lowcost: originDestination.journeys[0]?.lowcost,
      };
    }
  }

  private addFares(
    convert: any,
    segment: any,
    originDestination: any,
    fareTypes: string[]
  ): void {
    convert.fares[segment.company_iata + '#' + segment.fare_basis_code] = {
      provider: segment.company_iata,
      farebasis: segment.fare_basis_code,
      fare_type: segment.fare_type,
      ...(originDestination?.fare_code &&
        !fareTypes.includes(originDestination?.fare_code.toLowerCase()) && {
          fare_code: originDestination.fare_code,
        }),
    };
  }

  private addAssociations(
    convert: any,
    segment: any,
    originDestination: any,
    mainIndex: number,
    index: number
  ): void {
    convert.solutions[0].data.associations[mainIndex].segment_references[
      originDestination.journeys[0].id.split('@')[index]
    ] = {
      fare_basis_code: segment.fare_basis_code,
      fare_type: segment.fare_type,
      baggage: {
        quantity: segment.baggage,
        weight: segment?.baggage_weight,
      },
      cabin: {
        id: segment.tf,
        short_name: segment.cabin,
        long_name: segment.marketing_name,
      },
      ...((segment?.family_name || segment?.fare_name) && {
        fare_name: segment?.fare_name || segment?.family_name,
      }),
      class_of_service: segment.class,
      seats: segment.seats,
    };
  }

  private addJourneys(
    convert: any,
    originDestination: any,
    index: number
  ): void {
    convert.journeys[originDestination.journeys[0].id].segments.push(
      originDestination.journeys[0].id.split('@')[index]
    );
  }

  private addCompany(convert: any, segment: any): void {
    convert.data.company[segment.company_iata] = {
      name: segment.company_name,
    };

    convert.data.company[segment.op_company_iata] = {
      name: segment.op_company_name,
    };
  }

  private addSegmentData(segment: any): void {
    segment.departure_date = segment.departure_date_time;
    segment.arrival_date = segment.arrival_date_time;
    segment.departure = segment.origin_iata;
    segment.arrival = segment.destination_iata;
    segment.marketing_company = segment.company_iata;
    segment.operating_company = segment.op_company_iata;
    if (segment?.tro) {
      segment.departure_terminal = segment.tro;
    }
    if (segment?.trd) {
      segment.arrival_terminal = segment.trd;
    }
    if (segment?.technical_stops) {
      segment.technical_stops = segment.technical_stops.reduce(
        (arr: any, technicalStop: any) => {
          arr = [
            ...arr,
            {
              ...technicalStop,
              port: technicalStop.location.iata,
            },
          ];
          return arr;
        },
        []
      );
    }
  }

  private addPorts(segment: any, ports: any, convert: any): void {
    if (!ports.includes(segment.destination_iata)) {
      convert.data.port[segment.destination_iata] = {
        city: segment.destination_city_name,
        country: '',
        name: segment.destination_short_name,
        type: segment.destination_type,
      };
    }

    if (!ports.includes(segment.origin_iata)) {
      convert.data.port[segment.origin_iata] = {
        city: segment.origin_city_name,
        country: '',
        name: segment.origin_short_name,
        type: segment.origin_type,
      };
    }
  }

  private addTechnicalStops(segment: any, ports: any, convert: any): void {
    if (segment?.technical_stops) {
      for (const technicalStop of segment.technical_stops) {
        if (!ports.includes(technicalStop.location.iata)) {
          convert.data.port[technicalStop.location.iata] = {
            city: technicalStop.location.city_name,
            contry: technicalStop.location.country_name,
            name: technicalStop.location.long_name,
            type: technicalStop.location.type,
          };
        }
      }
    }
  }

  getIs2ow(item: any): boolean {
    return item.solutions[0].is2ow;
  }

  fillLastTicketDate(item: any): any {
    const tagPriority = ['EI', 'TL HOY', 'TL MAÑANA'];
    let lastTicket: any = {};
    for (const solution of item.solutions) {
      if (
        solution.transport?.last_ticket_date_data &&
        solution.transport?.last_ticket_date_data?.tag
      ) {
        if (!lastTicket?.tag) {
          lastTicket = solution.transport.last_ticket_date_data;
        } else if (
          tagPriority.includes(lastTicket.tag) &&
          tagPriority.indexOf(lastTicket.tag) >
            tagPriority.indexOf(solution.transport.last_ticket_date_data.tag)
        ) {
          lastTicket = solution.transport.last_ticket_date_data;
        } else if (
          !tagPriority.includes(lastTicket.tag) &&
          tagPriority.includes(solution.transport.last_ticket_date_data.tag)
        ) {
          lastTicket = solution.transport.last_ticket_date_data;
        } else if (
          lastTicket?.tag?.split(' ')[1]?.split('/')[1] >
            solution.transport.last_ticket_date_data.tag
              ?.split(' ')[1]
              ?.split('/')[1] ||
          (lastTicket?.tag?.split(' ')[1]?.split('/')[1] ===
            solution.transport.last_ticket_date_data.tag
              ?.split(' ')[1]
              ?.split('/')[1] &&
            lastTicket.tag?.split(' ')[1]?.split('/')[0] >
              solution.transport.last_ticket_date_data.tag
                ?.split(' ')[1]
                ?.split('/')[0])
        ) {
          lastTicket = solution.transport.last_ticket_date_data;
        }
      }
    }
    return lastTicket;
  }

  getBaggage(options: any): Observable<any> {
    const sulutionIds = options.flightId.includes('[')
      ? JSON.parse(options.flightId)
      : options.flightId;
    const journeys = options.journey
      ? Object.values(JSON.parse(options.journey))
      : Object.values(options);

    const solutionsIdsPar = this.getSolutionsIdsPar(sulutionIds);

    const journeyPar = this.getJourneyPar(sulutionIds, journeys);

    // return this.http.get<Flight[]>('assets/mocks/standalonecatalogue.json');
    return this.http.get<Flight[]>(
      environment.api_url +
        '/api/v2/transport/standalonecatalogue?format=json' +
        solutionsIdsPar +
        '&token=' +
        options.token +
        journeyPar
    );
  }

  sendMail(data: any, transportType = 'flight'): Observable<any> {
    let body = {};

    body = {
      name: data.name,
      email: data.email,
      price_format: data.price_format,
      pricing_url: this.getPricingUrl(data, transportType),
      transportType,
    };

    return this.http.post<any>(
      this.backendUrl + '/api/results/flight/send-email',
      body,
      { observe: 'response' }
    );
  }

  getAirportsData(airports: string[]): Observable<any> {
    const data: string = '["' + airports.join('","') + '"]';
    let params: HttpParams = new HttpParams();
    params = params.append('q', data);
    return this.http.get<any>(
      environment.api_url + '/private/v2/transport/airports/find',
      { params }
    );
  }

  onlyUnique(value: any, index: any, self: any): any {
    return self.indexOf(value) === index;
  }
}

export interface JourneysSelects {
  [key: string]: string;
}

export interface PriceBreakdown {
  total_price: number;
  fare_list: FarePriceBreakdown[];
}
export interface FarePriceBreakdown {
  passenger_type: string;
  base: number;
  total_taxes: number;
  country_fee: any;
  emission_fee: number;
  fee: number;
  quantity: number;
  amount: number;
  total: number;
}

export interface FareList {
  fares: Fare[];
}

export interface Fare {
  id: string;
  base: number;
  taxes: number;
  amount: number;
  total: number;
  fare_basis: string;
  cabin: string;
  cabin_name: string;
  service_class: string;
  fare_type: string;
  fare_types: string[];
  fare_code: string;
  baggage: boolean;
  refund: boolean;
  cabin_tip: string;
  currency: Currency;
  journeys: string[];
  family: string;
  families: string[];
  services: Service[];
  journeys_ids: string[];
  details: Detail[];
}

export interface Currency {
  iso: string;
  sign: string;
  position: string;
  decimals: number;
}

export interface Service {
  code: string;
  category: string;
  name: string;
  indicator: string;
  journeys: string[];
  names: string[];
}

export interface Detail {
  name: string;
  quantity: number;
}

export interface Options {
  indexGL?: number;
  noBooking?: boolean;
}
