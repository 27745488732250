import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSendFeedBack } from 'src/app/modules/application/transport/components/navbar-dropdown/navbar-dropdown.component';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SendFeedbackUserService {

  httpClient: HttpClient;

constructor(
  private http: HttpClient,
  handler: HttpBackend,
) {
  this.httpClient = new HttpClient(handler);
 }

// send data backend
sendFeedBackFromUser(data: DataSendFeedBack): Observable<any> {
  let headers = new HttpHeaders();
  headers = headers.append('hideError', 'yes');

 const body = {
   body: this.generateBodyEmail(data)
  };
    return this.http.post<any>(environment.api_backend_url + '/api/feedback/send-email',
      body, { observe: 'response', headers }
    );
}

generateBodyEmail(data: DataSendFeedBack): string{
  const html ='<h2 class="mb-3" >feedback</h2>'+
  '<input type="checkbox" disabled '+ (data.error ? 'checked' : '') +' /> He encontrado un error y no puedo avanzar <br/>'+
  '<input type="checkbox" disabled '+(data.function? 'checked' : '')  +' /> No encuentro funciones que antes usaba <br/>'+
  '<input type="checkbox" disabled '+ (data.dificult? 'checked' : '') +' /> Esta versión es complicada de usar o entender <br/>'+
  '<p>Indícanos cómo mejorar tu experiencia</p>'+
  '<p>'+data.comment+'</p> ';

  return html
}

}
