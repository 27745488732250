import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { PrimeNGConfig } from 'primeng/api';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-input-calendar-time',
  templateUrl: './input-calendar-time.component.html',
  styleUrls: ['./input-calendar-time.component.scss'],
})
export class InputCalendarTimeComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'text';
  @Input() options: any = {};
  @Input() numberOfMonths: any;
  @Input() startAt: any;
  @Input() onlyOne: boolean = false;
  @Output() changeM = new EventEmitter();
  @Output() show = new EventEmitter();
  @ViewChild('dropdown', { static: true }) dropd: any;

  @Input() set minDate(e) {
    if (e) {
      this.mDate = e;
      setTimeout(() => {
        this.monthChange();
      }, 500);
      if (
        this.selectedDate < this.mDate &&
        this.parentForm.get(this.fCName)?.enabled &&
        (!this.parentForm.get(this.fCName)?.value ||
          this.parentForm.get(this.fCName)?.value < this.mDate)
      ) {
        this.parentForm.get(this.fCName)?.setValue(e);
        this.selectedDate = this.mDate;
        this.DayAndDate = this.datePipe.transform(
          this.selectedDate,
          'DD-MM-YYYY'
        )!;
      }
    }
  }

  backSelect = false;
  optionSelectedDeparture = 'all';
  optionSelectedArrival = 'all';
  today = new Date();
  optionsRange = [
    {
      title: this.translateService.instant('all_'),
      option: 'all',
      minHour: 6,
      maxHour: 30,
    },
    {
      title: this.translateService.instant('morning_'),
      option: 'm',
      minHour: 6,
      maxHour: 14,
    },
    {
      title: this.translateService.instant('afternoon_'),
      option: 'a',
      minHour: 14,
      maxHour: 22,
    },
    {
      title: this.translateService.instant('night_'),
      option: 'n',
      minHour: 22,
      maxHour: 30,
    },
  ];
  language$: any = this.languageService.changeLanguageEvent.subscribe(() => {
    this.onSelect(new Date(this.selectedDate));
  });

  dropdownOptions = {
    class: 'dropdown',
  };

  mDate: Date;
  selectedDate = new Date();
  year: any;
  DayAndDate: string = this.datePipe.transform(
    this.selectedDate,
    'DD-MM-YYYY'
  )!;

  minValueDeparture = 6;
  maxValueDeparture = 30;
  optionsSliderDeparture: Options = {
    floor: 6,
    ceil: 30,
    draggableRangeOnly: false,
    translate: (value: number): string => {
      return value + 'h';
    },
  };

  minValueArrival = 6;
  maxValueArrival = 30;
  optionsSliderArrival: Options = {
    floor: 6,
    ceil: 30,
    draggableRangeOnly: false,
    translate: (value: number): string => {
      return value + 'h';
    },
  };

  showCalendar = true;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private datePipe: DatePipe,
    private config: PrimeNGConfig
  ) {}
  // TODO: Comento este fragmento de codigo por que intereferia en el componenete filter
  // de la tabla para el filtro de fechas. He realizado pruebas y parece que no afecto en
  // funcionamieno de la APP. Si en algun momento se detecta algo raro revisar si puede ser
  // este la causa.
  // ngOnChanges(changes: SimpleChanges) {
  //   this.resetChildForm();
  // }

  // // Numero de calendarios segun tamaño de pantalla
  // resetChildForm() {
  //   this.showCalendar = false;
  //   setTimeout(() => {
  //     this.showCalendar = true;
  //   }, 10);
  // }

  ngOnInit() {
    const translations = this.translateService.instant('calendar.trans');
    this.config.setTranslation(translations);
    if (this.onlyOne) {
      this.controlInitialDay();
      this.controlFinalDay();
    }
    setTimeout(() => {
      this.fillSlider();
    }, 500);

    if (this.options && this.options.initialDay) {
      this.parentForm.get(this.fCName)?.setValue(this.options.initialDay);
    }
    if (this.dropd) {
      this.dropd.emitter.subscribe(() => {
        this.monthChange();
      });
    }
  }

  controlInitialDay() {
    this.parentForm.get('finalDay')?.valueChanges.subscribe((val) => {
      if (this.parentForm.get('initialDay')?.value > val) {
        this.parentForm.get('initialDay')?.setValue(val);
      }
    });
  }

  controlFinalDay() {
    this.parentForm.get('initialDay')?.valueChanges.subscribe((val) => {
      if (this.parentForm.get('finalDay')?.value < val) {
        this.parentForm.get('finalDay')?.setValue(val);
      }
    });
  }

  ngOnDestroy() {
    this.language$.unsubscribe();
  }

  onSelect(event) {
    this.emitShow(false);
  }

  fillSlider() {
    if (
      this.fCName === 'initialDay' &&
      this.parentForm.get('schedulesDeparture') &&
      this.parentForm.get('schedulesDepartureArrival')
    ) {
      if (this.parentForm.get('schedulesDeparture')?.value) {
        this.minValueDeparture = parseInt(
          this.parentForm.get('schedulesDeparture')?.value[0].split(':')[0]
        );
        this.maxValueDeparture = parseInt(
          this.parentForm.get('schedulesDeparture')?.value[1].split(':')[0]
        );
      } else if (this.parentForm.get('schedulesDepartureArrival')?.value) {
        this.minValueArrival = parseInt(
          this.parentForm
            .get('schedulesDepartureArrival')
            ?.value[0].split(':')[0]
        );
        this.maxValueArrival = parseInt(
          this.parentForm
            .get('schedulesDepartureArrival')
            ?.value[1].split(':')[0]
        );
      }
    } else if (
      this.fCName === 'finalDay' &&
      this.parentForm.get('schedulesReturn') &&
      this.parentForm.get('schedulesReturnArrival')
    ) {
      if (this.parentForm.get('schedulesReturn')?.value) {
        this.minValueDeparture = parseInt(
          this.parentForm.get('schedulesReturn')?.value[0].split(':')[0]
        );
        this.maxValueDeparture = parseInt(
          this.parentForm.get('schedulesReturn')?.value[1].split(':')[0]
        );
      } else if (this.parentForm.get('schedulesReturnArrival')?.value) {
        this.minValueDeparture = parseInt(
          this.parentForm.get('schedulesReturnArrival')?.value[0].split(':')[0]
        );
        this.maxValueDeparture = parseInt(
          this.parentForm.get('schedulesReturnArrival')?.value[1].split(':')[0]
        );
      }
    }
  }

  inputOnFocus() {
    if (this.parentForm.get(this.fCName)?.enabled) {
      this.emitShow(true);
    }
  }

  inputBlur() {
    this.emitShow(false);
  }

  changeMonth() {
    setTimeout(() => {
      this.changeM.emit(true);
    }, 300);
  }

  emitShow($event: boolean) {
    this.options.show = $event;
    if ($event) {
      this.show.emit($event);
    }
  }

  changeDepartureHours() {
    if (this.fCName === 'initialDay') {
      this.fillFormHours(
        'schedulesDeparture',
        this.minValueDeparture,
        this.maxValueDeparture
      );
    } else if (this.fCName === 'finalDay') {
      this.fillFormHours(
        'schedulesReturn',
        this.minValueDeparture,
        this.maxValueDeparture
      );
    }
    setTimeout(() => {
      this.checkOptionSelectedDeparture();
    }, 100);
  }

  changeArrivalHours() {
    if (this.fCName === 'initialDay') {
      this.fillFormHours(
        'schedulesDepartureArrival',
        this.minValueArrival,
        this.maxValueArrival
      );
    } else if (this.fCName === 'finalDay') {
      this.fillFormHours(
        'schedulesReturnArrival',
        this.minValueArrival,
        this.maxValueArrival
      );
    }
    setTimeout(() => {
      this.checkOptionSelectedArrival();
    }, 100);
  }

  fillFormHours(control: string, minValue: number, maxValue: number) {
    this.parentForm
      .get(control)
      ?.setValue([
        this.datePipe.transform(
          new Date().setHours(minValue, 0, 0),
          'shortTime'
        ),
        this.datePipe.transform(
          new Date().setHours(maxValue, 0, 0),
          'shortTime'
        ),
      ]);
  }

  changeDeparture(options) {
    if (options === this.optionsRange[1].option) {
      this.minValueDeparture = this.optionsRange[1].minHour;
      this.maxValueDeparture = this.optionsRange[1].maxHour;
    } else if (options === this.optionsRange[2].option) {
      this.minValueDeparture = this.optionsRange[2].minHour;
      this.maxValueDeparture = this.optionsRange[2].maxHour;
    } else if (options === this.optionsRange[3].option) {
      this.minValueDeparture = this.optionsRange[3].minHour;
      this.maxValueDeparture = this.optionsRange[3].maxHour;
    } else {
      this.minValueDeparture = this.optionsRange[0].minHour;
      this.maxValueDeparture = this.optionsRange[0].maxHour;
    }
    this.checkOptionSelectedDeparture();
  }

  checkOptionSelectedDeparture() {
    if (
      this.minValueDeparture === this.optionsRange[0].minHour &&
      this.maxValueDeparture === this.optionsRange[0].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[0].option;
    } else if (
      this.minValueDeparture === this.optionsRange[1].minHour &&
      this.maxValueDeparture === this.optionsRange[1].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[1].option;
    } else if (
      this.minValueDeparture === this.optionsRange[2].minHour &&
      this.maxValueDeparture === this.optionsRange[2].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[2].option;
    } else if (
      this.minValueDeparture === this.optionsRange[3].minHour &&
      this.maxValueDeparture === this.optionsRange[3].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[3].option;
    } else {
      this.optionSelectedDeparture = 'none';
    }
  }

  checkOptionSelectedArrival() {
    if (
      this.minValueArrival === this.optionsRange[0].minHour &&
      this.maxValueArrival === this.optionsRange[0].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[0].option;
    } else if (
      this.minValueArrival === this.optionsRange[1].minHour &&
      this.maxValueArrival === this.optionsRange[1].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[1].option;
    } else if (
      this.minValueArrival === this.optionsRange[2].minHour &&
      this.maxValueArrival === this.optionsRange[2].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[2].option;
    } else if (
      this.minValueArrival === this.optionsRange[3].minHour &&
      this.maxValueArrival === this.optionsRange[3].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[3].option;
    } else {
      this.optionSelectedArrival = 'none';
    }
  }

  changeArrival(options) {
    if (options === this.optionsRange[1].option) {
      this.minValueArrival = this.optionsRange[1].minHour;
      this.maxValueArrival = this.optionsRange[1].maxHour;
    } else if (options === this.optionsRange[2].option) {
      this.minValueArrival = this.optionsRange[2].minHour;
      this.maxValueArrival = this.optionsRange[2].maxHour;
    } else if (options === this.optionsRange[3].option) {
      this.minValueArrival = this.optionsRange[3].minHour;
      this.maxValueArrival = this.optionsRange[3].maxHour;
    } else {
      this.minValueArrival = this.optionsRange[0].minHour;
      this.maxValueArrival = this.optionsRange[0].maxHour;
    }
    this.checkOptionSelectedArrival();
  }

  monthChange(e?) {
    let dateMonth: any;
    if (!e) {
      const d = new Date(this.parentForm.get(this.fCName)?.value);
      dateMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    } else {
      dateMonth = new Date(e.year, e.month, 1);
    }
    let months;

    if (this.mDate) {
      const years = this.mDate.getFullYear() - dateMonth.getFullYear();
      months = Math.abs(
        years * 12 + (this.mDate.getMonth() - dateMonth.getMonth())
      );
    } else {
      const years = this.today.getFullYear() - dateMonth.getFullYear();
      months = Math.abs(
        years * 12 + (this.today.getMonth() - dateMonth.getMonth())
      );
    }

    setTimeout(() => {
      if (document.getElementsByClassName('p-datepicker-next')[0]) {
        if (months > 12) {
          document
            .getElementsByClassName('p-datepicker-next')[0]
            .classList.add('remove');
        } else if (document.getElementsByClassName('p-datepicker-next')[0]) {
          document
            .getElementsByClassName('p-datepicker-next')[0]
            .classList.remove('remove');
        }
      }

      if (document.getElementsByClassName('p-datepicker-prev')[0]) {
        if (months > 1) {
          document
            .getElementsByClassName('p-datepicker-prev')[0]
            .classList.add('show');
        } else {
          document
            .getElementsByClassName('p-datepicker-prev')[0]
            .classList.remove('show');
        }
      }
    }, 100);
  }

  isBackSelect(date) {
    let initialDay = '';
    let finalDay = '';
    if (this.onlyOne) {
      if (
        this.parentForm.get('initialDay') &&
        this.parentForm.get('initialDay')?.value &&
        this.fCName === 'initialDay'
      ) {
        initialDay = this.datePipe.transform(
          this.parentForm.get('initialDay')?.value,
          'M-d-yyyy'
        )!;
      } else if (
        this.fCName === 'finalDay' &&
        this.parentForm.get('finalDay') &&
        this.parentForm.get('finalDay')?.value
      ) {
        finalDay = this.datePipe.transform(
          this.parentForm.get('finalDay')?.value,
          'M-d-yyyy'
        )!;
      }
    } else {
      initialDay = this.datePipe.transform(
        this.parentForm.get('initialDay')?.value,
        'M-d-yyyy'
      )!;
      if (
        this.parentForm.get('finalDay') &&
        this.parentForm.get('finalDay')?.value
      ) {
        finalDay = this.datePipe.transform(
          this.parentForm.get('finalDay')?.value,
          'M-d-yyyy'
        )!;
      }
    }

    if (initialDay !== '') {
      if (`${date.month + 1}-${date.day}-${date.year}` === initialDay) {
        return true;
      }
    }
    if (finalDay !== '') {
      if (`${date.month + 1}-${date.day}-${date.year}` === finalDay) {
        return true;
      }
    }
    return false;
  }
}
