import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'differenceBetweenDates',
})
export class DifferenceBetweenDatesPipe implements PipeTransform {
  transform(originDate: string, arrivalDate: string, format: string): number {
    const origin = new Date(originDate);
    const arrival = new Date(arrivalDate);
    const difference = arrival.getTime() - origin.getTime();

    return difference / (1000 * 3600 * 24);
  }
}
