<h4 *ngIf="!imageSrc[iata]" [pTooltip]="airline | uppercase" [tooltipPosition]="'top'">
  {{ airline | uppercase }}
</h4>
<img
  class="icons"
  [ngClass]="{ iryo: iata === 'IR' || iata === 'IRY' }"
  alt="{{ airline }} logo"
  *ngIf="imageSrc[iata]"
  [src]="urlAirlines + imageSrc[iata]"
/>
