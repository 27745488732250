<div class="container-switch">
  <ng-container *ngIf="optionsSwitchButttons">
    <div
      class="box-container-button"
      *ngFor="
        let option of optionsSwitchButttons;
        first as first;
        index as index;
        last as last
      "
      [ngClass]="{ borderRight: !last }"
      data-cy="box-switch-button"
    >
      <button
        class="switch-button"
        *ngIf="option.text"
        [ngClass]="{ selected: option.selected }"
        pRipple
        (click)="toggleControlSwitchButton(option)"
      >
        <div
          class="svg-box left"
          *ngIf="option.icon && option.option.positionIcon === 'left'"
        >
          <svg-icon [src]="option.icon"></svg-icon>
        </div>
        <div class="option-text">
          <p *ngIf="option.selected && option.textMin" class="min">
            {{ option.textMin | translate }}
          </p>
          <p>{{ option.text | translate }}</p>
        </div>
        <div
          class="svg-box right"
          *ngIf="option.icon && option.option.positionIcon === 'right'"
        >
          <svg-icon [src]="option.icon"></svg-icon>
        </div>
      </button>
    </div>
  </ng-container>
</div>
