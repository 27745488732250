import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-search-timetables-detail',
  templateUrl: './search-timetables-detail.component.html',
  styleUrls: ['./search-timetables-detail.component.scss'],
})
export class SearchTimetablesDetailComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;

  optionSelectedDeparture = 'all';
  optionSelectedArrival = 'all';
  optionsRange = [
    {
      title: this.translateService.instant('all_'),
      option: 'all',
      minHour: 6,
      maxHour: 30,
    },
    {
      title: this.translateService.instant('morning_'),
      option: 'm',
      minHour: 6,
      maxHour: 14,
    },
    {
      title: this.translateService.instant('afternoon_'),
      option: 'a',
      minHour: 14,
      maxHour: 22,
    },
    {
      title: this.translateService.instant('night_'),
      option: 'n',
      minHour: 22,
      maxHour: 30,
    },
  ];

  minValueDeparture = 6;
  maxValueDeparture = 30;
  optionsSliderDeparture: Options = {
    animate: false,
    floor: 6,
    ceil: 30,
    draggableRangeOnly: false,
    translate: (value: number): string => {
      return (value >= 24 ? value - 24 : value) + 'h';
    },
  };

  minValueArrival = 6;
  maxValueArrival = 30;
  optionsSliderArrival: Options = {
    animate: false,
    floor: 6,
    ceil: 30,
    draggableRangeOnly: false,
    translate: (value: number): string => {
      return (value >= 24 ? value - 24 : value) + 'h';
    },
  };

  constructor(
    private translateService: TranslateService,
    private config: PrimeNGConfig
  ) {}

  ngOnInit() {
    const translations = this.translateService.instant('calendar.trans');
    this.config.setTranslation(translations);
    setTimeout(() => {
      this.fillSlider();
    }, 500);
    this.parentForm.valueChanges.subscribe((state) => {
      if (this.fCName === 'initialDay') {
        if (state.schedulesDeparture.some((res) => !isNaN(res))) {
          this.minValueDeparture =
            state.schedulesDeparture[0] < 6
              ? state.schedulesDeparture[0] + 24
              : state.schedulesDeparture[0];
          this.maxValueDeparture =
            state.schedulesDeparture[1] <= 6
              ? state.schedulesDeparture[1] + 24
              : state.schedulesDeparture[1];
        }
        if (state.schedulesDepartureArrival.some((res) => !isNaN(res))) {
          this.minValueArrival =
            state.schedulesDepartureArrival[0] < 6
              ? state.schedulesDepartureArrival[0] + 24
              : state.schedulesDepartureArrival[0];
          this.maxValueArrival =
            state.schedulesDepartureArrival[1] <= 6
              ? state.schedulesDepartureArrival[1] + 24
              : state.schedulesDepartureArrival[1];
        }
      }
      if (this.fCName === 'finalDay') {
        if (state.schedulesReturn.some((res) => !isNaN(res))) {
          this.minValueDeparture =
            state.schedulesReturn[0] < 6
              ? state.schedulesReturn[0] + 24
              : state.schedulesReturn[0];
          this.maxValueDeparture =
            state.schedulesReturn[1] <= 6
              ? state.schedulesReturn[1] + 24
              : state.schedulesReturn[1];
        }

        if (state.schedulesReturnArrival.some((res) => !isNaN(res))) {
          this.minValueArrival =
            state.schedulesReturnArrival[0] < 6
              ? state.schedulesReturnArrival[0] + 24
              : state.schedulesReturnArrival[0];
          this.maxValueArrival =
            state.schedulesReturnArrival[1] <= 6
              ? state.schedulesReturnArrival[1] + 24
              : state.schedulesReturnArrival[1];
        }
      }
    });
  }

  fillSlider() {
    if (
      this.fCName === 'initialDay' &&
      this.parentForm.get('schedulesDeparture') &&
      this.parentForm.get('schedulesDepartureArrival')
    ) {
      if (
        this.parentForm.get('schedulesDeparture')?.value &&
        !isNaN(this.parentForm.get('schedulesDeparture')?.value)
      ) {
        this.minValueDeparture =
          this.parentForm.get('schedulesDeparture')?.value[0] < 6
            ? this.parentForm.get('schedulesDeparture')?.value[0] + 24
            : this.parentForm.get('schedulesDeparture')?.value[0];

        this.maxValueDeparture =
          this.parentForm.get('schedulesDeparture')?.value[1] <= 6
            ? this.parentForm.get('schedulesDeparture')?.value[1] + 24
            : this.parentForm.get('schedulesDeparture')?.value[1];
      }
      if (
        this.parentForm.get('schedulesDepartureArrival')?.value &&
        !isNaN(this.parentForm.get('schedulesDepartureArrival')?.value)
      ) {
        this.minValueArrival =
          this.parentForm.get('schedulesDepartureArrival')?.value[0] < 6
            ? this.parentForm.get('schedulesDepartureArrival')?.value[0] + 24
            : this.parentForm.get('schedulesDepartureArrival')?.value[0];

        this.maxValueArrival =
          this.parentForm.get('schedulesDepartureArrival')?.value[1] <= 6
            ? this.parentForm.get('schedulesDepartureArrival')?.value[1] + 24
            : this.parentForm.get('schedulesDepartureArrival')?.value[1];
      }
    }
    if (
      this.fCName === 'finalDay' &&
      this.parentForm.get('schedulesReturn') &&
      this.parentForm.get('schedulesReturnArrival')
    ) {
      if (
        this.parentForm.get('schedulesReturn')?.value &&
        !isNaN(this.parentForm.get('schedulesReturn')?.value)
      ) {
        this.minValueDeparture =
          this.parentForm.get('schedulesReturn')?.value[0] < 6
            ? this.parentForm.get('schedulesReturn')?.value[0] + 24
            : this.parentForm.get('schedulesReturn')?.value[0];

        this.maxValueDeparture =
          this.parentForm.get('schedulesReturn')?.value[1] <= 6
            ? this.parentForm.get('schedulesReturn')?.value[1] + 24
            : this.parentForm.get('schedulesReturn')?.value[1];
      }
      if (
        this.parentForm.get('schedulesReturnArrival')?.value &&
        !isNaN(this.parentForm.get('schedulesReturnArrival')?.value)
      ) {
        this.minValueArrival =
          this.parentForm.get('schedulesReturnArrival')?.value[0] < 6
            ? this.parentForm.get('schedulesReturnArrival')?.value[0] + 24
            : this.parentForm.get('schedulesReturnArrival')?.value[0];

        this.maxValueArrival =
          this.parentForm.get('schedulesReturnArrival')?.value[1] <= 6
            ? this.parentForm.get('schedulesReturnArrival')?.value[1] + 24
            : this.parentForm.get('schedulesReturnArrival')?.value[1];
      }
    }
  }

  changeDepartureHours() {
    setTimeout(() => {
      if (this.fCName === 'initialDay') {
        this.fillFormHours(
          'schedulesDeparture',
          this.minValueDeparture,
          this.maxValueDeparture
        );
      } else if (this.fCName === 'finalDay') {
        this.fillFormHours(
          'schedulesReturn',
          this.minValueDeparture,
          this.maxValueDeparture
        );
      }
      this.checkOptionSelectedDeparture();
    }, 100);
  }

  changeArrivalHours() {
    setTimeout(() => {
      if (this.fCName === 'initialDay') {
        this.fillFormHours(
          'schedulesDepartureArrival',
          this.minValueArrival,
          this.maxValueArrival
        );
      } else if (this.fCName === 'finalDay') {
        this.fillFormHours(
          'schedulesReturnArrival',
          this.minValueArrival,
          this.maxValueArrival
        );
      }
      this.checkOptionSelectedArrival();
    }, 100);
  }

  fillFormHours(control: string, minValue: number, maxValue: number) {
    this.parentForm
      .get(control)
      ?.setValue([
        minValue >= 24 ? minValue - 24 : minValue,
        maxValue >= 24 ? maxValue - 24 : maxValue,
      ]);
  }

  changeDeparture(options) {
    if (options === this.optionsRange[1].option) {
      this.minValueDeparture = this.optionsRange[1].minHour;
      this.maxValueDeparture = this.optionsRange[1].maxHour;
    } else if (options === this.optionsRange[2].option) {
      this.minValueDeparture = this.optionsRange[2].minHour;
      this.maxValueDeparture = this.optionsRange[2].maxHour;
    } else if (options === this.optionsRange[3].option) {
      this.minValueDeparture = this.optionsRange[3].minHour;
      this.maxValueDeparture = this.optionsRange[3].maxHour;
    } else {
      this.minValueDeparture = this.optionsRange[0].minHour;
      this.maxValueDeparture = this.optionsRange[0].maxHour;
    }
    this.checkOptionSelectedDeparture();
  }

  checkOptionSelectedDeparture() {
    if (
      this.minValueDeparture === this.optionsRange[0].minHour &&
      this.maxValueDeparture === this.optionsRange[0].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[0].option;
    } else if (
      this.minValueDeparture === this.optionsRange[1].minHour &&
      this.maxValueDeparture === this.optionsRange[1].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[1].option;
    } else if (
      this.minValueDeparture === this.optionsRange[2].minHour &&
      this.maxValueDeparture === this.optionsRange[2].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[2].option;
    } else if (
      this.minValueDeparture === this.optionsRange[3].minHour &&
      this.maxValueDeparture === this.optionsRange[3].maxHour
    ) {
      this.optionSelectedDeparture = this.optionsRange[3].option;
    } else {
      this.optionSelectedDeparture = 'none';
    }
  }

  checkOptionSelectedArrival() {
    if (
      this.minValueArrival === this.optionsRange[0].minHour &&
      this.maxValueArrival === this.optionsRange[0].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[0].option;
    } else if (
      this.minValueArrival === this.optionsRange[1].minHour &&
      this.maxValueArrival === this.optionsRange[1].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[1].option;
    } else if (
      this.minValueArrival === this.optionsRange[2].minHour &&
      this.maxValueArrival === this.optionsRange[2].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[2].option;
    } else if (
      this.minValueArrival === this.optionsRange[3].minHour &&
      this.maxValueArrival === this.optionsRange[3].maxHour
    ) {
      this.optionSelectedArrival = this.optionsRange[3].option;
    } else {
      this.optionSelectedArrival = 'none';
    }
  }

  changeArrival(options) {
    if (options === this.optionsRange[1].option) {
      this.minValueArrival = this.optionsRange[1].minHour;
      this.maxValueArrival = this.optionsRange[1].maxHour;
    } else if (options === this.optionsRange[2].option) {
      this.minValueArrival = this.optionsRange[2].minHour;
      this.maxValueArrival = this.optionsRange[2].maxHour;
    } else if (options === this.optionsRange[3].option) {
      this.minValueArrival = this.optionsRange[3].minHour;
      this.maxValueArrival = this.optionsRange[3].maxHour;
    } else {
      this.minValueArrival = this.optionsRange[0].minHour;
      this.maxValueArrival = this.optionsRange[0].maxHour;
    }
    this.checkOptionSelectedArrival();
  }
}
