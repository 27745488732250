import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableComponent {
  @Input() key: string;
  @Input() form: FormGroup;
  @Input() inputsStateForm: any;
  @Input() activeFilter = false;
  @Input() openFilter = false;
  @Output() filterParent = new EventEmitter<any>();
  @Output() resetFilterParent = new EventEmitter<any>();
  @Output() applyFilterNGPrime = new EventEmitter<{value:boolean ,key: any}>();
  @Output() controlOpenClosedFilterNGPrime = new EventEmitter<{value:boolean ,key: any}>();
  @Output() controlValueInput = new EventEmitter<any>();
  @Output() cleanInvalidInput = new EventEmitter<any>();

  enforceNumericInputField(event): void{
    this.controlValueInput.emit(event)
  }

  cleanInvalidInputField(event): void{
    this.controlValueInput.emit(event)
  }

  applyFilter(): void {
    if (
      Object.values(this.form.controls).every((control) => control.value !== '')
    ) {
      this.applyFilterNGPrime.emit({value:false, key:this.key});
      this.filterParent.emit(this.form);
    } else {
      this.openClosedFilterDate();
    }
  }
  resetFilter(): void {
    this.resetFilterParent.emit();
  }

  openClosedFilterDate(): void {
    this.controlOpenClosedFilterNGPrime.emit({value:!this.openFilter, key:this.key});
  }
}
