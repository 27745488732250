<ng-container [formGroup]="parentForm">
  <div [ngClass]="{ filter: show }">
    <p-multiSelect
      (onPanelShow)="positionInputMultiselect()"
      [formControlName]="fCName"
      [options]="options.data"
      displaySelectedLabel="false"
      [filter]="false"
      [ngClass]="options.class"
      [defaultLabel]="options.placeholder | translate"
      scrollHeight="100vh"
      maxSelectedLabels="0"
      (onPanelHide)="panelHide()"
      selectedItemsLabel="{{ options.placeholder | translate }}"
    >
      <ng-template let-option pTemplate="item">
        {{ option.label | translate }}
      </ng-template>
    </p-multiSelect>
  </div>
</ng-container>
