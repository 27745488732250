<section class="sectionBackClasicVersion">
  <div class="icons">
    <svg-icon src="assets/images/icons/logo-normal.svg"></svg-icon>
  </div>
  <ng-container *ngIf="isFormFeedback">
    <h2 class="mb-3">{{ 'modal-back-version-clasic_.title_' | translate }}</h2>
    <div class="expSearch">
      <app-fields-collection
        [inputs]="inputs"
        [form]="form"
      ></app-fields-collection>
    </div>
    <div class="button-box mt-3">
      <button pRipple class="btn-back" (click)="cancel()">
        {{ buttonsOptions.back.literal | translate }}
      </button>
      <app-submit
        [literal]="buttonsOptions.send.literal"
        [options]="buttonsOptions.send.options"
        (click)="send()"
      ></app-submit>
    </div>
  </ng-container>
  <ng-container *ngIf="!isFormFeedback">
    <h2 class="mb-3 center-text">
      {{ 'modal-back-version-clasic_.success_.title_' | translate }}
    </h2>
    <p class="center-text">
      {{ 'modal-back-version-clasic_.success_.text_' | translate }}
    </p>
  </ng-container>
</section>
