import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss'],
})
export class SelectIconComponent implements OnInit, OnDestroy {
  @Input() options: any = [];
  @Input() fCName: string;
  @Input() parentForm: FormGroup;
  @ViewChild('drop') input: any;
  sub: any;
  imageSelected: any = {};
  multiSelected: boolean;
  close = true;
  planeTrain: boolean;

  dropdownOptions = {
    class: 'dropdown',
  };

  ngOnInit() {
    const indexSelect = this.options
      .map((e: any) => e.value)
      .indexOf(this.parentForm.controls[this.fCName].value);
    this.imageSelected = this.options[indexSelect >= 0 ? indexSelect : 0];
    this.options[indexSelect >= 0 ? indexSelect : 0].active = true;
    this.planeTrain = true;

    this.sub = this.parentForm.get(this.fCName)?.valueChanges.subscribe((e) => {
      const index = this.options.findIndex((s: any) => s.value === e);

      this.imageSelected = this.options[index];
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  changeOption(options) {
    for (const option of this.options) {
      option.active = false;
    }

    options.active = true;

    this.input.show = false;
    const index = this.options.findIndex((s: any) => s.src === options.src);
    this.imageSelected = this.options[index];
    this.parentForm.controls[this.fCName].setValue(this.imageSelected.value);
    if (options.src === 'icon multidireccion') {
      this.multiSelected = true;
    } else if (options.src === 'plane+train') {
      this.planeTrain = true;
    } else {
      this.multiSelected = false;
      this.planeTrain = false;
    }
  }
}
