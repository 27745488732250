import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPatterText',
})
export class FormatPatterTextPipe implements PipeTransform {
  transform(value, pattern) {
    let i = 0;
    return pattern.replace(/#/g, (_) => value[i++]);
  }
}
