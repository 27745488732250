<p-header>
  <svg-icon [src]="data?.header?.logo" *ngIf="data.header.logo"></svg-icon>
  <p>{{ data.header.title }}</p>
</p-header>
<div class="separator one" *ngIf="data.info"></div>
<ng-container *ngFor="let item of data.info; index as index; last as last">
  <div class="title">
    <svg-icon src="assets/images/icons/dot_grey.svg"></svg-icon>
    <h4>
      {{ ('step_' | translate) + ' ' + (index + 1) }}
    </h4>
  </div>

  <div class="content">
    <p class="text" [innerHTML]="item.text | translate"></p>
  </div>
  <div
    *ngIf="item?.note"
    class="note"
    [innerHTML]="item?.note | translate"
  ></div>
  <div class="separator two" [ngClass]=" {last: last}"></div>
</ng-container>
<div class="separator one"></div>
<ng-container *ngIf="data.link">
  <div class="title">
    <svg-icon src="assets/images/icons/dot_grey.svg"></svg-icon>
    <h4>
      {{ 'external_links_' | translate | uppercase }}
    </h4>
  </div>

    <div class="link" >
      <svg-icon [src]="'assets/images/icons/link.svg'"></svg-icon>
      <a [href]="data.link.url" target="_blank"> {{ data.link.name }}</a>
    </div>

</ng-container>
