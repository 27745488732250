// api getUserData response.
// Consolidator info
import { DeserializableAbstract } from './deserializable.abstact';

export class ClientModel extends DeserializableAbstract {
  // client id
  public i: string;
  public ci: number;
  // consolidator domain.
  public d: string;
  // consoldiator png logo.
  public lp: string;
  // client png logo.
  public la: string;
  // consolidator svg logo.
  public ls: string;
  // consolidador png favicon
  public fi: string;
  // consolidador template name
  public tn: string;
  // consolidador endpoint
  public ep: string;
  // extra fee value
  public fee: string;
  // New tab
  public nt: boolean;
  // iframe url
  public aiu: string;
  // client lang
  public c: string;
  // client isHalcon
  public di: boolean;
  // Show tour control
  public tr: boolean;
  // Rail app control
  public ra: {
    da: boolean;
    dp: boolean;
  };
  // banners advertisement
  public b: {
    0: string[]; // home interstitial
    1: string[]; // loading
    2: string[]; // home robapaginas
  };
  public fac: {
    nombreSociedad: string;
    notifEmail: string;
    notifPhone: string;
  };
  // Flag que indica si mostramos o no el emisionFee en el desglose de precio
  public em: boolean;
  // Family consolidator
  public es: boolean;
  // show co2
  public co2 = false;
  // Mensaje modal cancelar + voidear
  public cvm = false;
  // Mensaje modal cancelar + voidear
  public we = false;
  // Search booking by: l=>Locator, q=>query
  public sb = 'query';
  // if consolidator is call center
  public cc = false;
  // call center provider IATA code
  public cci: string;
  // show emision fee not included
  public snemm = false;
  // show payment method
  public spm: boolean;
  // cto check TTOO
  public cto: boolean;
  // fto show TTOO check in home
  public fto: boolean;
  // ftoa value of the TTOO check in home
  public ftoa: boolean;
  // etnic value of the etnic check in home
  public etnic: boolean;
  // cupo value of the cupo check in home
  public cupo: boolean;
  // show REFerences in voucher
  public ref: boolean;
  // avoid usa filter
  public au: boolean;
  // currency
  public cfee: string;
  // apikey
  public ak: string;
  // double currency
  public dc: string[];

  override deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export enum BannersPlace {
  homeInterstitial = 0, // INTERSTITIAL
  loading = 1, // LOADING
  homeRobapaginas = 2, // ROBAPAGINAS
}
