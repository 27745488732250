import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(date1: any, date2?: any, isRentCar: boolean = false): any {
    const firstDate: Date = new Date(date1);
    const secondDate: Date = new Date(date2);
    let duration: any;
    let response: any;
    if (date1) {
      if (isNaN(firstDate.getTime())) {
        response = this.tranformSpecialDuration(date1);
      } else if (date2) {
        if (secondDate.getTime() > firstDate.getTime()) {
          duration = -(firstDate.getTime() - secondDate.getTime());
        } else {
          duration = firstDate.getTime() - secondDate.getTime();
        }

        response = this.transformDuration(duration);
      } else {
        duration = date1 * 1000;
        response = this.transformDuration(duration);
      }
    }

    if (
      isRentCar &&
      response &&
      response.days > 0 &&
      (response.hours > 0 || response.minutes > 0)
    ) {
      response.days += 1;
    }

    return response;
  }

  transformDuration(duration: number) {
    const totalSeconds = Math.floor(duration / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);

    const seconds = totalSeconds % 60;
    const minutes = totalMinutes % 60;
    const hours = totalHours % 24;

    return {
      seconds,
      minutes,
      hours,
      days,
    };
  }

  tranformSpecialDuration(duration: string) {
    let durationParams = duration.split(':');

    const seconds = 0;
    const minutes = +durationParams[2];
    const hours = +durationParams[1];
    const days = +durationParams[0];

    return {
      seconds,
      minutes,
      hours,
      days,
    };
  }

  tranformDurationInString(e: { seconds; minutes; hours; days }): string {
    const duration =
      (e.days ? e.days + 'd' : '') + ' ' + e.hours + 'h ' + e.minutes + 'min';

    return duration;
  }
}
