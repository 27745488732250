import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, filter } from 'rxjs';
import { FlightResultsService } from 'src/app/core/services/transport/flight-results/flight-results.service';
import { RouterService } from '../../../../core/services/router/router.service';

@Component({
  selector: 'app-no-result-avail-generic',
  templateUrl: './no-result-avail-generic.component.html',
  styleUrls: ['./no-result-avail-generic.component.scss'],
})
export class NoResultAvailGenericComponent {
  @Input() noResultAvailData: NoResultAvailData;
  @Input() buttonNavigateToHome = false;
  @Input() filterChanges: boolean;
  @Input() alertView = false;
  @Output() resetFiltersFather = new EventEmitter<void>();
  @Output() resetSearchSlugFather = new EventEmitter<void>();

  subscriptions: Subscription[] = [];

  constructor(
    public flightResultsService: FlightResultsService,
    private routerService: RouterService
  ) {}

  navigateToHome(): void {
    this.routerService.navigateToHome();
  }

  actionButtonClicked(): void {
    if (this.buttonNavigateToHome) {
      this.navigateToHome();
    } else {
      this.resetAll();
    }
  }

  resetAll(): void {
    if (this.filterChanges) {
      this.resetFiltersFather.emit();
    } else {
      this.resetSearchSlugFather.emit();
    }
  }
}

export interface NoResultAvailData {
  img: string;
  title: string;
  subtitle: string;
  step: string;
  description: string[];
  button?: string;
  filter?: NoResultAvailDataFilter;
}

interface NoResultAvailDataFilter {
  img: string;
  subtitle: string;
  description: string[];
  button: string;
}
