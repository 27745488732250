import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const KEYVALUE_STORAGE_TOKEN = new InjectionToken<KeyValueStorage>(
  'STORAGE'
);

export interface KeyValueStorage {
  setItem(key: string, value: any): Observable<void>;

  getItem<T>(key: string): Observable<T>;

  removeItem(key: string): Observable<void>;

  removeItems(keys: string[]): Observable<void>;

  clearAll(): Observable<void>;

  clearExpired(): Observable<void>;

  clearExcept(keys: string[]): Observable<void>;

  //keys(): Observable<string[]>;

  //length(): Observable<number>;

  //forEach();

  //setEncryptedItem(key: string, value: any): Observable<void>;

  //getEncryptedItem(key: string): Observable<void>;
}
