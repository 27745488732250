<ng-container
  *ngFor="
    let input of options.slice &&
    !(inputs?.length <= options.slice) &&
    !options.showMoreActive
      ? (inputs | slice : 0 : options.slice)
      : inputs;
    index as index
  "
>
  <ng-container
    *ngIf="
      form.get(input.name) &&
        input[input.name] &&
        checkIfFormHaveControls(form.get(input.name));
      else defaultLogic
    "
  >
    <div class="haveControls">
      <app-fields-collection
        [form]="form.get(input.name)"
        [inputs]="input[input.name]"
      >
      </app-fields-collection>
    </div>
  </ng-container>

  <ng-template #defaultLogic>
    <p class="selectRadio" *ngIf="index === 1 && options.selectRadioText">
      {{ 'complete_form_with_' | translate }}
    </p>

    <div
      [ngClass]="{
        disabled: form.get(input.name) && form.get(input.name).disabled,
        empty: form.get(input.name) && !form.get(input.name).value
      }"
      [ngSwitch]="input.type"
      [class]="input?.headerClass"
      [attr.data-cy]="
        'field-collection-div-' + (input.name | replace : ' ' : '-' : true)
      "
      *ngIf="!input.options?.hidden"
    >
      <div [ngClass]="input?.class" [attr.id]="input?.id ? input.id : null">
        <ng-container *ngIf="input.type !== 'special-input-with-check'">
          <label *ngIf="input.options?.label">
            {{ input.options.label | translate }}
          </label>
          <label class="secondaryLabel" *ngIf="input.options?.secondaryLabel">
            {{ input.options.secondaryLabel | translate }}
          </label>
          <div class="specialLabel" *ngIf="input.options?.specialLabel">
            <svg-icon
              *ngIf="input.options.specialLabelIcon"
              [src]="input.options.specialLabelIcon"
            ></svg-icon>
            <span>{{ input.options.specialLabel | translate }}</span>
          </div>
          <span
            class="asterisk"
            *ngIf="
              form.get(input.name)?.validator &&
              this.form.get(input.name).validator({})?.required &&
              input?.options?.required
            "
          >
            *
          </span>
          <svg-icon
            class="help"
            src="assets/images/icons/info.svg"
            *ngIf="input.options?.infoTooltipHeader"
            [pTooltip]="input.options.infoTooltipHeader | translate"
            [tooltipPosition]="'right'"
            [escape]="false"
            [tooltipStyleClass]="'infoTooltip'"
            data-cy="info"
          ></svg-icon
        ></ng-container>
        <ng-container *ngSwitchCase="'number-type'">
          <app-input
            [parentForm]="form"
            [fCName]="input.name"
            [currency]="input.currency"
            [porcentOver]="input.porcentOver"
            [type]="'text'"
            [options]="input.options"
            (controlValueInput)="enforceNumericInputField($event)"
            (cleanInvalidInput)="cleanInvalidInputField($event)"
          ></app-input>
        </ng-container>
        <ng-container *ngSwitchCase="'iata'">
          <app-input-iata
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
            [inputs]="inputs"
          ></app-input-iata>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <app-checkbox
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-checkbox>
          <div *ngIf="showButtonOnly" class="buttonOnly">
            <button
              (click)="butttonOnly(input.name)"
              [attr.data-cy]="input.name"
              style="text-decoration: underline"
            >
              {{ 'only_' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <app-select
            [parentForm]="form"
            [fCName]="input.name"
            [data]="input.options.data"
            [options]="input.options"
          >
          </app-select>
        </ng-container>
        <ng-container *ngSwitchCase="'slider'">
          <app-slider
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-slider>
        </ng-container>
        <ng-container *ngSwitchCase="'radio'">
          <app-input-radio
            [parentForm]="form"
            [name]="input.val"
            [fCName]="input.name"
            [options]="input.options"
          >
          </app-input-radio>
        </ng-container>
        <ng-container *ngSwitchCase="'checkboxSpecial'">
          <app-input-check-special
            [parentForm]="form"
            [name]="input.val"
            [fCName]="input.name"
            [options]="input.options"
            [inputs]="inputs"
          >
          </app-input-check-special>
        </ng-container>
        <ng-container *ngSwitchCase="'read'">
          <app-text-only-read
            [options]="input.options"
          ></app-text-only-read>
        </ng-container>
        <ng-container *ngSwitchCase="'calendar'">
          <app-input-calendar-time
            [parentForm]="form"
            [fCName]="input.name"
            [startAt]="calendarOptions.initialDay"
            [minDate]="calendarOptions.initialDay"
            [options]="calendarOptions"
            [numberOfMonths]="numberOfMonths"
          >
          </app-input-calendar-time>
        </ng-container>
        <ng-container *ngSwitchCase="'calendarSimple'">
          <app-input-calendar-time
            [parentForm]="form"
            [fCName]="input.name"
            [startAt]="options.initialDay"
            [minDate]="options.initialDay"
            [options]="input.options"
            [onlyOne]="true"
            [numberOfMonths]="1"
          >
          </app-input-calendar-time>
        </ng-container>
        <ng-container *ngSwitchCase="'autocomplete'">
          <app-input-autocomplete
            [data]="input.data"
            [parentForm]="form"
            [fCName]="input.name"
            [type]="'text'"
            [options]="input.options"
          ></app-input-autocomplete>
        </ng-container>
        <ng-container *ngSwitchCase="'generic-autocomplete'">
          <app-generic-autocomplete
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-generic-autocomplete>
        </ng-container>
        <ng-container *ngSwitchCase="'autocomplete-ngprime'">
          <app-input-autocomplete-ngprime
            [parentForm]="form"
            [fCName]="input.name"
            [data]="input.data"
            [options]="input.options"
            [inputsName]="inputsName"
          >
          </app-input-autocomplete-ngprime>
        </ng-container>
        <ng-container *ngSwitchCase="'multiselect'">
          <app-multiselect
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-multiselect>
        </ng-container>
        <ng-container *ngSwitchCase="'chip-multiselect'">
          <app-chip-multiselect
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-chip-multiselect>
        </ng-container>
        <ng-container *ngSwitchCase="'multiple-input'">
          <app-multiple-input
            [parentForm]="form.get(input.name)"
            [fCName]="input.name"
            [options]="input.options"
          ></app-multiple-input>
        </ng-container>
        <ng-container *ngSwitchCase="'select-input'">
          <app-select-input
            [parentForm]="form"
            [inputs]="input.options"
          ></app-select-input>
        </ng-container>
        <ng-container>
          <app-input-icon
            *ngSwitchCase="'input-icon'"
            [parentForm]="form"
            [options]="input.options"
          ></app-input-icon>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <app-textarea
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
            [attr.data-cy]="input.name"
          ></app-textarea>
        </ng-container>
        <ng-container *ngSwitchCase="'switchButton'">
          <app-switch-button
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-switch-button>
        </ng-container>
        <ng-container *ngSwitchCase="'genericAutocomplete'">
          <app-generic-autocomplete
            (openAddCompany)="openAddCompanyModule()"
            (openAddCompanyError)="openAddCompanyErrorSend($event)"
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          >
          </app-generic-autocomplete>
        </ng-container>
        <ng-container *ngSwitchCase="'mask'">
          <app-input-mask
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-input-mask>
        </ng-container>
        <ng-container *ngSwitchCase="'counter'">
          <app-counter
            [parentForm]="form"
            [fCName]="input.name"
            [options]="input.options"
          ></app-counter>
        </ng-container>
        <ng-container *ngSwitchCase="'array'">
          <ng-container [formGroup]="form">
            <ng-container
              *ngIf="form.get(input.name)"
              formArrayName="{{ input.name }}"
            >
              <div
                class="formArray"
                #element
                *ngFor="
                  let control of form.get(input.name)['controls'];
                  index as i;
                  let last = last
                "
              >
                <div [formGroupName]="i">
                  <ng-container *ngIf="input.inputs[i]">
                    <app-fields-collection
                      [form]="control"
                      [inputs]="input.inputs[i].fields"
                    >
                    </app-fields-collection>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'special-input-with-check'">
          <app-special-input-with-check
            [parentForm]="form"
            [fCName]="input.name"
            [type]="
              input.type === 'password'
                ? src[input.name].fieldText
                  ? 'text'
                  : 'password'
                : input.type
            "
            [options]="input.options"
          ></app-special-input-with-check>
          <div class="refSkeleton" *ngIf="input.options?.ref_skeleton">
            {{ input.options.ref_skeleton }}
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <app-input
            [parentForm]="form"
            [fCName]="input.name"
            [currency]="input.currency"
            [porcentOver]="input.porcentOver"
            [type]="
              input.type === 'password'
                ? src[input.name]?.fieldText
                  ? 'text'
                  : 'password'
                : input.type
            "
            [options]="input.options"
          ></app-input>
          <div class="refSkeleton" *ngIf="input.options?.ref_skeleton">
            {{ input.options.ref_skeleton }}
          </div>
          <div
            class="eyes"
            *ngIf="input.type === 'password'"
            (click)="toggleFieldTextType(input.name)"
          >
            <svg-icon [src]="src[input.name].src"></svg-icon>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>

<button
  class="show-all"
  *ngIf="options.slice && !(inputs?.length <= options.slice)"
  (click)="options.showMoreActive = !options.showMoreActive"
  data-cy="show-all"
>
  {{ (options.showMoreActive ? 'hide_' : 'show all_') | translate }}
</button>
