import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';

@Pipe({
  name: 'formatCurrencyPricesShow',
})
export class FormatCurrencyPricesShow implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: string, args?: string): string {
    let response = value;
    if (value) {
      const currencySymb = value.replace(/[0-9\.,]/g, '');
      const separator = getLocaleNumberSymbol(
        this.locale,
        NumberSymbol.CurrencyDecimal
      );

      let split: string[] = value.split(separator);
      const match = split[0].match(/^(\d+)\s/);
      if (match) {
        split[0] = match[1];
      }

      const sizeSymbol =
        split[0].length <= 3
          ? 'extraBigSize'
          : split[0].length === 5
            ? 'bigSize'
            : 'mediumSize';
      response = value.replace(
        currencySymb,
        `<small class="currency-symbol  ${sizeSymbol}" >` +
          currencySymb.trim() +
          '</small>'
      );
    }
    return response;
  }

  private hasNumber(value) {
    return /\d/.test(value);
  }
}
