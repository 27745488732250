import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FlightSearchForm } from '../../../../form-type/flight-search-form';

@Component({
  selector: 'app-flight-searchForm-calendar',
  templateUrl: './flight-searchForm-calendar.component.html',
  styleUrls: ['./flight-searchForm-calendar.component.scss'],
})
export class FlightSearchFormCalendarComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() transport: string = 'flight';
  @Input() multiDestinationIndex: string;
  @Output() newJourney = new EventEmitter<number>();

  today = moment();

  calendarControls = {
    initialDay: new FormControl(this.today.add(1, 'day').toDate(), [
      Validators.required,
    ]),
    finalDay: new FormControl(this.today.add(8, 'day').toDate(), []),
    originDayFlex: new FormControl(false, []),
    destinationDayFlex: new FormControl(false, []),
    daysFlex: new FormControl(false, []),
  };
  subscriptions: Subscription[] = [];

  tabIndexPrefix: string | null;
  daysFlex: boolean = false;
  originDayFlex: boolean = false;
  destinationDayFlex: boolean = false;
  constructor(private flightSearchForm: FlightSearchForm) {}

  ngOnInit() {
    this.tabIndexPrefix =
      this.multiDestinationIndex || this.multiDestinationIndex === '0'
        ? (parseInt(this.multiDestinationIndex) + 1).toString()
        : null;
    this.addControls();
    this.controlFinalDay();
    this.controlInitialDay();
    if (this.transport !== 'train') {
      this.controlDaysFlex();
    }
  }

  onFocus(event: Element) {
    this.form.get('forceCalendarOpen')?.setValue(event);
  }

  addControls() {
    for (const key of Object.keys(this.calendarControls)) {
      this.form.addControl(key, this.calendarControls[key]);
    }
  }

  controlInitialDay() {
    if (this.form.get('finalDay')) {
      this.subscriptions.push(
        this.form.get('finalDay')!.valueChanges.subscribe((val) => {
          if (this.form.get('initialDay')?.value > val) {
            this.form.get('initialDay')?.setValue(val);
          }
        })
      );
    }
  }

  controlFinalDay() {
    if (this.form.get('initialDay')) {
      this.subscriptions.push(
        this.form.get('initialDay')!.valueChanges.subscribe((val) => {
          if (this.form.get('finalDay')?.value < val) {
            this.form.get('finalDay')?.setValue(val);
          }
        })
      );
    }
  }

  changeTypeForm() {
    if (this.form.get('type') && this.form.get('type')?.value === 'one-way') {
      this.form.get('type')?.setValue('round-trip');
    }
    this.addJourney();
  }

  addJourney() {
    if (
      (this.form.get('type') &&
        this.form.get('type')?.value === 'multi-destination') ||
      this.multiDestinationIndex
    ) {
      this.newJourney.emit(parseInt(this.multiDestinationIndex) + 1);
    }
  }

  canAddMultiLine(): boolean {
    if (this.transport === 'train') {
      return true;
    }
    let response = true;
    if (this.flightSearchForm.getFormGroup().get('multiDestination')) {
      const multiFrom = this.flightSearchForm
        .getFormGroup()
        .get('multiDestination') as FormArray;
      if (multiFrom.at(4).enabled) {
        response = false;
      }
    }
    return response;
  }

  controlDaysFlex() {
    this.form.valueChanges.subscribe((val) => {
      this.originDayFlex = val.originDayFlex;
      this.destinationDayFlex = val.destinationDayFlex;
      this.daysFlex = val.daysFlex;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
