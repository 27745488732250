import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ModalBuilderService {
  renderer: Renderer2;
  dialogRef: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  openModal(component: any, optional?: any) {
    this.dialog.closeAll();

    this.dialogRef = this.dialog.open(component, {
      ...(optional.width && { width: optional.width }),
      ...(optional.id && { id: optional.id }),
      ...(optional.minWidth && { minWidth: optional.minWidth }),
      ...(optional.maxWidth && { maxWidth: optional.maxWidth }),
      ...(optional.maxHeight && { maxHeight: optional.maxHeight }),
      ...(optional.disableClose && { disableClose: optional.disableClose }),
    });
    setTimeout(() => {
      this.renderModal(optional);
    }, 500);
    return this.dialogRef;
  }

  protected renderModal(optional: any) {
    const div = this.renderer.createElement('div');
    if (optional?.buttonClose) {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'close');
      this.renderer.listen(button, 'click', (event) => {
        this.close();
      });
      this.renderer.appendChild(div, button);
    }

    this.renderer.addClass(div, 'header');
    this.renderer.insertBefore(
      document.getElementById(this.dialogRef.id),
      div,
      document.getElementById(this.dialogRef.id)?.firstChild
    );
  }

  close() {
    this.dialogRef.close();
  }
}
