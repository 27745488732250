import {Injectable, NgZone} from '@angular/core';
import { Observable } from 'rxjs';
import { ModalBuilderService } from 'src/app/core/services/modal/modal-builder.service';
import { AlertRefreshTokenComponent } from 'src/app/modules/application/transport/components/alert-refresh-token/alert-refresh-token.component';
import { JwtService } from '../../user/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class AlertRefreshTokenService {
  component: any;

  modalOptions = {
    width: '550px',
    height: '350px',
    disableClose: true,
  };

  constructor(
    private modalBuilder: ModalBuilderService,
    private jwt: JwtService,
    private zone: NgZone
  ) {}

  showModal(): void {
    if (this.jwt.getTokenExpirationDate()) {
      this.zone.run(() => {
        this.component = this.modalBuilder.openModal(
          AlertRefreshTokenComponent,
          this.modalOptions
        );
        this.subscribeButton();
      });
    }
  }

  subscribeButton(): Observable<any> {
    return this.component.componentInstance.buttonEmit;
  }

  closeModal() {
    if (this.component) {
      this.component.close();
    }
  }
}
