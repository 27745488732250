import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { BannersPlace } from 'src/app/shared/models/client.model';
import { CompanyService } from '../../../../../core/services/user/company.service';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { RouterService } from 'src/app/core/services/router/router.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void>;
  progress = '0%';
  company$: Observable<any>;
  listImgCarosuel: any[] = [];
  user: UserDataModel;
  passengers = 1;
  transporType: string;

  isCallCenter = false;

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private routeService: RouterService
  ) {
    this.company$ = this.companyService.companyData$;
    this.destroy$ = new Subject();
  }

  @Input() transport: string;
  // data loading search travel
  @Input() dataLoading: DataLoading;
  @Output() cancelModalWait = new EventEmitter();

  dataText: string[] = [];

  orig = '';
  dest = '';
  text = '';
  index = 0;
  timeOut: any;

  ngOnInit() {
    this.isCallCenter = this.userService.userIsCallCenter();
    this.initCurrentUser();
    this.getNewsAgency();
    this.getTransport();

    this.text = this.dataText[0];
    this.timeOut = setInterval(() => {
      this.initCarouselText();
    }, 5000);
  }

  getNewsAgency(): void {
    this.listImgCarosuel =
      this.userService.getNewsAgency()[BannersPlace.loading] ?? [];
  }

  initCarouselText(): void {
    if (this.index === this.dataText.length) {
      this.index = 0;
    }
    this.text = this.dataText[this.index];
    this.index++;
  }

  initCurrentUser(): void {
    this.userService
      .getCurrentUserObservable()
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => user)
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  getTransport() {
    this.dataText = [
      'loading.search_' + this.transport + '_',
      'loading.filters_',
      'loading.options_',
      'loading.one_second',
    ];
  }

  navigateToHome() {
    this.routeService.navigateToHome();
    if (this.transport === 'train') {
      this.cancelModalWait.emit();
    }
  }

  errorImg(event): void {
    event.target.src = this.user?.client?.lp;
  }

  ngOnDestroy() {
    if (this.timeOut) {
      clearInterval(this.timeOut);
    }
  }
}

export interface DataLoading {
  dataResult?: DataResult;
  multidestination?: DataResult[];
  type?: string;
  passengers?: number;
}
export interface DataResult {
  origin?: string;
  destination?: string;
  originIata?: string;
  destinationIata?: string;
  initialDay?: string;
  finalDay?: string;
}
