import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  private loadingValue = false;
  loadingStatus = new BehaviorSubject(false);

  get loading(): boolean {
    return this.loadingValue;
  }

  set loading(value) {
    this.loadingValue = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.loadingStatus.next(true);
    setTimeout(() => {
      this.stopLoading();
    }, 3000);
  }

  stopLoading() {
    this.loadingStatus.next(false);
  }
}
