import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tokenization',
})
export class TokenizationPipe implements PipeTransform {
  transform(value: string): string {
    return this.encode(window.btoa(value));
  }

  private encode(value: string): string {
    if (value.includes('=')) {
      value.replaceAll('=', '');
    }
    return value;
  }
}
