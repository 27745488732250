import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-carousel',
  templateUrl: './modal-carousel.component.html',
  styleUrls: ['./modal-carousel.component.scss'],
})
export class ModalCarouselComponent {
  @Input() listImgCarosuel: string[] = [];
  closedEmit = new EventEmitter<any>();

  closedNews() {
    this.closedEmit.emit('closed');
  }
}
