import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MarketService } from 'src/app/core/services/market/market.services';
import { RouterService } from '../../../../core/services/router/router.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-market',
  templateUrl: './select-market.component.html',
  styleUrls: ['./select-market.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMarketComponent implements OnInit {
  @Input() clientStaticData: any;
  marketForm: FormGroup = this.formBuilder.group({
    market: [''],
  });

  marketData: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private marketServices: MarketService,
    private routerService: RouterService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initMarketData();
  }

  initMarketData(): void {
    this.clientStaticData?.markets?.forEach((market) => {
      if (
        market?.client_type?.includes(
          this.routerService.getParamFromUrl('sib')?.toLocaleLowerCase()
        )
      ) {
        this.marketData.push({
          label:
            this.translate.instant(
              'language_market_flag.' + market?.flag?.toLowerCase()
            ) +
            ' (' +
            market.currency +
            ')',
          value: market.code,
          flag: market.flag,
        });
      }
    });
    const currentCode = this.routerService.getParamFromUrl('m');
    const currentMarket =
      this.marketData.find(
        (market) =>
          market.value.toLocaleLowerCase() === currentCode?.toLocaleLowerCase()
      ) ?? this.marketData[0];
    this.marketForm.controls['market'].setValue(currentMarket?.value);
    this.marketServices.setMarketSelected(currentMarket);
    this.agregarParametro(currentMarket?.value);
    this.changesSelector();
  }

  changesSelector(): void {
    this.marketForm.controls['market'].valueChanges.subscribe((res) => {
      this.agregarParametro(res);
      this.marketServices.setMarketSelected(res);
    });
  }

  agregarParametro(market): void {
    const section = this.routerService.getParamFromUrl('sib');
    const parametros = {
      m: market,
      sib: section,
    };
    this.routerService.addGetParamsToUrl(parametros);
  }
}
