<ng-container>
  <div class="items-container">
    <div class="header-info">
      <div class="title-box">
        <div class="title">{{ titleSection | translate }}</div>
        <div class="number-element" *ngIf="formName !== 'formOverItem'">
          {{
            totalElements === 0
              ? '0 ' + ('conditions_' | translate | lowercase)
              : totalElements > 1
              ? totalElements + ' ' + ('conditions_' | translate | lowercase)
              : totalElements + ' ' + ('condition_' | translate | lowercase)
          }}
        </div>
      </div>
      <!-- TODO -> Ver como sacarlo de este componente generico  -->
      <div *ngIf="formName === 'formOverItem'" class="overFormButtons">
        <button
          pRipple
          (click)="specialButtonCallFather('delete')"
          type="button"
          class="btn-primary-alternative delete"
          [ngClass]="{ disabled: editingItem }"
          [disabled]="editingItem"
        >
          <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
          {{ 'delete_' | translate }}
        </button>
        <button
          pRipple
          (click)="editItem(items[0])"
          type="button"
          class="btn-primary-alternative edit"
          [ngClass]="{ disabled: editingItem }"
          [disabled]="editingItem"
        >
          <svg-icon src="assets/images/icons/over_edit.svg"></svg-icon>
          {{ 'edit_' | translate }}
        </button>
        <!-- TODO: ACTIVAR -->
        <!-- <button
          pRipple
          (click)="specialButtonCallFather('clone')"
          type="button"
          class="btn-primary-alternative clone"
          [ngClass]="{ disabled: editingItem }"
          [disabled]="editingItem"
        >
          <svg-icon src="assets/images/icons/over_duplicate.svg"></svg-icon>
          {{ 'clone_' | translate }}
        </button> -->
      </div>
      <div
        *ngIf="items && items.length !== 0 && showAddButton"
        class="createNew"
      >
        <button
          pRipple
          (click)="createNewItem()"
          type="button"
          class="btn-primary-alternative"
          [ngClass]="{ disabled: editingItem || items.length >= 25 }"
          [disabled]="editingItem || items.length >= 25"
          [pTooltip]="maximunNumber"
          [tooltipDisabled]="items.length < 25"
          [escape]="false"
          tooltipPosition="top"
        >
          <svg-icon src="assets/images/icons/plus2.svg"></svg-icon>
          {{ 'create_' | translate }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="!items || items.length === 0">
      <div class="list-container">
        <div class="form-not-item">
          <app-fields-collection
            [class]="'form-' + 'class'"
            [form]="form"
            [inputs]="inputs"
            [inputsName]="inputsName"
          ></app-fields-collection>
        </div>

        <div class="actionButtons">
          <button
            [pTooltip]="'At least one condition must be saved_' | translate"
            [tooltipPosition]="'top'"
            class="delete disabled"
            [disabled]="true"
          >
            <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
          </button>
          <button
            [ngClass]="{ disabled: buttonsDisabled }"
            [disabled]="buttonsDisabled"
            pRipple
            (click)="saveItem()"
            type="button"
            class="btn-primary-outline"
          >
            {{ 'save_' | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="items && items.length !== 0">
      <div
        [ngClass]="{
          scroll_bar: scrollElement && !editingItem
        }"
        #focusScroll
      >
        <ng-container
          *ngFor="let item of items; index as itemIndex; last as last"
        >
          <div
            #elementoRef
            class="list-container"
            [ngClass]="{
            disabledItem:
              !item.active_element && !editingItem && tableName === 'overitem',
            lineSeparator: !last,
          }"
          >
            <ng-container *ngIf="!item.active">
              <div class="fieldsConfig">
                <ng-container *ngFor="let field of fieldsConfig; last as last">
                  <ng-container
                    *ngIf="
                      item[field.key] ||
                      item[field.key] === false ||
                      item[field.key] === 0
                    "
                  >
                    <div
                      class="field"
                      [ngClass]="{
                        isLast: last,
                        controlWidthInput: tableName !== 'overitem'
                      }"
                      *ngIf="field.key !== 'id'; else switch"
                    >
                      <p class="header" *ngIf="field.key !== 'delete'">
                        {{
                          field.label + '_' | translate | traductionTreatments
                        }}
                      </p>
                      <p
                        class="info"
                        *ngIf="
                          (item[field.key] ||
                            item[field.key] === false ||
                            item[field.key] === 0) &&
                          item[field.key] !== 'delete'
                        "
                      >
                        <ng-container
                          *ngIf="
                            item[field.key] === true &&
                            field.key === 'condition'
                          "
                        >
                          <svg-icon
                            class="iconsCheck"
                            [src]="'assets/images/icons/greenCheck.svg'"
                          >
                          </svg-icon>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            item[field.key] === false &&
                            field.key === 'condition'
                          "
                        >
                          <svg-icon
                            class="iconsCheck"
                            [src]="'assets/images/icons/aspa_red.svg'"
                          >
                          </svg-icon>
                        </ng-container>
                        <ng-cotainer *ngIf="field.key === 'condition'">
                          {{
                            (item[field.key] ? 'INC_' : 'EXC_')
                              | translate
                              | traductionTreatments
                          }}
                        </ng-cotainer>
                        <ng-cotainer *ngIf="field.key !== 'condition'">
                          {{
                            item[field.key] + '_'
                              | translate
                              | traductionTreatments
                          }}
                          <span
                            *ngIf="inputsWithPorcentIcon.includes(field.key)"
                            >%</span
                          >
                        </ng-cotainer>
                      </p>
                    </div>
                    <ng-template #switch>
                      <div class="box-id-switch">
                        <div>
                          <p class="header">{{ item[field.key] }}</p>
                        </div>
                        <div>
                          <app-switch-button
                            [item]="{
                              id: item.id,
                              value: item.active_element,
                              item: item,
                              isDisabled,
                              tooltipText: 'tooltip_reglas_over_switch_',
                              showTooltip: true
                            }"
                            [options]="{
                              colorClient: true
                            }"
                            (sendAction)="
                              activeOrNotActiveElementToParent($event)
                            "
                          ></app-switch-button>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
              <div class="actionButtons" *ngIf="formName !== 'formOverItem'">
                <button class="delete" (click)="deleteItem(item.id)">
                  <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
                </button>
                <button
                  class="edit"
                  (click)="editItem(item)"
                  [disabled]="editingItem"
                >
                  <svg-icon src="assets/images/icons/over_edit.svg"></svg-icon>
                  {{ 'edit_' | translate }}
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="item.active">
              <div class="fieldsConfig form">
                <div class="field" *ngIf="item.active">
                  <app-fields-collection
                    [class]="'form-' + 'class'"
                    [form]="form"
                    [inputs]="inputs"
                    [inputsName]="inputsName"
                  ></app-fields-collection>
                </div>
              </div>
              <!--(click)="updateItem(item.id)"-->
              <div class="actionButtons">
                <button class="delete" (click)="stopEditing(item, itemIndex)">
                  <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
                </button>
                <button
                  [ngClass]="{ disabled: buttonsDisabled }"
                  [disabled]="buttonsDisabled"
                  pRipple
                  (click)="saveItem(item.id)"
                  type="button"
                  class="btn-primary-outline"
                >
                  {{ 'save_' | translate }}
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="totals !== 0 && havePagination">
    <ngb-pagination
      (pageChange)="fetchMore($event)"
      [collectionSize]="totals"
      [pageSize]="pageSize"
      [rotate]="true"
      [(page)]="currentPage"
      [boundaryLinks]="true"
      aria-label="Default pagination"
    >
    </ngb-pagination>
  </ng-container>
</ng-container>
