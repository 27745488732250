<section
  *ngIf="!noInfo"
  class="sectionConditions"
  [ngClass]="{ notReady: !ready }"
>
  <ng-container *ngIf="ready; else loading">
    <ng-container
      *ngIf="
        (
          conditions?.solutions?.length > 0) ||
          (conditionData && conditionData.length > 0)
      "
    >
      <h2 *ngIf="title" class="title" data-cy="title-conditions">
        <span>
          <svg-icon src="assets/images/icons/bookOpen.svg"></svg-icon>
        </span>
        {{ 'conditions_' | translate }}
      </h2>
      <div class="conditions"  id="conditionsID" *ngIf="showJourneyButtons; else onlyOne">
        <p-tabView
          class="condition"
        >
          <ng-container *ngFor="let solution of conditions?.solutions; index as indexSolution">
            <p-tabPanel
              header="{{ ((indexSolution === 0 ? 'one way_' : 'return_')  | translate | uppercase) +  getOriginDestination(indexSolution) }}"
            >
              <ng-template pTemplate="content">
                <app-accordion
                  [tabContext]="propertyAccordition[indexSolution]"
                  [showBody]="true"
                ></app-accordion>
              </ng-template>
            </p-tabPanel>
          </ng-container>
        </p-tabView>
      </div>
      <ng-template #onlyOne>
        <app-accordion
          [tabContext]="propertyAccordition[0]"
          [showBody]="true"
        ></app-accordion>
      </ng-template>

      <button
        type="button"
        [ngClass]="{ modal: modal }"
        class="goUp"
        (click)="scrollToTop()"
      >
        <svg-icon src="assets/images/icons/buttonUp.svg"></svg-icon>
      </button>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="loadingBox">
      <app-loading></app-loading>
    </div>
  </ng-template>
</section>
<section
  *ngIf="noInfo"
  class="sectionConditionsError"
  [ngClass]="{ notReady: !ready, isModal: modal }"
>
  <div>
    <svg-icon
      class=""
      src="assets/images/icons/interrogation.svg"
      [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"
    ></svg-icon>
  </div>
  <div class="box-text-no-info">
    <strong class="mb-5 no-info">
      {{ 'requested_service_is_not_available_' | translate }}
    </strong>
    <p class="no-info">
      {{ 'contact_the_company_for_more_information_' | translate }}
    </p>
    <div *ngIf="errorMessage && errorMessage !== ''" class="error-reference">
      <span>
        {{ 'reference_' | translate }}
      </span>
      <span class="error"> {{ errorMessage }} </span>
    </div>
  </div>
</section>
