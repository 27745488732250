import {
  Component,
  Input,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { switchElement } from '../forms/fields/switch-button/switch-button.component';
type FieldsConfigItem = {
  label: string;
  key: string;
};
@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss'],
})
export class GenericListComponent {
  @Input() items: any;
  @Input() fieldsConfig: FieldsConfigItem[];
  @Input() title: string;
  @Input() total: any;
  @Input() currentPage: number;
  @Input() pageSize: number;
  @Input() showTitle = true;
  @ContentChild(TemplateRef) itemWrapper: TemplateRef<any>;
  @Output() selectedPage = new EventEmitter();
  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemEdit = new EventEmitter<any>();
  @Output() activeOrNotActiveElement = new EventEmitter<switchElement>();

  fetchMore(event: IPageInfo) {
    this.selectedPage.emit(event);
  }

  itemDelete(item) {
    this.itemDeleted.emit(item);
  }
  itemEdited(item) {
    this.itemEdit.emit(item);
  }
  activeOrNotActiveElementToParent(data: switchElement) {
    data.value = !data.value;
    this.activeOrNotActiveElement.emit(data);
  }
}
