import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unserializeAirlines'
})
export class UnserializeAirlinesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const regex = /"([^"]+)"/g;
    if (value !== undefined) {
       const companies = value.match(regex);
       if ( companies !== undefined && companies !== null ) {
              return companies.join().replace(/"/g, '').replace(',', '-');
       } else {
          return '';
       }
    } else {
       return '';
    }
  }

}
