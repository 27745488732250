// Servicio para manejar los token de JWT
// si hay que modificar el modo de almacenamiento
// sólo lo tendremos que hacer desde aquí.
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from '../utils/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  refreshTokenExpirationDateKey = 'rtf';
  refreshTokenAlertTime = 10 * 60;
  tokenAlertTime = 5 * 60;
  public connectedUserToken$ = new BehaviorSubject<string>('');

  // constructor
  constructor(
    private jwtHelper: JwtHelperService,
    private storageService: StorageService
  ) {
    let self = this;
    window.addEventListener(
      'storage',
      function (e) {
        function parseJwt(token) {
          if (!token || token === '') {
            return '';
          }
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(
            window
              .atob(base64)
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
          );
          return JSON.parse(jsonPayload);
        }

        if (e.key === 'currentUser') {
          const newValue = JSON.parse(e.newValue ?? '{}');
          const oldValue = JSON.parse(e.oldValue ?? '{}');
          if (
            (newValue?.a?.i &&
              oldValue?.a?.i &&
              newValue.a.i !== oldValue.a.i) ||
            (newValue?.c?.i &&
              oldValue?.c?.i &&
              newValue.c.i !== oldValue.c.i) ||
            (newValue?.c?.ci &&
              oldValue?.c?.ci &&
              newValue.c.ci !== oldValue.c.ci)
            //self.getToken() &&
            //!self.isTokenExpired()
          ) {
            setTimeout(() => {
              location.reload();
            }, 1500);
          }
        }
        if (e.key === 'token') {
          const newValue = parseJwt(e.newValue);
          const oldValue = parseJwt(e.oldValue);
          if (!newValue.id || !oldValue.id || newValue.id !== oldValue.id) {
            setTimeout(() => {
              location.reload();
            }, 1500);
          }
        }
      },
      false
    );
  }

  decodeToken(token) {
   return this.storageService.decodeToken(token);
  }

  // get token from localStorage
  getToken(): string {
    return localStorage.getItem('token')!;
  }

  // set token into localStorage
  setToken(token: string): void {
    const refreshTokenExpirationDate = this.storageService.utf8_to_b64(
      this.calculateRefreshTokenExpirationDate(token)
    );
    this.storageService.setItem(
      this.refreshTokenExpirationDateKey,
      refreshTokenExpirationDate
    );
    localStorage.setItem('token', token);
    this.connectedUserToken$.next(token);
  }

  // get refresh token
  getRefreshToken(): string {
    return localStorage.getItem('refresh_token')!;
  }

  // set refresh token
  setRefreshToken(token: string): void {
    localStorage.setItem('refresh_token', token);
  }

  // is token expired.
  isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.getToken());
  }

  // is refresh token expired
  isRefreshTokenExpired(): boolean {
    if (this.getRefreshTokenExpirationDate()) {
      return this.getRefreshTokenExpirationDate() < new Date();
    }
    return false;
  }

  isRefreshTokenAlmostExpired(): boolean {
    if (this.getRefreshTokenExpirationDate()) {
      return (
        new Date(
          this.getRefreshTokenExpirationDate().getTime() -
            this.refreshTokenAlertTime * 1000
        ) < new Date()
      );
    }
    return false;
  }

  isTokenAlmostExpired() {
    if (this.getTokenExpirationDate()) {
      return (
        new Date(
          this.getTokenExpirationDate()!.getTime() - this.tokenAlertTime * 1000
        ) < new Date()
      );
    }
    return false;
  }

  // get token expiration date
  getTokenExpirationDate() {
    return this.jwtHelper.getTokenExpirationDate(this.getToken());
  }

  getRefreshTokenExpirationDate(): Date {
    const key = this.storageService.getItem(this.refreshTokenExpirationDateKey);
    if (key) {
      return new Date(this.storageService.b64_to_utf8(key));
    } else {
      return null!;
    }
  }

  calculateRefreshTokenExpirationDate(token) {
    const diff =
      (this.jwtHelper.getTokenExpirationDate(token)!.getTime() -
        new Date()?.getTime()) *
      2;
    return new Date(new Date()?.getTime() + diff);
  }
}
