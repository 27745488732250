<div
  id="header-modal-tour"
  data-cy="header-modal-tour"
  class="col-12 pr-0 header-modal"
>

  <div
    *ngIf="!init"
    [ngClass]="{ hidden: sliderPosition >= infoViewCont.length - 1 }"
    class="pr- pl-0 col-11 display-sliders"
  >
    <div
      *ngFor="let init of infoViewCont; last as last"
      class="slider"
      [ngClass]="{ visited: init.id <= sliderPosition, hidden: last }"
    ></div>
  </div>
  <div *ngIf="!init" class="col-1 closed-button">
    <button
      type="button"
      class="close"
      data-cy="close"
      (click)="closedTutorialAndCleanStyles()"
    >
      <svg-icon
        src="assets/images/icons/close.svg"
        [svgStyle]="{ 'width.px': 14, 'heigth.px': 14 }"
      ></svg-icon>
    </button>
  </div>
</div>
<section class="section-modal-welcome pt-0">
  <div *ngIf="!init" class="box-container">
    <div class="mt-2">
      <p class="title-modal-welcome">{{ selectSlider.title }}</p>
      <p class="description-modal-welcome" *ngIf="infoViewCont">
        {{ selectSlider.description }}
      </p>
      <div class="box-links" *ngIf="sliderPosition === infoViewCont.length - 1">
        <ng-container
          *ngFor="
            let option of infoViewCont[sliderPosition].options;
            index as index
          "
        >
          <p
            class="links-actions"
            data-cy="links-actions"
            *ngIf="index > 1"
            (click)="action(option.id)"
          >
            {{ option.text }}
            <svg-icon
              class="ml-2 icons-actions"
              src="assets/images/icons/camera.svg"
            ></svg-icon>
          </p>
        </ng-container>
      </div>
      <div class="button-navegated">
        <div>
          <button
            pRipple
            *ngIf="sliderPosition < infoViewCont.length - 1"
            [ngClass]="{ hidden: sliderPosition <= 0 }"
            type="button"
            class="btn-back"
            data-cy="btn-grey-welcome"
            (click)="beforeTutorialInstruction(index)"
          >
            {{ 'buttons-navegated-tour_.before_' | translate }}
          </button>
        </div>
        <div>
          <button
            pRipple
            *ngIf="sliderPosition < infoViewCont.length - 1"
            type="button"
            class="btn-primary"
            data-cy="btn-primary-welcome"
            (click)="nextTutorialInstruction(index)"
          >
            {{ 'buttons-navegated-tour_.next_' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="init" class="box-container">
    <div class="mt-5">
      <p class="title-modal-welcome-init" *ngIf="optional.title">
        {{ optional.title | translate }}
      </p>
      <p class="description-modal-welcome-init" *ngIf="infoViewCont">
        {{ optional.text | translate }}
      </p>
      <div class="box-plane">

        <div class="box-image">

          <svg-icon

            src="assets/images/images-generic/bienvenida_illu.svg"

          />
        </div>
        <!-- <div class="circle"></div> -->
<div class="buttons">
          <button
            type="button"
            class="btn-primary-alternative"
            data-cy="close"
            (click)="closedTutorialAndCleanStyles()"
          >
          {{ 'dont show again_' | translate }}
          </button>
           <button
            pRipple
            class="btn-primary"
            data-cy="btn-primary-welcome"
            (click)="initTutorial()"
          >
            {{ 'welcome_tour_button_init_' | translate }}
          </button>
        </div>

      </div>
    </div>
  </div>
</section>
