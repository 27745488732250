import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-conditions-ib',
  templateUrl: './conditions-ib.component.html',
  styleUrls: ['./conditions-ib.component.scss']
})
export class ConditionsIBComponent {
  @Input() data: any;
}
