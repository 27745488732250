import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-special-input-with-check',
  templateUrl: './special-input-with-check.component.html',
  styleUrls: ['./special-input-with-check.component.scss'],
})
export class SpecialInputWithCheckComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'text';
  @Input() options: any = {};

  checked = false;

  ngOnInit(): void {
    this.checked =
      this.options.isCreateView ||
      (this.options.checkboxValue &&
        (this.parentForm.get(this.fCName)?.value ||
          this.parentForm.get(this.fCName)?.value === 0));

    if (!this.checked) {
      this.disableForm();
    }
  }

  checkboxControl(): void {
    if (!this.checked) {
      this.enableForm();
    } else {
      this.disableForm();
    }

    if (
      Object.keys(this.parentForm.controls).filter(
        (control) =>
          control !== 'errors' && !this.parentForm.get(control)?.disabled
      ).length === 0
    ) {
      this.enableForm();
    }

    this.parentForm.get(this.fCName)?.updateValueAndValidity();
  }

  disableForm(): void {
    this.parentForm.get(this.fCName)?.disable();
    this.parentForm.get(this.fCName)?.removeValidators([Validators.required]);
    this.parentForm.get('errors')?.setValue(false);
    this.checked = false;
  }

  enableForm(): void {
    this.parentForm.get(this.fCName)?.enable();
    this.parentForm.get(this.fCName)?.addValidators([Validators.required]);
    this.parentForm.get('errors')?.setValue(true);
    this.checked = true;
  }
}
