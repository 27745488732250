import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiHttpService } from '../../http/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  marketSelected$: Subject<any> = new Subject();
  constructor(private apiHttp: ApiHttpService, private http: HttpClient) {}

  setMarketSelected(res) {
    this.marketSelected$.next(res);
  }

  onChangesMarketSelector(): Observable<any> {
    return this.marketSelected$.asObservable();
  }
}
