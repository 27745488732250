import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorsService } from '../services/erros/errors.service';
import { StorageService } from '../services/utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private errorsService: ErrorsService,
    private storageService: StorageService,
    private router: Router
  ) {}

  handleError(error: Error) {
    this.zone.run(
      () => null,
      this.errorsService.registerError(
        error,
        this.storageService.getItem('token')!,
        this.router.url
      )
    );
  }
}
