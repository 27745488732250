<div>
  <h1>
    {{options.header | translate}}
  </h1>

  <p>
    {{options.content | translate}}
  </p>
  <svg-icon *ngIf="options.src" [src]="options.src"></svg-icon>
</div>
