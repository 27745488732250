import { ErrorHandler, NgModule } from '@angular/core';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { SharedModule } from '../shared/shared.module';
import { GlobalErrorHandler } from './erros/global-error-handler';
import { environment } from 'src/environments/environment';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: 'wss://socket.lleego.es/?ch=consolidador10', options: { transports: ["websocket"] } };

@NgModule({
  declarations: [
    LoadingScreenComponent
  ],
  imports: [
    SharedModule,
    SocketIoModule.forRoot(config)
  ],
  exports: [
    LoadingScreenComponent
  ],
  providers: errorHandlerProd()
})
export class CoreModule { }

export function errorHandlerProd() {
  if (environment.env !== 'dev' && environment.env !== 'staging') {
    return [{
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }];
  }
  return [];
}
