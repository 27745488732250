import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/core/services/user/company.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit, OnDestroy {
  @Input() isRailApp = false;

  formLanguage: FormGroup = this.formBuilder.group({
    language: [''],
  });
  dataLangs: any[];
  isCallCenter = false;
  subscriptions: Subscription[] = [];
  icons = this.languageService.icons;
  clientStaticData: any;

  constructor(
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService
  ) {
    this.initIsCallCenter();
    if (this.languageService.langs) {
      this.languageControl(this.languageService.langs);
    }
  }

  initIsCallCenter(): void {
    this.isCallCenter = this.userService.userIsCallCenter();
  }

  languageControl(language: any[]): void {
    this.dataLangs = language.map((lang: any) => {
      return {
        label: lang.label,
        value: lang.value,
        ...(this.icons[lang.value?.split('-')[1]] && {
          icon: this.icons[lang.value?.split('-')[1]],
        }),
      };
    });
    this.formLanguage.controls['language'].setValue(
      localStorage.getItem('lang') ?? this.languageService.translate.currentLang
    );
  }

  ngOnInit(): void {
    this.initChangeLanguage();
  }

  initChangeLanguage(): void {
    if (this.formLanguage.get('language')) {
      this.subscriptions.push(
        this.formLanguage.get('language')!.valueChanges.subscribe((res) => {
          this.languageService.changeLanguage(res);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
