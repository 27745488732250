<section class="sectionGenericPriceBreakdown page-block">
  <div class="title">
    <h2

      data-cy="priceBreakdown"
      class="price-breakdown-title"
    >
      {{ 'priceBreakdown_' | translate }}
    </h2>
    <div class="separator-currency"></div>
    <p class="currency">
      {{ 'currency_' | translate | titlecase }}
      <span class="currency-code">{{
          refundData.currency | uppercase
        }}</span>
    </p>
  </div>

  <table class="tablePriceBreakdown">
    <tr>
      <th>
        <h2 class="first">{{ 'paxes_' | translate }}</h2>
      </th>
      <th>
        <h2>{{ 'total trip_' | translate }}</h2>
      </th>
      <th>
        <h2>{{ 'refund cost_' | translate }}</h2>
      </th>
      <th>
        <h2>{{ 'to refund_' | translate }}</h2>
      </th>
    </tr>

    <ng-container *ngIf="refundData.byPassengers; else allPax">
      <ng-container *ngFor="let refund of refundData.byPassengers">
        <tr class="name">
          <td>
            <p class="first">
              {{ refund.name + ' ' + refund.surname }}
              <span class="paxType">{{ refund.paxType | translate }}</span>
            </p>
          </td>

          <td>
            <p *ngIf="refund && refund.hasOwnProperty('original')">
              {{ refund.original}}
            </p>
          </td>
          <td>
            <p *ngIf="refund && refund.hasOwnProperty('penalty_amount')">
              {{ refund.penalty_amount}}
            </p>
          </td>
          <td>
            <p
              class="total"
              *ngIf="refund && refund.hasOwnProperty('offer_amount')"
            >
              {{ refund.offer_amount}}
            </p>
          </td>
        </tr>
        <tr
          class="taxe"
          *ngIf="refund && refund.hasOwnProperty('no_refundable_amount')"
        >
          <td class="tax">
            <p class="first">
              {{ 'fee_rate_noRefundable_' | translate }}
            </p>
          </td>
          <td class="tax"><p></p></td>
          <td class="tax">
            <p>
              {{
                refund.no_refundable_amount

              }}
            </p>
          </td>

          <td class="tax last"><p></p></td>
        </tr>
        <tr class="taxe" *ngIf="refund && refund.hasOwnProperty('penalty')">
          <td class="tax one d-flex">
            <div
              [pTooltip]="'tooltip_penalty_refund_' | translate"
              [tooltipPosition]="'top'"
              [tooltipStyleClass]="'tooltipPenalty'"
              [escape]="false"
              class="help"
            >
              <svg-icon src="assets/images/icons/info.svg"></svg-icon>
            </div>
            <p class="first">
              {{ 'penalty_' | translate }}
            </p>
          </td>
          <td class="tax"><p></p></td>
          <td class="tax">
            <p>{{ refund.penalty }}</p>
          </td>

          <td class="tax last"><p></p></td>
        </tr>
        <tr class="taxe" *ngIf="refund.fee_refund">
          <td class="tax one">
            <p class="first">
              {{ 'fee_refund_' | translate }}
            </p>
          </td>
          <td class="tax"><p></p></td>
          <td class="tax">
            <p>
              {{ refund.fee_refund }}
            </p>
          </td>

          <td class="tax last"><p></p></td>
          <td></td>
        </tr>
        <tr
          class="taxe"
          *ngIf="
            refund.total_supplements &&
            refund.hasOwnProperty('total_supplements')
          "
        >
          <td class="tax one">
            <p class="first">
              {{ 'total_supplements_' | translate }}
            </p>
          </td>
          <td class="tax"><p></p></td>
          <td class="tax"><p></p></td>
          <td class="tax last">
            <p>
              {{
                refund.total_supplements
              }}
            </p>
          </td>

          <td></td>
        </tr>
      </ng-container>
    </ng-container>
    <ng-template #allPax>
      <ng-container>
        <ng-container>
          <tr class="name">
            <td>
              <p class="first">
                {{ 'every_' | translate }}
              </p>
            </td>

            <td>
              <p *ngIf="refundData && refundData.hasOwnProperty('original')">
                {{ refundData.original }}
              </p>
            </td>
            <td>
              <p
                *ngIf="
                  refundData && refundData.hasOwnProperty('penalty_amount')
                "
              >
                {{
                  refundData.penalty_amount

                }}
              </p>
            </td>
            <td>
              <p
                class="total"
                *ngIf="refundData && refundData.hasOwnProperty('offer_amount')"
              >
                {{
                  refundData.offer_amount
                }}
              </p>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-template>
  </table>
  <div class="totalPrice" [ngClass]="{ marginTop: refundData.byPassengers }">
    <div class="d-flex">
      <div>
        <p class="total">
          {{ 'total to refund_' | translate }}
          <span
            [innerHTML]="
              refundData.offer_amount
                | currencyCustom : refundData.currency : 'symbol-narrow' : '0.0'
                | divideDecimal : refundData.currency
            "
          >
          </span>
        </p>
      </div>
    </div>
  </div>
</section>
