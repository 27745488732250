import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ModalButtons,
  ModalIcon,
} from '../modal-options/modal-options.component';
import { baseUrlImgProvidersFavicon } from 'src/app/core/configurations/configurations';

@Component({
  selector: 'app-modal-errors-multiproviders',
  templateUrl: './modal-errors-multiproviders.component.html',
  styleUrls: ['./modal-errors-multiproviders.component.scss'],
})
export class ModalErrorsMultiproviders {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() ListBookings: any[] = [];
  @Input() buttons: ModalButtons;
  @Input() icon: ModalIcon;
  @Output() closedEmit = new EventEmitter<any>();

  emitInfo(event) {
    this.closedEmit.emit(event);
  }
  protected readonly baseUrlImgProvidersFavicon = baseUrlImgProvidersFavicon;
}
