import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-check-special',
  templateUrl: './input-check-special.component.html',
  styleUrls: ['./input-check-special.component.scss'],
})
export class InputCheckSpecialComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() name: string;
  @Input() value: string;
  @Input() options: any = {};
  @Input() inputs: any;

  onChanges($event, value) {
    for (const input of this.inputs) {
      if (input.name === this.fCName) {
        this.parentForm
          .get(this.fCName)
          ?.setValue($event.target.checked ? value : null, {
            emitEvent: this.options.emit ? this.options.emit : false,
          });
      } else {
        this.parentForm.get(input.name)?.setValue(null, {
          emitEvent: this.options.emit ? this.options.emit : false,
        });
      }
    }
  }
}
