<!-- section header -->
<div class="header" id="last-search-title">
  <h1>{{ 'last_queries_' | translate }}</h1>
</div>
<section>
  <ul
    *ngIf="
      lastQueries && lastQueries.length > 0;
      else loadingAndErrorAndNoResult
    "
    class="items"
  >
    <ng-container
      *ngFor="
        let lastQuery of lastQueries;
        first as first;
        last as last;
        index as index
      "
    >
      <li class="item"
          *ngIf="index < pageSize"
          [ngClass]="{ first: first, last: last }"
      >
      <app-last-query
        [lastQuery]="lastQuery"
        [first]="first"
        [last]="last"
        (lastQueryNavigate)="lastQueryNavigate($event)"
      ></app-last-query>
      </li>
    </ng-container>
  </ul>

  <!--Template error and loading -->
  <ng-template #loadingAndErrorAndNoResult>
    <!-- loading -->
    <app-loading *ngIf="loading"></app-loading>
    <!-- error -->
    <div *ngIf="!loading && searchErrorList" class="response-error">
      <app-general-error-text
        [showTryAgainButton]="true"
        (tryAgain)="tryAgain($event)"
      ></app-general-error-text>
    </div>
    <!-- No result -->
    <div *ngIf="!loading && !searchErrorList" class="error">
      <app-error-last-search
        [textDescription]="'no_last_queries_'"
        [isCallCenter]="true"
      ></app-error-last-search>
    </div>
  </ng-template>
</section>
