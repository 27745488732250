<ng-container [formGroup]="parentForm">
  <div [class]="fCName" [ngClass]="{ noLabel: options.fixLabel }">
    <label
      [ngClass]="{ active: active }"
      class="otherLabel"
      [id]="options.id + 'Label'"
      *ngIf="options.fixLabel"
      >{{ options.placeholder | translate }}
    </label>
    <p-dropdown
      (onShow)="show()"
      (onHide)="hide()"
      [options]="data"
      [class]="options.class"
      [formControlName]="fCName"
      [id]="options.id"
      [virtualScroll]="options.phone"
      placeholder="{{ options.placeholder | translate }}"
      [ngClass]="{
        error:
          options.showErrors &&
          parentForm.get(fCName)?.errors &&
          !parentForm.get(fCName)?.value
      }"
      [required]="parentForm.get(fCName)?.hasError('required')"
      [attr.data-cy]="fCName"
    >
      <ng-template pTemplate="item" let-item *ngIf="options.suffixDate">
        <div class="flex align-items-center suffix-box">
          <span>{{ item.label }}</span>
          <span class="suffix">{{ item.suffix }}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="selectedItem" let-item *ngIf="options.suffixDate">
        <div class="suffix-box">
          <span>{{ item.label }}</span>
          <span class="suffix">{{ item.suffix }}</span>
        </div>
      </ng-template>

      <ng-template
        pTemplate="item"
        let-item
        *ngIf="options.noTranslate && !options.suffixDate"
      >
        {{ options.phone ? item.name[locale] + ' +' + item.value : item.label }}
      </ng-template>
      <ng-template
        pTemplate="item"
        let-item
        *ngIf="!options.noTranslate && !options.suffixDate"
      >
        <svg-icon
          *ngIf="item?.icon"
          [src]="'assets/images/icons/' + item.icon + '.svg'"
        ></svg-icon>
        <p>
          {{ getItem(item, options) | translate }}
          <ng-container *ngIf="item.suffix">
            <span>{{ item.suffix }}</span>
          </ng-container>
        </p>
      </ng-template>
      <ng-template
        pTemplate="selectedItem"
        let-item
        *ngIf="options.noTranslate && !options.suffixDate"
      >
        {{ options.phone ? item.label + ' +' + item.value : item.label }}
      </ng-template>
      <ng-template
        pTemplate="selectedItem"
        let-item
        *ngIf="!options.noTranslate && !options.suffixDate"
      >
        <svg-icon
          *ngIf="item?.icon"
          [src]="'assets/images/icons/' + item.icon + '.svg'"
        ></svg-icon>
        {{
          (options.phone ? item.label + ' +' + item.value : item.label)
            | translate
        }}
      </ng-template>
    </p-dropdown>
  </div>
</ng-container>
<app-errors-fields
  class="select"
  [errors]="parentForm.controls[fCName].errors"
  [show]="options.showErrors"
>
</app-errors-fields>
