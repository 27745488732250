<ng-container>
  <div *ngIf="showTitle" class="list-form">
    <h2>{{ title | translate }}</h2>
  </div>

  <div class="items-container">
    <div class="list-header">
      <div *ngFor="let field of fieldsConfig">
        <ng-container *ngIf="field.key !== 'edit'">
          <h3>{{ field.label | translate }}</h3>
        </ng-container>
      </div>
    </div>
    <ng-container *ngFor="let item of items; last as last">
      <div
        class="list-container"
        [ngClass]="{ disabledItem: !item.active_element && title === 'overs_' }"
      >
        <ng-container *ngIf="title === 'overs_'">
          <div *ngFor="let field of fieldsConfig; last as last">
            <p
              *ngIf="item[field.key] !== 'delete' && item[field.key] !== 'edit'"
            >
              {{ item[field.key] }}
            </p>
            <ng-container
              *ngIf="
                field.key === 'id' && item.hasOwnProperty('active_element')
              "
            >
              <app-switch-button
                [options]="{ disabled: item.active_element, colorClient: true }"
                [item]="{
                  id: item.id,
                  value: item.active_element,
                  item: item
                }"
                (sendAction)="($event)"
              ></app-switch-button>
            </ng-container>
            <div class="edit" (click)="itemEdited(item)">
              <button
                pRipple
                type="button"
                class="btn-primary-alternative"
                *ngIf="last && item[field.key] === 'edit'"
              >
                <svg-icon [src]="field.icon"></svg-icon>
                {{ 'edit_' | translate }}
              </button>
            </div>
            <div class="delete" (click)="itemDelete(item)">
              <svg-icon
                *ngIf="last && item[field.key] === 'delete'"
                src="assets/images/icons/delete-bin.svg"
              ></svg-icon>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="title === 'configured_quarantines_'">
          <div *ngFor="let field of fieldsConfig; last as last">
            <p *ngIf="item[field.key] !== 'delete'">{{ item[field.key] }}</p>
            <div class="delete" (click)="itemDelete(item)">
              <svg-icon
                *ngIf="last && item[field.key] === 'delete'"
                src="assets/images/icons/delete-bin.svg"
              ></svg-icon>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="title === 'logs_'">
          <div *ngFor="let field of fieldsConfig">
            <p>{{ item[field.key] }}</p>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="total !== 0">
    <ngb-pagination
      (pageChange)="fetchMore($event)"
      [collectionSize]="total"
      [pageSize]="pageSize"
      [rotate]="true"
      [maxSize]="8"
      [(page)]="currentPage"
      [boundaryLinks]="true"
      aria-label="Default pagination"
    >
    </ngb-pagination>
  </ng-container>
</ng-container>
