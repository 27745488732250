import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent {
  @Input() literal: string;
  @Input() options: any = {};

  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
